import React from 'react';

import './elementHighlight.css';

const ElementHighlight = ({element=''}) => {
    let highlightMarkup;
    switch(element) {
        case 'plans':
            highlightMarkup = <svg width="229" height="270" viewBox="0 0 229 270" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_ddd_16:8)"> <path d="M210 17.5L19 95.5V250L210 173V17.5Z" stroke="#3E5DFF" strokeWidth="5" strokeLinejoin="bevel" shapeRendering="crispEdges" /> </g> <defs> <filter id="filter0_ddd_16:8" x="0.5" y="0.185547" width="228" height="269.133" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"> <feFlood floodOpacity="0" result="BackgroundImageFix" /> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /> <feOffset dy="1" /> <feGaussianBlur stdDeviation="8" /> <feComposite in2="hardAlpha" operator="out" /> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0" /> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16:8" /> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /> <feOffset dy="1" /> <feGaussianBlur stdDeviation="8" /> <feComposite in2="hardAlpha" operator="out" /> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0" /> <feBlend mode="normal" in2="effect1_dropShadow_16:8" result="effect2_dropShadow_16:8" /> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /> <feOffset dy="1" /> <feGaussianBlur stdDeviation="8" /> <feComposite in2="hardAlpha" operator="out" /> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0" /> <feBlend mode="normal" in2="effect2_dropShadow_16:8" result="effect3_dropShadow_16:8" /> <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_16:8" result="shape" /> </filter> </defs></svg>;
            break;

        case 'images':
            highlightMarkup = <svg width="226" height="253" viewBox="0 0 226 253" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_ddd_16:4)"> <path d="M207.5 92L18.5 17.5V156.5L207.5 232.947V92Z" stroke="#3E5DFF" strokeWidth="5" strokeLinejoin="bevel" shapeRendering="crispEdges"/> </g> <defs> <filter id="filter0_ddd_16:4" x="0" y="0.174316" width="226" height="252.09" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"> <feFlood floodOpacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16:4"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="effect1_dropShadow_16:4" result="effect2_dropShadow_16:4"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="effect2_dropShadow_16:4" result="effect3_dropShadow_16:4"/> <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_16:4" result="shape"/> </filter> </defs> </svg>;
            break;

        case 'documents':
            highlightMarkup = <svg width="253" height="441" viewBox="0 0 253 441" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_ddd_16:12)"> <path d="M19 414.5L19.5 415.5V85.5L187.5 17.5L234 37L234.5 400L182.5 421L181.5 418.5" stroke="#3E5DFF" strokeWidth="5" strokeLinejoin="bevel"/> </g> <defs> <filter id="filter0_ddd_16:12" x="0.763672" y="0.182617" width="252.236" height="440.135" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"> <feFlood floodOpacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16:12"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="effect1_dropShadow_16:12" result="effect2_dropShadow_16:12"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="effect2_dropShadow_16:12" result="effect3_dropShadow_16:12"/> <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_16:12" result="shape"/> </filter> </defs> </svg>;
            break;

        case 'overview':
            highlightMarkup = <svg width="109" height="106" viewBox="0 0 109 106" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_ddd_16:9)"> <path d="M18.5 75.5V78L61.5 86.5L91 61.5V17L61.5 42V54L45.5 52L18.5 75.5Z" stroke="#3E5DFF" strokeWidth="4" strokeLinejoin="bevel" shapeRendering="crispEdges"/> </g> <defs> <filter id="filter0_ddd_16:9" x="0.5" y="0.474121" width="108.5" height="104.988" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"> <feFlood floodOpacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16:9"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="effect1_dropShadow_16:9" result="effect2_dropShadow_16:9"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="1"/> <feGaussianBlur stdDeviation="8"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/> <feBlend mode="normal" in2="effect2_dropShadow_16:9" result="effect3_dropShadow_16:9"/> <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_16:9" result="shape"/> </filter> </defs> </svg>;
            break;

        case 'map':
            highlightMarkup = <svg width="135" height="73" viewBox="0 0 135 73" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_ddd_102_4)"><path d="M47.5 16.5L17 34.5L87.5 54.5L118 36.5L47.5 16.5Z" stroke="#3E5DFF" stroke-width="3" stroke-linejoin="bevel" shape-rendering="crispEdges"/></g><defs><filter id="filter0_ddd_102_4" x="0.237671" y="0.0571289" width="134.525" height="72.8857" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="8"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_102_4"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="8"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_dropShadow_102_4" result="effect2_dropShadow_102_4"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="8"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0.320833 0 0 0 0 0.429922 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect2_dropShadow_102_4" result="effect3_dropShadow_102_4"/><feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_102_4" result="shape"/></filter></defs></svg>;
            break;
    }
    return(
        <div className={`elementHighlight --${element}`}>
        {highlightMarkup}
        </div>
    )
}

export default ElementHighlight;