import React from 'react';
import './elementLabel.css';

const ElementLabel = ({text, element}) => {
    return (
        <div className={`elementLabel__container --${element}`}>
            <div className="elementLabel__text">
                {text}
            </div>
            <div className="elementLabel__triangle"></div>
        </div>
    )

}
export default ElementLabel;