import React, {useState, useEffect} from "react";
import Divider from "src/components/shared/divider";
import FormGroup from "src/components/shared/formGroup";
import RadioInput from "src/components/shared/inputs/radioInput";
import MultiSelect from "src/components/shared/inputs/multiSelect";
import NumberInput from "src/components/shared/inputs/numberInput";
import { useOvermind } from "src/overmind";
import "../steps.scss";
import BackNextBar from "src/components/shared/backNextBar";
import FileInput from "src/components/shared/inputs/fileInput";
import { checkPublicId, convertUrltoFile } from "src/utils/fileUpload";
import LoadingBar from "src/components/shared/loadingBar";
import Modal from "src/components/shared/modal";
import { validateFileInput, validateTextInput, validateNumberInput } from "src/utils/fieldValidation";

const buildingInfoFields = [
    'common_spaces', 'project_amenities', 'parking_type', 'electric_parking',
    'electric_parking_price', 'parking_price_included', 'extra_parking_fee_type', 'extra_int_fee',
    'extra_ext_fee', 'visitor_parking', 'street_parking',
    'parking_with_permit', 'locker_price_included', 'extra_locker_fee_type',
    'extra_locker_fee', 'structure', 'exterior_features',
    'roof', 'leed', 'heritage_building', 
    'policies_dog_tolerance', 'policies_dog_weight', 'policies_cat_tolerance',
    'policies_smoking_tolerance', 'policies_bbq_tolerance', 'policies_credit_check',
    'policies_criminal_check', 'documents'
];

const BuildingInformation = ({}) => {

    const {state, actions, effects} = useOvermind();
    const [showDocumentsLoaderBar, setShowDocumentsLoaderBar] = useState(false);
    const [isDocumentsLoaded, setIsDocumentsLoaded] = useState(false);
    const [uploadingText, setUploadingText] = useState("");
    const [stepFields, setStepFields]= useState({
        parking_type: {type: 'text', error: true},
        parking_price_included: {type: 'text', error: true},
        electric_parking: {type: 'text', error: true},
        visitor_parking: {type: 'text', error: true},
        street_parking: {type: 'text', error: true},
        parking_with_permit: {type: 'text', error: true},
        locker_price_included: {type: 'text', error: true},
        leed: {type: 'text', error: true},
        heritage_building: {type: 'text', error: true},
        policies_dog_tolerance: {type: 'text', error: true},
        policies_cat_tolerance: {type: 'text', error: true},
        policies_smoking_tolerance: {type: 'text', error: true},
        policies_bbq_tolerance: {type: 'text', error: true},
        ... state.productForm.newProduct.fields.parking_price_included.value === 'no' ? {extra_parking_fee_type: {type: 'text', error: true}} : {},
        ... state.productForm.newProduct.fields.parking_type.value === 'interiorGarage' || state.productForm.newProduct.fields.parking_type.value === 'interiorAndExterior' && state.productForm.newProduct.fields.parking_price_included.value === 'no' ? {extra_int_fee: {type: "number", error: true}} : {},
        ... state.productForm.newProduct.fields.parking_type.value === 'exteriorSpaces' || state.productForm.newProduct.fields.parking_type.value === 'interiorAndExterior' && state.productForm.newProduct.fields.parking_price_included.value === 'no' ? {extra_ext_fee: {type: "number", error: true}} : {},
        ... state.productForm.newProduct.fields.electric_parking.value === 'available' && state.productForm.newProduct.fields.parking_price_included.value ? {electric_parking_price: {type: 'number', error: true}} : {},
        ... state.productForm.newProduct.fields.project_type.value === 'rent' ? {policies_credit_check: {type: 'text', error: true}} : {},
        ... state.productForm.newProduct.fields.project_type.value === 'rent' ? {policies_criminal_check: {type: 'text', error: true}} : {}
    });

    const validateStep = (isFirstLoad) => {
        for (const field in stepFields) {
            if(stepFields[field]?.type === 'text') {
                let newError = validateTextInput(state.productForm.newProduct.fields[field]?.value, {isMandatory: true});
                if(typeof newError === 'string' && isFirstLoad) {
                    newError = true;
                }
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [field]: {
                            ...prev[field], 
                            error: newError
                        }
                    }
                });
            }
            if(stepFields[field]?.type === 'image') {
                let newError = validateFileInput(state.productForm.newProduct.fields[field]?.value?.array, {isMandatory: true});
                if(typeof newError === 'string' && isFirstLoad) {
                    newError = true;
                }
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [field]: {
                            ...prev[field], 
                            error: newError
                        }
                    }
                });
            }
            if(stepFields[field]?.type === 'number') {
                let newError = validateNumberInput(state.productForm.newProduct.fields[field]?.value, {isMandatory: true});
                if(typeof newError === 'string' && isFirstLoad) {
                    newError = true;
                }
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [field]: {
                            ...prev[field], 
                            error: newError
                        }
                    }
                });
            }
        }
    }

    const validateField = (fieldName) => {
        if(stepFields[fieldName]?.type === 'text') {
            let newError = validateTextInput(state.productForm.newProduct.fields[fieldName].value, {isMandatory: true});
            setStepFields((prev) => {
                return {
                    ...prev, 
                    [fieldName]: {
                        ...prev[fieldName], 
                        error: newError
                    }
                }
            });
        }
        if(stepFields[fieldName]?.type === 'image') {
            let newError = validateFileInput(state.productForm.newProduct.fields[fieldName].value.array, {isMandatory: true});
            setStepFields((prev) => {
                return {
                    ...prev, 
                    [fieldName]: {
                        ...prev[fieldName], 
                        error: newError
                    }
                }
            });
        }
        if(stepFields[fieldName]?.type === 'number') {
            let newError = validateNumberInput(state.productForm.newProduct.fields[fieldName]?.value, {isMandatory: true});
            setStepFields((prev) => {
                return {
                    ...prev, 
                    [fieldName]: {
                        ...prev[fieldName], 
                        error: newError
                    }
                }
            });
        }
    }

    const calculateCompletedFields = () => {
        return Object.values(stepFields).filter((field) => field.error === false).length;
    }

    useEffect(() => {
        validateStep(state.productForm.newProduct.fields.form_steps_data.value.array[1] === 0);
    }, []);

    useEffect(() => {
        actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
    }, [stepFields]);

    
    useEffect(() => {
        if(state.productForm.newProduct.fields.parking_price_included.value == 'yes') {
            delete stepFields.extra_parking_fee_type;
            delete stepFields.extra_int_fee;
            delete stepFields.extra_ext_fee;
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
        if(state.productForm.newProduct.fields.parking_price_included.value == 'no' && stepFields.extra_parking_fee_type === undefined && stepFields.extra_int_fee === undefined && stepFields.extra_ext_fee === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['extra_parking_fee_type']: {type: 'text', error: true},
                    ['extra_int_fee']: {type: 'number', error: true},
                    ['extra_ext_fee']: {type: 'number', error: true}
                }
            });
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
    }, [state.productForm.newProduct.fields.parking_price_included.value]);
    
    useEffect(() => {
        if(state.productForm.newProduct.fields.parking_type.value === 'interiorGarage') {
            delete stepFields.extra_ext_fee;
        }
        if(state.productForm.newProduct.fields.parking_type.value === 'interiorGarage' && stepFields.extra_int_fee === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['extra_int_fee']: {type: 'number', error: true},
                }
            });
        }
        if(state.productForm.newProduct.fields.parking_type.value === 'exteriorSpaces') {
            delete stepFields.extra_int_fee;
        }
        if(state.productForm.newProduct.fields.parking_type.value === 'exteriorSpaces' && stepFields.extra_ext_fee === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['extra_ext_fee']: {type: 'number', error: true},
                }
            });
        }
        if(state.productForm.newProduct.fields.parking_type.value === 'interiorAndExterior' && stepFields.extra_int_fee === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['extra_int_fee']: {type: 'number', error: true},
                }
            });
        }
        if(state.productForm.newProduct.fields.parking_type.value === 'interiorAndExterior' && stepFields.extra_ext_fee === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['extra_ext_fee']: {type: 'number', error: true},
                }
            });
        }
    }, [state.productForm.newProduct.fields.parking_type.value]);
    
    useEffect(() => {
        if(state.productForm.newProduct.fields.electric_parking.value == 'notAvailable' || state.productForm.newProduct.fields.parking_price_included.value === 'yes') {
            delete stepFields.electric_parking_price;
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
        if(state.productForm.newProduct.fields.electric_parking.value == 'available' && state.productForm.newProduct.fields.parking_price_included.value === 'no'  && stepFields.electric_parking_price === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['electric_parking_price']: {type: 'number', error: true},
                }
            });
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
    }, [state.productForm.newProduct.fields.electric_parking.value, state.productForm.newProduct.fields.parking_price_included.value]);

    useEffect(() => {
        if(state.productForm.newProduct.fields.locker_price_included.value == 'yes') {
            delete stepFields.extra_locker_fee_type;
            delete stepFields.extra_locker_fee;
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
        if(state.productForm.newProduct.fields.locker_price_included.value == 'no' && stepFields.extra_locker_fee_type === undefined && stepFields.extra_locker_fee === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['extra_locker_fee_type']: {type: 'text', error: true},
                    ['extra_locker_fee']: {type: 'number', error: true},
                }

            });
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
    }, [state.productForm.newProduct.fields.locker_price_included.value]);

    useEffect(() => {
        if(state.productForm.newProduct.fields.policies_dog_tolerance.value == 'notAllowed' || state.productForm.newProduct.fields.policies_dog_tolerance.value == 'allowed') {
            delete stepFields.policies_dog_weight;
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
        if(state.productForm.newProduct.fields.policies_dog_tolerance.value == 'sizeLimited') {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['policies_dog_weight']: {type: 'number', error: true},
                }
            });
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
    }, [state.productForm.newProduct.fields.policies_dog_tolerance.value]);

    useEffect(() => {
        if(state.productForm.newProduct.fields.project_type.value == 'sell') {
            delete stepFields.policies_credit_check;
            delete stepFields.policies_criminal_check;
        }
        if(state.productForm.newProduct.fields.project_type.value == 'rent' && state.productForm.newProduct.fields.policies_criminal_check.value === undefined && state.productForm.newProduct.fields.policies_credit_check.value === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['policies_criminal_check']: {type: 'text', error: true},
                    ['policies_credit_check']: {type: 'text', error: true}
                    
                }
            });
            actions.productForm.setStepCompletedFields({step: 2, completedFields: calculateCompletedFields()});
        }
    }, [state.productForm.newProduct.fields.project_type.value])

    const handleMultiSelect = (newValue, dataObj) => {
        actions.productForm.pushToFieldArray(
            {
                fieldId: dataObj.fieldId,
                value: newValue
            }
        )
    }

    const handleMultiDeselect = (index, dataObj) => {
        actions.productForm.removeFromFieldArray(
            {
                fieldId: dataObj.fieldId,
                index: index
            }
        )
    }

    const handleNumberInputChange = (e, dataObj) => {
        if(e) {
            e.currentTarget.value = parseInt(e.currentTarget.value, 10);
            actions.productForm.setProductField(
                {
                    fieldId: dataObj.fieldId,
                    value: e.currentTarget.value,
                }
            );
        } else {
            actions.productForm.setProductField(
                {
                    fieldId: dataObj.fieldId,
                    value: dataObj.value,
                }
            );
        }
    }

    const handleRadioInputChange = (e, dataObj) => {
        if(dataObj.fieldId === 'parking_price_included' && e.currentTarget.getAttribute('data-value') == 'yes') {
            const tempArray = ['extra_parking_fee_type', 'extra_int_fee', 'extra_ext_fee', 'electric_parking_price'];
            for (const fieldId of tempArray) {
                actions.productForm.setProductField(
                    {
                        fieldId: fieldId,
                        value: '',
                    }
                );
            }
        }
        if(dataObj.fieldId === 'parking_type' && e.currentTarget.getAttribute('data-value') == 'interiorGarage') {
            actions.productForm.setProductField(
                {
                    fieldId: 'extra_ext_fee',
                    value: '',
                }
            );
            delete stepFields.extra_ext_fee;
        }
        if(dataObj.fieldId === 'parking_type' && e.currentTarget.getAttribute('data-value') == 'exteriorSpaces') {
            actions.productForm.setProductField(
                {
                    fieldId: 'extra_int_fee',
                    value: '',
                }
            );
            delete stepFields.extra_int_fee;
        }
        if(dataObj.fieldId === 'electric_parking' && e.currentTarget.getAttribute('data-value') == 'notAvailable') {
            actions.productForm.setProductField(
                {
                    fieldId: 'electric_parking_price',
                    value: '',
                }
            );
        }
        if(dataObj.fieldId === 'locker_price_included' && e.currentTarget.getAttribute('data-value') == 'yes') {
            const tempArray = ['extra_locker_fee_type', 'extra_locker_fee'];
            for (const fieldId of tempArray) {
                actions.productForm.setProductField(
                    {
                        fieldId: fieldId,
                        value: '',
                    }
                );
            }
        }
        if(dataObj.fieldId === 'policies_dog_tolerance' && (e.currentTarget.getAttribute('data-value') == 'allowed' || e.currentTarget.getAttribute('data-value') == 'notAllowed')) {
            actions.productForm.setProductField(
                {
                    fieldId: 'policies_dog_weight',
                    value: '',
                }
            );
        }
        actions.productForm.setProductField(
            {
                fieldId: dataObj.fieldId,
                value: e.currentTarget.getAttribute('data-value'),
            }
        )
    }

    const handleBackClick = async () => {
        if(state.productForm.newProduct.fields.documents.value.array.length > 0) { 
            if(checkPublicId(state.productForm.newProduct.fields.documents.value.array)) {
                let formData = new FormData();
                for (const file of state.productForm.newProduct.fields.documents.value.array){
                    formData.append('documentInfo', JSON.stringify(file));
                    let convertedFile = await convertUrltoFile(file.src, file.name)
                    formData.append('project_documents', convertedFile);
                }
                setUploadingText('Saving documents...');
                setShowDocumentsLoaderBar(true);
                await actions.productForm.updateProjectDocuments(formData).then(() => {
                    setIsDocumentsLoaded(true);
                })
            }
        }
        actions.productForm.updateProductFields(buildingInfoFields).then(() => {
            actions.productForm.decrementFormStep();
        });
    }

    const handleNextClick = async () => {
        if(state.productForm.newProduct.fields.documents.value.array.length > 0) { 
            if(checkPublicId(state.productForm.newProduct.fields.documents.value.array)) {
                let formData = new FormData();
                for (const file of state.productForm.newProduct.fields.documents.value.array){
                    formData.append('documentInfo', JSON.stringify(file));
                    let convertedFile = await convertUrltoFile(file.src, file.name)
                    formData.append('project_documents', convertedFile);
                }
                setUploadingText('Saving documents...');
                setShowDocumentsLoaderBar(true);
                await actions.productForm.updateProjectDocuments(formData).then(() => {
                    setIsDocumentsLoaded(true);
                })
            }
        }
        actions.productForm.updateProductFields(buildingInfoFields).then(() => {
            validateStep(false);
            actions.productForm.incrementFormStep();
        });
    }

    return(
        <div className="steps__container">
            <div className="steps__title-desc-container">
                <div className="steps__title">Building information</div>
                <div className="steps__desc">Select all building features that apply to your project.</div>
            </div>
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup
                title="Project inclusions"
                fields={[
                    <MultiSelect 
                        label="Common spaces" 
                        value={state.productForm.newProduct.fields.common_spaces?.value?.array}
                        placeholder="Select common spaces"
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'common_spaces'}} 
                        auditorComment={state.productForm.newProduct.fields.common_spaces?.comment}
                        translationsNamespace='overview' 
                        status={state.productForm.newProduct.fields.common_spaces?.status}
                        options={{hasAlphabet: true, isVso: true, translationKey: 'commonSpace', maxWidth: '400px'}}
                        selectOptions={[
                            'artsRoom',
                            'backyard',
                            'bar',
                            'basketballCourt',
                            'carWashArea',
                            'childrensPlayroom',
                            'cigarRoom',
                            'cinemaRoom',
                            'clubGameRoom',
                            'clubHouse',
                            'coldStorage',
                            'commonTerrace',
                            'conferenceRoom',
                            'dogPark',
                            'elevator',
                            'gardenCourtyard',
                            'greenhouse',
                            'gym',
                            'indoorSwimmingPool',
                            'laundryRoom',
                            'library',
                            'lobby',
                            'lounge',
                            'marina',
                            'massageRoom',
                            'musicAndRecordingStudio',
                            'officeRoom',
                            'outdoorGrillingAreas',
                            'outdoorGym',
                            'outdoorSpace',
                            'outdoorSwimmingPool',
                            'packageRoom',
                            'petSpa',
                            'pilatesRoom',
                            'privateDining',
                            'recreationRoom',
                            'restaurant',
                            'roofTerrace',
                            'sauna',
                            'soundproofedStudyRooms',
                            'spa',
                            'squashCourt',
                            'steamRoom',
                            'sunDeck',
                            'swimmingPool',
                            'tennisCourt',
                            'urbanChalet',
                            'urbanVegetableGarden',
                            'virtualGolfRoom',
                            'walkingTrails',
                            'wineCellar',
                            'yogaRoom'
                        ]}  
                    />,
                    <MultiSelect 
                        label="Project amenities"
                        placeholder="Select project amenities" 
                        value={state.productForm.newProduct.fields.project_amenities?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'project_amenities'}} 
                        auditorComment={state.productForm.newProduct.fields.project_amenities?.comment} 
                        status={state.productForm.newProduct.fields.project_amenities?.status}
                        translationsNamespace='overview' 
                        options={{hasAlphabet: true, isVso: true, translationKey: 'projectAmenities', maxWidth: '400px'}}
                        selectOptions={[
                            '24HourConcierge',
                            '24HourEmergencyMaintenance',
                            'alarmSystem',
                            'bbq',
                            'bicycleStorage',
                            'centralAc',
                            'communityCar',
                            'controlledAccess',
                            'doorman',
                            'electricCarChargingStations',
                            'fireplace',
                            'garbageDisposal',
                            'intercom',
                            'keylessElectronicUnitEntry',
                            'livingGreenWalls',
                            'magneticEntranceCardKey',
                            'packageLocker',
                            'valet',
                            'videoSecurity',
                            'virtualConciergeApp',
                            'wheelchairAccessRamp',
                        ]}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="Parking details"
                fields={[
                    <RadioInput 
                        label="Parking type"
                        validateField={validateField}
                        error={stepFields.parking_type.error}  
                        value={state.productForm.newProduct.fields.parking_type?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'parking_type'}} 
                        auditorComment={state.productForm.newProduct.fields.parking_type?.comment} 
                        status={state.productForm.newProduct.fields.parking_type?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['interiorGarage', 'Interior Garage'],
                            ['exteriorSpaces', 'Exterior Space'],
                            ['interiorAndExterior', 'Interior & Exterior']
                        ]}
                    />,
                    <RadioInput 
                        label="Is the parking included in the unit price?"
                        validateField={validateField}
                        error={stepFields.parking_price_included.error}
                        value={state.productForm.newProduct.fields.parking_price_included?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'parking_price_included'}} 
                        auditorComment={state.productForm.newProduct.fields.parking_price_included?.comment} 
                        status={state.productForm.newProduct.fields.parking_price_included?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['yes', 'Yes'],
                            ['no', 'No'],
                        ]}
                    />,
                    ... state.productForm.newProduct.fields?.parking_price_included?.value == 'no' ?
                    [
                        <RadioInput 
                            label="Choose extra parking fee type"
                            validateField={validateField}
                            error={stepFields.extra_parking_fee_type?.error} 
                            value={state.productForm.newProduct.fields.extra_parking_fee_type?.value} 
                            setValue={handleRadioInputChange} 
                            dataObj={{fieldId: 'extra_parking_fee_type'}} 
                            auditorComment={state.productForm.newProduct.fields.extra_parking_fee_type?.comment} 
                            status={state.productForm.newProduct.fields.extra_parking_fee_type?.status}
                            options={{isMandatory: true}}
                            radioOptions={[
                                ['fixedFee', 'Fixed fee'],
                                ['monthlyFee', 'Monthly fee'],
                            ]}
                        />,
                        [
                            ... state.productForm.newProduct.fields.parking_type.value === 'interiorGarage' || state.productForm.newProduct.fields.parking_type.value === 'interiorAndExterior' ? 
                            [
                                <NumberInput 
                                    label="Interior extra parking fee"
                                    validateField={validateField}
                                    error={stepFields.extra_int_fee?.error} 
                                    placeholder="Price"
                                    value={state.productForm.newProduct.fields.extra_int_fee?.value}
                                    setValue={handleNumberInputChange} 
                                    dataObj={{fieldId: 'extra_int_fee'}}
                                    auditorComment={state.productForm.newProduct.fields.extra_int_fee?.comment}
                                    status={state.productForm.newProduct.fields.extra_int_fee?.status}
                                    options={{minVal: 1, isMandatory: true, suffix: state.productForm.newProduct.fields.extra_parking_fee_type?.value == 'monthlyFee' ? '$/mo' : '$', maxWidth: '104px'}}
                                />,
                            ]
                            : 
                            [],
                            ... state.productForm.newProduct.fields.parking_type.value === 'exteriorSpaces' || state.productForm.newProduct.fields.parking_type.value === 'interiorAndExterior' ?
                            [
                                <NumberInput 
                                    label="Exterior extra parking fee"
                                    placeholder="Price"
                                    validateField={validateField}
                                    error={stepFields.extra_ext_fee?.error} 
                                    value={state.productForm.newProduct.fields.extra_ext_fee?.value}
                                    setValue={handleNumberInputChange} 
                                    dataObj={{fieldId: 'extra_ext_fee'}}
                                    auditorComment={state.productForm.newProduct.fields.extra_ext_fee?.comment}
                                    status={state.productForm.newProduct.fields.extra_ext_fee?.status}
                                    options={{minVal: 1, isMandatory: true, suffix: state.productForm.newProduct.fields.extra_parking_fee_type?.value == 'monthlyFee' ? '$/mo' : '$', maxWidth: '104px'}}
                                />
                            ]
                            :
                            []
                        ],
                    ] 
                    : 
                    [],
                    <RadioInput 
                        label="Electric parking" 
                        value={state.productForm.newProduct.fields.electric_parking?.value}
                        validateField={validateField}
                        error={stepFields.electric_parking.error}
                        setValue={handleRadioInputChange}
                        dataObj={{fieldId: 'electric_parking'}}
                        auditorComment={state.productForm.newProduct.fields.electric_parking?.comment} 
                        status={state.productForm.newProduct.fields.electric_parking?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['available', 'Available'],
                            ['notAvailable', 'Not available'],
                        ]}
                    />,
                    ... state.productForm.newProduct.fields.electric_parking?.value === 'available' && state.productForm.newProduct.fields.parking_price_included.value === 'no' ? 
                    [
                        <NumberInput
                            label="Electric parking price"
                            placeholder="Price" 
                            value={state.productForm.newProduct.fields.electric_parking_price?.value}
                            validateField={validateField}
                            error={stepFields.electric_parking_price?.error}
                            setValue={handleNumberInputChange}
                            dataObj={{fieldId: 'electric_parking_price'}}
                            auditorComment={state.productForm.newProduct.fields.electric_parking_price?.comment} 
                            status={state.productForm.newProduct.fields.electric_parking_price?.status}
                            options={{isMandatory: true, maxWidth: '104px', suffix: state.productForm.newProduct.fields.extra_parking_fee_type?.value === 'monthlyFee' ? '$/mo' : '$'}}
                        />,
                    ]
                    :
                    [],
                    <RadioInput 
                        label="Vistor parking" 
                        value={state.productForm.newProduct.fields.visitor_parking?.value}
                        validateField={validateField}
                        error={stepFields.visitor_parking.error}
                        setValue={handleRadioInputChange}
                        dataObj={{fieldId: 'visitor_parking'}}
                        auditorComment={state.productForm.newProduct.fields.visitor_parking?.comment} 
                        status={state.productForm.newProduct.fields.visitor_parking?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['available', 'Available'],
                            ['notAvailable', 'Not available'],
                        ]}
                    />,
                    [
                        <RadioInput 
                            label="On street parking"
                            validateField={validateField}
                            error={stepFields.street_parking.error} 
                            value={state.productForm.newProduct.fields.street_parking?.value} 
                            setValue={handleRadioInputChange} 
                            dataObj={{fieldId: 'street_parking'}} 
                            auditorComment={state.productForm.newProduct.fields.street_parking?.comment} 
                            status={state.productForm.newProduct.fields.street_parking?.status}
                            options={{isMandatory: true}}
                            radioOptions={[
                                ['available', 'Available'],
                                ['notAvailable', 'Not available'],
                            ]}
                        />,
                        <RadioInput 
                            label="With permit"
                            validateField={validateField}
                            error={stepFields.parking_with_permit.error}
                            value={state.productForm.newProduct.fields.parking_with_permit?.value} 
                            setValue={handleRadioInputChange} 
                            dataObj={{fieldId: 'parking_with_permit'}} 
                            auditorComment={state.productForm.newProduct.fields.parking_with_permit?.comment} 
                            status={state.productForm.newProduct.fields.parking_with_permit?.status}
                            options={{isMandatory: true, maxWidth: '200px'}}
                            radioOptions={[
                                ['yes', 'Yes'],
                                ['no', 'No'],
                            ]}
                        />
                    ],
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="Locker details"
                fields={[
                    <RadioInput 
                        label="Is the locker included in the unit price?"
                        validateField={validateField}
                        error={stepFields.locker_price_included.error}  
                        value={state.productForm.newProduct.fields.locker_price_included?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'locker_price_included'}} 
                        auditorComment={state.productForm.newProduct.fields.locker_price_included?.comment} 
                        status={state.productForm.newProduct.fields.locker_price_included?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['yes', 'Yes'],
                            ['no', 'No'],
                        ]}
                    />,
                    ... state.productForm.newProduct.fields.locker_price_included?.value == 'no' ?
                    [
                        <RadioInput 
                            label="Choose extra locker fee type"
                            validateField={validateField}
                            error={stepFields.extra_locker_fee_type?.error} 
                            value={state.productForm.newProduct.fields.extra_locker_fee_type?.value} 
                            setValue={handleRadioInputChange} 
                            dataObj={{fieldId: 'extra_locker_fee_type'}} 
                            auditorComment={state.productForm.newProduct.fields.extra_locker_fee_type?.comment} 
                            status={state.productForm.newProduct.fields.extra_locker_fee_type?.status}
                            options={{isMandatory: true}}
                            radioOptions={[
                                ['fixedFee', 'Fixed fee'],
                                ['monthlyFee', 'Monthly fee'],
                            ]}
                        />,
                        <NumberInput 
                            label="Choose extra locker fee"
                            validateField={validateField}
                            error={stepFields.extra_locker_fee?.error} 
                            placeholder="Price"
                            value={state.productForm.newProduct.fields.extra_locker_fee?.value}
                            setValue={handleNumberInputChange} 
                            dataObj={{fieldId: 'extra_locker_fee'}}
                            auditorComment={state.productForm.newProduct.fields.extra_locker_fee?.comment}
                            status={state.productForm.newProduct.fields.extra_locker_fee?.status}
                            options={{minVal: 1, isMandatory: true, suffix: state.productForm.newProduct.fields.extra_locker_fee_type?.value == 'monthlyFee' ? '$/mo' : '$', maxWidth: '104px'}}
                        />,
                    ]
                    :
                    [],
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="Building details" 
                fields={[
                    <MultiSelect 
                        label="Structure"
                        placeholder="Select structure materials" 
                        value={state.productForm.newProduct.fields.structure?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'structure'}} 
                        auditorComment={state.productForm.newProduct.fields.structure?.comment} 
                        status={state.productForm.newProduct.fields.structure?.status}
                        translationsNamespace="overview"
                        options={{hasAlphabet: true, translationKey: 'structure', maxWidth: '400px'}}
                        selectOptions={['brick', 'concrete', 'metal', 'stone', 'wood']}
                    />,
                    <MultiSelect 
                        label="Facade materials"
                        placeholder="Select facade materials" 
                        value={state.productForm.newProduct.fields.exterior_features?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'exterior_features'}}
                        translationsNamespace="overview" 
                        auditorComment={state.productForm.newProduct.fields.exterior_features?.comment} 
                        status={state.productForm.newProduct.fields.exterior_features?.status}
                        options={{hasAlphabet: true, translationKey: 'facadeMaterials', maxWidth: '400px'}}
                        selectOptions={[
                            'acrylicCoating',
                            'adobe',
                            'aluminum',
                            'brick',
                            'cementSiding',
                            'concreteBlock',
                            'engineeredWood',
                            'lapSiding',
                            'log',
                            'metal',
                            'metalSiding',
                            'shingle',
                            'stone',
                            'straw',
                            'stucco',
                            'vinylSiding',
                            'wood'
                        ]}
                    />,
                    <MultiSelect 
                        label="Roof"
                        placeholder="Select roof materials" 
                        value={state.productForm.newProduct.fields.roof?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'roof'}} 
                        auditorComment={state.productForm.newProduct.fields.roof?.comment}
                        translationsNamespace='overview' 
                        status={state.productForm.newProduct.fields.roof?.status}
                        options={{hasAlphabet: true, translationKey: 'roof', maxWidth: '400px'}}
                        selectOptions={[
                            'asphaltShingle',
                            'cementTile',
                            'compositionShingle',
                            'concreteTile',
                            'elastomericBitumenMembrane',
                            'epdmMembrane',
                            'fiberglassTile',
                            'metal',
                            'multilayerRoofing',
                            'prepaintedGalvanizedSteel',
                            'pvc',
                            'recycledRubberTile',
                            'rock',
                            'shake',
                            'sheetMetal',
                            'sheetMetalTile',
                            'slate',
                            'slateTile',
                            'tar',
                            'terraCottaTile',
                            'tile',
                            'tilesOfCompositeMaterials',
                            'wood'
                        ]}
                    />,
                    <RadioInput 
                        label="Is your project a LEED Project?"
                        validateField={validateField}
                        error={stepFields.leed.error}  
                        value={state.productForm.newProduct.fields.leed?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'leed'}} 
                        auditorComment={state.productForm.newProduct.fields.leed?.comment} 
                        status={state.productForm.newProduct.fields.leed?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['yes', 'Yes'],
                            ['no', 'No'],
                        ]}
                    />,
                    <RadioInput 
                        label="Is your project an heritage building"
                        validateField={validateField}
                        error={stepFields.heritage_building.error}  
                        value={state.productForm.newProduct.fields.heritage_building?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'heritage_building'}} 
                        auditorComment={state.productForm.newProduct.fields.heritage_building?.comment} 
                        status={state.productForm.newProduct.fields.heritage_building?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['yes', 'Yes'],
                            ['no', 'No'],
                        ]}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup
                title="Project policies"
                fields={[
                    [
                        <RadioInput 
                            label="Dog tolerance" 
                            value={state.productForm.newProduct.fields.policies_dog_tolerance?.value}
                            validateField={validateField}
                            error={stepFields.policies_dog_tolerance.error}  
                            setValue={handleRadioInputChange} 
                            dataObj={{fieldId: "policies_dog_tolerance"}} 
                            auditorComment={state.productForm.newProduct.fields.policies_dog_tolerance?.comment} 
                            status={state.productForm.newProduct.fields.policies_dog_tolerance?.status}
                            options={{isMandatory: true}}
                            radioOptions={[
                                ['allowed', 'Allowed'],
                                ['sizeLimited', 'Size limited'],
                                ['notAllowed', 'Not allowed'],
                            ]}
                        />,
                        ... state.productForm.newProduct.fields.policies_dog_tolerance?.value === 'sizeLimited' ?
                            [
                                <NumberInput 
                                    label="Max dog weight"
                                    placeholder="Weight"
                                    validateField={validateField}
                                    error={stepFields.policies_dog_weight?.error} 
                                    value={state.productForm.newProduct.fields.policies_dog_weight?.value} 
                                    setValue={handleNumberInputChange} 
                                    dataObj={{fieldId: 'policies_dog_weight'}} 
                                    auditorComment={state.productForm.newProduct.fields.policies_dog_weight?.comment} 
                                    status={state.productForm.newProduct.fields.policies_dog_weight?.status}
                                    options={{minVal: 1, isMandatory: true, suffix: 'lbs', maxWidth: '84px' }}
                                />
                            ]
                            :
                            []
                    ],
                    <RadioInput 
                        label="Cat tolerance"
                        validateField={validateField}
                        error={stepFields.policies_cat_tolerance.error} 
                        value={state.productForm.newProduct.fields.policies_cat_tolerance?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'policies_cat_tolerance'}} 
                        auditorComment={state.productForm.newProduct.fields.policies_cat_tolerance?.comment} 
                        status={state.productForm.newProduct.fields.policies_cat_tolerance?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['allowed', 'Allowed'],
                            ['notAllowed', 'Not allowed']
                        ]}
                    />,
                    <RadioInput 
                        label="Smoking tolerance"
                        validateField={validateField}
                        error={stepFields.policies_smoking_tolerance.error}  
                        value={state.productForm.newProduct.fields.policies_smoking_tolerance?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'policies_smoking_tolerance'}} 
                        auditorComment={state.productForm.newProduct.fields.policies_smoking_tolerance?.comment} 
                        status={state.productForm.newProduct.fields.policies_smoking_tolerance?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['allowed', 'Allowed'],
                            ['notAllowed', 'Not allowed']
                        ]}
                    />,
                    <RadioInput 
                        label="Bbq tolerance"
                        validateField={validateField}
                        error={stepFields.policies_bbq_tolerance.error}  
                        value={state.productForm.newProduct.fields.policies_bbq_tolerance?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'policies_bbq_tolerance'}} 
                        auditorComment={state.productForm.newProduct.fields.policies_bbq_tolerance?.comment} 
                        status={state.productForm.newProduct.fields.policies_bbq_tolerance?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['allowed', 'Allowed'],
                            ['notAllowed', 'Not allowed']
                        ]}
                    />,
                    ... state.productForm.newProduct.fields.project_type?.value === 'rent' ?
                    [
                        <RadioInput 
                            label="Credit score check"
                            validateField={validateField}
                            error={stepFields.policies_credit_check?.error}  
                            value={state.productForm.newProduct.fields.policies_credit_check?.value} 
                            setValue={handleRadioInputChange} 
                            dataObj={{fieldId: 'policies_credit_check'}} 
                            auditorComment={state.productForm.newProduct.fields.policies_credit_check?.comment} 
                            status={state.productForm.newProduct.fields.policies_credit_check?.status}
                            options={{isMandatory: true}}
                            radioOptions={[
                                ['allowed', 'Allowed'],
                                ['notAllowed', 'Not allowed']
                            ]}
                        />,
                        <RadioInput 
                            label="Criminal check"
                            validateField={validateField}
                            error={stepFields.policies_criminal_check?.error}  
                            value={state.productForm.newProduct.fields.policies_criminal_check?.value} 
                            setValue={handleRadioInputChange} 
                            dataObj={{fieldId: 'policies_criminal_check'}} 
                            auditorComment={state.productForm.newProduct.fields.policies_criminal_check?.comment} 
                            status={state.productForm.newProduct.fields.policies_criminal_check?.status}
                            options={{isMandatory: true}}
                            radioOptions={[
                                ['allowed', 'Allowed'],
                                ['notAllowed', 'Not allowed']
                            ]}
                        /> 
                    ]
                    :
                    []
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="Project documents"
                fields={[
                    <FileInput 
                        label="Upload documents"
                        info="Placeholder info"
                        removeFile={actions.productForm.removeProjectDocument} 
                        caption={<>Upload documentation that helps clients understand what you are offering in your project. The documents will be available for download when clients visit the sales office.</>}
                        value={state.productForm.newProduct.fields?.documents?.value?.array}
                        setValue={actions.productForm.setProjectDocuments}
                        options={{isMandatory: false, isMultiple: true, sizeLimit: 9000000, fileLimit: 10,
                                isImageInput: false, acceptedFormat: '.jpg, .jpeg, .png, .pdf, .xlsx, .xls, .pptx, .ppt, .docx, .doc',
                                hasCoverIcon: false}}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <BackNextBar onBackClick={handleBackClick} onNextClick={handleNextClick} />
            <Modal isOpen={showDocumentsLoaderBar} setIsOpen={setShowDocumentsLoaderBar} options={{contentCenter: true, disableBackdropClick: true}}>
                <div className="loadingBar__container">
                    <div className="loadingBar__text">{uploadingText}</div>
                    <LoadingBar isLoaded={isDocumentsLoaded} setIsOpen={setShowDocumentsLoaderBar} /> 
                </div>
            </Modal> 
        </div>
    )
}

export default BuildingInformation;