import React, {useState, useEffect} from "react";
import "./textInput.scss";

const TextInput = ({value, setValue, placeholder, dataObj, options}) => {
    const [fieldId, setFieldId] = useState('');

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(`tti-${Math.floor(Math.random() * Date.now())}`));
    }, []);

    const handleValueChange = (e) => {
        dataObj.newValue = e.currentTarget.value;
        setValue(dataObj);
    }

    return(
        <input className="tableInput__text" id={`input__${fieldId}`} type="text" value={value} onChange={handleValueChange} placeholder={placeholder} />
    )
}

export default TextInput;