import { derived } from "overmind";

export const state = {
    formType: '',
    firstFormStep: 1,
    formStep: 1,
    newProduct: {
        id: '',
        project_id: '',
        fields: {
          form_steps_data: {value: {array: [0, 0, 0, 0, 0]}},
        }
    },
    vsoStatus: derived((state, rootState) => {
      return rootState.VSOs?.list[state.newProduct?.id]?.status;
    }),
    unitDetails: {
      activeFloor: 1,
      units: [],
      selectedVSOsortedFloorList: derived((state) => {
        if(state.units.length === 0) {
          return [];
        }
        return state.units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == state.activeFloor : unit.unit_number.toString().slice(0, 2) == state.activeFloor)
        .sort((unitA, unitB) => {
            if(unitA.unit_number > unitB.unit_number) {
                return 1;
            } else if(unitA.unit_number < unitB.unit_number) {
                return -1;
            }
            return 0;
        })
      }),
      sortedUnitsList: derived((state) => {
        if(state.units.length === 0) {
          return []
        }
        let unitsArr = [...state.units];
        return unitsArr.sort((a, b) => {
            if(a?.unit_number > b?.unit_number) { 
                return 1;
            } else if (a?.unit_number < b?.unit_number) { 
                return -1
            }
            return 0
        })
      }),
      statusFilteredVSOFloorList: derived((state) => {
        if(state.units.length === 0) {
          return [];
        }
        return state.units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == state.activeFloor : unit.unit_number.toString().slice(0, 2) == state.activeFloor)
        .filter(unit => unit.status != 'notAvailable')
        .sort((unitA, unitB) => {
            if(unitA.unit_number > unitB.unit_number) {
                return 1;
            } else if(unitA.unit_number < unitB.unit_number) {
                return -1;
            }
            return 0;
        })
      }), 
      optionalColumns: [
        {name: "orientation", isShown: true, type: "single_select", isCustom: false}, 
        {name: "position", isShown: true, type: "single_select", isCustom: false}, 
        {name: "view", isShown: true, type: "single_select", isCustom: false}, 
        {name: "parking", isShown: true, type: "single_select", isCustom: false}, 
        {name: "locker", isShown: true, type: "toggle", isCustom: false}
      ],
      extraColumnContent: {},
    },
    reservationDays:{
      value: ""
    },
    reservationDeposit:{
      value: ""
    },
    contactInfo: {
        title: "",
        firstName: "",
        lastName: "",
        number: "",
        email: "",
        booking_link: ""
    },
    displayPlansCol: derived((state) => {
      return state.formStep === 6;
    }),
    unitPlans: [],
    isPlansUploaded: false,
    projectImages: [],
    projectDocuments: []
};
