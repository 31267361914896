import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTable, useResizeColumns, useFlexLayout} from 'react-table';

import Row from './row';

import './table.scss';

const Table = ({data, columns, handleClickRow, noDataMessage}) => {

    const [tableHeight, setTableHeight] = useState(null);
    const tableScrollContainerRef = useRef(null);
    
    const headerProps = (props, { column }) => getStyles(props, column.align);
    const cellProps = (props, { cell }) => getStyles(props, cell.column.align);
    const getStyles = (props) => [
      props,
      {
        style: {
          justifyContent: 'flex-start',
          alignItems: 'center',
          display: 'flex',
        },
      },
    ];

    const defaultColumn = useMemo(() => (
        {
            minWidth: 80,
            width: 40,
            maxWidth: 160,
        }
    ), []);

    const { getTableProps, headers, rows, prepareRow } = useTable(
        {
            columns: columns,
            data: data,
            defaultColumn,
        },
        useResizeColumns,
        useFlexLayout,
    );

    useEffect(() => {
        setTableHeight(rows.length * 56)
    }, [rows]);

    const scrollToTop = () => {
        tableScrollContainerRef.current.scrollTo({
            top: tableScrollContainerRef.current.scrollHeight,
            behavior: 'smooth'
        });
    }

    return (
        <div className="table__container">
            <div ref={tableScrollContainerRef} className="table__scroll-container">
                <div {...getTableProps()} className="unitsTable__table" style={{height: tableHeight}}>
                    <div className="table__headers-container">
                        <div className="table__header-row">
                        {headers.map((column, index) => {
                            return(
                                <div {...column.getHeaderProps(headerProps)} className={`table__header-cell ${index === 0 ? '--sticky' : ''}`}>
                                    <span>{column.render('Header')}</span>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                    <div className="table__body">
                        {rows.length > 0 ?
                        rows.map((row) => {
                            prepareRow(row);
                            return (
                                <Row
                                    rowAttributes={{
                                    className: `table__row ${handleClickRow ? '--clickable' : ''}`,
                                    onClick: () => handleClickRow(row.original.id),
                                    ...row.getRowProps()
                                    }}
                                    cells={row.cells}
                                    cellProps={cellProps}
                                />
                            );
                        })
                        :
                        <div className="table__no-data">{noDataMessage}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Table;