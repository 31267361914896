import React, { useState } from 'react';
import { useOvermind } from 'src/overmind';
import { useTranslation } from 'react-i18next';

import TextInput from 'src/components/shared/inputs/textInput';
import { validateTextInput } from 'src/utils/fieldValidation';
import '../reservationForm/reservationForm.css';
import Modal from 'src/components/shared/modal';
import Button from 'src/components/shared/button';
import TextAreaInput from 'src/components/shared/inputs/textAreaInput';


const ContactForm = () => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation();

    const [showThanksScreen, setShowThanksScreen] = useState(false);
    const [message, setMessage] = useState('');
    const [formErrors, setFormErrors] = useState({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        message: null
    });

    const handleTextInputChange = (e, dataObj) => {
        if(dataObj.stateKey === 'firstName') {
            actions.currentVSO.setUserFirstName(e.target.value);
        } else if(dataObj.stateKey === 'lastName') {
            actions.currentVSO.setUserLastName(e.target.value);
        } else if(dataObj.stateKey === 'email') {
            actions.currentVSO.setUserEmail(e.target.value);
        } else if(dataObj.stateKey === 'phone') {
            actions.currentVSO.setUserPhone(e.target.value);
        } else if(dataObj.stateKey === 'message') {
            setMessage(e.target.value);
        }
    }

    const validateField = (userField) => {
        const error = validateTextInput(state.currentVSO.currentUser[userField], {isMandatory: true, isEmail: userField === 'email'});
        setFormErrors((prevState) => {
            return {
                ...prevState,
                [userField]: error
            }
        });
        return error;
    }

    const validateForm = () => {
        const newErrors = {
            firstName: validateTextInput(state.currentVSO.currentUser.firstName, {isMandatory: true}),
            lastName: validateTextInput(state.currentVSO.currentUser.lastName, {isMandatory: true}),
            email: validateTextInput(state.currentVSO.currentUser.email, {isMandatory: true, isEmail: true}),
            phone: validateTextInput(state.currentVSO.currentUser.phone, {isMandatory: true}),
            message: validateTextInput(message),
        }
        
        setFormErrors(newErrors);
        
        return Object.values(newErrors).every((value) => value === false);
    }

    const handleSendMessage = async () => {
        if(validateForm()) {
            const res = await actions.currentVSO.sendMessage({
                firstName: state.currentVSO.currentUser.firstName,
                lastName: state.currentVSO.currentUser.firstName,
                email: state.currentVSO.currentUser.email,
                phoneNumber: state.currentVSO.currentUser.phone,
                message: message,
            });
            console.log(res)
            setShowThanksScreen(true);
        }
    }

    return(
        <Modal isOpen={state.currentVSO.isContactFormOpen} setIsOpen={actions.currentVSO.setIsContactFormOpen}>
            {!showThanksScreen ?
            <div className="identityForm__container">
                <div className="identityForm__title">{t('salesOffice.contactForm.title')}</div>
                <div className="identityForm__row-half">
                    <TextInput 
                        label={t('salesOffice.loginForm.firstName')} value={state.currentVSO.currentUser.firstName} 
                        setValue={handleTextInputChange} placeholder={t('salesOffice.loginForm.firstName')} 
                        dataObj={{stateKey: 'firstName'}} theme="vsoForm"
                        error={formErrors.firstName}
                        validateField={() => validateField('firstName')}
                    />
                    <TextInput 
                        label={t('salesOffice.loginForm.lastName')} value={state.currentVSO.currentUser.lastName} 
                        setValue={handleTextInputChange} placeholder={t('salesOffice.loginForm.lastName')}
                        dataObj={{stateKey: 'lastName'}} theme="vsoForm"
                        error={formErrors.lastName}
                        validateField={() => validateField('lastName')}
                    />
                </div>
                <TextInput 
                    label={t('salesOffice.loginForm.email')} value={state.currentVSO.currentUser.email} 
                    setValue={handleTextInputChange} placeholder={t('salesOffice.loginForm.email')}
                    dataObj={{stateKey: 'email'}} options={{isEmail: true}} theme="vsoForm"
                    error={formErrors.email}
                    validateField={() => validateField('email')}
                />
                <TextInput 
                    label={t('salesOffice.reservationForm.phone')} value={state.currentVSO.currentUser.phone} 
                    setValue={handleTextInputChange} placeholder={t('salesOffice.reservationForm.phone')}
                    dataObj={{stateKey: 'phone'}} options={{isPhone: true}} theme="vsoForm"
                    error={formErrors.phone}
                    validateField={() => validateField('phone')}
                />
                <TextAreaInput
                    label={t('salesOffice.contactForm.message')} value={message}
                    setValue={handleTextInputChange} placeholder={t('salesOffice.contactForm.message')}
                    dataObj={{stateKey: 'message'}} theme="vsoForm"
                    error={formErrors.message}
                    validateField={() => validateField('message')}
                />
                <Button options={{type: "primary"}} text="Send message" onClick={handleSendMessage}/>
            </div>
            :
            <div className="reservationConfirmation__container">
                <div className="reservationConfirmation__title">{t('salesOffice.reservationForm.thankYou')}</div>
                <div className="reservationConfirmation__paragraph">
                    {t('salesOffice.contactForm.thankYouParagraph')}
                </div>
            </div>
            }
        </Modal>
    )
}

export default ContactForm;