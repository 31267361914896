import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/shared/button';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useOvermind } from 'src/overmind';
import '../reservationForm.css';
import checkmarkIcon from 'src/assets/images/icons/green_checkmark.svg';

const ReservationConfirmation = () => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation();
    
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        actions.currentVSO.setReservationUnit(new URLSearchParams(location.uid));
    }, [location]);
    
    const handleReturnVSO = () => {
        actions.currentVSO.setCurrentFloor(1);
        history.push('/virtual-sales-office/' + params.id);
    }

    return (
        <div className="reservationConfirmation__container">
            <div className="reservationConfirmation__title">{t('salesOffice.reservationForm.thankYou')}</div>
            <div className="reservationConfirmation__paragraph">
                {t('salesOffice.reservationForm.nowReserved', {unitNumber: state.currentVSO.reservation.currentUnit?.unit_number, reservationTime: state.currentVSO.reservation_time})}
            </div>
            <img className="reservationConfirmation__checkmark" src={checkmarkIcon} />
            <div className="reservationConfirmation__subtitle">{t('salesOffice.reservationForm.validUntil', {limitDate: null})}</div>
            <div className="reservationConfirmation__paragraph">
                <div>
                    <span>
                        {t('salesOffice.reservationForm.youKnowHave1', {reservationTime: state.currentVSO.reservation_time})}
                    </span>
                    {t('salesOffice.reservationForm.youKnowHave2', {reservationTime: state.currentVSO.reservation_time, projectName: state.currentVSO.fields.project_name})}
                </div>
                <div className="reservationConfirmation__button-container">
                    <Button text={t('salesOffice.reservationForm.bookMyApp')} />
                </div>
            </div>

        </div>
    );
}

export default ReservationConfirmation;