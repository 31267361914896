import React, {useState, useEffect, useRef} from 'react';
import './selectInput.scss';
import AuditorComment from '../../auditorComment';

const SelectInput = ({
    label, value, setValue, dataObj, theme,
    validateField, status = 'pending', auditorComment,
    placeholder, error, options={isMandatory: false, maxWidth: ''}, selectOptions}) => {
    const [fieldClasses, setFieldClasses] = useState('');
    const [fieldStatus, setFieldStatus] = useState(status);
    const [fieldId, setFieldId] = useState('');
    const [isCloseHovered, setIsCloseHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const selectInputRef = useRef(null);

    
    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(label + Math.floor(Math.random() * Date.now())));
    }, []);

    useEffect(() => {

        const handleClickOutside = (e) => {
            if(selectInputRef.current && !selectInputRef.current.contains(e.target)) {
                setIsActive(false);
            }
        }
        
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        // clean up
        document.removeEventListener("mousedown", handleClickOutside);
        }

    }, [selectInputRef]);

    useEffect(() => {
        let classes = ['input__select'];
        if(theme) {
            classes.push(`--${theme}`);
        }
        if(fieldStatus) {
            classes.push(`--${fieldStatus}`);
        }
        if(typeof error === 'string') {
            console.log('is error')
            if(classes.indexOf(`--denied`) === -1) {
                classes.push(`--denied`);
            }
        } else if(error === false) {
            if(classes.indexOf(`--valid`) === -1) {
                classes.push(`--valid`);
            }
        }
        setFieldClasses(classes.join(' '));
    }, [theme, fieldStatus, error]);

    const handleValueChange = (e) => {
        setValue(e, dataObj);
        if(validateField) {
            if(!validateField(dataObj.fieldId)){
                setFieldStatus('valid');
            } else {
                setFieldStatus('denied');
            };
        }
    }

    const handleCloseHover = () => {
        setIsCloseHovered(true);
    }

    const handleCloseHoverOut = () => {
        setIsCloseHovered(false);
    }

    const handleCloseValueChange = () => {
        options.setStateValue("");
        setIsCloseHovered(false);
    }

    const handleActiveState = () => {
        setIsActive(!isActive);
    }


    return(
    <div className="selectInput__container" style={{maxWidth: options.maxWidth}}>
        <label>{label} {options.isMandatory && <span>*</span>}</label>
            <select ref={selectInputRef} className={`${fieldClasses} --${fieldStatus} ${isActive ? '--active' : ''}`} id={`input__${fieldId}`} value={value} onClick={handleActiveState} onChange={handleValueChange} required>
                <option key={`option__${fieldId}`} value="" disabled selected>{placeholder}</option>
                    {selectOptions.map((o, index) => {
                        return(
                            <>
                            {   
                                <option key={`option__${fieldId}-${index}`} value={o[0]}>{o[1]}</option>
                            }
                            </> 
                        )
                    })}
            </select> 
        {auditorComment && 
        <AuditorComment comment={auditorComment}/>
        }
        {error && 
        <div className="selectInput__error">{error}</div>
        }
    </div>
    )
}

export default SelectInput;