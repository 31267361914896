import React, {useState, useEffect} from 'react';
import "./checkboxInput.scss";

const CheckboxInput = ({label, value, setValue, name, dataObj, options}) => {
    const [fieldId, setFieldId] = useState('');

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(label + Math.floor(Math.random() * Date.now())));
    }, []);

    const handleClick = (e) => {
        if(options.isMultiSelect) { 
            setValue(e);
        } else {
            setValue(dataObj, value);
        }
    }

    return(
        <label htmlFor={`input__${fieldId}`} className="checkbox__container">
            <input id={`input__${fieldId}`} type="checkbox" disabled={options.disabled} data-value={options.dataValue} checked={value} onClick={handleClick} name={name} />
            <span className="checkbox__checkmark"></span>
            {label}
        </label>
    );
}

export default CheckboxInput;