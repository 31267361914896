import React, { useEffect, useMemo, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import {
    useTable,
    useResizeColumns,
    useFlexLayout,
} from 'react-table';

import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

import {
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import Button from '../button';
import SortableUnitRow from './sortableRow';

import './unitsTable.scss';
import closeIcon from "src/assets/images/icons/close.svg";
import SvgIcons from '../svgIcons';
import Dropdown from '../dropdown';
import AddMultiUnits from './addMultiUnits';
import FilterError from '../filterTable/filterError';

const UnitsTable = ({
    showPrices,
    headings,
    addUnit,
    deleteUnit,
    uploadPlan,
    removePlan,
    units,
    floorHasUnits,
    changeUnitsOrder,
    handleRowClick,
    projectType = 'rent',
    clickAction = false,
    isEditMode = false,
    activeFloor,
    sortedActiveFloor,
    setProductField,
    addUnits,
    options = {}}) => {
    const [currentUnitPlan, setCurrentUnitPlan] = useState(null);
    const [activeUnitNumber, setActiveUnitNumber] = useState(null);
    const [multipleUnits, setMultipleUnits] = useState(false);
    const [tableHeight, setTableHeight] = useState(null);
    const [coords, setCoords] = useState({});
    const addUnitButtonRef = useRef(null);
    const tableScrollContainerRef = useRef(null);
    const tableRef = useRef(null);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const headerProps = (props, { column }) => getStyles(props, column.align)
    const cellProps = (props, { cell }) => getStyles(props, cell.column.align)
    const getStyles = (props) => [
      props,
      {
        style: {
          justifyContent: 'flex-start',
          alignItems: 'center',
          display: 'flex',
        },
      },
    ]

    const defaultColumn = useMemo(() => (
        {
          minWidth: 80,
          width: 40,
          maxWidth: 160,
        }
    ), []);

    const { getTableProps, headers, rows, prepareRow } = useTable(
        {
          columns: headings,
          data: units,
          defaultColumn,
        },
        useResizeColumns,
        useFlexLayout,
    )

    useEffect(() => {
        rows.forEach(row => {
            row.id = row.original.unit_id;
        });
    }, []);

    useEffect(() => {
        setTableHeight(rows.length * 56)
    }, [rows]);

    const handleClickPlan = (e) => {
        const currentPlanIndex = e.currentTarget.getAttribute('data-plan-index');
        // setCurrentUnitPlan(unitsArray[currentFloor][currentPlanIndex]);
    }

    const handleClosePlan = () => {
        setCurrentUnitPlan(null);
    }

    const handleDragStart = (e) => {
        const {active} = e;
        setActiveUnitNumber(active.id);
    }

    const handleDragEnd = (e) => {
        const {active, over} = e;
        if (active.id !== over.id) {
            changeUnitsOrder(active.id, over.id);
        }
        setActiveUnitNumber(null);
    }

    const handleMultiUnitsClick = () => {
        setMultipleUnits(!multipleUnits);
        const rect = addUnitButtonRef.current.getBoundingClientRect();
        setCoords({
            left: rect.x + window.scrollX,
            top: rect.y + window.scrollY,
            height: rect.height,
            width: rect.width
        })
    }

    const handleAddUnitsClick = async (numberOfUnits) => {
        await addUnits(numberOfUnits);
        setTimeout(() => {
            tableScrollContainerRef.current.scrollTo({
                top: tableScrollContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }, 100);
    }

    const handleAddUnitClick = async () => {
        await addUnit();
        tableScrollContainerRef.current.scrollTo({
            top: tableScrollContainerRef.current.scrollHeight,
            behavior: 'smooth'
        });
    }

    return (
        <>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div className="unitsTable__container" style={options.maxHeight ? {height: options.maxHeight} : null}>
            <div ref={tableScrollContainerRef} className="unitsTable__scroll-container">
                <div {...getTableProps()} className="unitsTable__table" ref={tableRef} style={{height: tableHeight}}>
                    <div className="unitsTable__headers-container">
                        <div className="unitsTable__header-row">
                            {headers.map((column, index) => {
                                return(
                                    <div {...column.getHeaderProps(headerProps)} className={`unitsTable__header-cell ${index === 0 ? '--sticky' : ''}`}>
                                        <span>{column.render('Header')}</span>
                                        {column.canResize && (
                                            <div {...column.getResizerProps()} className={`unitsTable__resizer ${column.isResizing ? '--resizing' : ''}`}></div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="unitsTable__body">
                        {/* <SortableContext items={rows} strategy={verticalListSortingStrategy}> */}
                        <SortableContext items={rows} strategy={verticalListSortingStrategy}>
                            {rows.length > 0 ?
                            rows.map((row) => {
                                prepareRow(row);
                                return(
                                    <SortableUnitRow 
                                        rowAttributes={{
                                            className: `unitsTable__row ${!isEditMode ? `--${row.values.status}` : ''} ${clickAction && row.values.status === 'Available' ? '--clickable' : ''}`,
                                            onClick: clickAction && row.values.status === 'Available' ? clickAction : null,
                                            ...row.getRowProps()
                                        }}
                                        cells={row.cells}
                                        rowId={row.id}
                                        cellProps={cellProps}
                                        isEditMode={isEditMode}
                                        deleteUnit={deleteUnit}
                                        uploadPlan={uploadPlan}
                                        removePlan={removePlan}
                                        unitId={row.original.unit_id}
                                        projectType={projectType}
                                        handleRowClick={handleRowClick}
                                        activeFloor={activeFloor}
                                        unitStatus={row.values.status}
                                        showPrices={showPrices}
                                    />
                                )
                            })
                            : units.length === 0 && floorHasUnits ? <div className="unitsTable__no-units"><FilterError /></div> : <div className="unitsTable__no-units">This floor has no units.</div>
                            }
                        </SortableContext>
                        {isEditMode && (activeFloor !== "" && activeFloor !== 0) &&
                        <div className="unitsTable__after-units-container">
                            <div className="unitsTable__add-unit-container" ref={addUnitButtonRef}>
                                <button className="unitsTable__add-unit" onClick={handleAddUnitClick} disabled={sortedActiveFloor?.length === 99}>Add a unit</button>
                                <div className="unitsTable__add-unit-arrow" onClick={handleMultiUnitsClick}>
                                    <Dropdown isOpen={multipleUnits} setIsOpen={setMultipleUnits} position="se" coords={coords} items={[
                                        {content: <AddMultiUnits setOpen={setMultipleUnits} 
                                        activeFloor={activeFloor}
                                        units={units} 
                                        setProductField={setProductField} 
                                        addUnits={handleAddUnitsClick}/>
                                        }
                                    ]}/>
                                    <SvgIcons type="carret_down"/>
                                </div>
                            </div>
                        </div>
                        }
                        {/*
                        createPortal(
                            <DragOverlay modifiers={[restrictToVerticalAxis]} >
                                {activeUnitNumber ? 
                                <UnitRow 
                                unitNumber={activeUnitNumber} 
                                headings={headings} 
                                cells={null} 
                                rowId={null}
                                setValue={null} 
                                isEditMode={true} 
                                isDragging={true}
                                deleteUnit={null}
                                projectType={projectType}
                                />
                                : null}
                            </DragOverlay>,
                            document.body)
                                */}
                    </div>
                </div>
            </div>
        </div>
        {currentUnitPlan && 
        createPortal(
        <div className="unitsTable__modal"> 
            <div className="unitsTable__modal-backdrop" onClick={handleClosePlan}></div>
            <div className="unitsTable__modal-container">
                <div className="unitsTable__modal-close">
                    <img src={closeIcon} 
                            alt="cancel-icon" 
                            onClick={() => setCurrentUnitPlan(null)} 
                    />
                </div>
                <div className="unitsTable__modal-name">Unit <span>{currentUnitPlan.unit_number}</span></div>
                <div className="unitsTable__modal-image" style={{backgroundImage: `url(${currentUnitPlan.plan})`}}></div>
                <Button text="Reserve Unit"/>
            </div>
        </div>,
        document.body)
        }
        </DndContext>
        </>
    );
}

export default UnitsTable;