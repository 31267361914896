require('dotenv').config();

export const fetchMarketTestFields = async (marketTestId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'marketTests/' + marketTestId + '/fields/user',
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const fetchAllMarketTests = async () => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'marketTests/',
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
        
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const changeMarketTestStatus = async (marketTestId, newStatus) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + "marketTests/" + marketTestId + '/status',
            {
                method: 'PUT',
                headers: {'token' : localStorage.token,
                'Content-Type' : 'application/json'},
                body: JSON.stringify({status: newStatus})
            });
        
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const updateField = async (marketTestId, field_content_id, field_value, status, auditor_comment) => {
    try {

        const body = {field_content_id, status, field_value, auditor_comment};

        const response = await fetch(
        process.env.REACT_APP_SERVER_URL + "marketTests/" + marketTestId + '/fields/edit',
        {
            method: 'PUT',
            headers: {'token' : localStorage.token,
            'Content-Type' : 'application/json'},
            body: JSON.stringify(body)
        });
    } catch (err) {
        console.error(err.message);
        return err;
    }
}