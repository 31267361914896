import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOvermind } from 'src/overmind';

import './filterTable.scss';
import '../inputs/selectInput/index';
import Button from '../button';
import SvgIcons from '../svgIcons';
import Dropdown from '../dropdown';
import FilterDropdown from './filterDropdown';
import FilterMultiSelect from './filterMultiSelect';
import RangeSlider from '../rangeSlider';
import FilterSingleSelect from './filterSingleSelect';
import FilterTags from './filterTags';

const FilterTable = ({sortedActiveFloorList, setFilteredUnits, setCurrentFloor, currentFloor, numberOfFloors, optionalColumn, units}) => {
    const {state, actions} = useOvermind();
    
    const [isAllFloorsOpen, setIsAllFloorsOpen] = useState(false);
    const [budgetLimit, setBudgetLimit] = useState({});
    const [sqftLimit, setSqftLimit] = useState({});
    const [secondaryFilters, setSecondaryFilters] = useState(false);
    const [filterArray, setFilterArray] = useState([]);
    const [coords, setCoords] = useState({});
    const [allFloorButtonCoords, setAllFloorButtonCoords] = useState({});
    const secondaryFilterRef = useRef(null);
    const allFloorsButtonRef = useRef(null);
    const [dropDownName, setDropdownName] = useState('');
    const { t, i18n } = useTranslation('filterTable');

    useEffect(() => {
        actions.currentVSO.checkOptionalColumns();
        let arrayPrice = [];
        let arraySqft = []
        sortedActiveFloorList.map(el => arrayPrice.push(el.price));
        sortedActiveFloorList.map(el => arraySqft.push(el.sqft));
        let maxPrice = Math.ceil(((Math.max(...arrayPrice) + 1000) / 1000)) * 1000;
        let maxSqft =  Math.ceil(((Math.max(...arraySqft) + 1000) / 1000)) * 1000;
        setBudgetLimit({min: 0, max: maxPrice});
        setSqftLimit({min: 0, max: maxSqft});
        actions.currentVSO.setFilterRange({dataObj: {contentId: 'sqft'}, value: [0, maxSqft]});
        actions.currentVSO.setFilterRange({dataObj: {contentId: 'price'}, value: [0, maxPrice]});
    }, []);
    
    useEffect(() => {
        setFilterArray([
                        ...(state.currentVSO.filterTable.bedroom?.filters.length > 0 ? [state.currentVSO.filterTable.bedroom?.filters.length] : []),
                        ...(state.currentVSO.filterTable.bathroom?.filters.length > 0 ? [state.currentVSO.filterTable.bathroom?.filters.length] : []), 
                        ...(state.currentVSO.filterTable.orientation?.filters.length > 0 ? [state.currentVSO.filterTable.orientation?.filters.length] : []), 
                        ...(state.currentVSO.filterTable.position?.filters.length > 0 ? [state.currentVSO.filterTable.position?.filters.length] : []), 
                        ...(state.currentVSO.filterTable.view?.filters.length > 0 ? [state.currentVSO.filterTable.view?.filters.length] : []), 
                        ...(state.currentVSO.filterTable.parking?.filters.length > 0 ? [state.currentVSO.filterTable.parking?.filters.length] : []), 
                        ...(state.currentVSO.filterTable.status?.filters.length > 0 ? [state.currentVSO.filterTable.status?.filters.length] : []),
                        ...(sqftLimit.max !== undefined ? (state.currentVSO.filterTable.sqft?.filters[0] > 0 || state.currentVSO.filterTable.sqft?.filters[1] != sqftLimit.max) ? [true] : [] : []),
                        ...(budgetLimit.max !== undefined ? (state.currentVSO.filterTable.price?.filters[0] > 0 || state.currentVSO.filterTable.price?.filters[1] != budgetLimit.max) ? [true] : [] : [])
                    ]);

        let tempArr = sortedActiveFloorList.filter((el) =>
            (state.currentVSO.filterTable.bedroom?.filters.length > 0 ? state.currentVSO.filterTable.bedroom?.filters.includes(el.bedroom) : state.currentVSO.filterTable.bedroom?.filters.length === 0) &&
            (state.currentVSO.filterTable.bathroom?.filters.length > 0 ? state.currentVSO.filterTable.bathroom?.filters.includes(el.bathroom) : state.currentVSO.filterTable.bathroom?.filters.length === 0) &&
            (state.currentVSO.filterTable.orientation?.filters.length > 0 ? state.currentVSO.filterTable.orientation?.filters.includes(el.orientation) : state.currentVSO.filterTable.orientation?.filters.length === 0 || state.currentVSO.filterTable.orientation?.filters.length === undefined) &&
            (state.currentVSO.filterTable.position?.filters.length > 0 ? state.currentVSO.filterTable.position?.filters.includes(el.position) : state.currentVSO.filterTable.position?.filters.length === 0 || state.currentVSO.filterTable.position?.filters.length === undefined) &&
            (state.currentVSO.filterTable.view?.filters.length > 0 ? state.currentVSO.filterTable.view?.filters.includes(el.view) : state.currentVSO.filterTable.view?.filters.length === 0 || state.currentVSO.filterTable.view?.filters.length === undefined) &&
            (state.currentVSO.filterTable.parking?.filters.length > 0 ? state.currentVSO.filterTable.parking?.filters.includes(el.parking) : state.currentVSO.filterTable.parking?.filters.length === 0 || state.currentVSO.filterTable.parking?.filters.length === undefined) &&
            (state.currentVSO.filterTable.status?.filters.length > 0 ? state.currentVSO.filterTable.status?.filters.includes(el.status) : state.currentVSO.filterTable.status?.filters.length === 0) &&
            (parseInt(el.sqft) >= parseInt(state.currentVSO.filterTable?.sqft.filters[0]) && parseInt(el.sqft) <= parseInt(state.currentVSO.filterTable.sqft?.filters[1])) &&
            (parseInt(el.price) >= parseInt(state.currentVSO.filterTable?.price.filters[0]) && parseInt(el.price) <= parseInt(state.currentVSO.filterTable.price?.filters[1])) 
        );
        setFilteredUnits(tempArr);
    }, [
        state.currentVSO.filterTable.bedroom?.filters.length,
        state.currentVSO.filterTable.bathroom?.filters.length,
        state.currentVSO.filterTable.orientation?.filters.length,
        state.currentVSO.filterTable.position?.filters.length,
        state.currentVSO.filterTable.view?.filters.length,
        state.currentVSO.filterTable.parking?.filters.length,
        state.currentVSO.filterTable.bedroom?.filters.length,
        state.currentVSO.filterTable.status?.filters.length,
        state.currentVSO.filterTable.sqft?.filters[0],
        state.currentVSO.filterTable.sqft?.filters[1],
        state.currentVSO.filterTable.price?.filters[0],
        state.currentVSO.filterTable.price?.filters[1],
        sortedActiveFloorList,
    ]);

    const getFloors = () => {
        let floors = [];
        for (let i = 0; i <= numberOfFloors; i++) {
            let filteredUnitsFloorLength = units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == i : unit.unit_number.toString().slice(0, 2) == i)
            if(i === 0 ) {
                floors.push(['', `${t('floors.all')} ${t('floors.floors')}`]);
            } else if (filteredUnitsFloorLength.length > 0) {
                floors.push([i, 
                    (i === 1 ? `${t('floors.1st')} ${t('floors.floor')}`:
                    i === 2 ? `${t('floors.2nd')} ${t('floors.floor')}`:
                    i === 3 ? `${t('floors.3rd')} ${t('floors.floor')}`:
                    i + `${t('floors.th')} ${t('floors.floor')}`)]);
            }
        }
        return floors;
    }

    const handleSecondaryFilterClick = () => {
        setSecondaryFilters(!secondaryFilters)
        const rect = secondaryFilterRef.current.getBoundingClientRect();
        setCoords({
            left: rect.left + window.scrollX,
            top: rect.y + window.scrollY,
            height: rect.height,
            width: rect.width
        })
    }

    const handleAllFloorDropdownContainer = () => {
        setIsAllFloorsOpen(!isAllFloorsOpen)
        const rect = allFloorsButtonRef.current.getBoundingClientRect();
        setAllFloorButtonCoords({
            left: rect.left + window.scrollX,
            top: rect.y + window.scrollY,
            height: rect.height,
            width: rect.width
        })
    }

    const handleDropdownClick = (name) => {
        if(dropDownName === name) {
            setDropdownName('');
        } else {
            setDropdownName(name);
        }
    }

    const handleMultiSelect = (newValue, dataObj) => {
        actions.currentVSO.pushToFilterArray({dataObj, value: newValue});
    }
    
    const handleMultiDeselect = (index, dataObj) => {
        actions.currentVSO.removeFromFilterArray({dataObj, index: index});
    }

    const handleRangeChange = (rangeArray, dataObj) => {
        actions.currentVSO.setFilterRange({dataObj, value: rangeArray});
    }

    const getFilterName = (name, value) => {
       if(name != 'sqft' && name != 'price') {
            const selectOptionIndex = state.currentVSO.filterTable[name].selectOptions.findIndex(el => el === value)
            return t(`filterOptions.${state.currentVSO.filterTable[name].selectOptions[selectOptionIndex]}`);
       }
    }

    const getFilterTags = (name) => {
        switch (name) {
            case 'Bedroom':
                return state.currentVSO.filterTable.bedroom.filters;
            case 'Bathroom':
                return state.currentVSO.filterTable.bathroom.filters;
            case 'SQFT':
                if(sqftLimit.min !== state.currentVSO.filterTable.sqft.filters[0] || sqftLimit.max !== state.currentVSO.filterTable.sqft.filters[1]) {
                    return state.currentVSO.filterTable.sqft.filters;
                }
                return;
            case 'Parking':
                return state.currentVSO.filterTable.parking.filters;
            case 'Price':
                if(budgetLimit.min !== state.currentVSO.filterTable.price.filters[0] || budgetLimit.max !== state.currentVSO.filterTable.price.filters[1]) {
                    return state.currentVSO.filterTable.price.filters;
                }
                return;
            case 'Orientation':
                return state.currentVSO.filterTable.orientation.filters;
            case 'Position':
                return state.currentVSO.filterTable.position.filters;
            case 'Status':
                return state.currentVSO.filterTable.status.filters;
            case 'View':
                return state.currentVSO.filterTable.view.filters;
            default:
                break;
        }
    }

    const handleClearRange = (_, dataObj) => {
        if(dataObj.contentId === 'sqft') {
            actions.currentVSO.setFilterRange({dataObj, value: [0, sqftLimit.max]});
        } else {
            actions.currentVSO.setFilterRange({dataObj, value: [0, budgetLimit.max]});
        }
    }
    
    return(
        <div className="filterTable__container">
            <div className="filterTable__main-filters">
                <Button type="secondary" 
                        suffix={<SvgIcons type="cabana_dropdown_icon" />} 
                        suffixGap={'36px'}
                        options={{activeState: currentFloor !== '' || isAllFloorsOpen, ref: allFloorsButtonRef}}
                        onClick={handleAllFloorDropdownContainer}
                        >
                        <div className="filterTable__filter-floor-text">
                            {currentFloor === '' ? `${t('floors.all')} ${t('floors.floors')}` : 
                             (currentFloor == 1 ? `${t('floors.1st')} ${t('floors.floor')}`:
                             currentFloor == 2 ? `${t('floors.2nd')} ${t('floors.floor')}` :
                             currentFloor == 3 ? `${t('floors.3rd')} ${t('floors.floor')}` :
                             currentFloor + `${t('floors.th')} ${t('floors.floor')}`)
                            }
                        </div>
                </Button>
                <Button type="secondary"
                        suffix={filterArray.length > 0 ? <div className="filterTable__number-of-filters">{filterArray.length}</div> : <SvgIcons type="cabana_plus_circle" />} 
                        suffixGap="24px"
                        options={{activeState: filterArray.length > 0 || secondaryFilters, ref: secondaryFilterRef}}
                        onClick={handleSecondaryFilterClick}
                        >
                    {<div className="filterTable__filter-icon-and-text">
                        <SvgIcons type="cabana_filter_icon"/>
                        <div>{t('filters')}</div>
                     </div>}
                </Button>
                <div className="filterTable__filterTags-container">
                    {
                        Object.keys(state.currentVSO.filterTable).map((name) => 
                         name != 'price' && name != 'sqft' ?
                            state.currentVSO.filterTable[name].filters.map((el, index) => {
                                return(
                                    <FilterTags label={getFilterName(name, el)} value={index} handleClearFilter={handleMultiDeselect} dataObj={{contentId: name}} options={{hasClearFilterButton: true}}/>   
                                );
                            })
                            :
                            name === 'sqft' && (sqftLimit.min !== state.currentVSO.filterTable.sqft.filters[0] || sqftLimit.max !== state.currentVSO.filterTable.sqft.filters[1]) ? 
                                    <FilterTags label={state.currentVSO.filterTable.sqft.filters[0] + "ft²" + " - " + state.currentVSO.filterTable.sqft.filters[1] + "ft²"} handleClearFilter={handleClearRange} dataObj={{contentId: name}} options={{hasClearFilterButton: true}}/>
                            :
                            name === 'price' && (budgetLimit.min !== state.currentVSO.filterTable.price.filters[0] || budgetLimit.max !== state.currentVSO.filterTable.price.filters[1]) ?
                                    <FilterTags label={state.currentVSO.filterTable.price.filters[0] + "$" + " - " + state.currentVSO.filterTable.price.filters[1] + "$"} handleClearFilter={handleClearRange} dataObj={{contentId: name}} options={{hasClearFilterButton: true}}/> 
                            :
                            ""
                        )
                    }
                </div>
            </div>
            <Dropdown position="se" isOpen={isAllFloorsOpen} setIsOpen={setIsAllFloorsOpen} coords={allFloorButtonCoords} 
                items={[{
                    content: 
                        <div className="filterTable__choose-floor-container">
                            <div className="filterTable__choose-floor-header">
                                <div className="filterTable__choose-floor-text">{t('floors.floors')}</div>
                                <div className="filterTable__choose-floor-info">{t('floorSearch')}</div>
                            </div>
                            <div className="filterTable__choose-floor">
                                <FilterSingleSelect value={currentFloor} selectOptions={getFloors()} setValue={setCurrentFloor}/>
                            </div>
                        </div>
                }]}
            />
            <Dropdown position="se" isOpen={secondaryFilters} setIsOpen={setSecondaryFilters} coords={coords}
            items={[{
                content:
                    <div className="filterTable__secondary-filter-dropdown">
                        <div className="filterTable__secondary-filter-header-container">
                            <div className="filterTable__secondary-filter-header-text">{t('filters')}</div>
                            <div className="filterTable__secondary-filter-header-info">{t('filterSearch')}</div>
                        </div>
                        {Object.keys(state.currentVSO.filterTable)?.map((el) => {
                            return(
                                <div className="filterTable__dropdown-items">
                                    <FilterDropdown name={t(`filtersName.${state.currentVSO.filterTable[el].name.toLowerCase()}`)} 
                                                activeState={state.currentVSO.filterTable[el].name === dropDownName} 
                                                onClick={() => handleDropdownClick(state.currentVSO.filterTable[el].name)} 
                                                filterTags={getFilterTags(state.currentVSO.filterTable[el].name)}>

                                    <FilterMultiSelect handleMultiDeselect={handleMultiDeselect} 
                                                handleMultiSelect={handleMultiSelect} value={state.currentVSO.filterTable[el].filters}
                                                dataObj={{contentId: el}}
                                                selectOptions={state.currentVSO.filterTable[el].selectOptions}
                                                />
                                        {
                                            el === 'sqft' && 
                                            <RangeSlider range={state.currentVSO.filterTable[el].filters} 
                                                setRange={handleRangeChange} 
                                                dataObj={{contentId: 'sqft'}} 
                                                limits={sqftLimit} suffix="ft²"/>
                                        }
                                        
                                        {
                                            el === 'price' && 
                                            <RangeSlider range={state.currentVSO.filterTable[el].filters} 
                                                setRange={handleRangeChange} 
                                                dataObj={{contentId: 'price'}} 
                                                limits={budgetLimit} suffix="$"/>
                                        }
                                    </FilterDropdown>
                                </div>
                            )
                        })
                    }
                </div>
            }]}>
            </Dropdown>
        </div>
    )
}

export default FilterTable;