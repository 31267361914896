import React from "react";
import SvgIcons from "../../svgIcons";
import "./filterTags.scss";

const FilterTags = ({label, handleClearFilter, value, dataObj, options={hasClearFilterButton: false}}) => {

    const clearFilter = () => {
        handleClearFilter(value, dataObj);
    }

    return(
        <div className="filterTags__container">
            <div className="filterTags__info">
                <div className="filterTags__label">{label}</div>
            </div>
            <div className="filterTags__close" onClick={clearFilter}>
                <SvgIcons type="cabana_circle_close_icon"/>
            </div>
        </div>
    );
}

export default FilterTags;