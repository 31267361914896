import React, { useState, useEffect, useRef } from 'react';
import './contactUsCard.scss';

import { useOvermind } from 'src/overmind';
import { useTranslation } from 'react-i18next';

import Modal from 'src/components/shared/modal';
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';
import Calendly from 'src/components/shared/calendly';


const ContactUsCard = ({startVideoCall}) => {
    const { state, actions } = useOvermind();
    const [isOpen, setIsOpen] = useState(false);
    const [calendlyModal, setCalendlyModal] = useState(false);
    const { t } = useTranslation();

    const contactUsCardRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if(contactUsCardRef.current && !contactUsCardRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        // clean up
        document.removeEventListener("mousedown", handleClickOutside);
        }

    }, [contactUsCardRef]);

    const handleBookingClick = (e) => {
        e.stopPropagation();
        if(state.currentVSO.fields.contact_info?.booking_link.includes("calendly")){
            setCalendlyModal(true);
        } else {
            window.open(state.currentVSO.fields.contact_info?.booking_link);
        }
    }

    const handleSendMessageClick = (e) => {
        e.stopPropagation();
        actions.currentVSO.setIsContactFormOpen(true);
    }

    return (
        <>
            <div ref={contactUsCardRef} className={`contactUsCard__container ${isOpen ? "--active" : ""}`} onClick={() => setIsOpen(true)}>
                {isOpen ?
                <>
                    <div className="contactUsCard__close-button" onClick={(e) => {e.stopPropagation(); setIsOpen(false);}}>
                        <SvgIcons type="cabana_white_close" />
                    </div>
                    <div className="contactUsCard__text-container">
                        <h5>{t('salesOffice.contactUs')}</h5>
                    </div>
                    <div className="contactUsCard__info-container">
                        <div className="contactUsCard__mail-info">
                            <SvgIcons type="cabana_mail_icon" />
                            <div className="contact-info">{state.currentVSO.fields.contact_info?.email}</div>
                        </div>
                        <div className="contactUsCard__phone-info">
                            <SvgIcons type="cabana_phone_icon" />
                            <div className="contact-info">{state.currentVSO.fields.contact_info?.number}</div>
                        </div>
                    </div>
                    <div className="contactUsCard__divider">
                        <div className="divider" />
                        <div>{t('salesOffice.or')}</div>
                        <div className="divider" />
                    </div>
                    <div className="contactUsCard__buttons">
                            {state.currentVSO.fields.contact_info?.booking_link !== "" && 
                            <Button type="primary" options={{isFluid: true}} text={t('salesOffice.bookAppointment')} onClick={(e) => handleBookingClick(e)}><SvgIcons type="cabana_calendar_icon"/></Button>
                            }
                            <Button type="primary" text={t('salesOffice.sendAMessage')} options={{isFluid: true}} onClick={(e) => handleSendMessageClick(e)}><SvgIcons type="cabana_message_bubble_icon"/></Button>
                    </div>
                </>
                :
                <>
                    <div className="contactUsCard__text">
                    {t('salesOffice.contactUs')}
                    </div>
                    <div className="contactUsCard__message-bubble-icon">
                        <SvgIcons type="cabana_message_bubble_icon"/>
                    </div>
                </>
                }
            </div>
            <Modal isOpen={calendlyModal} setIsOpen={setCalendlyModal} 
                options={{
                    disableBackdropClick: false, 
                    contentCenter: true,
            }}>
                <Calendly url={state.currentVSO.fields.contact_info?.booking_link} />
            </Modal>
        </>
    )

}
export default ContactUsCard;