import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOvermind } from 'src/overmind';

import UnitsTable from 'src/components/shared/unitsTable';
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';
import Popover from 'src/components/shared/popover';
import EditColumns from 'src/components/shared/unitsTable/editColumns';
import FilterSingleSelect from 'src/components/shared/filterTable/filterSingleSelect';

import './units.scss';
import BatchUploadPlans from 'src/components/shared/unitsTable/batchUploadPlans';
import DuplicateFloor from 'src/components/shared/unitsTable/duplicateFloor';

const Units = () => {
    const { state, actions } = useOvermind();
    const { t, i18n } = useTranslation(['translations', 'filterTable']);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [viewMode, setViewMode] = useState('table_view');
    const [floorSelectorBtnCoords, setfloorSelectorBtnCoords] = useState({});
    const [isFloorSelectorOpen, setIsFloorSelectorOpen] = useState(false);
    const allFloorsButtonRef = useRef(null);

    const handleUnitValueChange = (dataObj) => {
        actions.units.saveUnitValue({unitId: dataObj.unitId, valueKey: dataObj.valueKey, newValue: dataObj.newValue, isCustom: dataObj.isCustom});
    }

    const columns = useMemo(() => 
    [
        {accessor: 'unit_number', Header: t('unitTable.unit'), minWidth: 80},
        {accessor: 'bedroom', Header: t('unitTable.bedroom'), minWidth: 96, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['studio', 'oneBedroom', 'twoBedroom', 'threeBedroom', 'fourBedroom', 'fiveBedroom']}},
        {accessor: 'bathroom', Header: t('unitTable.bathroom'), editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['oneBathroom', 'twoBathroom', 'threeBathroom', 'fourBathroom', 'fiveBathroom']}},
        {accessor: 'sqft', Header: <div className="columnHeader__sqft">{t('unitTable.sqft')} <strong>(net)</strong></div>, minWidth: 96, editData: {setValue: handleUnitValueChange, type: 'number'}},
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'orientation')]?.isShown ? [{accessor: 'orientation', Header: t('unitTable.orientation'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['north', 'east', 'south', 'west', 'oneEightyDegrees', 'twoSeventyDegrees', 'threeSixtyDegrees', 'notAvailable']}}] : [],
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'position')]?.isShown ? [{accessor: 'position', Header: t('unitTable.position'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['corner', 'center', 'notAvailable']}}] : [],
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'view')]?.isShown ? [{accessor: 'view', Header: t('unitTable.view'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['city', 'garden', 'mountain', 'park', 'street', 'water', 'notAvailable']}}] : [],
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'parking')]?.isShown ? [{accessor: 'parking', Header: t('unitTable.parking'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['oneInterior', 'twoInterior', 'threePlusInterior', 'oneExterior', 'twoExterior', 'threePlusExterior', 'notAvailable']}}] : [],
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'locker')]?.isShown ? [{accessor: 'locker', Header: t('unitTable.locker'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'radio', translationsNamespace: 'unitTable', options: ['included', 'notIncluded']}}] : [],
        {accessor: 'price', Header: <div className="columnHeader__price">{t('unitTable.price')} <strong>(tx incl.)</strong></div>, minWidth: state.VSOs.selectedVSO.fields.project_type.value === 'rent' ? 160 : 132, editData: {setValue: handleUnitValueChange, type: 'number'}},
        {accessor: 'plan', Header: t('unitTable.plan'), minWidth: state.VSOs.selectedVSO.fields.project_type.value === 'rent' ? 128 : 132},
        ... state.units.columns?.filter((element) => element.isCustom === true).length > 0 ?
            state.units.columns?.filter((element) => element.isCustom === true && element.isShown)
                .map((element) => (
                    element.type === 'text' || element.type === 'date' || element.type ==='number' || element.type === 'price' || element.type === 'dimension' ?
                    {accessor: `extraContent_${element.key}`, Header: element.name.en,  minWidth: element.type === 'date' ? 160 : 132, editData: {setValue: handleUnitValueChange, type: element.type, isCustom: element.isCustom}, dimensionType: element.type === 'dimension' ? element.dimensionType : ''}
                    :
                    {accessor: `extraContent_${element.key}`, Header: element.name.en, minWidth: 132, editData: {setValue: handleUnitValueChange, type: element.type === 'toggle' ? 'radio' : element.type === 'single_select' ? 'select' : element.type, options: [...element.selectOptions], isCustom: element.isCustom}}
                )) : [],
        {accessor: 'status', Header: t('unitTable.status'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: state.productForm.newProduct.fields.project_type?.value === 'rent' ? ['available', 'reserved', 'rented'] : ['available', 'reserved', 'sold']}},
    ], [i18n.language, JSON.stringify(state.units.columns)]);

    useEffect(() => {
        actions.vsoDashboard.setCurrentPage('units');
        actions.units.loadUnits();
        actions.units.getOptionalColumns();
    }, []);

    useEffect(() => {
        if(state.units.isLoading === false) {
            setIsFirstLoad(false);
        }
    }, [state.units.isLoading]);

    const getFloors = () => {
        let floors = [];
        for (let i = 0; i <= state.VSOs.selectedVSO?.fields.num_floors.value; i++) {
            let filteredUnitsFloorLength = state.units.list.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == i : unit.unit_number.toString().slice(0, 2) == i)
            if(i === 0 ) {
                floors.push(['', `${t('floors.all', {ns: 'filterTable'})} ${t('floors.floors', {ns: 'filterTable'})}`]);
            // } else if (filteredUnitsFloorLength.length > 0) {
            } else {
                floors.push([i, 
                    (i === 1 ? `${t('floors.1st', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`:
                    i === 2 ? `${t('floors.2nd', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`:
                    i === 3 ? `${t('floors.3rd', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`:
                    i + `${t('floors.th', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`)]);
            }
        }
        return floors;
    }

    const handleClickEdit = () => {
        setIsEditMode((prevState) => !prevState);
    }

    const handleRowClick = () => {
        return;
    }

    const handleAllFloorDropdownContainer = () => {
        setIsFloorSelectorOpen(!isFloorSelectorOpen);
        const rect = allFloorsButtonRef.current.getBoundingClientRect();
        setfloorSelectorBtnCoords({
            left: rect.left + window.scrollX,
            top: rect.y + window.scrollY,
            height: rect.height,
            width: rect.width
        });
    }

    const handleChangeUnitsOrder = (oldPos, newPos) => {
        actions.units.saveUnitsOrder({oldPos: oldPos, newPos: newPos});
    }

    return (
        <div className="vsoDashUnits__container">
            <div className="vsoDashUnits__header-container">
                <div className="vsoDashUnits__header-inner-container">
                    <div className="vsoDashUnits__header">
                        <div>Units list</div>
                        <p>View and edit your unit list</p>
                    </div>
                    <div className="vsoDashUnits__view-buttons-container">
                        <Button 
                            type="pill" 
                            text={t('salesOffice.unitsPage.tableView')}
                            onClick={() => setViewMode('table_view')} 
                            options={{activeState: viewMode === 'table_view'}}
                        >
                            <SvgIcons type="cabana_table_view_icon"/>
                        </Button>
                        <Button 
                            type="pill" 
                            text={t('salesOffice.unitsPage.planView')}
                            onClick={() => setViewMode('plan_view')} 
                            options={{activeState: viewMode === 'plan_view'}}
                        >
                            <SvgIcons type="cabana_plan_view_icon"/>
                        </Button>
                        <Button 
                            type="pill" 
                            text={t('salesOffice.unitsPage.buildingView')}
                            onClick={() => setViewMode('building_view')} 
                            options={{activeState: viewMode === 'building_view'}}
                        >
                            <SvgIcons type="cabana_building_view_icon"/>
                        </Button>
                    </div>
                </div>
                <div className="vsoDashUnits__buttons-container">
                    <div className="vsoDashUnits__buttons-left-container">
                        <Button 
                            type="secondary" 
                            size="small"
                            suffix={<SvgIcons type="cabana_dropdown_icon" />} 
                            suffixGap={'36px'}
                            options={{activeState: state.units.selectedFloor !== '' || isFloorSelectorOpen, ref: allFloorsButtonRef}}
                            onClick={handleAllFloorDropdownContainer}
                            text={state.units.selectedFloor === '' ? `${t('floors.all', {ns: 'filterTable'})} ${t('floors.floors', {ns: 'filterTable'})}` : 
                                state.units.selectedFloor == 1 ? `${t('floors.1st', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`:
                                state.units.selectedFloor == 2 ? `${t('floors.2nd', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}` :
                                state.units.selectedFloor == 3 ? `${t('floors.3rd', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}` :
                                state.units.selectedFloor + `${t('floors.th', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`}
                        />
                        <Popover position="se" isOpen={isFloorSelectorOpen} setIsOpen={setIsFloorSelectorOpen} coords={floorSelectorBtnCoords} visibleBackdrop>
                            <div className="filterTable__choose-floor-container">
                                <div className="filterTable__choose-floor-header">
                                    <div className="filterTable__choose-floor-text">{t('floors.floors', {ns: 'filterTable'})}</div>
                                    <div className="filterTable__choose-floor-info">{t('floorSearch', {ns: 'filterTable'})}</div>
                                </div>
                                <div className="filterTable__choose-floor">
                                    <FilterSingleSelect value={state.units.selectedFloor} selectOptions={getFloors()} setValue={actions.units.setCurrentFloor}/>
                                </div>
                            </div>
                        </Popover>
                        {isEditMode ? <>
                        <EditColumns 
                            optionalColumns={state.units.columns} 
                            setOptionalColumns={actions.units.setOptionalColumns} 
                            setCustomColumn={actions.units.setCustomColumns} 
                            removeCustomColumn={actions.units.removeCustomColumn} 
                            addColumnsData={actions.units.addColumnsData}
                            sendEditColumnData={actions.units.sendColumnData}
                            sendAllColumnsData={actions.units.sendAllColumnsData}
                        />
                        <BatchUploadPlans
                            isDisabled={state.units.list.length < 1}
                            unitPlans={state.units.plans}
                            setUnitPlans={actions.units.setUnitPlans}
                            uploadUnitPlans={actions.units.uploadUnitPlans}
                        />
                        <DuplicateFloor
                            isDisabled={state.units.list.length < 1}
                            totalFloors={state.VSOs.selectedVSO?.fields.num_floors.value}
                        />
                        </>
                        : <></>}
                    </div>
                    <div className="vsoDashUnits__buttons-right-container">
                        <div className={`vsoDashUnits__save-status ${!isFirstLoad && state.vsoDashboard.isLoading ? '--shown' : ''}`}>
                            Saving changes...
                        </div>
                        <Button type="secondary"
                            size="small"
                            text="Edit"
                            onClick={handleClickEdit}
                            options={{activeState: isEditMode === true}}
                        >
                            <SvgIcons type="cabana_pen"/>
                        </Button>
                    </div>
                </div>
            </div>
            {isFirstLoad ? 'Loading .........'
            :
            <UnitsTable 
                units={state.units.sortedFloorUnits}
                projectType={state.VSOs.selectedVSO.fields.project_type?.value}
                isEditMode={isEditMode}
                headings={columns}
                activeFloor={state.units.selectedFloor}
                handleRowClick={handleRowClick}
                addUnit={actions.units.addUnit}
                addUnits={actions.units.addMultiUnits}
                deleteUnit={actions.units.removeUnit}
                uploadPlan={actions.units.uploadUnitPlan}
                removePlan={actions.units.removeUnitPlan}
                changeUnitsOrder={handleChangeUnitsOrder}
                showPrices={state.VSOs.selectedVSO?.show_prices}
                // sortedActiveFloor={state.units.list}
                // options={{maxHeight: '400px'}}
            />
            }
        </div>
    );
}

export default Units;