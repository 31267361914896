import React, {useState, useEffect, useRef} from "react";
import "./list.scss";
import { useTranslation } from "react-i18next";

const MultiSelectList = ({
    listName, value, handleMultiSelect, handleMultiDeselect, dataObj, isShown, translationsNamespace,
    options={isVso: false}, selectOptions}) => {
    const [fieldId, setFieldId] = useState('');
    const listRef = useRef(null);
    const { t } = useTranslation(translationsNamespace); 
    
    const sortedOptions = selectOptions.sort((a, b) => a - b);
    const numOptions = sortedOptions.filter(o => new RegExp('^[0-9]').test(t(`${options.translationKey}.${o}`)));
    const aOptions = sortedOptions.filter(o => new RegExp('^a|^A').test(t(`${options.translationKey}.${o}`)));
    const bOptions = sortedOptions.filter(o => new RegExp('^b|^B').test(t(`${options.translationKey}.${o}`)));
    const cOptions = sortedOptions.filter(o => new RegExp('^c|^C').test(t(`${options.translationKey}.${o}`)));
    const dOptions = sortedOptions.filter(o => new RegExp('^d|^D').test(t(`${options.translationKey}.${o}`)));
    const eOptions = sortedOptions.filter(o => new RegExp('^e|^E').test(t(`${options.translationKey}.${o}`)));
    const fOptions = sortedOptions.filter(o => new RegExp('^f|^F').test(t(`${options.translationKey}.${o}`)));
    const gOptions = sortedOptions.filter(o => new RegExp('^g|^G').test(t(`${options.translationKey}.${o}`)));
    const hOptions = sortedOptions.filter(o => new RegExp('^h|^H').test(t(`${options.translationKey}.${o}`)));
    const iOptions = sortedOptions.filter(o => new RegExp('^i|^I').test(t(`${options.translationKey}.${o}`)));
    const jOptions = sortedOptions.filter(o => new RegExp('^j|^J').test(t(`${options.translationKey}.${o}`)));
    const kOptions = sortedOptions.filter(o => new RegExp('^k|^K').test(t(`${options.translationKey}.${o}`)));
    const lOptions = sortedOptions.filter(o => new RegExp('^l|^L').test(t(`${options.translationKey}.${o}`)));
    const mOptions = sortedOptions.filter(o => new RegExp('^m|^M').test(t(`${options.translationKey}.${o}`)));
    const nOptions = sortedOptions.filter(o => new RegExp('^n|^N').test(t(`${options.translationKey}.${o}`)));
    const oOptions = sortedOptions.filter(o => new RegExp('^o|^O').test(t(`${options.translationKey}.${o}`)));
    const pOptions = sortedOptions.filter(o => new RegExp('^p|^P').test(t(`${options.translationKey}.${o}`)));
    const qOptions = sortedOptions.filter(o => new RegExp('^q|^Q').test(t(`${options.translationKey}.${o}`)));
    const rOptions = sortedOptions.filter(o => new RegExp('^r|^R').test(t(`${options.translationKey}.${o}`)));
    const sOptions = sortedOptions.filter(o => new RegExp('^s|^S').test(t(`${options.translationKey}.${o}`)));
    const tOptions = sortedOptions.filter(o => new RegExp('^t|^T').test(t(`${options.translationKey}.${o}`)));
    const uOptions = sortedOptions.filter(o => new RegExp('^u|^U').test(t(`${options.translationKey}.${o}`)));
    const vOptions = sortedOptions.filter(o => new RegExp('^v|^V').test(t(`${options.translationKey}.${o}`)));
    const wOptions = sortedOptions.filter(o => new RegExp('^w|^W').test(t(`${options.translationKey}.${o}`)));
    const xOptions = sortedOptions.filter(o => new RegExp('^x|^X').test(t(`${options.translationKey}.${o}`)));
    const yOptions = sortedOptions.filter(o => new RegExp('^y|^Y').test(t(`${options.translationKey}.${o}`)));
    const zOptions = sortedOptions.filter(o => new RegExp('^z|^Z').test(t(`${options.translationKey}.${o}`)));

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(listName + Math.floor(Math.random() * Date.now())));
    }, []);

    useEffect(() => {
        listRef.current.scrollTo(0,0);
    }, [isShown]);


    const handleValueChange = (e) => {
        const index = value.findIndex((elem) => {
            return elem === e.target.getAttribute('data-value');
        });
        if(index === -1) {
            handleMultiSelect(e.target.getAttribute('data-value'), dataObj);
        } else {
            handleMultiDeselect(index, dataObj);
        }
    }

    return(
        <div ref={listRef} className="multi-select__list">
            {
                options.hasAlphabet ? 
                <>
                {numOptions.length > 0 && 
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--#`}>#</div>    
                    <div className="multi-select__items-container">
                        {numOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-num-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {aOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--A`}>A</div>
                    <div className="multi-select__items-container">
                        {aOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-a-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {bOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--B`}>B</div>
                    <div className="multi-select__items-container">
                        {bOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-b-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {cOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--C`}>C</div>
                    <div className="multi-select__items-container">
                        {cOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-c-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {dOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--D`}>D</div>
                    <div className="multi-select__items-container">
                        {dOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-d-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {eOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--E`}>E</div>
                    <div className="multi-select__items-container">
                        {eOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-e-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {fOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--F`}>F</div>
                    <div className="multi-select__items-container">
                        {fOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-f-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {gOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--G`}>G</div>
                    <div className="multi-select__items-container">
                        {gOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-g-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {hOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--H`}>H</div>
                    <div className="multi-select__items-container">
                        {hOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-h-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {iOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--I`}>I</div>
                    <div className="multi-select__items-container">
                        {iOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-i-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {jOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--J`}>J</div>
                    <div className="multi-select__items-container">
                        {jOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-j-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {kOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--K`}>K</div>
                    <div className="multi-select__items-container">
                        {kOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-k-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {lOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--L`}>L</div>
                    <div className="multi-select__items-container">
                        {lOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-l-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {mOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--M`}>M</div>
                    <div className="multi-select__items-container">
                        {mOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-m-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {nOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--N`}>N</div>
                    <div className="multi-select__items-container">
                        {nOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-n-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {oOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--O`}>O</div>
                    <div className="multi-select__items-container">
                        {oOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-o-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {pOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--P`}>P</div>
                    <div className="multi-select__items-container">
                        {pOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-p-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {qOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--Q`}>Q</div>
                    <div className="multi-select__items-container">
                        {qOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-q-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {rOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--R`}>R</div>
                    <div className="multi-select__items-container">
                        {rOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-r-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {sOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--S`}>S</div>
                    <div className="multi-select__items-container">
                        {sOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-s-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {tOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--T`}>T</div>
                    <div className="multi-select__items-container">
                        {tOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-t-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {uOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--U`}>U</div>
                    <div className="multi-select__items-container">
                        {uOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-u-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {vOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--V`}>V</div>
                    <div className="multi-select__items-container">
                        {vOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-v-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {wOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--W`}>W</div>
                    <div className="multi-select__items-container">
                        {wOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-w-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {xOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--X`}>X</div>
                    <div className="multi-select__items-container">
                        {xOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-x-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {yOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--Y`}>Y</div>
                    <div className="multi-select__items-container">
                        {yOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-y-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                {zOptions.length > 0 &&
                <>
                <div className="multi-select__category" id={`multi-select-${fieldId}__letter--Z`}>Z</div>
                    <div className="multi-select__items-container">
                        {zOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-z-${index}`} className={`multi-select__item ${value?.find(elem => elem === o) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o}>{t(`${options.translationKey}.${o}`)}</div>
                            )
                        })}
                    </div>
                </>
                }
                </>
                :
                <>
                <div className="multi-select__items-container">
                    {
                        sortedOptions.map((o, index) => {
                            return(
                                <div key={`multi-select__item-${fieldId}-${index}`} className={`multi-select__item --no-alphabet ${value?.find(elem => elem === o[0]) ? `selected` : ''}`}  onClick={handleValueChange} data-value={o[0]}>{o[1]}</div>
                            )
                        })
                    }
                </div>
                </>
            }
        </div>
    )
}

export default MultiSelectList;