import React, {useState, useEffect} from "react";
import "./numberInput.scss";

const NumberInput = ({value, setValue, placeholder, dataObj, suffix, options}) => {
    const [fieldId, setFieldId] = useState('');

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(`nti-${Math.floor(Math.random() * Date.now())}`));
    }, []);

    const handleFocus = (e) => {
        if(e.currentTarget.value === "0"){
            e.currentTarget.select();
        }
    }

    const handleValueChange = (e) => {

        if(e.currentTarget.value === "") { 
            e.currentTarget.value = "0";
        }

        if(e.currentTarget.value === "0"){
            e.currentTarget.select();
        }
        
        dataObj.newValue = Number(e.currentTarget.value);
        setValue(dataObj);
    }

    return(
        <div className="tableInput__number-container">
            <input id={`input__${fieldId}`} className={`tableInput__number ${dataObj.valueKey === "price" ? "--price" : ""}`} type="number" value={value} onChange={handleValueChange} placeholder={placeholder} onFocus={handleFocus}/>
            {suffix &&
            <span>{suffix}</span>
            }
        </div>
    )
}

export default NumberInput;