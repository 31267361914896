import React from 'react';
import { createPortal } from 'react-dom';

import './popover.scss';

const Popover = ({children, isOpen, coords, setIsOpen, position = 's', visibleBackdrop}) => {

    const handleBackdropClick = () => {
        setIsOpen(false);
    }

    return(
        <>
        {
        coords ?
            createPortal(
                <div className={`popover__portal ${isOpen ? '' : '--closed'}`} style={coords}>
                    {isOpen &&
                        <div className={`popover__backdrop ${visibleBackdrop ? '--visible' : ''}`} onClick={handleBackdropClick}></div>
                    }
                    <div className={`popover__container ${isOpen ? '' : '--closed'} --${position}`} onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </div>, document.querySelector('#popovers')
            )
            :
            <>
            {isOpen &&
            <div className="popover__backdrop" onClick={handleBackdropClick}></div>
            }
            <div className={`popover__container ${isOpen ? '' : '--closed'} --${position}`}>
                {children}
            </div>
            </>
        }
        </>
    );
}

export default Popover;