import React from "react";
import { DndContext, closestCenter, useSensors, useSensor, PointerSensor, KeyboardSensor} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, sortableKeyboardCoordinates,} from '@dnd-kit/sortable';


const DragAndDrop = ({value, onSortEnd, ...props}) => {

    const sensors = useSensors(useSensor(PointerSensor, {activationConstraint: {
        distance: 1,
      },}), useSensor(KeyboardSensor, {coordinateGetter: sortableKeyboardCoordinates}));

    return(
        <DndContext onDragEnd={onSortEnd} collisionDetection={closestCenter} sensors={sensors}>
            <SortableContext items={Array.isArray(value) ? value : []} strategy={rectSortingStrategy}>
                {props.children}
            </SortableContext>
        </DndContext>
    )
}

export default DragAndDrop;
