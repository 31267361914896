import React, { useState, useEffect, useMemo } from 'react';
import { useOvermind } from 'src/overmind';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/shared/table';
import Modal from 'src/components/shared/modal';

import './leads.scss';

const Leads = () => {
    const { state, actions } = useOvermind();
    const { t, i18n } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessageId, setModalMessageId] = useState(null);
    
    const columns = useMemo(() => 
    [
        {accessor: 'created_at', Header: 'Date', minWidth: 64},
        {accessor: 'name', Header: 'Name', minWidth: 64},
        {accessor: 'lead_id', Header: 'Email', minWidth: 96},
        {accessor: 'phone_number', Header: 'Phone', minWidth: 64},
        {accessor: 'unit', Header: 'Unit', minWidth: 32},
        {accessor: 'message', Header: 'Message', minWidth: 96},
        {accessor: 'action', Header: 'Action', minWidth: 48}
    ], [i18n.language]);

    useEffect(() => {
        actions.vsoDashboard.setCurrentPage('leads');
        actions.vsoDashboard.getLeadsMessages();
    }, []);

    const handleClickRow = (id) => {
        setIsModalOpen(true);
        setModalMessageId(id);
    }

    return (
        <>
        <div className="vsoDashUnits__container">
            <div className="vsoDashUnits__header-container">
                <div className="vsoDashUnits__header-inner-container">
                    <div className="vsoDashUnits__header">
                        <div>Leads</div>
                        <p>Leads text</p>
                    </div>
                </div>
            </div>
            <Table 
                data={state.vsoDashboard.leadsMessages.data}
                columns={columns}
                handleClickRow={handleClickRow}
                noDataMessage="No lead has left a message yet..."
            />
        </div>
        <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <div className="leads__modal-container">
                <div className="leads__modal-header">
                    <div>
                        {state.vsoDashboard.leadsMessages.getMessageById(modalMessageId)?.name}
                    </div>
                    <div>
                        {state.vsoDashboard.leadsMessages.getMessageById(modalMessageId)?.created_at}
                    </div>
                </div>
                <div className="leads__modal-contact-info">
                    <div>{state.vsoDashboard.leadsMessages.getMessageById(modalMessageId)?.lead_id}</div>
                    <div>{state.vsoDashboard.leadsMessages.getMessageById(modalMessageId)?.phone_number}</div>
                </div>
                <div className="leads__modal-body">
                    <div>
                        <div className="leads__modal-text --bold">Unité</div>
                        <div className="leads__modal-text">{state.vsoDashboard.leadsMessages.getMessageById(modalMessageId)?.unit}</div>
                    </div>
                    <div>
                        <div className="leads__modal-text --bold">Message</div>
                        <div className="leads__modal-text">{state.vsoDashboard.leadsMessages.getMessageById(modalMessageId)?.message}</div>
                    </div>
                </div>
            </div>
        </Modal>
        </>
    );
}

export default Leads;