import React, { useState, useRef } from "react";
import TextAreaInput from "src/components/shared/inputs/textAreaInput";
import SvgIcons from "src/components/shared/svgIcons";
import "./selectOptionPanel.scss";
import ErrorMessage from "src/components/shared/errorMessage";
import Modal from "src/components/shared/modal";
import Tooltip from "src/components/shared/tooltip";

const SelectOptionPanel = ({customOptions, setCustomOptions}) => {
    const [selectOptions, setSelectOptions] = useState("");
    const [emptyOptionError, setEmptyOptionError] = useState(false);
    const [infoContainer, setInfoContainer] = useState(false);
    let placeholderText = "Option #1\n\nOption #2\n\nOption #3";
    let textAreaInputRef = useRef(null);

    const handleSelectOption = () => {
        if(selectOptions.trim() === "" || selectOptions === "") {
            setEmptyOptionError(true);
            setSelectOptions("");
            textAreaInputRef.current.blur();
        } else {
            let optionsArr = selectOptions.split('\n').filter((el) => el.trim() !== "");
            setCustomOptions([...new Set([...customOptions, ...optionsArr])]);
            setSelectOptions("");
        }
    }

    const handleRemoveOption = (element) => {
        let filterArray = customOptions.filter((el) => el !== element);
        setCustomOptions(filterArray);
    }

    const handleEnterKey = (e) => {
        if(e.keyCode === 13 && !e.shiftKey) {
            if(selectOptions.trim() === "" || selectOptions === "") {
                setEmptyOptionError(true);
                setSelectOptions("");
                textAreaInputRef.current.blur();
            } else {
                e.preventDefault();
                let optionValues = e.target.value.split("\n").filter((el) => el.trim() !== "");
                setCustomOptions([...new Set([...customOptions, ...optionValues])]);
                setSelectOptions("");
                textAreaInputRef.current.blur();
            }
        }
    }
    
    const handleCustomOptionSort = () => {
        let filteredArray = customOptions.sort();
        setCustomOptions([...filteredArray]);
    }

    return(
        <div className="selectOptionPanel__container">
            <div className="selectOptionPanel__helper-container">
                <div className="selectOptionPanel__label-info-container">
                    <div className="form__input-group">
                        <label>Options (required)</label>
                    </div>
                    <div className="selectOptionPanel__info-icon-container" onMouseEnter={() => setInfoContainer(true)} onMouseLeave={() => setInfoContainer(false)}>
                        <SvgIcons type="circle_info_icon"/>
                        <Tooltip text="Enter the options one by one or write several options on separate lines by hitting Shift + Enter" isOpen={infoContainer} options={{maxWidth: '300px'}}/>
                    </div>
                </div>
                <div className="selectOptionPanel__sort-container" onClick={handleCustomOptionSort}>
                    <SvgIcons type="sort_alpha_icon" />
                </div>
            </div>
            <div className={`selectOptionPanel__options ${customOptions.length > 0 ? "--active" : ""}`}>
                {
                    customOptions?.map((element, index) => {
                        return(
                            <div className="selectOptionPanel__option-item-container">
                                <div className="selectOptionPanel__option-item">{element}</div>
                                <div className="selectOptionPanel__remove_item" onClick={() => handleRemoveOption(element)}><SvgIcons type="trash_can" /></div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="selectOptionPanel__input-options">
                <TextAreaInput placeholder={placeholderText} onKeyDown={(e) => handleEnterKey(e)}
                            value={selectOptions} setValue={(e) => setSelectOptions(e.target.value)} options={{ref: textAreaInputRef}}/>
                <div className="selectOptionPanel__checkmark" onClick={handleSelectOption}>
                    <SvgIcons type="green_checkmark" />
                </div>
            </div>
            <Modal isOpen={emptyOptionError} 
            setIsOpen={setEmptyOptionError} 
            options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={() => setEmptyOptionError(false)} 
                    items={[
                        {content: <div>Options cannot be empty</div>},
                        ]}
                    errorCode={'1310'}
                    />   
            </Modal>
        </div>
    )
}

export default SelectOptionPanel;