import React, { useState, useEffect } from 'react';
import { useOvermind } from 'src/overmind';
import { useTranslation } from 'react-i18next';

import './gallery.scss';

import leftIcon from 'src/assets/images/icons/left_arrow.svg';
import rightIcon from 'src/assets/images/icons/right_arrow.svg';
import SvgIcons from 'src/components/shared/svgIcons';

const Gallery = ({closeElement}) => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation();

    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        actions.topbar.setCurrentPage('images');
        
        return () => {
            actions.topbar.setCurrentPage('');
        }
    }, [])

    const handleClickPrevious = () => {
        if(currentImage === 0) {
            setCurrentImage(state.currentVSO.fields.images?.length - 1);
        } else {
            setCurrentImage(currentImage - 1);
        }
    }

    const handleClickNext = () => {
        if(currentImage === state.currentVSO.fields.images?.length - 1) {
            setCurrentImage(0);
        } else {
            setCurrentImage(currentImage + 1);
        }
    }

    const handleClickThumbnail = (e) => {
        setCurrentImage(parseInt(e.currentTarget.getAttribute('data-thumbnail-index')));
    }

    const imageName = (name) => {
        let tempName = name.split(".")
        return tempName.slice(0, tempName.length - 1).join(".");
    }

    return (
        <>
        <div className="officeImages__container">
            <div className="officeImages__image-topbar">
                <div className="officeImages__back-button" onClick={closeElement}>
                    <SvgIcons type="cabana_left_arrow_short"/>
                    {t('salesOffice.gallery.return')}
                </div>
            </div>
            <div className="officeImages__backdrop" onClick={closeElement}></div>
            <div className="officeImages__button --previous"  onClick={handleClickPrevious}>
                <SvgIcons type="cabana_left_arrow"/>
            </div>
            <div className="officeImages__button --next"  onClick={handleClickNext}>
                <SvgIcons type="cabana_right_arrow"/>
            </div>
            <div className="officeImages__images-container">
                {state.currentVSO.fields.images?.map((image, index) => 
                <div className={`officeImages__image${currentImage === index ? ' --current' : ''}`} key={`images-${index}`}>
                    <img src={image.src} />
                </div>
                )}
            </div>
            <div className="officeImages__thumbnails-container">
            <div className="officeImages__image-title">{imageName(state.currentVSO.fields.images?.[currentImage]?.name)}</div>
                <div className="officeImages__vertical-dots">
                    {state.currentVSO.fields.images?.map ((_, index) => {
                        return(
                            <div key={`vertical-dots-${index}`} className={`officeImages__dots ${index === currentImage && "active"}`} data-thumbnail-index={index} onClick={handleClickThumbnail}/>
                        )
                    })}
                </div>
            </div>
        </div>
        </>
    );
}

export default Gallery;