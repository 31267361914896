export const loadMarketTests = async ({state, effects}) => {
    try {
        const marketTests = await effects.marketTests.fetchMarketTests();
        for(const mt of marketTests) {
            let fieldsObj = {};
            for(const f of mt.fields) {
                if(f.field_value != null) {
                    let tempVal = f.field_value;
                    if(f.field_value.includes('{"')) {
                        tempVal = JSON.parse(f.field_value);
                    }
                    fieldsObj[f.field_content_id] = 
                    {
                        value: tempVal,
                    }
                }
            }
            mt.fields = fieldsObj;
        }

        state.marketTests.marketTestsList = marketTests;

    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const loadAllMarketTests = async ({state, effects}) => {
    try {
        const marketTests = await effects.marketTests.fetchAllMarketTests();
        for(const mt of marketTests) {
            let fieldsObj = {};
            for(const f of mt.fields) {
                if(f.field_value != null) {
                    let tempVal = f.field_value;
                    if(f.field_value.includes('{"')) {
                        tempVal = JSON.parse(f.field_value);
                    }
                    fieldsObj[f.field_content_id] = 
                    {
                        value: tempVal,
                    }
                }
            }
            mt.fields = fieldsObj;
        }

        state.marketTests.marketTestsList = marketTests;
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const loadMarketTestFields = async ({state, effects},id) => {
    try {
        const i = String(id);
        const marketTestFields = await effects.marketTests.fetchMarketTestFields(id);
        state.marketTests.marketTestFieldsList = marketTestFields;
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const deleteMarketTest = async ({state, effects}, id) => {
    try {
        const result = await effects.marketTests.deleteMarketTest(id);
        if(result.success) {
            const mtIndex = state.marketTests.marketTestsList.findIndex(mt => mt.market_test_id == id);
            state.marketTests.marketTestsList.splice(mtIndex, 1);
        }
    } catch (err) {
        console.error(err.message);
        return err;
    }
}