export const floorNaming = (num) => {
    let j = num % 10, k = num % 100;
    if (j == 1 && k != 11) {
        return num + 'st';
    }
    if (j == 2 && k != 12) {
        return num + 'nd';
    }
    if (j == 3 && k != 13) {
        return num + 'rd';
    }
    return num + 'th'; 
}

export const getActiveFloorUnits = (units, floor) => {
    if(units.length === 0) {
        return [];
      }
      return units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == floor : unit.unit_number.toString().slice(0, 2) == floor)
      .sort((unitA, unitB) => {
          if(unitA.unit_number > unitB.unit_number) {
              return 1;
          } else if(unitA.unit_number < unitB.unit_number) {
              return -1;
          }
          return 0;
      })
}

export const checkOutlineContentExists = (floorUnits) => {
    for (const unit of floorUnits) {
        if(unit.outline_content.isFinished) {
            return true;
        }
    }
    return false;
}