export const fetchListing = async (marketTestId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'listings/' + marketTestId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const sendResult = async (body) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'results/lead/',
            {
                method: 'POST',
                headers: {'Content-Type' : 'application/json'},
                body: JSON.stringify(body)
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}