import React from "react";
import "./headerBanner.scss";
import SvgIcons from "../svgIcons";

const HeaderBanner = ({headerTitle, infoText, setIsOpen, options={vsoForm: false}}) => {

    return(
        <div ref={options?.ref} className={`headerBanner__container ${options.vsoForm ? "--form" : ""}`}>
            <div className="headerBanner__close-button" onClick={setIsOpen}>
                <SvgIcons type="cabana_white_close" />
            </div>
            <h4>{headerTitle}</h4>
            <p>{infoText}</p>
        </div>
    );
}

export default HeaderBanner;