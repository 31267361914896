import React, { useState } from 'react';
import Popover from '../popover';
import SvgIcons from '../svgIcons';
import './infoPopover.scss';

const InfoPopover = ({content}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [coords, setCoords] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const handleHoverIn = (e) => {
        // setIsHovered(true);
        setIsOpen(true);
        const rect = e.target.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY
          });
    }

    const handleClick = (e) => {
        const rect = e.target.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY
          });
        setIsOpen(true);
    }

    return(
        <div className="infoPopover__container">
            <div className="infoPopover__icon-container" onClick={handleClick}>
                <SvgIcons type="circle_info_icon" />
            </div>
            <Popover
                position='n'
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                coords={coords}
                visibleBackdrop
            >
                {content}
            </Popover>
        </div>
    )
}

export default InfoPopover;