export const flatten = (items) => {
    const flat = [];
        items.forEach((item) => {
            if (Array.isArray(item)) {
                flat.push(...flatten(item));
            } else {
                flat.push(item);
            }
        });
        return flat;
}

export const findCenterOfPolygon = (points) => {
    let ans = new Array(2);
    ans.fill(0);
       
    let n = points.length;
    let signedArea = 0;
       
    // For all vertices
    for (let i = 0; i < n; i++)
    {
           
        let x0 = points[i][0];
        let y0 = points[i][1];
        let x1 = points[(i + 1) % n][0];
        let y1 = points[(i + 1) % n][1];
                               
        let A = (x0 * y1) - (x1 * y0);
        signedArea += A;
           
        // Calculating coordinates of
        // centroid of polygon
        ans[0] += (x0 + x1) * A;
        ans[1] += (y0 + y1) * A;
    }
   
    signedArea *= 0.5;
    ans[0] = (ans[0]) / (6 * signedArea) - 30;
    ans[1] = (ans[1]) / (6 * signedArea) - 20;
   
    return ans;
};