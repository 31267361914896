import React, { useEffect } from 'react';
import { useOvermind } from 'src/overmind';
import { useParams, useLocation } from 'react-router';
import Sidebar from './sidebar';

import Dashboard from './pages/dashboard';
import Units from './pages/units';
import Deals from './pages/deals';
import Leads from './pages/leads';
import Stats from './pages/stats';
import Settings from './pages/settings';

import './vsoDashboard.scss';

const VsoDashboard = ({setAuth}) => {
    const { actions, effects } = useOvermind();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        actions.sidebar.setPage('dashboard');
        actions.topbar.showShadow();
        actions.topbar.showLogo();

        effects.currentUser.verifyToken().then((res) => {
            if(res) {
                actions.currentUser.loadCurrentUser();
                actions.VSOs.loadVSOs().then(() => {
                    actions.VSOs.setSelectedVSOid(params.id);
                });
                actions.vsoDashboard.setVsoId(params.id);
            }
            else {
                setAuth(false);
            }
        });
    }, []);

    return (
        <div className="vsoDashboard__container">
            <Sidebar />
            <div className="vsoDashboard__content-container">
                { location.pathname.includes('units') ? <Units />
                : location.pathname.includes('deals') ? <Deals />
                : location.pathname.includes('leads') ? <Leads />
                : location.pathname.includes('stats') ? <Stats />
                : location.pathname.includes('settings') ? <Settings />
                : <Dashboard />
                }
            </div>
        </div>
    );
}

export default VsoDashboard;