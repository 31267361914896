import React from "react";
import "./modal.scss";
import { createPortal } from "react-dom";

const Modal = ({isOpen, setIsOpen, children, options={animation: false, disableBackdropClick: false, contentCenter: true}}) => {

   return(
        <>
            {isOpen ? 
            createPortal(
                <div className={`modal__backdrop ${options.animation ? `--animation-${options.animation}` : ''} ${options.contentCenter ? "--contentCenter" : ""}`} 
                    onClick={() => !options.disableBackdropClick && setIsOpen(false)}
                >
                    <div className="modal__panel" onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </div>, document.querySelector('#modals'))      
            :
            <></>
            }
        </>
    )
}

export default Modal;