import Button from 'src/components/shared/button';
import React, { useState, useEffect, useRef } from 'react';
import SvgIcons from 'src/components/shared/svgIcons';
import { useOvermind } from 'src/overmind';
import DrawingCanvas from 'src/components/shared/drawingCanvas';
import { flatten } from 'src/utils/canvas';
import './floorPlanOutline.scss';
import { floorNaming, checkOutlineContentExists, getActiveFloorUnits } from 'src/utils/units';
import SelectInput from 'src/components/shared/inputs/selectInput';
import { useHistory } from 'react-router-dom';
import { useResizeContainer } from 'src/utils/hooks';
import Modal from 'src/components/shared/modal';
import CopyFloor from 'src/components/shared/copyFloor';
import HeaderBanner from 'src/components/shared/headerBanner';
import { checkFileSize, checkFileValid } from 'src/utils/fileUpload';
import ErrorMessage from 'src/components/shared/errorMessage';
import WarningMessage from 'src/components/shared/warningMessage';

const FloorPlanOutline = ({changeStep, steps, validateForm}) => {
    const [currentUnit, setCurrentUnit] = useState(0);
    const [points, setPoints] = useState([]);
    const [flattenedPoints, setFlattenedPoints] = useState([]);
    const [isClosed, setIsClosed] = useState(false);
    const [isCopyFloorOpen, setIsCopyFloorOpen] = useState(false);
    const [chooseFloors, setChooseFloors] = useState([]);
    const [selectFloor, setSelectFloor] = useState('');
    const [warningMessage, setWarningMessage] = useState(false);
    const [fileNameErrorModal, setFileNameErrorModal] = useState(false);
    const [fileSizeErrorModal, setFileSizeErrorModal] = useState(false);
    const history = useHistory();
    const floorPlanRef = useRef(null);
    const floorUploadRef = useRef(null)

    const {state, actions, effects} = useOvermind();

    const { containerWidth, containerHeight } = useResizeContainer(floorPlanRef);

    useEffect(() => {
        checkIfUnitNotFinished();
    }, []);

    useEffect(() => {
        checkIfUnitNotFinished();
    }, [state.units.sortedFloorUnits]);

    useEffect(() => {
        if(state.productForm.newProduct.fields.floor_plans?.value?.array[state.units.selectedFloor - 1]?.plan == '') {
            setIsClosed(true);
        }
    }, [state.productForm.newProduct.fields.floor_plans?.value?.array[state.units.selectedFloor - 1]?.plan]);

    const checkIfUnitNotFinished = () => {
        for (const [index, unit] of state.units.sortedFloorUnits.entries()) {
            if(!unit.outline_content?.isFinished && unit.outline_content?.points?.length > 0) {
                setCurrentUnit(index);
                setIsClosed(true);
                setPoints([...unit.outline_content?.points]);
                setFlattenedPoints(flatten([...unit.outline_content?.points]));
                return;
            } else if(!unit.outline_content.isFinished && unit.outline_content?.points?.length == 0) {
                setPoints([]);
                setFlattenedPoints([]);
                setCurrentUnit(index);
                setIsClosed(false);
                return;
            } else {
                setPoints([]);
                setFlattenedPoints([]);
            }
        }
        setCurrentUnit(0);
        setIsClosed(true);
    }
         
    const handleSaveClick = (item) => {
        let tempObj = {
            points: points,
            isFinished: true
        }
        actions.units.setUnitPoints({updatedContents: tempObj, unit: item}).then(() => {
            setIsClosed(false);
            setPoints([]);
            setFlattenedPoints([]);
        });
    }

    const handleEditClick = async (item, index) => {
        if(index !== currentUnit) {
            if(state.units.sortedFloorUnits[currentUnit]?.outline_content.points.length > 0) {
                let unitObj = {
                    ...state.units.sortedFloorUnits[currentUnit]?.outline_content,
                    isFinished: true
                }
                await actions.units.setUnitPoints({updatedContents: unitObj, unit: state.units.sortedFloorUnits[currentUnit]});
            }
        }
        let tempObj = {
            points: [...item?.outline_content?.points],
            isFinished: false,
        }
        actions.units.setUnitPoints({updatedContents: tempObj, unit: item});
    }

    const handleDeleteClick = async (item, index) => {
        if(index !== currentUnit) {
            if(state.units.sortedFloorUnits[currentUnit]?.outline_content.points.length > 0) {
                let unitObj = {
                    ...state.units.sortedFloorUnits[currentUnit]?.outline_content,
                    isFinished: true
                }
                await actions.units.setUnitPoints({updatedContents: unitObj, unit: state.units.sortedFloorUnits[currentUnit]});
            }
        }
        let tempObj = {
            points: [],
            isFinished: false
        }
        actions.units.setUnitPoints({updatedContents: tempObj, unit: item});
    }

    const handleSaveAndExit = () => {
        history.push('/virtual-office-list');
    }

    const handleBackClick = () => {
        actions.units.setCurrentFloor(1);
        changeStep(steps - 1);
    }

    const handleNextClick = () => {
        validateForm(false);
        actions.units.setCurrentFloor(1);
        actions.productForm.incrementFormStep();

    }

    const getFloorOptions = () => {
        let selectedFloorArr = [];
        for (let i = 1; i <= parseInt(state.productForm.newProduct.fields.num_floors?.value); i++) { 
            let tempArr = Array.from(state.units.list.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == i : unit.unit_number.toString().slice(0, 2) == i))
            if(tempArr.length > 0){
                selectedFloorArr.push([i, `${floorNaming(i)} floor`])
            }
        }
        return selectedFloorArr;
    }

    const handleFloorSelect = (e) => {
        /*Check if a unit is being outlined and editing*/
        let tempArr = state.units.sortedFloorUnits;
        if(tempArr[currentUnit].outline_content.points.length > 0 && !tempArr[currentUnit].outline_content.isFinished) {
            let tempObj = {
                points: tempArr[currentUnit].outline_content.points,
                isFinished: true
            }
            actions.units.setUnitPoints({updatedContents: tempObj, unit: tempArr[currentUnit]});
        }
        actions.units.setCurrentFloor(e.target.value)
    }

    const selectFloorOption = () => {
        let tempArr = [];
        for (let i = 1; i <= state.productForm.newProduct.fields.num_floors.value; i++) {
            let floorUnits = getActiveFloorUnits(state.units.list, i);
            if(state.productForm.newProduct.fields.floor_plans?.value?.array[i - 1]?.plan != '' && checkOutlineContentExists(floorUnits)) {
                tempArr.push([i, `${floorNaming(i)} floor`]);
            }
        }
        return tempArr;
    }

    const chooseFloorOptions = () => {
        let tempArr = [];
        for (let i = 1; i <= state.productForm.newProduct.fields.num_floors.value; i++) {
            if(state.productForm.newProduct.fields.floor_plans?.value?.array[i - 1]?.plan != '') {
                tempArr.push(i);
            }
        }
        return tempArr;
    }

    const handleModalClose = () => {
        setChooseFloors([]);
        setSelectFloor('');
        setIsCopyFloorOpen(false);
    }

    const handeCopyFloorBackClick = () => {
        setChooseFloors([]);
        setSelectFloor('');
        setIsCopyFloorOpen(false);
    }

    const handleCopyNextClick = () => {
        if(selectFloor == '') {
            return;
        } else if (chooseFloors.length == 0) {
            return;
        } else {
            let tempObj = {
                selectedFloor: selectFloor,
                floorsArr: chooseFloors
            }
            actions.units.copyOutlineFloors(tempObj).then(() => {
                setChooseFloors([]);
                setSelectFloor('');
                setIsCopyFloorOpen(false);
                setIsClosed(true);
            }) 
        }
    }

    const handleUploadNewFloorPlan = () => {
        let activeFloor = parseInt(state.units.selectedFloor);
        let tempArray = getActiveFloorUnits(state.units.list, activeFloor);
        if(checkOutlineContentExists(tempArray)) {
            setWarningMessage(true);
        } else {
            floorUploadRef.current.click();
        }
    }

    const handleWarningConfirmClick = () => {
        setWarningMessage(false);
        floorUploadRef.current.click();
    }

    const handleFloorPlanChange = () => {
        if(floorUploadRef.current.files.length > 0) {
            if(checkFileValid([floorUploadRef.current.files[0]], setFileNameErrorModal, true)){
                if(!checkFileSize(floorUploadRef.current.files[0])) {
                    let activeFloor = parseInt(state.units.selectedFloor);
                    let tempArray = getActiveFloorUnits(state.units.list, activeFloor);
                    if(checkOutlineContentExists(tempArray)) {
                        for (const item of tempArray) {
                            let tempObj = {
                                points: [],
                                isFinished: false,
                            }
                            actions.units.setUnitPoints({updatedContents: tempObj, unit: item});
                        }
                    }
                    let currentFloorIndex = Number(state.units.selectedFloor) - 1
                    setTimeout(() => {
                        let formData = new FormData();
                        formData.append('floorPlan', floorUploadRef.current.files[0]);
                        formData.append('floorId', state.productForm.newProduct.fields.floor_plans?.value?.array[currentFloorIndex]?.name)
                        effects.productForm.uploadFloorPlan(state.productForm.newProduct.id, formData)
                        .then((res) => {
                            let tempObj = {
                                ...state.productForm.newProduct.fields.floor_plans?.value?.array[currentFloorIndex],
                                plan: res,
                                isUploaded: true
                            };
                            actions.productForm.setFloorPlan(tempObj);
                            actions.productForm.updateProductFields(['floor_plans']);
                            setIsClosed(false);
                        });
                    }, 500);
                } else {
                    floorUploadRef.current.value = null;
                    setFileSizeErrorModal(true);
                }
            }
        }
    }

    const handleTypeError = () => {
        setFileNameErrorModal(!fileNameErrorModal);
        floorUploadRef.current.value = '';
    }

    const handleFileSizeError = () => {
        setFileSizeErrorModal(!fileSizeErrorModal);
        floorUploadRef.current.value = '';
    }

    return(
        <div className="floorPlanOutline__container">
            <div className="floorPlanOutline__split-container">
                <div className="floorPlanOutline__outline-container"> 
                    <div className="floorPlanOutline__outline-change-image">
                        <div className="floorPlanOutline__outline-text">Outline <strong>unit {state.units?.sortedFloorUnits[currentUnit]?.unit_number}</strong></div>
                        <Button type="secondary" text="Copy floor outline" onClick={() => setIsCopyFloorOpen(true)}><SvgIcons type="copy_table"/></Button> 
                    </div>
                    <div ref={floorPlanRef} className="floorPlanOutline__floor-plan-outline">
                        <DrawingCanvas 
                            type="floor"
                            editMode={true}
                            points={points}
                            setPoints={setPoints}
                            isClosed={isClosed}
                            setIsClosed={setIsClosed}
                            flattenedPoints={flattenedPoints}
                            setFlattenedPoints={setFlattenedPoints}
                            activeFloor={state.units.selectedFloor}
                            containerWidth={containerWidth}
                            containerHeight={containerHeight}
                            imageUrl={state.productForm.newProduct.fields.floor_plans?.value?.array[state.units.selectedFloor - 1]?.plan}
                            data={state.units.sortedFloorUnits}
                        />
                        <div className="floorPlanOutline__change-plan-container">
                            <Button type="secondary" text="Upload a new floor plan" onClick={handleUploadNewFloorPlan}><SvgIcons type="cabana_floor_plan_upload"/></Button>
                            <input
                                type="file"
                                ref={floorUploadRef}
                                onChange={handleFloorPlanChange}
                                style={{display: 'none'}} 
                            />
                        </div>
                    </div>
                </div>
                <div className="floorPlanOutline__units-container">
                    <div className="floorPlanOutline__units-save-exit-container">
                        <div className="floorPlanOutline__units-guide-select-container">
                            <div className="floorPlanOutline__units-guide-container">
                                <div className="floorPlanOutline__units-guide-title">Outline project units</div>
                                <div className="floorPlanOutline__units-guide-info">Draw every units of the project</div>
                            </div>
                            <SelectInput 
                                options={{isVso: true}} 
                                selectOptions={getFloorOptions()} 
                                setValue={handleFloorSelect}
                                value={state.units.selectedFloor}
                            />
                        </div>
                        <Button type="primary" onClick={handleSaveAndExit}>Save floors and exit</Button>
                    </div>
                    <div className="floorPlanOutline__units-items-container">
                        {state.units.sortedFloorUnits.map((el, index) => {
                        return (
                            <div className="floorPlanOutline__units-items">
                                <div className="floorPlanOutline__unit-item-text-icon">
                                    <div className="unit-item-text">Unit {el.unit_number}</div>
                                        {
                                        el.outline_content?.isFinished ? 
                                        <SvgIcons type="cabana_green_circle_checkmark" />
                                        : 
                                        index === currentUnit ? 
                                        <SvgIcons type="cabana_blue_right_arrow_circle" />
                                        :
                                        <div className="floorPlanOutline__checkmark-placeholder" />     
                                        }   
                                </div>
                                {el.outline_content?.isFinished ? (
                                    <div className="floorPlanOutline__edit-delete-buttons">
                                        <Button type="secondary" style={{padding: '6px 16px'}} onClick={() => handleEditClick(el, index)}><SvgIcons type="cabana_edit"/>Edit</Button>
                                        <Button type="secondary" style={{padding: '6px 16px'}} onClick={() => handleDeleteClick(el, index)}><SvgIcons type="cabana_trashcan"/>Delete</Button>
                                    </div>
                                ) : index == currentUnit ? (
                                    <div className="floorPlanOutline__edit-delete-buttons">
                                        <Button type="primary" style={{padding: '6px 16px'}} onClick={() => handleSaveClick(el, index)}><SvgIcons type="cabana_save"/>Save</Button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                        })}
                    </div>
                    <div className="floorPlanOutline__options-container">
                        <div className="floorPlanOutline__divider" />
                        <div className="floorPlanOutline__button-options">
                            <Button type="ternary" onClick={handleBackClick}>Back</Button>
                            <Button type="primary"onClick={handleNextClick}>Next</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isCopyFloorOpen} setIsOpen={handleModalClose} options={{contentCenter: true, disableBackdropClick: true}}>
                <div className="floorPlanOutline__copy-floor-modal">
                    <HeaderBanner 
                        headerTitle="Copy floor to floor(s)"
                        infoText="Copy the floor outline to the floors desired" 
                        options={{vsoForm: true}}
                        setIsOpen={handleModalClose}
                    />
                    <CopyFloor 
                        floorOptions={selectFloorOption()} 
                        chooseFloorOptions={chooseFloorOptions()}
                        targetFloors={chooseFloors}
                        setTargetFloors={setChooseFloors}
                        copiedFloor={selectFloor}
                        setCopiedFloor={setSelectFloor}
                        handleBackClick={handeCopyFloorBackClick}
                        handleNextClick={handleCopyNextClick}
                    />
                </div>
            </Modal>
            <Modal isOpen={fileNameErrorModal} 
                setIsOpen={setFileNameErrorModal} 
                options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={handleTypeError}
                        items={[
                            {content: <div>The file format for one or multiple uploaded floor plan is invalid.</div>},
                            {content: <div>Suported files include: .jpg, .jpeg, .png</div>}
                        ]}
                        errorCode={'1306'}/>
            </Modal>
            <Modal isOpen={fileSizeErrorModal} setIsOpen={setFileSizeErrorModal} options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage 
                    onClick={handleFileSizeError}
                    items={[
                        {content: <div>The file size for one or multiple uploaded floor plan is too big.</div>},
                        {content: <div>File size should be less than 9MB</div> }
                    ]}
                    errorCode={'1305'}
                />
            </Modal>
            <Modal isOpen={warningMessage} setIsOpen={setWarningMessage}>
                <WarningMessage 
                    title="Are you sure you want to change the floor plan?"
                    infoMessage="Outline progress is going to be lost."
                    cancelClick={() => setWarningMessage(false)}
                    confirmClick={handleWarningConfirmClick}
                />
            </Modal>
        </div>
    )
}

export default FloorPlanOutline;