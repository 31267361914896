import React from 'react' 
import SvgIcons from '../svgIcons';
import "./infoTemplate.scss";

const InfoTemplate = ({textsArr}) => {
    return(
        <div className="infoTemplate__container">
            {
                textsArr.map((el, index) => {
                    return(
                        <div className="infoTemplate__items">
                            <div className="infoTemplate__icons">
                            {
                                index === 0 ? <SvgIcons type="cabana_upload_file_info_1"/> :
                                index === 1 ? <SvgIcons type="cabana_upload_file_info_2"/> :
                                index === 2 ? <SvgIcons type="cabana_upload_file_info_3"/> :
                                index === 3 ? <SvgIcons type="cabana_upload_file_info_4"/> :
                                ""
                            }
                            </div>
                            <div className="infoTemplate__text-container">
                                <div className="infoTemplate__info-title">{el.title}</div>
                                <div className="infoTemplate__info-text">{el.text}</div>  
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default InfoTemplate;