import React from 'react';
import './imageGuide.scss';
import SvgIcons from 'src/components/shared/svgIcons';

const ImageGuide = () => {
    return(
        <div className="imageGuide__guide-icon-container">
            <div className="imageGuide__cover-image-guide">
                <div className="imageGuide__icon">
                    <SvgIcons type="cover_image" />
                </div>
                <div className="imageGuide__cover-image-text">
                    <h4>Cover Image</h4>
                    <p>This image will be the featured photo in your sales office.</p>
                </div> 
            </div>
            <div className="imageGuide__trash-can-image-guide">
                <div className="imageGuide__icon">
                    <SvgIcons type="trash_can" />
                </div>
                <div className="imageGuide__trash-can-image-text">
                    <h4>Delete Image</h4>
                    <p>Click the trash icon to remove uploaded image.</p>
                </div>
            </div> 
            <div className="imageGuide__move-image-guide">
                <div className="imageGuide__icon">
                    <SvgIcons type="move_image" />
                </div>
                <div className="imageGuide__move-image-text">
                    <h4>Reorder images</h4>
                    <p>Click and drag images to reorder them.</p>
                </div>
            </div>
        </div>
    )
}

export default ImageGuide;