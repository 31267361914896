import React, { useEffect, useState, useRef } from 'react';

import './planView.scss';
import Modal from 'src/components/shared/modal';
import PlanViewCard from './planViewCard';
import FilterError from 'src/components/shared/filterTable/filterError';
import HeaderBanner from 'src/components/shared/headerBanner';
import PlanDetailViewer from './planDetailViewer';
import { useTranslation } from 'react-i18next';

const PlanView = ({units, projectType, reservationClick, moveDate}) => {
    const { t } = useTranslation('planView') 
    const [unitPlan, setUnitPlan] = useState({});
    const [expandPlan, setExpandPlan] = useState(false);
    const [detailViewerHeight, setDetailViewerHeight] = useState('');
    const headerBannerRef = useRef(null);

    useEffect(() => {
        if(headerBannerRef.current) {
            setDetailViewerHeight(`calc(100vh - 64px - ${headerBannerRef.current.offsetHeight}px)`);
        }
    });

    const noUnitPlanStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const handleExpandPlanClick = (unit) => {
        setExpandPlan(true)
        setUnitPlan(unit);
    }

    return(
        <div className="planView__container" style={units.length === 0 ? noUnitPlanStyle : {}}>
            {
                units.length > 0 ?
                <div className="planView__card-items">
                    {units.map((unit) => {
                        return(
                            <PlanViewCard item={unit} type={projectType} setExpandPlan={handleExpandPlanClick} />
                        )
                    })}
                </div>
                :
                <FilterError /> 
            }
            <Modal isOpen={expandPlan} setIsOpen={setExpandPlan}>
                <div className="planView__modal-view">
                    <HeaderBanner options={{ref: headerBannerRef}} headerTitle={`${t('planViewCard.unit')} ${unitPlan.unit_number}`} setIsOpen={() => setExpandPlan(false)} />
                    <PlanDetailViewer detailViewerHeight={detailViewerHeight}  item={unitPlan} type={projectType} reservationClick={reservationClick} moveDate={moveDate}/>
                </div>
            </Modal>
        </div>
    ) 
}

export default PlanView