import React, { useState } from 'react';
import './vsoItem.css';
import SvgIcons from '../../shared/svgIcons';
import Dropdown from 'src/components/shared/dropdown';
import Modal from 'src/components/shared/modal';

const VSOItem = ({projectName, projectAddress, projectStatus, projectImage, projectUpdatedAt, vsoId, handleDelete, formatUpdatedAt, pushToLocation, typeOfText, changeStatus}) => {
    const [options, setOptions] = useState(false);
    const [coords, setCoords] = useState({});
    const [vsoStatus, setVsoStatus] = useState('');
    const [modal, setModal] = useState(false);

    const handleStatusClick = (e) => {
        if(e.currentTarget.textContent === 'Delete') { 
            setVsoStatus('Delete');
            setModal(true);
        } else if (e.currentTarget.textContent === 'Pause') {
            setVsoStatus('Pause');
            setModal(true);
        } else if (e.currentTarget.textContent === 'Activate') {
            setVsoStatus('Activate');
            setModal(true);
        }
    }

    const pushToStatusLocation = () => {
        if(projectStatus === "inactive") { 
            setVsoStatus('Activate');
            setModal(true);
        } else {
            pushToLocation(projectStatus, vsoId);
        }
    }

    const handleOptionsClick = (e) => {
        setOptions(!options);
        const rect = e.target.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY
        })
    }
    
    const Status = ({status}) => {
        return (
            <div className="vso__item-status-type">
                {status === 'draft' ? 
                    <> 
                        <SvgIcons type="draft_pencil" />
                        <p>{status.charAt(0).toUpperCase() + status.substring(1)}</p>
                    </>
                    :
                    <>
                        <div className={`circle ${status}`} />
                        <p className={`status-text ${status}`}>{status.charAt(0).toUpperCase() + status.substring(1)}</p>
                    </>
                }
            </div>
        )
    }

    const handleModalConfirmation = () => {
        if(vsoStatus === 'Delete') {
            handleDelete(vsoId);
            setModal(false);
        } else if(vsoStatus === 'Pause') {
            changeStatus({id: vsoId, status: 'inactive'});
            setModal(false);
        } else if(vsoStatus === 'Activate') { 
            changeStatus({id: vsoId, status: 'active'});
            setModal(false);
        }
    }
    
    return(
        <>
            <Dropdown isOpen={options} 
                setIsOpen={setOptions} 
                position="sw" 
                items={[
                       ... projectStatus === "active" ? [{content: <div className="vso__item-options-items --pause" onClick={(e) => handleStatusClick(e)}>Pause</div>}] : [],
                       ... projectStatus === "inactive" ? [{content: <div className="vso__item-options-items --activate" onClick={(e) => handleStatusClick(e)}>Activate</div>}] : [],
                        {content: <div className="vso__item-options-items --delete" onClick={(e) => handleStatusClick(e)}>Delete</div>}
                        ]}
                coords={coords} />
            <div className="vso__list-item" key={vsoId}>
                <div className="vso__item-image" style={projectImage ? {backgroundImage: `url(${projectImage.src})`} : {backgroundColor: '#c4c4c4'}}></div>
                <div className="vso__item-header">
                    <h2>{projectName}</h2>
                    <p>{projectAddress}</p>
                </div>
                <div className="vso__item-options" onClick={handleOptionsClick}>
                    <SvgIcons type="three_vertical_dots" />
                </div>
                <div className="vso__item-status-last-update-container">
                    <div className="vso__item-status-container">
                        <p>Status:</p>
                        <Status status={projectStatus}/>
                    </div>
                    <p>Last update: {formatUpdatedAt(projectUpdatedAt)}</p>
                </div>
                <div className="vso__item-button-upon-status" onClick={pushToStatusLocation}>
                    <SvgIcons type="circle_arrow_icon"/>
                    <p>{typeOfText(projectStatus)}</p>
                </div>   
            </div>
            <Modal isOpen={modal} setIsOpen={setModal} options={{disableBackdropClick: true, contentCenter: true}}>
                <div className="vso__item-modal">
                    <div className="vso__item-modal-text">
                        Are you sure you want to <strong>{vsoStatus === 'Delete' ? 'DELETE' : vsoStatus === 'Pause' ? 'PAUSE' : vsoStatus === 'Activate' ? 'ACTIVATE' : ''}</strong> the virtual sales office {projectName}?
                    </div>
                    <div className="vso__item-modal-button-container">
                        <div className="vso__item-modal-button" onClick={handleModalConfirmation}>Yes</div>
                        <div className="vso__item-modal-button" onClick={() => setModal(false)}>No</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default VSOItem;