import React from "react";
import SvgIcons from "../../svgIcons";
import "./filterError.scss";

const FilterError = () => {
    return(
        <div className="filterError__container">
            <div className="filterError__title">OOPS</div>
            <div className="filterError__error-image">
                <SvgIcons type="cabana_undraw_filter_error"/>
            </div>
            <div className="filterError__error-message">No unit matches the current filter settings.</div>
        </div>
    );
}

export default FilterError;