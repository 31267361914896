import { state } from "../projects";

export const incrementFormStep = ({state}) => {
    let currentStep = state.projects.formStep;
    currentStep++;
    state.projects.formStep = currentStep;
}

export const decrementFormStep = ({state}) => {
    let currentStep = state.projects.formStep;
    currentStep--;
    state.projects.formStep = currentStep;
}

export const clearProjectForm = ({state}) => {
    state.projects.formStep = state.projects.firstFormStep;
}

export const loadProjects = async ({state, effects}) => {
    try {
        const projectsData = await effects.projects.fetchProjects();
        state.projects.projectsList = projectsData;
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const getNewProjectFromStorage = () => {
    state.projects.newProject = localStorage.newProject;
}

export const storeNewProjectData = ({effects}, newProjectData) => {
    effects.projects.storeNewProjectData(newProjectData);
    state.projects.newProject = newProjectData;
}

export const createProject = async ({effects}, newProjectData) => {
    try {
        const newProject = await effects.projects.createProject(newProjectData);
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const setNewProjectProp = ({state}, key, value) => {
    state.projects.newProject[key] = value;
}