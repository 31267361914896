import React from 'react';
import { useTranslation } from 'react-i18next';

import './floorsPagination.css';

const FloorsPagination = ({currentFloor, setCurrentFloor, numberOfFloors, options={isUnitDetails: false}}) => {
    const { t } = useTranslation();
    /*const writtenNumberArray = 
    [
        'First', 'Second', 'Third', 'Fourth', 'Fifth',
        'Sixth', 'Seventh', 'Eight', 'Ninth', 'Tenth',
        'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth',
        'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth', 'Twentieth'
    ]*/
    let floorsMarkup = [];

    const handleFloorClick = (e) => {
        setCurrentFloor(e.target.getAttribute('data-floor'));
    }
    
    for(let i = 1; i <= numberOfFloors; i++) {
        floorsMarkup.push(
            <div className={`floorsPagination__item${Number(currentFloor) === i ? ' --current' : ''}`} key={`floor-${i}`} data-floor={i} onClick={handleFloorClick}>
                {i === 1 ? t('unitTable.floors.1st') : i === 2 ? t('unitTable.floors.2nd') : i === 3 ? t('unitTable.floors.3rd') : i + t('unitTable.floors.th')} 
                <br/>{t('unitTable.floors.floor')}
            </div>
        )
    }

    floorsMarkup.push(
        <div className={`floorsPagination__item${currentFloor === "" || currentFloor === 0 ? ' --current' : ''}`} key={`floor-all-floors`} data-floor={""} onClick={handleFloorClick}>
            {t('unitTable.floors.all')}<br/>{t('unitTable.floors.floors')}
        </div>
    )

    return (
        <div className={`floorsPagination__container ${options.isUnitDetails ? "--unit-details" : ""}`}>
            {floorsMarkup}
        </div>
    );
}

export default FloorsPagination;