import React, {useState, useEffect} from "react";
import './radioInput.scss';
import AuditorComment from '../../auditorComment';

const RadioInput = ({
    label, value, setValue, dataObj, 
    validateField, status = 'pending', auditorComment, error, 
    options={isHorizontal: false, isMandatory: false, maxWidth: ''}, radioOptions}) => {
    const [fieldStatus, setFieldStatus] = useState(status);
    const [fieldId, setFieldId] = useState('');

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(label + Math.floor(Math.random() * Date.now())));
    }, []);

    const handleValueChange = (e) => {
        setValue(e, dataObj);
        if(validateField) {
            if(!validateField(dataObj.fieldId)){
                setFieldStatus('valid');
            } else {
                setFieldStatus('denied');
            };
        }
    }

    return(
    <div className="radioInput__container" style={{maxWidth: options.maxWidth}}>
        <label htmlFor={`input__${fieldId}`}>{label} {options.isMandatory && <span>*</span>}</label>
        <div id={`input__${fieldId}`} className={`input__radio --${fieldStatus}`}>
            {radioOptions.map((o, index) => {
                return(
                <div key={`option__${fieldId}-${index}`} data-value={o[0]} onClick={handleValueChange} className={`${value === o[0] ? `selected` : ''}`}>{o[1]}</div>
                )
            })}
        </div>
        {auditorComment && 
        <AuditorComment comment={auditorComment}/>
        }
        {error && 
        <div className="radioInput__error">{error}</div>
        }
    </div>
    )
}

export default RadioInput;