import React from 'react';

import './fileIcon.css';

const FileIcon = ({type = "", selected = false, options = {}}) => {
    return(
        <div className={`fileIcon__container ${selected ? '--selected' : ''} ${options.bigIcon ? '--big-icon' : ''}`}>
            <div className="fileIcon__bg"></div>
            <div className={`fileIcon__type --${type}`}>
                {type ? type : ''}
            </div>
        </div>
    )
}

export default FileIcon;