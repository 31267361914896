import React, { useState, useEffect } from 'react';
import { useOvermind } from 'src/overmind';
import Chart from 'react-apexcharts';
import SvgIcons from 'src/components/shared/svgIcons';

import './stats.scss';
import SummaryBlock from './summaryBlock';

const Stats = () => {
    const { state, actions } = useOvermind();
    const [depositGraph, setDepositGraph] = useState({
        options: {
            chart: {id: 'deposit-bar'},
            xaxis: {categories: ['Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22']}
        },
        series: [{
            name: 'desposit-series', 
            data: [6500, 5000, 4500, 4750, 7000, 5250]
        }]
    });
    const [unitsTypeGraph, setUnitsTypeGraph] = useState({
        options: {
            chart: {id: 'units-bar'},
            xaxis: {categories: ['4 BDR.', '3 BDR.', '2 BDR.', '2 BDR.', '1 BDR.', 'Total']},
            plotOptions: {
                bar: {
                    horizontal: true,
                    rangeBarGroupRows: true,
                    rangeBarOverlap: false
                }
            }
        },
        series: [{
            name: 'units-type-available', 
            data: [12, 11, 16, 1, 40]
        }, {
            name: 'units-type-reserved', 
            data: [1, 0, 3, 0, 4]
        }, {
            name: 'units-type-available', 
            data: [4, 21, 16, 17, 58]
        }]
    });

    const [mostViewedGraph, setMostViewedGraph] = useState({
        options: {
            chart: {id: 'most-viewed-bar'},
            xaxis: {categories: ['101', '203', '202', '301', '104', '103', '102', '201']},
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            }
        },
        series: [{
            name: 'most-viewed', 
            data: [102, 93, 87, 79, 58, 46, 40, 33]
        }]
    });


    useEffect(() => {
        actions.vsoDashboard.setCurrentPage('stats');
    }, []);

    return (
        <div className="vsoDashStats__container">
            <div className="vsoDashStats__header-container">
                <div className="vsoDashStats__header-inner-container">
                    <div className="vsoDashStats__header">
                        <div>Statistics</div>
                        <p>Explore the leads, units and team statistics of your project.</p>
                    </div>
                </div>
            </div>
            <div className="vsoDashStats__sections-container">
                <div className="vsoDashStats__section">
                    <div className="vsoDashStats__title">Weekly Summary</div>
                    <div className="vsoDashStats__summary-container">
                        <SummaryBlock
                            title="Leads generated"
                            value={317}
                            progress={5}
                        />
                        <SummaryBlock
                            title="New sales"
                            value={7}
                            progress={36}
                        />
                        <SummaryBlock
                            title="New reservations"
                            value={2}
                            progress={-25}
                        />
                        <SummaryBlock
                            title="Cancellations"
                            value={0}
                            progress={0}
                        />
                        <SummaryBlock
                            title="Visits"
                            value={6540}
                            progress={13}
                        />
                        <SummaryBlock
                            title="Returning visistors"
                            value={4013}
                            progress={31}
                        />
                        <SummaryBlock
                            title="Appointments"
                            value={15}
                            progress={-1}
                        />
                        <SummaryBlock
                            title="Video calls"
                            value={13}
                            progress={8}
                        />
                    </div>
                </div>
                <div className="vsoDashStats__section">
                    <div className="vsoDashStats__title">Units Conversion Progress</div>
                    <div className="vsoDashStats__progress-container">
                        <div className="vsoDashStats__progress-charts"></div>
                    </div>
                </div>
                <div className="vsoDashStats__section">
                <div className="vsoDashStats__title">Deposit Statistics</div>
                    <div className="vsoDashStats__deposit-container">
                        <div className="vsoDashStats__deposit-boxes">
                            <div className="vsoDashStats__deposit-box">
                                <div className="vsoDashStats__deposit-value">8500$</div>
                                <div className="vsoDashStats__deposit-title">This week received deposits</div>
                            </div>
                            <div className="vsoDashStats__deposit-box">
                                <div className="vsoDashStats__deposit-value">75,000$</div>
                                <div className="vsoDashStats__deposit-title">Total received deposits</div>
                            </div>
                        </div>
                        <div className="vsoDashStats__deposit-chart">
                            <Chart
                                options={depositGraph.options}
                                series={depositGraph.series}
                                type="bar"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div> 
                </div>
                <div className="vsoDashStats__section">
                <div className="vsoDashStats__title">Unit Type Statistics</div>
                    <div className="vsoDashStats__unit-type-container">
                        <div className="vsoDashStats__stats-table">
                            <div className="vsoDashStats__stats-table-header">
                                <div>Type</div>
                                <div>All</div>
                                <div>Available</div>
                                <div>Reserved</div>
                                <div>Sold</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>4 BDR.</div>
                                <div>17</div>
                                <div>12</div>
                                <div>1</div>
                                <div>4</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>3 BDR.</div>
                                <div>32</div>
                                <div>11</div>
                                <div>0</div>
                                <div>21</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>2 BDR.</div>
                                <div>35</div>
                                <div>16</div>
                                <div>3</div>
                                <div>16</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>1 BDR.</div>
                                <div>18</div>
                                <div>1</div>
                                <div>0</div>
                                <div>17</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row --total">
                                <div>Total</div>
                                <div>102</div>
                                <div>40</div>
                                <div>4</div>
                                <div>58</div>
                            </div>
                        </div>
                        <div className="vsoDashStats__unit-type-chart">
                            <Chart
                                options={unitsTypeGraph.options}
                                series={unitsTypeGraph.series}
                                type="bar"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div> 
                </div>
                <div className="vsoDashStats__section">
                <div className="vsoDashStats__title">Unit Floor Statistics</div>
                    <div className="vsoDashStats__unit-floor-container">
                    <div className="vsoDashStats__stats-table">
                            <div className="vsoDashStats__stats-table-header">
                                <div>Floor</div>
                                <div>All</div>
                                <div>Available</div>
                                <div>Reserved</div>
                                <div>Sold</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>1st</div>
                                <div>17</div>
                                <div>12</div>
                                <div>1</div>
                                <div>4</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>2nd</div>
                                <div>32</div>
                                <div>11</div>
                                <div>0</div>
                                <div>21</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>3rd</div>
                                <div>35</div>
                                <div>16</div>
                                <div>3</div>
                                <div>16</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>4th</div>
                                <div>18</div>
                                <div>1</div>
                                <div>0</div>
                                <div>17</div>
                            </div>
                            <div className="vsoDashStats__stats-table-row">
                                <div>5th</div>
                                <div>102</div>
                                <div>40</div>
                                <div>4</div>
                                <div>58</div>
                            </div>
                        </div>
                        <div className="vsoDashStats__unit-floor-chart">
                            yo
                        </div>
                    </div> 
                </div>
                <div className="vsoDashStats__section">
                    <div className="vsoDashStats__title">Most viewed units</div>
                    <div className="vsoDashStats__viewed-units-container">
                        <div className="vsoDashStats__viewed-units-chart">
                            <Chart
                                options={mostViewedGraph.options}
                                series={mostViewedGraph.series}
                                type="bar"
                                width="100%"
                                height="400px"
                            />
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Stats;