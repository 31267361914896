import React, { useState, useEffect, useRef } from 'react';
import DrawingCanvas from 'src/components/shared/drawingCanvas';
import { useOvermind } from 'src/overmind';
import { useResizeContainer } from 'src/utils/hooks';
import './buildingView.scss';
import BuildingViewFloorPlan from './buildingViewFloorPlan';
import BuildingViewTable from './buildingViewTable';
import HeaderBanner from 'src/components/shared/headerBanner';
import PlanDetailViewer from '../planView/planDetailViewer';
import Modal from 'src/components/shared/modal';

const BuildingView = () => {
    const {state, actions} = useOvermind();
    const buildingRef = useRef(null);
    const headerBannerRef = useRef(null);
    const [detailViewerHeight, setDetailViewerHeight] = useState('');
    const {containerWidth, containerHeight} = useResizeContainer(buildingRef);
    const [expandPlan, setExpandPlan] = useState(false);
    const [unitInfo, setUnitInfo] = useState();

    useEffect(() => {
        if(headerBannerRef.current) {
            setDetailViewerHeight(`calc(100vh - 64px - ${headerBannerRef.current.offsetHeight}px)`);
        }
    });

    const handleUnitClick = (unit) => {
        if(unit.status == 'available') {
            setUnitInfo(unit);
            setExpandPlan(true);
        } else {
            return;
        }
    }

    const handleTableUnitClick = (unitId) => {
        if(state.currentVSO.units[state.currentVSO.units.findIndex(u => u.unit_id == unitId)].status == 'available') {
            let unitIndex = state.currentVSO.units.findIndex(u => u.unit_id == unitId);
            setUnitInfo(state.currentVSO.units[unitIndex]);
            setExpandPlan(true);
        }
    }

    return (
        <div className="buildingView__container">
            <div ref={buildingRef} className="buildingView__choose-floor">
                <DrawingCanvas 
                    type="building"
                    editMode={false}
                    isClosed={true}
                    imageUrl={state.currentVSO.fields?.buildingView_image}
                    data={state.currentVSO.fields?.buildingView_floors}
                    activeFloor={state.currentVSO.currentFloor}
                    setActiveFloor={actions.currentVSO.setCurrentFloor}
                    containerWidth={containerWidth}
                    containerHeight={containerHeight}
                    units={state.currentVSO.units}
                />
            </div>
            <div className="buildingView__floor-plan-table-container">
                <div className="buildingView__floor-plan">
                    <BuildingViewFloorPlan handleUnitClick={handleUnitClick} />
                </div>
                <div className="buildingView__unit-table">
                    <BuildingViewTable handleUnitClick={handleTableUnitClick} />
                </div>
            </div>
            <Modal isOpen={expandPlan} setIsOpen={setExpandPlan}>
                <div className="planView__modal-view">
                    <HeaderBanner 
                        options={{ref: headerBannerRef}} 
                        headerTitle={`Unit ${unitInfo?.unit_number}`} 
                        setIsOpen={() => setExpandPlan(false)} 
                    />
                    <PlanDetailViewer 
                        detailViewerHeight={detailViewerHeight} 
                        item={unitInfo} type={state.currentVSO.fields?.project_type} 
                        reservationClick={() => ""} 
                        moveDate={state.currentVSO.fields?.est_delivery_date}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default BuildingView;