import React, { useEffect, useRef, useState } from 'react';
import "./selectColumnType.scss";


const SelectColumnType = ({label, items, options, value, setValue, placeholder}) => {
    const [openContainer, setOpenContainer] = useState(false)
    const selectColumnTypeRef = useRef(null);

    useEffect(() => {

        const handleClickOutside = (e) => {
            if(selectColumnTypeRef.current && !selectColumnTypeRef.current.contains(e.target)) {
                setOpenContainer(false)
            }
        }
        
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        // clean up
        document.removeEventListener("mousedown", handleClickOutside);
        }

    }, [selectColumnTypeRef])
    
    return(
        <div className="form__input-group">
            <label>{label}</label>
            <div ref={selectColumnTypeRef} className={`selectColumnType__container ${openContainer ? "--active" : ""}`} onClick={() => setOpenContainer(!openContainer)}>
                <div className="selectColumnType__selected-value">{value ? items.find(( {columnValue} ) => columnValue === value).content : placeholder}</div>
                <div ref={selectColumnTypeRef} className={`selectColumnType__select-container ${openContainer ? "--active" : ""}`}>
                    {items?.map((element, index) => {
                        return(
                            <div key={index} className='selectColumnType__item' onClick={() => {setValue(element.columnValue)}}>
                                {element.content_desc}
                            </div>
                        )
                    })}
                    
                </div>
            </div>
        </div>
    )
}

export default SelectColumnType;