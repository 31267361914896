import React, { useEffect, useState } from 'react';
import './topbar.css';
import { useOvermind } from '../../overmind';
import logo from '../../assets/images/logo.svg';
import notification from '../../assets/images/icons/notification.svg';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import Button from "../shared/button";
import LangSelector from './langSelector';
import { useTranslation } from 'react-i18next';


const Topbar = ({setAuth, isAuth}) => {
    const { state, actions } = useOvermind();
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    let location = useLocation();
    const history = useHistory();
    const params = useParams();
    const { t } = useTranslation();

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.setItem('isAuth', false);
        actions.currentUser.clearCurrentUser();
        setIsProfileDropdownOpen(false);
        actions.marketTestForm.clearNewMarketTest();
        setAuth(false);
    }

    const toggleProfileDropdown = () => {
        if(isProfileDropdownOpen) {
            setIsProfileDropdownOpen(false);
        } else {
            setIsProfileDropdownOpen(true);
        }
    }

    const handleClickBackdrop = () => {
        setIsProfileDropdownOpen(false);
    }

    const handleOfficeElemClick = (e) => {
        let pathWithId = location.pathname.match(/(\/virtual-sales-office\/\d+)/)[0];        
        history.push(pathWithId + `/${e.currentTarget.getAttribute('data-element')}`);
    }

    const handleOfficeLogoClick = () => {
        let pathWithId = location.pathname.match(/(\/virtual-sales-office\/\d+)/)[0];        
        history.push(pathWithId);
    }

    const handleReserveUnitClick = () => {
        let pathWithId = location.pathname.match(/(\/virtual-sales-office\/\d+)/)[0];        
        history.push(pathWithId + '/reservation');
    }

    return (
        <>
        {location.pathname.includes('virtual-sales-office') ?
            <nav className="topbar">
                <div className="topbar__container-vso">
                    <div className="topbar__left-container--vso">
                        {state.currentVSO.fields.project_logo && 
                        <div className={`topbar__vso-logo${['overview', 'plans', 'documents', 'area'].includes(state.topbar.currentPage) ? ' --minimized' : ''}`} onClick={handleOfficeLogoClick}>
                            <img src={state.currentVSO.fields?.project_logo[0]?.src} alt="Sales Office Logo" />
                        </div>
                        }
                        {/* <div className="topbar__left-logo-text-vso">&nbsp;Virtual Office</div> */}
                    </div>
                    <div className="topbar__center-container--vso">
                        {/*Custom nav items components will go here */}
                        <a data-element="" onClick={handleOfficeElemClick} className={`${state.topbar.currentPage === 'home' ? '--active' : '' }`}>{t('salesOffice.home')}</a>
                        <a data-element="overview" onClick={handleOfficeElemClick} className={`${state.topbar.currentPage === 'overview' ? '--active' : '' }`}>{t('salesOffice.overview')}</a>
                        <a data-element="plans" onClick={handleOfficeElemClick} className={`${state.topbar.currentPage === 'plans' ? '--active' : '' }`}>{t('salesOffice.unitPlans')}</a>
                        {state.currentVSO.fields.images &&
                            <a data-element="gallery" onClick={handleOfficeElemClick} className={`${state.topbar.currentPage === 'images' ? '--active' : '' }`}>{t('salesOffice.images')}</a>
                        }
                        {state.currentVSO.fields.documents &&
                            <a data-element="documents" onClick={handleOfficeElemClick} className={`${state.topbar.currentPage === 'documents' ? '--active' : '' }`}>{t('salesOffice.documents')}</a>
                        }
                        <a data-element="map" onClick={handleOfficeElemClick} className={`${state.topbar.currentPage === 'area' ? '--active' : '' }`}>{t('salesOffice.areaMap')}</a>
                    </div>
                    <div className="topbar__right-container--vso">
                        <Button type="primary"
                        text={`${state.currentVSO.enable_reservations ? state.currentVSO.reservation.currentStep > 0 && !location.pathname.includes('reservation') ? t('salesOffice.continueReservation') : t('salesOffice.reserveUnit') : t("salesOffice.sendAMessage")}`} 
                        onClick={state.currentVSO.enable_reservations ? handleReserveUnitClick : () => actions.currentVSO.setIsContactFormOpen(true)}/>
                        <LangSelector />
                    </div>
                </div> 
            </nav>
         : 
         location.pathname.includes('listing') ? <></> : 
         <nav className={`topbar ${state.topbar.hasShadow ? '' : 'topbar--no-shadow'}`}>
             <div className="topbar__container">
                {/* {!isAuth || state.topbar.hasLogo ? 
                <div className={!isAuth ? 'topbar__logo' : 'topbar__logo--left'}>
                    <img src={logo} alt="Smooth logo"/>
                </div>
                :
                <></>} */}
                {/* <Link to="/virtual-office-list">
                <div className={!isAuth ? 'topbar__logo' : 'topbar__logo--left'}>
                    <img src={logo} alt="Smooth logo"/>
                </div>
                </Link> */}
                {state.topbar.hasLogo ? 
                <div className="topbar__center-container">
                    <div className="topbar__logo--center">
                        <img src={logo} alt="Smooth logo" />
                    </div>
                </div>
                : <></>}
                {isAuth ?
                <>
                <div className="topbar__right-container">
                    {/* <div className="topbar__notifications-container">
                    <div className="topbar__notifications-button">
                        <img src={notification} alt="Notification icon"/>
                    </div>
                    <div className="topbar__notifications-number"></div>
                    </div>
                    </div> */}
                    <div className="topbar__profile-container">
                        <div className="topbar__profile-button" onClick={toggleProfileDropdown}>
                            <div className="topbar__profile-icon">
                                <span>{state.currentUser.initial}</span>
                            </div>
                            <div className="topbar__profile-name">{state.currentUser.firstName + ' ' + state.currentUser.lastName}</div>
                            <div className="topbar__profile-caret"></div>
                        </div>
                    <div className={"topbar__profile-dropdown" + (isProfileDropdownOpen ? " topbar__profile-dropdown--open" : "")}>
                            <div className="topbar__profile-item">
                                <button className="topbar__profile-log-out-btn" onClick={handleLogout}>Log out</button>
                            </div>
                        </div>
                        <div className={`topbar__profile-dropdown-backdrop ${isProfileDropdownOpen ? 'topbar__profile-dropdown--open': ''}`} onClick={handleClickBackdrop}></div>
                    </div>
                </div>
                </>
                : <></>
                }
             </div>
         </nav>
         }
        </>
    )
    

};

export default Topbar;