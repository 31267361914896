import React from 'react';
import SvgIcons from 'src/components/shared/svgIcons';
import OverviewTags from '../overviewTags';
import './unitBox.scss';
import { useTranslation } from 'react-i18next';

const UnitBox = ({name, numberOfUnits, minArea, maxArea, minPrice, maxPrice, projectType}) => {
    const { t } = useTranslation();

    return (
        <div className="unitBox__units-container">
            {/* <div className="unitBox__units-name">{name}</div> */}
            <OverviewTags text={name} />
            <div className="unitBox__units-stats-container"> 
                <div className="unitBox__units-stat"><SvgIcons type="cabana_overview_avail_units" /> {numberOfUnits} {t('salesOffice.overviewPage.unitAvail').toLowerCase()}</div>
                <div className="unitBox__units-stat">
                    <SvgIcons type="cabana_overview_ruler" />
                    {numberOfUnits == 1 ?
                        <>{minArea}</> :
                        <>{minArea} - {maxArea}</>
                    }
                    &nbsp;{t('salesOffice.overviewPage.sqft')}
                </div>
                <div className="unitBox__units-stat">
                    <SvgIcons type="cabana_overview_price"/>
                    {numberOfUnits == 1 ? 
                        <>{minPrice}</> : 
                        <>{minPrice} - {maxPrice}</>
                    }
                    &nbsp;$ {projectType === 'rent' ? '/' + t('salesOffice.overviewPage.month') : ''}
                </div>
            </div>
        </div>
    );
}

export default UnitBox;