import React, {useState, useEffect, useRef} from 'react';
import './numberInput.scss';
import AuditorComment from '../../auditorComment';

const NumberInput = ({
    label, value, setValue, 
    placeholder, dataObj, validateField, theme, 
    status = 'pending', auditorComment, error, 
    options={isMandatory: false, minVal: "", maxVal: "", maxWidth: ""}}) => {
    const [fieldStatus, setFieldStatus] = useState(status);
    const [fieldClasses, setFieldClasses] = useState('');
    const [fieldId, setFieldId] = useState('');
    const [currentPos, setCurrentPos] = useState(null);
    const [oldValue, setOldValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(label + Math.floor(Math.random() * Date.now())));
    }, []);

    const handleFocus = (e) => {
        if(e.currentTarget.value === "0"){
            e.currentTarget.select();
        }
    }
    const handleDollarSignFocus = (e) => {
        if(e.target.value === "0"){
            e.target.select();
        }
    }

    useEffect(() => {
        if(inputRef.current !== null && options.dollarSign){
            let oldCommaCount = (oldValue.match(/,/g) || []).length;
            let newCommaCount = (inputRef.current.value.match(/,/g) || []).length;
            let commaDiff = newCommaCount - oldCommaCount;
            inputRef.current.setSelectionRange(currentPos + commaDiff, currentPos + commaDiff);
        }
    }, [inputRef.current?.value]);

    useEffect(() => {
        let classes = ['inputNumber__input'];
        if(theme) {
            classes.push(`--${theme}`);
        }
        if(fieldStatus) {
            classes.push(`--${fieldStatus}`);
        }
        if(typeof error === 'string') {
            console.log('is error')
            if(classes.indexOf(`--denied`) === -1) {
                classes.push(`--denied`);
            }
        } else if(error === false) {
            if(classes.indexOf(`--valid`) === -1) {
                classes.push(`--valid`);
            }
        }
        setFieldClasses(classes.join(' '));
    }, [theme, fieldStatus, error]);


    const handleValueChange = (e) => {
        // let debounce = require('lodash.debounce');
        /*
        dataObj.value = parseInt(e.currentTarget.value, 10);
        if(options.minVal) {
            if(value === null) {
                dataObj.value = options.minVal;
            }
            dataObj.value = Math.max(dataObj.value, options.minVal);
        } else {
            if(value === null) {
                dataObj.value = 0;
            }
        }
        if(options.maxVal) {
            dataObj.value = Math.max(dataObj.value, options.minVal);
        }
        */
        
        if(e.target.value === "" || isNaN(e.target.value.split(',').join(""))){
            e.target.value = 0; 
        }
        
        setCurrentPos(e.target.selectionStart)
        setOldValue(e.target.value);
        setValue(e, dataObj);
        if(validateField) {
            if(!validateField(dataObj.fieldId)){
                setFieldStatus('valid');
            } else {
                setFieldStatus('denied');
            };
        }

    }

    const handleDecrementClick = () => {
        if(value.toString() === options.minVal){
            dataObj.value = Number(options.minVal);
            setValue(null, dataObj)
        } else {
            dataObj.value = Math.max(parseInt(value - 1), 0);
            setValue(null, dataObj);
        }
    }

    const handleIncrementClick = () => {
        if(value.toString() === options.maxVal) { 
            dataObj.value = Number(options.maxVal);
            setValue(null, dataObj)
        } else {
            dataObj.value = parseInt((Number(value) + 1), 10);
            setValue(null, dataObj);
        }
    }

    return(
    <>
        <div className="inputNumber__container">
            <label htmlFor={`input__${fieldId}`}>{label} {options.isMandatory && <span>*</span>}</label>
            <div className={`inputNumber__suffix-container`}>
                <input id={`input__${fieldId}`} className={fieldClasses} style={{maxWidth: options.maxWidth}} type="number" value={value} onChange={handleValueChange} placeholder={placeholder} onFocus={handleFocus} min={options.minVal} max={options.maxVal} />
                {options.suffix && <span className="inputNumber__suffix">{options.suffix}</span>}             
            </div>
            {auditorComment && 
            <AuditorComment comment={auditorComment}/>
            }
            {error && 
            <div className="inputNumber__error">{error}</div>
            }
        </div>
    </>
    )
}

export default NumberInput;