import React, { useState, useEffect } from 'react';
import { useOvermind } from 'src/overmind';
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import Button from 'src/components/shared/button';
import FileIcon from 'src/components/shared/fileIcon';
import { saveAs } from 'file-saver';
import './documents.scss';
import Modal from 'src/components/shared/modal';
import SvgIcons from 'src/components/shared/svgIcons';
import JSZip from 'jszip';
import { convertUrltoFile } from 'src/utils/fileUpload';
import HeaderBanner from 'src/components/shared/headerBanner';

const Documents = ({documentModal, setDocumentModal}) => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation();

    const [currentDoc, setCurrentDoc] = useState(0);
    const history = useHistory();
    const params = useParams();
    const zip = new JSZip();

    useEffect(() => {
        actions.topbar.setCurrentPage('documents');
        
        return () => {
            actions.topbar.setCurrentPage('');
        }
    }, []);

    const handleMouseEnterDocument = (e) => {
        setCurrentDoc(Number(e.currentTarget.getAttribute('data-index')));
    }

    const handleMouseLeaveDocument = () => {
        setCurrentDoc("");
    }

    const fileExtensionName = (str) => {
        if(str){
            return str.split(".")[str.split(".").length - 1];
        }
    }

    const downloadFile = (data, fileName) => {
        saveAs(data, fileName);
    }

    const nameWithoutType = (fileName) => {
        return fileName.split(".").slice(0, fileName.split(".").length - 1).join(".");
    }

    const findFileSize = (bytes, decimals = 2) => {
        if(bytes === 0) { 
            return '0 bytes'
        }
        
        const stdSize = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['bytes', 'KB','MB','GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(stdSize));
        return parseFloat((bytes / Math.pow(stdSize, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const handleDocumentClose = (bool) => {
        setDocumentModal(bool);
        history.push("/virtual-sales-office/" + params.id);
    }

    const getDocumentType = (file) => {
        const splitLength = file.name.split(".").length
        const fileType = file.name.split(".")[splitLength - 1];
        switch (fileType) {
            case "pdf":
                return t('salesOffice.documentsPage.pdf');
            case "xls":
                return t('salesOffice.documentsPage.xlsx');
            case "xlsx":
                return t('salesOffice.documentsPage.xlsx');
            case "doc":
                return t('salesOffice.documentsPage.doc');
            case "docx":
                return t('salesOffice.documentsPage.doc');
            case "ppt":
                return t('salesOffice.documentsPage.pptx');
            case "pptx":
                return t('salesOffice.documentsPage.pptx');
            case "txt":
                return t('salesOffice.documentsPage.txt');
            case "mp4":
                return t('salesOffice.documentsPage.mp4');
            case "png":
                return t('salesOffice.documentsPage.png');
            case "jpeg":
                return t('salesOffice.documentsPage.jpg');
            default:
                return "document";
        }
    }

    const downloadAll = async () => {
        let documentZip = zip.folder("documents")
        for (const file of state.currentVSO.fields.documents) {
            let convertedFile = await convertUrltoFile(file.src, file.name);
            documentZip.file(file.name, convertedFile);
        }
        documentZip.generateAsync({type: "blob"}).then((content) => {
            saveAs(content, state.currentVSO.fields.project_name + "_documents.zip");
        })
    }

    return (
        <>
        <Modal isOpen={documentModal} setIsOpen={handleDocumentClose} 
                options={{disableBackdropClick: false, contentCenter: true}}>
            <div className="documents__container">
                <HeaderBanner headerTitle="Documentation" 
                            infoText={t('salesOffice.documentsPage.exploreDocs')}
                            setIsOpen={handleDocumentClose}/>
                <div className="documents__item-container">
                    {
                        state.currentVSO.fields.documents?.map((file, index) => {
                            return(
                                <div key={`file-item-${index}`} className={`documents__item${currentDoc === index ? ' --current' : ''}`} onMouseEnter={handleMouseEnterDocument} onMouseLeave={handleMouseLeaveDocument} data-index={index}>
                                    <div className="documents__icon-name-size-container">
                                        <div className="documents__icon-container">
                                            <FileIcon type={fileExtensionName(file.name)} selected={currentDoc === index}/>
                                        </div>
                                        <div className="documents__info-container">
                                            <div className={`documents__name${currentDoc === index ? ' --current' : ''}`}>
                                                {nameWithoutType(file.name)}
                                            </div>
                                            <div className="documents__size">
                                                {getDocumentType(file) + ": " + findFileSize(file.size)}
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="documents__single-download" onClick={() => downloadFile(file.src, file.name)}><SvgIcons type="cabana_single_download_file_icon" />{t('salesOffice.documentsPage.download')}</div>
                                </div>
                            )
                        })
                    }
                </div>
                {state.currentVSO.fields.documents?.length > 1 && 
                    <div className="documents__download-all">
                        <Button type='primary' text={t('salesOffice.documentsPage.downloadAll')} onClick={downloadAll}><SvgIcons type="cabana_multiple_download_file_icon" /></Button>
                    </div>
                }
            </div>
        </Modal>
        </>
    );
}

export default Documents;