require('dotenv').config();

export const postVso = async (id) => {
    try {
        const body = {userId: id};

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/',
            {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
    
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const putVsoFields = async (id, fields) => {
    try {
        const body = {
            vsoId : id,
            fields: fields
        };

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'fields/vso/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const putVsoStatus = async (id, status) => {
    try {
        const body = {
            vsoId : id,
            status: status
        };

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/' + id + '/status',
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const uploadImage = async (id, contentId, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/image/${contentId}/${id}`,
            {
                method: 'PUT',
                headers: {
                    // 'Content-Type' : 'multipart/form-data',
                    'token' : localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const putNearbyPlaces = async (id, addressData) => {
    try{
        let body = addressData;
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/address/vso/${id}`, 
            {
                method: 'PUT',
                headers: {
                    'token' : localStorage.token,
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    }
    catch(err) {
        console.error(err.message);
        return err;
    }
}

export const removeImage = async (id, contentId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/image/${contentId}/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                }
            }
        );
    } catch (err) {
        console.error(err.message);
        return err;
    }
}