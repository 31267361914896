import React, { useEffect, useState, useRef, useCallback } from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import { useOvermind } from 'src/overmind';
import Modal from 'src/components/shared/modal';
import ErrorMessage from 'src/components/shared/errorMessage';
import './addressInput.scss';
import Map from '../../map';

const AddressInput = ({
    dataObj, validateField, error, status = null,
    label, placeholder, mapHeight, 
    options={isMandatory: false, containsMap: false,}}) => {
    
    const { state, actions, effects } = useOvermind();
    const [initialAddress, setInitialAddress] = useState(state.productForm.newProduct.fields.project_address?.value?.full_string);
    const [address, setAddress] = useState('');
    const [modal, setModal] = useState(false);
    const [searchOptions, setSearchOptions] = useState({});
    const autoCompleteRef = useRef('');
    const [fieldClasses, setFieldClasses] = useState('');
    const [fieldStatus, setFieldStatus] = useState(status);
    

    const libraries = ['places'];
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
        libraries,
    });
    
    const mapRef = useRef(null);
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const mapOptions = {
        center: {
            lat: state.productForm.newProduct.fields.project_address?.value.coords.lat !== null ? 
                state.productForm.newProduct.fields.project_address?.value.coords.lat 
                : 45.5438845,
            lng: state.productForm.newProduct.fields.project_address?.value.coords.lng !== null ? 
                state.productForm.newProduct.fields.project_address?.value.coords.lng 
                : -73.6883796
        },
        zoom: 11,
        fullscreenControl: true,
        zoomControl: true,
        disableDefaultUI: true,
    }

    useEffect(() => {
        let classes = ['location-search-input'];
        if(fieldStatus) {
            classes.push(`--${fieldStatus}`);
        }

        if(typeof error === 'string') {
            if(classes.indexOf(`--denied`) === -1) {
                classes.push(`--denied`);
            }
        } else if(error === false) {
            if(classes.indexOf(`--valid`) === -1) {
                classes.push(`--valid`);
            }
        }
        setFieldClasses(classes.join(' '));
    }, [error]);


    useEffect(() => {
        if(state.productForm.newProduct.fields.project_address?.value?.full_string) {
            setAddress(state.productForm.newProduct.fields.project_address?.value?.full_string)
        }
    }, [state.productForm.newProduct.fields.project_address?.value?.full_string]);

    useEffect(() => {
        if(isLoaded) {
            setSearchOptions({
                location: new window.google.maps.LatLng(45, 73),
                radius: 100*2000,
                types: ['address'],
                componentRestrictions: {country: 'ca'}
            });
        }
    }, [isLoaded]);

    const setProductAddressField = (coords, results) => {
        actions.productForm.setProductField(
            {
                fieldId: 'project_address',
                value: {
                coords: {lat: coords.lat, lng: coords.lng},
                full_string: results[0].formatted_address,
                address_components: results[0].address_components,
                }
            }
        );
    }
    
    const setProductAddressFieldNull = () => {
        actions.productForm.setProductField(
            {
                fieldId: 'project_address',
                value: {
                    coords: {lat: null, lng: null},
                    full_string: null,
                    address_components: null
                }
            }
        );
    }

    const handleChange = (address) => {
        setAddress(address);
    }

    const handleSelect = async (address) => {
        const results = await geocodeByAddress(address)
                                .then((result) => {return result})
                                .catch((error) => {return error});
        
        const coords = results !== 'ZERO_RESULTS' ? await getLatLng(results[0])
                                                            .then((coord) => {return coord})
                                                            .catch((error) => {return error}) : null;

        if(results !== 'ZERO_RESULTS' && results !== 'INVALID_REQUEST') { 
            if(results[0].types.includes('street_address') || results[0].types.includes('premise')) { 
                setAddress(address); 
                setProductAddressField(coords, results);
                mapRef.current.panTo({lat: state.productForm.newProduct.fields.project_address?.value?.coords?.lat, lng:state.productForm.newProduct.fields.project_address?.value?.coords?.lng});
                mapRef.current.setZoom(14);
                if(options.containsMap) {
                    if(initialAddress != state.productForm.newProduct.fields.project_address?.value.full_string || state.productForm.newProduct.fields.nearby_places?.value == undefined) {
                        let addressData = {
                            coords: {lat: state.productForm.newProduct.fields.project_address?.value?.coords?.lat, lng: state.productForm.newProduct.fields.project_address?.value?.coords?.lng},
                            full_string: state.productForm.newProduct.fields.project_address?.value?.full_string,
                            address_components: state.productForm.newProduct.fields.project_address.value?.address_components
                        }
                        // effects.productForm.putNearbyPlacesVso(state.productForm.newProduct.id, addressData).then(() => {
                        //     actions.productForm.updateProductFields(['project_address']);
                        // });
                    }
                }
                if(validateField) {
                    if(!validateField(dataObj.fieldId)){
                        setFieldStatus('valid');
                    } else {
                        setFieldStatus('denied');
                    };
                }
            } else {
                setModal(true);
                setAddress('');
                autoCompleteRef?.current.blur();
                setProductAddressFieldNull();
            } 
        } else {
            setModal(true);
            setAddress('');
            autoCompleteRef?.current.blur();
            setProductAddressFieldNull();
        }
    }
    return (
        <>
            <Modal 
                isOpen={modal} 
                setIsOpen={setModal} 
                options={{disableBackdropClick: true, contentCenter: true}}
            >
                <ErrorMessage onClick={() => setModal(false)} 
                            items={[
                                {content: <div>The address is not a street address nor a premise please enter a correct address</div>},
                                {content: <div>Ex: 9345 Rue Mortier Montreal Quebec H2A 7H5</div>}
                            ]}
                            errorCode={'1301'}/>
            </Modal>
            {loadError ? <>{JSON.stringify(loadError)}</> : isLoaded ? 
                <div className="addressInput__container">
                    <div className="addressInput__address-box">
                        <label>{label} {options.isMandatory && <span>*</span>}</label>
                        <PlacesAutocomplete
                            value={address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            highlightFirstSuggestion={true}
                            shouldFetchSuggestions={address.length > 3}
                            searchOptions={searchOptions}  
                        >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                            <div className="autocomplete-container">
                                <input 
                                    {...getInputProps({
                                    placeholder: placeholder,
                                    className: fieldClasses,
                                    ref: autoCompleteRef 
                                    })}
                                />
                                <div className={`autocomplete-dropdown-container ${suggestions.length > 0 && '--active'}`}>
                                    {suggestions.map(suggestion => {
                                    const className = `suggestion-item ${suggestion.active && '--active'}`;
                                    return (
                                        <div 
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                    })}
                                </div>
                            </div>
                        )}
                        </PlacesAutocomplete>
                    </div>
                    {options.containsMap && 
                        <Map 
                            mapOptions={mapOptions} 
                            onMapLoad={onMapLoad} 
                            addressState={state.productForm.newProduct.fields?.project_address}
                            isLoaded={isLoaded}
                            loadError={loadError}
                            mapHeight={mapHeight}
                        />
                    }
                    {typeof error === 'string' && 
                        <div className="addressInput__error">{error}</div>
                    }
                </div>
                :
                <></>
            }
        </>
    )

}

export default AddressInput;