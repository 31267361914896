import React, { useState, useEffect, useRef } from 'react';
import './loadingBar.css';

const LoadingBar = ({text, isLoaded, setIsOpen, barStyle}) => {
    const [counter, setCounter] = useState(4);
    const [intervalCounter, setIntervalCounter] = useState(0);

    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        let arrayIntervals = [getRandomInt(10, 20), getRandomInt(35, 55), getRandomInt(60, 85), 95];

        const interval = setInterval(() => {
            setCounter(arrayIntervals[intervalCounter]);
            setIntervalCounter((intervalCounter) => intervalCounter + 1);
        }, getRandomInt(250, 1000))

        if(isLoaded) {
            setCounter(100);
            setTimeout(() => {
                setIsOpen(false);
            }, 1000);
        }

        if(counter === 100) { 
            clearInterval(interval)
            setTimeout(() => {
                setIsOpen(false);
            }, 1300)
        }

        return () => {
            clearInterval(interval);
        }
    }, [counter, isLoaded, intervalCounter])

    return(
        <div className={`loadingBar__outer-bar ${barStyle === 'thin' ? ' --thin' : ''}`}>
            <div className="loadingBar__inner-bar" style={{width: `${counter}%`}}>
            </div>
        </div>
    )
}

export default LoadingBar;