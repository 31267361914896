import React from 'react'; 
import './button.scss'; 

const Button = ({children, type, size, prefix, suffix, suffixGap, text, style, onClick, disabled, options = {hasDropdownButton: false, isFluid: false, activeState: false, ref: null}}) => {

    return(
        <button ref={options.ref} className={`button ${type ? `--${type}`: ''} ${size ? `--${size}`: ''} ${options.isFluid ? '--fluid' : ''} ${options.activeState ? '--active' : ''}`} 
        style={{...style, gap: suffixGap}} 
        onClick={onClick} disabled={disabled}>
            {prefix}
            {children}
            {text}
            {suffix}
        </button>
    )
}

export default Button;