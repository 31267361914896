import React, { useMemo } from 'react';
import './buildingViewTable.scss';
import { useTranslation } from 'react-i18next';
import { useOvermind } from 'src/overmind';
import UnitsTable from 'src/components/shared/unitsTable';

const BuildingViewTable = ({handleUnitClick}) => {
    const {state, actions} = useOvermind();
    const { t, i18n } = useTranslation();

    const columns = useMemo(() => [
        {accessor: 'unit_number', Header: t('unitTable.unit'), minWidth: 80},
        {accessor: 'bedroom', Header: t('unitTable.bedroom'), minWidth: 96},
        {accessor: 'bathroom', Header: t('unitTable.bathroom')},
        {accessor: 'sqft', Header: <div className="columnHeader__sqft">{t('unitTable.sqft')} <strong>(net)</strong></div>, minWidth: 96},
        {accessor: 'price', Header: <div className="columnHeader__price">{t('unitTable.price')} <strong>(tx incl.)</strong></div>, minWidth: 104},
        {accessor: 'status', Header: t('unitTable.status'), minWidth: 120},
    ], [i18n.language]);

    return(
        <div className="buildingViewTable__container">
            <UnitsTable 
                headings={columns} 
                units={state.currentVSO.currentFloor == "" ? state.currentVSO.allSortedUnits : state.currentVSO.sortedFloorUnitsList}
                floorHasUnits={state.currentVSO.sortedFloorUnitsList.length > 0 ? true : false} 
                currentFloor={state.currentVSO.currentFloor} 
                projectType={state.currentVSO.fields.project_type} 
                isEditMode={false} 
                options={{maxHeight: '400px'}}
                handleRowClick={handleUnitClick} 
            />
        </div>
    )
}

export default BuildingViewTable;