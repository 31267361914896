import React, { useEffect, useState } from 'react'
import "./stepProgressBar.scss"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


// per is the default props for percentage 
const StepProgressBar = ({completedFields, totalFields, options={circleWidth: ''}}) => {
    const [per, setPer] = useState(0);

    useEffect(() => {
        let newPer = Math.trunc(completedFields / totalFields * 100);
        setPer(newPer);
    }, [completedFields]);

    return (
        <div className="stepProgressBar__container">
            <div style={{width: options.circleWidth}}>
                <CircularProgressbar
                    value={per}
                    text={`${per}%`}
                    strokeWidth={6}
                    styles={buildStyles({
                        strokeLinecap: 'butt',
                        pathTransitionDuration: 0.5,
                        pathColor: `#3d59ec`,
                        })}
                />
            </div>
        </div>
    )
}

export default StepProgressBar;
