import React from 'react';
import { floorNaming } from 'src/utils/units';
import Button from '../button';
import SelectInput from '../inputs/selectInput';
import './copyFloor.scss';
import SvgIcons from '../svgIcons';

const CopyFloor = ({floorOptions, chooseFloorOptions, copiedFloor, setCopiedFloor, targetFloors, setTargetFloors, handleNextClick, handleBackClick}) => {

    const handleSelectInputChange = (e) => {
        setCopiedFloor(e.target.value)
    }

    const handleMultiSelect = (newValue) => {
        setTargetFloors([...targetFloors, newValue]);
    }
    
    const handleMultiDeselect = (index) => {
        let tempArr = targetFloors.filter((_, i) => i !== index);
        setTargetFloors(tempArr);
    }

    const handleValueChange = (e) => {
        const index = targetFloors.findIndex((elem) => {
            return elem === e.target.getAttribute('data-value');
        });
        if(index === -1) {
            handleMultiSelect(e.target.getAttribute('data-value'));
        } else {
            handleMultiDeselect(index);
        }
    }

    const CheckboxInput = ({dataValue, checked, onClick, label, disabled}) => {
        return(
            <label className="checkbox__input-label">
                <input id={`input__checkbox`} type="checkbox" disabled={disabled} data-value={dataValue} checked={checked} onClick={onClick} />
                {label}
            </label>
        )
    }

    return(
        <div className="copyFloor__container">
            <div className="copyFloor__inner-container">
                <div className="copyFloor__select-floor">
                    <SelectInput
                        placeholder="Select Floor" 
                        label="Select the floor you want copy"
                        selectOptions={floorOptions}
                        setValue={handleSelectInputChange}
                        value={copiedFloor}
                        options={{filterTable: true}}
                    />
                </div>
                <div className="copyFloor__icon-container">
                    <SvgIcons type="confirm_copy_table"/>
                    <SvgIcons type="right_arrow_long"/>
                    <div className="copyFloor__icon-text">will be copied to</div>
                </div>
                <div className="copyFloor__choose-floor">
                    <div className="copyFloor__choose-text">Choose the floor(s) to copy to*</div>
                    <div className="copyFloor__floor-items-container">
                        {chooseFloorOptions?.map((o) => {
                            return(
                                copiedFloor != o &&
                                <CheckboxInput 
                                    dataValue={o}
                                    checked={targetFloors?.find(elem => elem == o) ?'checked' : ''} 
                                    onClick={handleValueChange}
                                    label={`${floorNaming(o)} floor`}
                                    disabled={copiedFloor == ''}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="copyFloor__buttons">
                <Button type="ternary" text="Back" onClick={handleBackClick}/>
                <Button type="primary" text="Next" onClick={handleNextClick} disabled={copiedFloor == '' || targetFloors?.length == 0}/>
            </div>
        </div>
    )
}

export default CopyFloor;