import React from 'react';
import './divider.scss';

const Divider = ({orientation, color, margin, length}) => {
    const lengthStyle = {};
    
    if(length) { 
        lengthStyle = orientation == 'horizontal' ? lengthStyle.width = length : lengthStyle.height = length;
    }

    return(
        <div className={`divider ${orientation}`} style={{margin: margin, ...lengthStyle}}/>
    )
}

export default Divider;