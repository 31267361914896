export const validateTextInput = (value, validationRules = {}, extraRules = []) => {
    for(let i = 0; i < extraRules.length; i++) {
        if(extraRules[i]) {
            return extraRules[i];
        }
    }
    if(validationRules.isMandatory) {
        if(value === '' || value === null) {
            return 'This field is mandatory.';
        }
    }
    if(validationRules.isEmail) {
        if(!value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            return 'This email address is invalid.';
        }
    }
    if(validationRules.limit) {
        if(value?.length > validationRules.limit) {
            return 'Please enter a shorter value.';
        }
    } else {
        if(value?.length > 1000) {
            return 'Please enter a shorter value.';
        }
    }
    return false;
}

export const validateAddressInput = (value, validationRules = {}, extraRules = []) => {
    if(validationRules.isMandatory) {
        if(value === null || value === undefined || value === '') {
            return 'This field is mandatory.';
        }
    }
    return false;
}

export const validateFileInput = (value, validationRules = {}, extraRules = []) => {
    if(validationRules.isMandatory) {
        if(value?.length === 0 ||  value === undefined || value === null) {
            return 'This field is mandatory.';
        }
    }
    return false;
}

export const validateNumberInput = (value, validationRules = {}, extraRules = []) => {
    if(validationRules.isMandatory) {
        if(value === undefined || value === null || value === '') {
            return 'This field is mandatory.';
        }

        if(value == 0) {
            return 'This field cannot be zero';
        }
    }
    return false;
}

export const validateBooleanInput = (value, validationRules = {}, extraRules = []) => {
    if(validationRules.isMandatory) {
        if(value === '' || value === null || value === undefined) {
            return 'This field is mandatory.';
        }
    }
    return false;
}

export const validateNumberOfUnits = (value, validationRules = {}, extraRules = []) => {
    if(validationRules.isMandatory) {
        if(value === '' || value === null || value === undefined) {
            return 'This field is mandatory.'
        }
        if(value === 0) {
            return 'Number of units cannot be empty.'
        }
    }
    return false;
}

export const validateFloorPlans = (value, validationRules = {}, extraRules = []) => {
    if(validationRules.isMandatory) {
        for (const item of value) {
            if(item.isUploaded) {
                return false;
            }
        }
        return 'This field is mandatory, upload floor plans.'
    }
}

export const validateImageSelection = (value, validationRules = {}, extraRules = []) => {
    if(validationRules.isMandatory) {
        if(value == null || value == '' || value == undefined) {
            return 'This field is mandatory, please choose an image'
        }
    }
    return false;
}

export const validateBuildingFloors = (value, validationRules = {}, extraRules = []) => {
    if(validationRules.isMandatory) {
        for (const item of value) {
            if(item.isFinished) {
                return false;
            }
        }
    }
    return 'This field is mandatory, please outline the building.';
}
