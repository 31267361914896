import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import UnitRow from '../unitRow';

import '../unitRow/row.css';

const SortableUnitRow = ({
        cells, 
        rowId,
        cellProps, 
        rowAttributes, 
        headings, 
        isEditMode,
        deleteUnit,
        uploadPlan,
        removePlan,
        unitId,
        projectType,
        handleRowClick,
        activeFloor,
        unitStatus,
        showPrices,
    }) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
        id: rowId,
        transition: {
            duration: 150,
            easing: 'ease'
        }
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };
    
    return(
        <UnitRow 
            ref={setNodeRef} 
            style={style} 
            headings={headings} 
            cells={cells} 
            cellProps={cellProps}
            isEditMode={isEditMode}
            deleteUnit={deleteUnit}
            uploadPlan={uploadPlan}
            removePlan={removePlan}
            unitId={unitId}
            projectType={projectType}
            handleRowClick={handleRowClick}
            rowAttributes={rowAttributes}
            attributes={attributes}
            listeners={listeners}
            activeFloor={activeFloor}
            unitStatus={unitStatus}
            showPrices={showPrices}
        />
    )
}

export default SortableUnitRow;