import React, { useEffect, useState } from 'react';
import BuildingOutline from './buildingOutline';
import ImageSelection from './imageSelection';
import './buildingViewSteps.scss';
import UploadFloorPlans from './uploadFloorPlans';
import FloorPlanOutline from './floorPlanOutline';
import { useOvermind } from 'src/overmind';



const BuildingViewSteps = ({unitListStep, setUnitListStep, validateForm, validateField, formErrors}) => {
    const [steps, setSteps] = useState(1);
    const {state, actions} = useOvermind();
    
    return(
        <div className="buildingViewSteps__container">
            {
                steps === 1 ? 
                <ImageSelection 
                    changeStep={setSteps} 
                    steps={steps} 
                    unitListStep={unitListStep} 
                    setUnitListStep={setUnitListStep} 
                    validateForm={validateForm}
                    validateField={validateField}
                    formErrors={formErrors}
                /> :
                steps === 2 ?
                <BuildingOutline 
                    changeStep={setSteps} 
                    steps={steps} 
                    validateForm={validateForm} 
                    validateField={validateField}
                    formErrors={formErrors}
                /> :
                steps === 3 ? 
                <UploadFloorPlans 
                    changeStep={setSteps}
                    steps={steps} 
                    validateForm={validateForm}
                    validateField={validateField}
                    formErrors={formErrors}
                /> :
                steps === 4 ? 
                <FloorPlanOutline 
                    changeStep={setSteps} 
                    steps={steps}
                    validateForm={validateForm} 
                /> :
                ''
            }
        </div>
    )
}

export default BuildingViewSteps;