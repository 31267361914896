import React from 'react';
import './toggleButton.scss';

const ToggleButton = ({setValue, value}) => {
    return(
        <label className="toggleButton__switch">
            <input type="checkbox" checked={value} onChange={setValue} />
            <span className="toggleButton__slider" />
        </label>
    )
}

export default ToggleButton;