import React from 'react';
import Button from '../button';
import './backNextBar.scss';

const BackNextBar = ({backText='Back', nextText='Next', onBackClick, onNextClick}) => {
    return(
        <div className="backNextBar__container">
            <Button type="ternary" text={backText} onClick={onBackClick}/>
            <Button type="primary" text={nextText} onClick={onNextClick}/>
        </div>
    );
}

export default BackNextBar;