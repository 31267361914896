import React, { useMemo } from 'react';

import { useOvermind } from 'src/overmind';

import '../reservationForm.css';
import UnitsTable from 'src/components/shared/unitsTable';
import FloorsPagination from 'src/components/shared/floorsPagination';
import { useTranslation } from 'react-i18next';

const UnitSelection = () => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation();

    const columns = useMemo(() => [
        {accessor: 'unit_number', Header: t('unitTable.unit'), minWidth: 80},
        {accessor: 'bedroom', Header: t('unitTable.bedroom'), minWidth: 96},
        {accessor: 'bathroom', Header: t('unitTable.bathroom')},
        {accessor: 'sqft', Header: <div className="columnHeader__sqft">{t('unitTable.sqft')} <strong>(net)</strong></div>, minWidth: 96},
        {accessor: 'orientation', Header: t('unitTable.orientation'), minWidth: 104},
        {accessor: 'position', Header: t('unitTable.position'), minWidth: 104},
        {accessor: 'view', Header: t('unitTable.view'), minWidth: 104},
        {accessor: 'parking', Header: t('unitTable.parking'), minWidth: 104},
        {accessor: 'locker', Header: t('unitTable.locker'), minWidth: 104},
        {accessor: 'price', Header: <div className="columnHeader__price">{t('unitTable.price')} <strong>(tx incl.)</strong></div>, minWidth: 104},
        {accessor: 'plan', Header: t('unitTable.plan'), minWidth: 104},
    ]);

    const handleUnitClick = (unitId) => {
        if(state.currentVSO.units[state.currentVSO.units.findIndex(u => u.unit_id == unitId)].status == 'available') {            
            actions.currentVSO.setReservationUnit(unitId);
            actions.currentVSO.setReservationStep(1);
        }
    }

    return (
        <div className="unitSelection__container">
            <div className="reservationForm__step-title">{t('salesOffice.reservationForm.selectTheUnit')}</div>
            <UnitsTable 
                headings={columns} 
                units={state.currentVSO.currentFloor === "" ? state.currentVSO.allSortedUnits : state.currentVSO.sortedFloorUnitsList} 
                currentFloor={state.currentVSO.currentFloor} 
                projectType={state.currentVSO.fields.project_type} 
                isEditMode={false} 
                options={{maxHeight: '400px'}}
                handleRowClick={handleUnitClick}
            />
            <FloorsPagination currentFloor={state.currentVSO.currentFloor} setCurrentFloor={actions.currentVSO.setCurrentFloor} numberOfFloors={state.currentVSO.fields.num_floors}/>        
        </div>
    );
}

export default UnitSelection;