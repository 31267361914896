import React, {forwardRef} from 'react';
import SvgIcons from 'src/components/shared/svgIcons';
import './document.scss';
import FileIcon from 'src/components/shared/fileIcon';
import { findFileSize, fileExtensionName, createTinyName } from 'src/utils/fileUpload';

const Document = forwardRef(({style, firstIndex, element, onRemove, ...props}, ref) => {
    return (
        <div className="documents__documents-item" ref={ref} style={style} {...props}>
            <div className="documents__documents-item-options" onClick={() => onRemove(element)}><SvgIcons type="trash_can"/></div>
            <FileIcon type={fileExtensionName(element.name)} />
            <p>{createTinyName(element.name)}</p>
            <div className="documents__documents-item-separator" />
            <div className="documents__documents-item-file-size">
                <p>Filesize:</p>
                <p>{findFileSize(element.size, 1)}</p>
            </div>
        </div>
        );
})

export default Document;