import React from "react";
import "./filterDropdown.scss"
import { useTranslation } from "react-i18next";

const FilterDropdown = ({children, name, activeState, onClick, filterTags}) => {
    const { t } = useTranslation();

    const getFilterTags = (name) => {
        if(name != t('filters.filtersName.sqft') && name != t('filters.filtersName.price')) {
            return filterTags?.length > 0 ? filterTags.length : "";
        }
        if(name === t('filters.filtersName.sqft')) {
            if(filterTags !== undefined) {
                return filterTags[0] + "ft²" + " - " + filterTags[1] + "ft²"
            }
        }
         if (name === t('filters.filtersName.price')) {
            if(filterTags !== undefined) {
                return filterTags[0] + "$" + " - " + filterTags[1] + "$"
            }
        }
    }


    return (
        <div className="filterDropdown__container" onClick={onClick}>
            <div className="filterDropdown__arrow-and-name">
                <div className={`filterDropdown__arrow ${activeState ? "--down" : "--right"}`} />
                <div className="filterDropdown__name">{name}</div>
                <div className="filterDropdown__filter-tags">
                    {getFilterTags(name)}
                </div>
            </div>
            <div className={`filterDropdown__content ${activeState ? "--open" : '--closed'}`} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default FilterDropdown;