import React from "react";
import "./tooltip.scss";
import {createPortal} from 'react-dom'

const Tooltip = ({isOpen, text, options = {}}) => {
    let customStyle =  {...options.coords};
    if (options.maxWidth) {
        customStyle.maxWidth = options.maxWidth;
    }

    return(
       <>
       {options.coords ? 
        createPortal(
           <div className="tooltip__portal" style={options.coords}>
                <div className={`tooltip__container${isOpen ? '' : ' --closed'}`} style={{maxWidth: options.maxWidth}}> 
                    {text}
                </div>
           </div>, document.querySelector('#tooltips')
        )
        : 
        <div className={`tooltip__container${isOpen ? '' : ' --closed'}`} style={{maxWidth: options.maxWidth}}> 
            {text}
        </div>
       }
      </>
    );
}

export default Tooltip;