import React, { useState, useEffect, useRef } from 'react';
import '../datePicker/datePicker.scss';
import DropDownArrow from '../../../assets/images/icons/cabana_cheveron_down.svg';
import Modal from '../modal';

const DatePicker = ({
    label, value, setValue, theme,
    placeholder, dataObj, validateField, 
    status = 'pending', auditorComment, 
    error, options={isVso: false, isUnitTable: false, isMandatory: false, maxWidth: ''}}) => {
   
    const [openDateContainer, setOpenDateContainer] = useState(false);
    const [monthContainer, setMonthContainer] = useState(false);
    const [seasonContainer, setSeasonContainer] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedSeason, setSelectedSeason] = useState();
    const [yearContainer, setYearContainer] = useState(false);
    const [fieldClasses, setFieldClasses] = useState('');
    const [fieldStatus, setFieldStatus] = useState(status);
    const [fieldId, setFieldId] = useState('');
    const datePickerRef = useRef(null);
    const datePickerContainerRef = useRef(null);
    
    
    const date = new Date();
    const months = ['January', 
                    'February', 
                    'March', 
                    'April', 
                    'May', 
                    'June', 
                    'July', 
                    'August', 
                    'September', 
                    'October', 
                    'November', 
                    'December'];

    const seasons = ['Spring', 'Summer', 'Fall', 'Winter'];
    const yearArr = [];


    useEffect(() => {

        const handleClickOutside = (e) => {
            if(datePickerContainerRef.current && !datePickerContainerRef.current.contains(e.target) && datePickerRef.current && !datePickerRef.current.contains(e.target)) {
                setOpenDateContainer(false);
            }
        }
        
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        // clean up
        document.removeEventListener('mousedown', handleClickOutside);
        }

    }, [datePickerRef]);

    useEffect(() => {
        let classes = ['datePicker__selector-container'];
        if(theme) {
            classes.push(`--${theme}`);
        }
        if(fieldStatus) {
            classes.push(`--${fieldStatus}`);
        }
        if(typeof error === 'string') {
            console.log('is error')
            if(classes.indexOf(`--denied`) === -1) {
                classes.push(`--denied`);
            }
        } else if(error === false) {
            if(classes.indexOf(`--valid`) === -1) {
                classes.push(`--valid`);
            }
        }
        setFieldClasses(classes.join(' '));
    }, [theme, fieldStatus, error]);

    
    const calculateYears = (year) => { 
        for(let i = 0; i < 20; i++){
            yearArr.push(year + i);
        }
        return yearArr;
    }

    calculateYears(date.getFullYear() - 2);

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(label + Math.floor(Math.random() * Date.now())));
    }, []);

    useEffect(() => {
        if(selectedSeason || selectedMonth){
            setYearContainer(true);
            setMonthContainer(false);
            setSeasonContainer(false);
        }
    }, [selectedMonth, selectedSeason]) 

    const openContainer = () => {
        setOpenDateContainer(!openDateContainer);
        setMonthContainer(true);
        setSeasonContainer(false);
        setYearContainer(false);
    }

    const showMonthContainer = () => {
        setSelectedSeason('')
        setMonthContainer(true)
        setSeasonContainer(false);
        setYearContainer(false);
    }

    const showSeasonContainer = () => {
        setSelectedMonth('');
        setMonthContainer(false);
        setSeasonContainer(true);
        setYearContainer(false);
    }

    const confirmValue = (element) => {
        let finalDate = selectedMonth ? selectedMonth.concat(' ' + element.toString()) : selectedSeason.concat(' ' + element.toString());
        if(options.isUnitTable) {
            dataObj.newValue = finalDate;
            setValue(dataObj);
        } else {
            setValue(finalDate, dataObj);
            if(validateField) {
                if(!validateField(dataObj.fieldId)){
                    setFieldStatus('valid');
                } else {
                    setFieldStatus('denied');
                };
            }
        }
        setOpenDateContainer(false);
        setYearContainer(false);
        setMonthContainer(true);
        setSelectedMonth('')
        setSelectedSeason('');
    }

    const DropDownDateContainer = () => {
        return(
            <div ref={datePickerRef} className={`datePicker__dropdown-container ${openDateContainer ? '--active' : ''}`}>
                <div className={`datePicker__month-season-container ${options.isVso ? '--vso' : ''} ${options.isUnitTable ? "--unit-table" : ''}`}>
                    <div className="datePicker__date-selector">
                        <button className={`datePicker__month ${options.isVso ? '--vso' : ''} ${monthContainer ? '--active' : ''}`} onClick={showMonthContainer}>Months</button>
                        <button className={`datePicker__season ${options.isVso ? '--vso' : ''} ${seasonContainer ? '--active' : ''}`} onClick={showSeasonContainer}>Seasons</button>
                        <button className={`datePicker__year ${options.isVso ? '--vso' : ''} ${yearContainer ? '--active' : ''}`} style={!yearContainer ? {color: 'rgba(51, 51, 51, 50%)'} : {}}>Years</button>
                    </div>
                    <div className={`datePicker__month-container ${options.isVso ? '--vso' : ''} ${monthContainer ? '--active' : ''}`}>
                        {months.map((element, id) => {
                            return(<div key={id} onClick={() => setSelectedMonth(element)}>{element}</div>);
                        })}
                    </div>
                    <div className={`datePicker__season-container ${options.isVso ? '--vso' : ''} ${seasonContainer ? '--active' : ''}`}>
                        {seasons.map((element, id) => {
                            return(<div key={id} onClick={() => setSelectedSeason(element)}>{element}</div>);
                        })}
                    </div>
                    <div className={`datePicker__year-list-container ${options.isVso ? '--vso' : ''} ${yearContainer ? '--active' : ''}`}>
                    {yearArr.map(element => {
                        return(<div onClick={() => confirmValue(element)}>{element}</div>);
                    })}
                    </div> 
                </div>
            </div>
        )
    }

    return(
     <>
        <div  className={`datePicker__container ${options.isUnitTable ? '--unit-table' : ''}`} style={{maxWidth: options.maxWidth}}>
            <label>{label} {options.isMandatory && <span>*</span>}</label>
            <div ref={datePickerContainerRef} className={`${fieldClasses} ${options.isUnitTable ? '--unit-table' : ''} ${openDateContainer ? '--active' : ''}`}  onClick={openContainer}>
                <div className={`datePicker__value-placeholder ${value ? '' : '--active'}`}>{value ? value : placeholder}</div>
                <img src={DropDownArrow} alt="drop down arrow"/>
            </div>
            {options.isUnitTable ? 
                <Modal isOpen={openDateContainer} setIsOpen={setOpenDateContainer}
                         options={{disableBackdropClick: true, contentCenter: true}}>
                    <DropDownDateContainer />
                </Modal>
                :
                <DropDownDateContainer />
            }
            {typeof error === 'string' && 
            <div className="datePicker__error">{error}</div>
            }
        </div>
     </>
    )
}

export default DatePicker;
