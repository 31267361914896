import React from 'react';

import Cell from '../cell';

import './row.scss';

const Row = ({
        cells, 
        cellProps, 
        rowAttributes,
        style,
    }) => {

    let markup = cells?.map((cell, index) => {
        let tempPrefix = null;
        let tempSuffix = null;
        let tempContent = cell.value;
        
        return (
            <div {...cell.getCellProps(cellProps)} className="unitsTable__cell">
                <Cell
                    id={cell.column.id} 
                    content={tempContent} 
                    isCustom={cell.column.editData?.isCustom}
                    prefix={tempPrefix}
                    suffix={tempSuffix}
                />
            </div>
        )
    })

    return(
        <>
        <div {...rowAttributes} style={{...style, transition: '0.15s'}}>
            {markup}
        </div>
        </>
    );
}

export default Row;