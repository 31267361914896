import React from 'react';
import "./rangeSlider.scss";
import { Range, getTrackBackground } from 'react-range';
import Tooltip from 'src/components/shared/tooltip';

const RangeSlider = ({setRange, range, limits, suffix, dataObj}) => {

	const formatNumber = (rangeIndex) => {
	if(rangeIndex === null){
		return;
	}
	
	return rangeIndex.toString()
                    .split("")
                    .reverse()
                    .map((element, index) => index !== 0 && index % 3 === 0 ? `${element},` : element)
                    .reverse()
                    .join("")
	}
    return (
      <div className="rangeSlider__container">
        <div style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        }}>
        {/* <span>{limits.min}$</span> */}
        {range[0] >= limits.min && range[1] <= limits.max ?
        <Range 
            allowOverlap={false}
            values={range} 
            onChange={(range) => {
                setRange(range, dataObj);
            }} 
            min={limits.min} 
            max={limits.max}
            step={10}
            renderTrack={({ props, children }) => (
                <div className="rangeSlider__range-track-container"
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                  }}
                >
                  <div
                    ref={props.ref}
                    className="rangeSlider__range-track"
                    style={{
                      background: getTrackBackground({
                        values : range,
                        colors: ['#fff', '#6475F7', '#fff'],
                        min: limits.min,
                        max: limits.max,
                      }),
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, index }) => (
                <div
                  {...props}
                  className="rangeSlider__thumb"
                  style={{
                    ...props.style,
                  }}
                >
                <Tooltip isOpen={true} text={formatNumber(range[index]) + ` ${suffix}`}/>
                </div>
              )}
        />
        : ''}
        </div>
            <div className="rangeSlider__limits-label">
                <div className="rangeSlider__limits">{limits.min} {suffix}</div>
                <div className="rangeSlider__limits">{limits.max} {suffix}</div>
            </div>
        </div>

    )
}

export default RangeSlider;