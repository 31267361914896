export const getLeadsMessages = async (vsoId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}virtualSalesOffices/${vsoId}/messages`,
            {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                }
            });

        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}