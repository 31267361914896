import React, {useEffect, useState} from 'react';
import "./passwordConstraints.css";

const PasswordConstraints = ({isOpen, value, setError}) => {
    const [hasLowercase, setHasLowercase] = useState(false); 
    const [hasUppercase, setHasUppercase] = useState(false); 
    const [hasDigit, setHasDigit] = useState(false); 
    const [hasSpecialChar, setHasSpecialChar] = useState(false); 
    const [isRightLength, setIsRightLength] = useState(false); 

    useEffect(() => {
        validatePassword();
    }, [value]);

    const validatePassword = () => {
        const lowerCaseExp = new RegExp('(?=.*[a-z])');
        const upperCaseExp = new RegExp('(?=.*[A-Z])');
        const digitExp = new RegExp('(?=.*[0-9])');
        const specialExp = new RegExp('^(?=.*[!@#$%^&*])');

        setHasLowercase(lowerCaseExp.test(value));
        setHasUppercase(upperCaseExp.test(value));
        setHasDigit(digitExp.test(value));
        setHasSpecialChar(specialExp.test(value));
        setIsRightLength(value.trim().length > 8 && value.trim().length < 128);

        if(lowerCaseExp.test(value) && upperCaseExp.test(value) && digitExp.test(value) && specialExp.test(value) && value.trim().length > 8 && value.trim().length < 128) {
            setError(null);
        } else {
            setError('Password does not satisfy constraints');
        }
    }
 
    return(
        // <div className={`pwd-constraints__main-container ${isOpen ? '' : '--hidden'} ${hasLowercase && hasUppercase && hasDigit && hasSpecialChar && isRightLength}`}>
        <div className={`pwd-constraints__main-container ${isOpen ? '' : '--hidden'}`}>
            {/* {!(hasLowercase && hasUppercase && hasDigit && hasSpecialChar && isRightLength) ?  */}
            <>
            <div className="pwd-constraints__header">
                Your password must satisfy the following criterias :
            </div>
            <ul className="pwd-constraints__list-container">
                <li className={`pwd-constraints__list-item ${hasLowercase ? '--faded' : ''}`}>One lowercase character</li>
                <li className={`pwd-constraints__list-item ${hasUppercase ? '--faded' : ''}`}>One uppercase character</li>
                <li className={`pwd-constraints__list-item ${hasDigit ? '--faded' : ''}`}>One number</li>
                <li className={`pwd-constraints__list-item ${hasSpecialChar ? '--faded' : ''}`}>One special character</li>
                <li className={`pwd-constraints__list-item ${isRightLength ? '--faded' : ''}`}>8 to 128 characters length</li>
            </ul>
            </>
        {/* : */}
            {/* <div className={`pwd-constraints__success`}>Your password is secure.</div> */}
            <div className={`pwd-constraints__success ${hasLowercase && hasUppercase && hasDigit && hasSpecialChar && isRightLength ? '' : '--hidden'}`}>Your password is secure.</div>
        {/* } */}
        </div>
    );
};

export default PasswordConstraints;