import React, { useEffect, useState } from 'react';
import { useOvermind } from '../../overmind';
import { useHistory, useLocation } from 'react-router';
import { generatePath, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useTranslation } from 'react-i18next';

import VSOLogin from './login';
import ElementLabel from './elementLabel'; 
import NameTag from './nameTag';
import Overview from './overview';
import Gallery from './gallery';
import UnitsPlans from './unitsPlans';
import AreaMap from './areaMap';
import Documents from './documents';
import ReservationForm from './reservationForm';
import Diaporama from './diaporama';
import Tooltip from 'src/components/shared/tooltip'; 
import ElementHighlight from './elementHighlight';
import ContactForm from './contactForm';

import './salesOffice.css';
import backgroundImage from 'src/assets/images/salesOffice/background.png';
import infiniteLogo from 'src/assets/images/infiniteLogo.svg';
import ContactUsCard from './contactUsCard';
import VideoCall from '../shared/videoCall';


const SalesOffice = () => {
    const { state, actions } = useOvermind();    
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const { t } = useTranslation();

    const [isBottomLogoHovered, setIsBottomLogoHovered] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isVideoCallActive, setIsVideoCallActive] = useState(false);
    const [numberOfVisitors, setNumberOfVisitors] = useState(0);
    const [documentModal, setDocumentModal] = useState(false);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        actions.currentVSO.loadVsoFields(params.id)
        .then(() => {
            setHasLoaded(true);
        });
        if(sessionStorage.getItem('isVSOAuth')){
            actions.currentVSO.loadCurrentUser();
        }
        actions.topbar.setCurrentPage('home');
    }, []);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_SERVER_URL);
        setSocket(newSocket);

        const visitorsInterval = setInterval(() => {
            newSocket.emit('vso:req-visitors', {vsoId: params.id});
        }, 60 * 1000);

        return () => {
            clearInterval(visitorsInterval);
            newSocket.close();    
        }
    }, [setSocket]);

    useEffect(() => {
        const enterListener = () => {
            socket.emit('vso:enter', {vsoId: params.id, name: state.currentVSO.currentUser.fullName});
        }
        const visitorsListener = (currentVisitors) => {
            setNumberOfVisitors(currentVisitors);
        }

        if(socket) {
            // socket.on('connect', enterListener);
            socket.on('vso:visitors', visitorsListener);
        }

        return () => {
            if(socket) {
                // socket.off('connect', enterListener);
                socket.off('vso:visitors', visitorsListener);
            }
        }
    }, [socket]);

    useEffect(() => {
        socket?.emit('vso:enter', {vsoId: params.id, name: state.currentVSO.currentUser.fullName});
    }, [socket, state.currentVSO.currentUser.fullName]);

    useEffect(() => {
        if(location.pathname.includes('documents')) {
            setDocumentModal(true);
        }

    }, [location.pathname.includes('documents')])

    const handleOfficeElemClick = (e) => {
        history.push(generatePath('/virtual-sales-office/:id/' + e.currentTarget.getAttribute('data-element'), {id: params.id}));
    }

    const clearCurrentElement = () => {
        history.push(generatePath('/virtual-sales-office/:id/', {id: params.id}));
    }

    const handleHoverInBottomLogo = () => {
        setIsBottomLogoHovered(true);
    }

    const handleHoverOutBottomLogo = () => {
        setIsBottomLogoHovered(false);
    }

    const handleStartVideoCall = (meetingId) => {
        setIsVideoCallActive(true);
        socket.emit('vso:video-call', {vsoId: params.id, isRequestingCall: true, callId: meetingId});
    }

    const handleStopVideoCall = () => {
        setIsVideoCallActive(false);
        socket.emit('vso:video-call', {vsoId: params.id, isRequestingCall: false});
    }

    return (
        <>
        {/* {!state.currentVSO.currentUser.isAuth && <VSOLogin />} */}
        {hasLoaded &&
        <>
        <div className="sales-office__container">
            <div className="sales-office__split-container">
                <div className="sales-office__bg-image-container">
                    <img className="sales-office__bg-image" src={backgroundImage} />
                    <div className="sales-office__elements-wrapper">
                        <div className="sales-office__element-container --plans" data-element="plans" onClick={handleOfficeElemClick}>
                            <ElementHighlight element="plans" />
                            <Diaporama images={[{src: state.currentVSO.diaporamaPlan}]} element="plans" />
                            <ElementLabel text={t('salesOffice.unitPlans')} element="plans"/>
                        </div>
                        {state.currentVSO.fields.images &&
                        <div className="sales-office__element-container --images" data-element="gallery" onClick={handleOfficeElemClick}>
                            <ElementHighlight element="images" />
                            <Diaporama images={state.currentVSO.fields.images} element={'images'}/>
                            <ElementLabel text={t('salesOffice.images')} element="images"/>
                        </div>
                        }
                        <div className="sales-office__element-container --map" data-element="map" onClick={handleOfficeElemClick}>
                            <ElementHighlight element="map" />
                            <ElementLabel text={t('salesOffice.areaMap')} element="map"/>
                        </div>
                        {state.currentVSO.fields.documents &&
                        <div className="sales-office__element-container --documents" data-element="documents" onClick={handleOfficeElemClick}>
                            <ElementHighlight element="documents" />
                            <ElementLabel text={t('salesOffice.documents')} element="documents"/>
                        </div>
                        }
                        <div className="sales-office__element-container --overview" data-element="overview" onClick={handleOfficeElemClick}>
                            <ElementHighlight element="overview" />
                            <ElementLabel text={t('salesOffice.overview')} element="overview"/>
                        </div>
                        {state.currentVSO.currentUser.isAuth && state.currentVSO.currentUser.firstName && state.currentVSO.currentUser.firstName !== '' &&
                        <NameTag text={state.currentVSO.currentUser.fullName} isClient={true}/>
                        }
                    </div>
                </div>
                {isVideoCallActive &&
                <div className="sales-office__video-chat-container">
                    <VideoCall participantName={state.currentVSO.currentUser.fullName} stopVideoCall={handleStopVideoCall} sendSocketReq={handleStartVideoCall}/>
                </div>
                }
            </div>
            {/* <div className="sales-office__bottom-logo-container">
                <div className="sales-office__bottom-logo-wrapper" onMouseEnter={handleHoverInBottomLogo} onMouseLeave={handleHoverOutBottomLogo}>
                    <Tooltip text={t('salesOffice.poweredBy')} isOpen={isBottomLogoHovered} options={{maxWidth: 'initial'}}/> 
                    <img className="sales-office__bottom-logo" src={infiniteLogo} />
                </div>
            </div> */}
            {state.currentVSO.fields.contact_info && !isVideoCallActive && !location.pathname.includes('map') && !location.pathname.includes('plans') &&
                <ContactUsCard startVideoCall={handleStartVideoCall}/>
            }
            {numberOfVisitors > 1 &&
                <div className="sales-office__visitors-number">
                    {t('salesOffice.numberOfVisitors1')}
                    {numberOfVisitors}
                    {t('salesOffice.numberOfVisitors2')}
                </div>
            }
        </div>
        <ContactForm />   
        {location.pathname.includes('overview') &&
        <Overview />
        }
        {location.pathname.includes('gallery') &&
        <Gallery closeElement={clearCurrentElement}/>
        }
        {location.pathname.includes('plans') &&
        <UnitsPlans />
        }
        {location.pathname.includes('documents') &&
        <Documents documentModal={documentModal} setDocumentModal={setDocumentModal}/>
        }
        {location.pathname.includes('map') &&
        <AreaMap />
        }
        {location.pathname.includes('reservation') &&
        <ReservationForm />
        }
        </>
        }
        </>
    );
}

export default SalesOffice;