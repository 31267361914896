import React, {useState, useEffect, useRef } from 'react';

import { useOvermind } from '../../overmind'
import { useParams } from "react-router-dom";

import '../app/app.css';
import '../dashboard/dashboard.css';
import './productForm.scss';
import ProjectIdentity from './steps/projectIdentity';
import UnitDetails from './steps/unitDetails';
import BuildingInformation from './steps/buildingInformation';
import UnitList from './steps/unitList';
import ContactCustom from './steps/contactCustom';
import StepsBanner from './stepsBanner';
import CampaignLaunch from './steps/campaignLaunch';

const ProductForm = ({setAuth}) => {

    const { state, actions, effects } = useOvermind();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const stepContainer = useRef(null);
    const [currentAddress, setCurrentAddress] = useState('');

    const projectIdentitySteps = [
        'project_name', 'project_address', 'project_logo',
        'project_type', 'unit_type', 'num_buildings',
        'num_floors', 'construction_type', 'project_status', 
        'est_delivery_date', 'images',
        ... state.productForm.newProduct.fields?.project_type?.value === 'rent' ? ['lease_duration'] : [],
        'available_parking', 'available_lockers',
    ];

    const buildingInformationSteps = [
        'parking_type', 'parking_price_included',
        ... state.productForm.newProduct.fields?.parking_price_included?.value === 'no' ? ['extra_parking_fee_type'] : [], 
        ... state.productForm.newProduct.fields?.parking_price_included?.value === 'no' && (state.productForm.newProduct.fields?.parking_type?.value === 'interiorGarage' || state.productForm.newProduct.fields?.parking_type?.value === 'interiorAndExterior') ? ['extra_int_fee'] : [],
        ... state.productForm.newProduct.fields?.parking_price_included?.value === 'no' && (state.productForm.newProduct.fields?.parking_type?.value === 'exteriorSpaces' || state.productForm.newProduct.fields?.parking_type?.value === 'interiorAndExterior') ? ['extra_ext_fee'] : [],
        'electric_parking', 'visitor_parking', 'street_parking', 'parking_with_permit', 'locker_price_included',
        ... state.productForm.newProduct.fields?.electric_parking?.value === 'available' && state.productForm.newProduct.fields?.parking_price_included?.value === 'no' ? ['electric_parking_price'] : [],
        ... state.productForm.newProduct.fields?.locker_price_included?.value === 'no' ? ['extra_locker_fee_type', 'extra_locker_fee'] : [],
        'leed', 'heritage_building', 'policies_dog_tolerance',
        ... state.productForm.newProduct.fields?.policies_dog_tolerance?.value === 'sizeLimited' ? ['policies_dog_weight'] : [], 
        'policies_cat_tolerance', 'policies_smoking_tolerance',
        'policies_bbq_tolerance', 
        ... state.productForm.newProduct.fields?.project_type?.value === 'rent' ? ['policies_credit_check', 'policies_criminal_check'] : []
    ];

    const unitListSteps = [
        'units',
        'buildingView_image',
        'buildingView_floors',
        'floor_plans',
    ];

    const unitDetailsSteps = [
        'units_furnishing'
    ];

    const contactAndCustomizationSteps = [
        'project_email', 'show_price', 'deposit', 'reservations'
    ];

    const scrollContainerToTop = () => {
        if(stepContainer.current) {
            stepContainer.current.scrollTo(0,0);
        }
    }

    useEffect(() => {
        actions.sidebar.hide();
        actions.topbar.showShadow();
        actions.topbar.showLogo();

        effects.currentUser.verifyToken().then((res) => {
            if(res) {
                actions.currentUser.loadCurrentUser();
            }
            else {
                setAuth(false);
            }
        });

        if(params.id !== undefined) {
            actions.productForm.setProductId(params.id);
            actions.productForm.loadNewProduct().then(() => {
                if(state.productForm.newProduct.fields.form_steps_data.value.array[0] < projectIdentitySteps.length) {
                    actions.productForm.setCurrentFormStep(1);
                } else if (state.productForm.newProduct.fields.form_steps_data.value.array[1] < buildingInformationSteps.length) {
                    actions.productForm.setCurrentFormStep(2);
                } else if (state.productForm.newProduct.fields.form_steps_data.value.array[2] < unitListSteps.length) {
                    actions.productForm.setCurrentFormStep(3);
                } else if (state.productForm.newProduct.fields.form_steps_data.value.array[3] < unitDetailsSteps.length) {
                    actions.productForm.setCurrentFormStep(4);
                } else if (state.productForm.newProduct.fields.form_steps_data.value.array[4] < contactAndCustomizationSteps.length) {
                    actions.productForm.setCurrentFormStep(5);
                }
                actions.units.loadVsoInfo({vsoId: parseInt(params.id), projectId: state.productForm.newProduct.project_id});
                actions.units.getOptionalColumns();
                actions.productForm.getReservationInfo().then(() => {
                    actions.productForm.getContactInfo().then(() => {
                        setIsLoading(false);
                    });
                });
            });
        }
    }, []);

    useEffect(() => {
        scrollContainerToTop();
    }, [state.productForm.formStep]);

    useEffect(() => {
        return () => {
            actions.topbar.hideLogo();
        }
    }, []);

    useEffect(() => {
        if(state.productForm.newProduct.fields.project_address?.value?.full_string){
            setCurrentAddress(state.productForm.newProduct.fields.project_address?.value?.full_string);
        }
    }, []);

    let totalFields = [projectIdentitySteps.length, buildingInformationSteps.length, unitListSteps.length, unitDetailsSteps.length, contactAndCustomizationSteps.length].reduce((generatedSum, num) => generatedSum + num, 0);
    let completedFields = state.productForm.newProduct.fields.form_steps_data?.value?.array.reduce((generatedSum, num) => generatedSum + num, 0);

    return (
        <>
        <div className="product-form__container">
            {!isLoading &&
            <>
            <StepsBanner 
                isCurrent={state.productForm.formStep} 
                setCurrentStep={actions.productForm.setCurrentFormStep} 
                subStepsArray={state.productForm.newProduct.fields.form_steps_data.value.array}
                totalSubStepsArray={[projectIdentitySteps.length, buildingInformationSteps.length, unitListSteps.length, unitDetailsSteps.length, contactAndCustomizationSteps.length]}
                state={state}
                actions={actions}
                effects={effects}
                projectIdentitySteps={projectIdentitySteps.filter((el) => !el.includes('available'))}
                buildingInformationSteps={buildingInformationSteps}
                unitListSteps={unitListSteps}
                unitDetailsSteps={unitDetailsSteps}
                contactAndCustomizationSteps={contactAndCustomizationSteps}
            />
            <div className={`product-form__step-container ${state.productForm.formStep === 3 ? '--fullWidth' : ''}`} ref={stepContainer}>
                {state.productForm.formStep === 1 ? <ProjectIdentity /> :
                state.productForm.formStep === 2 ? <BuildingInformation /> :
                state.productForm.formStep === 3 ? <UnitList /> :
                state.productForm.formStep === 4 ? <UnitDetails /> :
                state.productForm.formStep === 5 ? <ContactCustom currentAddress={currentAddress}/> :
                state.productForm.formStep === 6 ? <CampaignLaunch completedFields={completedFields} totalFields={totalFields} currentAddress={currentAddress}/> :
                <></>}
            </div>
            </>
            }
        </div>
        </>
    );



}

export default ProductForm;