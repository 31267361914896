export const hide = ({state}) => {
    state.topbar.isShown = false;
}

export const show = ({state}) => {
    state.topbar.isShown = true;
}

export const hideShadow = ({state}) => {
    state.topbar.hasShadow = false;
}

export const showShadow = ({state}) => {
    state.topbar.hasShadow = true;
}

export const hideLogo = ({state}) => {
    state.topbar.hasLogo = false;
}

export const showLogo = ({state}) => {
    state.topbar.hasLogo = true;
}

export const setCurrentPage = ({state}, page) => {
    state.topbar.currentPage = page;
}