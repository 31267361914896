import React, { useEffect } from 'react';
import { useOvermind } from 'src/overmind';

const Settings = () => {
    const { state, actions } = useOvermind()

    useEffect(() => {
        actions.vsoDashboard.setCurrentPage('settings');
    }, []);

    return (
        <div>Settings</div>
    )
}

export default Settings;