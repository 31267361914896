import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import Image from '../images';

const SortableImage = ({id, url, firstIndex, element, onRemove}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition, 
    } = useSortable({id: id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundImage: `url(${url})`,
        zIndex: attributes['aria-pressed'] === true ? 5 : 0
    };

    return (
        <Image 
            ref={setNodeRef} 
            style={style}            
            firstIndex={firstIndex}
            element={element}
            onRemove={onRemove}        
            {...attributes}
            {...listeners} 
        />
    );
}

export default SortableImage;