import React,  { useState } from 'react';
import { useHistory } from 'react-router-dom';
import StepsCard from './stepsCard';
import "./stepsBanner.scss";
import StepProgressBar from './stepProgressBar';
import Button from 'src/components/shared/button';
import { checkPublicId, convertUrltoFile } from 'src/utils/fileUpload';
import Modal from 'src/components/shared/modal';
import LoadingBar from 'src/components/shared/loadingBar';

const StepsBanner = ({
    state, actions, effects,
    isCurrent, setCurrentStep, subStepsArray, totalSubStepsArray, 
    projectIdentitySteps, buildingInformationSteps, unitListSteps,
    unitDetailsSteps, contactAndCustomizationSteps
}) => {
    let totalSubStepsFields = totalSubStepsArray.reduce((generatedSum, num) => generatedSum + num, 0);
    let currentSubStepFields = subStepsArray.reduce((generatedSum, num) => generatedSum + num, 0);

    const [showImagesLoaderBar, setShowImagesLoaderBar] = useState(false);
    const [isImagesLoaded, setIsImagesLoaded] = useState(false);
    const [uploadingText, setUploadingText] = useState("");
    const [showDocumentsLoaderBar, setShowDocumentsLoaderBar] = useState(false);
    const [isDocumentsLoaded, setIsDocumentsLoaded] = useState(false);
    const history = useHistory();


    const handleSaveAndExit = async () => {
        if(isCurrent === 1) {
            if(state.productForm.newProduct.fields.project_logo?.value?.array[0]?.src) {
                if(checkPublicId(state.productForm.newProduct.fields.project_logo?.value?.array)) {
                    let formData = new FormData();
                    let convertedFile = await convertUrltoFile(state.productForm.newProduct.fields.project_logo?.value?.array[0]?.src, state.productForm.newProduct.fields.project_logo?.value?.array[0]?.name);
                    formData.append('logo', convertedFile);
                    effects.productForm.uploadImageVso(state.productForm.newProduct.id, 'project_logo', formData).then((res) => {
                        actions.productForm.setProductField(
                            {
                                fieldId: 'project_logo',
                                value: {array: [{...state.productForm.newProduct.fields.project_logo?.value?.array[0], src: res}]}
                            }
                            )
                        });  
                }
            }
            if(state.productForm.newProduct.fields.images.value.array.length > 0) { 
                if(checkPublicId(state.productForm.newProduct.fields.images.value.array)) {
                    let formData = new FormData();
                    for(let f of state.productForm.newProduct.fields.images.value.array) {
                        formData.append('imageInfo', JSON.stringify(f));
                        let convertedFile = await convertUrltoFile(f.src, f.name)
                        formData.append('images', convertedFile);
                    }
                    setUploadingText('Saving Images...');
                    setShowImagesLoaderBar(true);
                    await actions.productForm.updateProjectImages(formData).then(() => {
                        setIsImagesLoaded(true);
                    })
                }
            }
            actions.productForm.updateProductFields(projectIdentitySteps);
        } else if(isCurrent === 2) {
            if(state.productForm.newProduct.fields.documents.value.array.length > 0) { 
                if(checkPublicId(state.productForm.newProduct.fields.documents.value.array)) {
                    let formData = new FormData();
                    for (const file of state.productForm.newProduct.fields.documents.value.array){
                        formData.append('documentInfo', JSON.stringify(file));
                        let convertedFile = await convertUrltoFile(file.src, file.name)
                        formData.append('project_documents', convertedFile);
                    }
                    setUploadingText('Saving documents...');
                    setShowDocumentsLoaderBar(true);
                    await actions.productForm.updateProjectDocuments(formData).then(() => {
                        setIsDocumentsLoaded(true);
                    })
                }
            }
            actions.productForm.updateProductFields(buildingInformationSteps)
        } else if(isCurrent === 4) {
            actions.productForm.updateProductFields(unitDetailsSteps)
        } else if(isCurrent === 5) {
            actions.productForm.sendContactInfo();
            actions.productForm.updateReservationDays();
            actions.productForm.updateReservationDeposit();
        }
        actions.productForm.clearProduct();
        history.push('/virtual-office-list');
    }


    return(
        <div className="stepsBanner__container">
            <StepProgressBar 
                completedFields={currentSubStepFields} 
                totalFields={totalSubStepsFields}
                options={{circleWidth: '82px'}} 
            />
            <StepsCard
                stepNumber={1}
                title="Project Identity"
                currentSubStep={subStepsArray[0]}
                totalSubSteps={totalSubStepsArray[0]} 
                isCurrent={isCurrent === 1}
                setCurrentStep={() => setCurrentStep(1)} 
            />
            <StepsCard
                stepNumber={2}
                title="Building information"
                currentSubStep={subStepsArray[1]}
                totalSubSteps={totalSubStepsArray[1]} 
                isCurrent={isCurrent === 2}
                setCurrentStep={() => setCurrentStep(2)} 
            />
            <StepsCard
                stepNumber={3}
                title="Unit list"
                currentSubStep={subStepsArray[2]}
                totalSubSteps={totalSubStepsArray[2]} 
                isCurrent={isCurrent === 3}
                setCurrentStep={() => setCurrentStep(3)} 
            />
            <StepsCard
                stepNumber={4}
                title="Unit details"
                currentSubStep={subStepsArray[3]}
                totalSubSteps={totalSubStepsArray[3]} 
                isCurrent={isCurrent === 4}
                setCurrentStep={() => setCurrentStep(4)} 
            />
            <StepsCard
                stepNumber={5}
                title="Customization"
                currentSubStep={subStepsArray[4]}
                totalSubSteps={totalSubStepsArray[4]} 
                isCurrent={isCurrent === 5}
                setCurrentStep={() => setCurrentStep(5)} 
            />
            <Button type="primary" text="Save & exit" onClick={handleSaveAndExit}/>
            <Modal isOpen={showDocumentsLoaderBar} setIsOpen={setShowDocumentsLoaderBar} options={{contentCenter: true, disableBackdropClick: true}}>
                <div className="loadingBar__container">
                    <div className="loadingBar__text">{uploadingText}</div>
                    <LoadingBar isLoaded={isDocumentsLoaded} setIsOpen={setShowDocumentsLoaderBar} /> 
                </div>
            </Modal> 
            <Modal isOpen={showImagesLoaderBar} setIsOpen={setShowImagesLoaderBar} options={{contentCenter: true, disableBackdropClick: true}}>
                <div className="loadingBar__container">
                    <div className="loadingBar__text">{uploadingText}</div>
                    <LoadingBar isLoaded={isImagesLoaded} setIsOpen={setShowImagesLoaderBar} /> 
                </div>
            </Modal>
        </div>
    )
}

export default StepsBanner;