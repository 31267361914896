import React from 'react';
import "./planModal.scss";
import SvgIcons from '../svgIcons';
import { createPortal } from 'react-dom';

const PlanModal = ({children, isOpen, setIsOpen, options={disableBackdropClick: false, hasCloseButton: false}}) => {
    return(
        <>
            {isOpen ? 
            createPortal(
                <div className={`planModal__backdrop`} onClick={() => !options.disableBackdropClick && setIsOpen(false)}>
                    {
                            options.hasCloseButton && 
                            <div className="planModal__backdrop-close" onClick={() => setIsOpen(false)}>
                                <SvgIcons type="close_modal" />
                            </div>
                    }
                    <div className="planModal__panel" onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </div>, document.querySelector('#modals'))      
            :
            <></>
            }
        </>
    );
}

export default PlanModal;