import React, { useState } from 'react';
import NumberInput from 'src/components/shared/inputs/numberInput';
import Button from '../../button';
import './addMultiUnits.scss';

const AddMultiUnits = ({setOpen, units, addUnits}) => {
    const [numberOfUnits, setNumberOfUnits] = useState(0);
    
    const handleMultipleUnitsValue = (e) => {
        let restrictionValue = (99 - units?.length);
        if(e.currentTarget.value <= restrictionValue) {
            e.currentTarget.value = parseInt(e.currentTarget.value, 10);  
            setNumberOfUnits(e.currentTarget.value);
        }
    }

    const handleAddMultiUnits = () => {
        addUnits(parseInt(numberOfUnits));
        setOpen(false);
    }

    return(
        <div className="addMultiUnits__container">
            <div className="addMultiUnits__input-button-container">
                <NumberInput
                    label="Add multiple units:"
                    placeholder="add units" 
                    options={{maxWidth: '140px'}}
                    setValue={handleMultipleUnitsValue}
                    value={numberOfUnits}
                    dataObj={{}}
                />
                <Button type="primary" text="Add" onClick={handleAddMultiUnits} disabled={units?.length === 99} />
            </div>
        </div>
    )
}

export default AddMultiUnits;