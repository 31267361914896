import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useOvermind } from '../../overmind';
import jwt_decode from 'jwt-decode';
/*import { GoogleLogin } from 'react-google-login';
import TermsAndCondition from '../termsAndCondition/';
import Modal from '../shared/modal/Modal';*/
import PasswordConstraints from '../shared/passwordConstraints';
import { validateTextInput } from 'src/utils/fieldValidation';
import TextInput from 'src/components/shared/inputs/textInput';

require('dotenv').config();

const Signup = ({setAuth}) => {
    const { actions } = useOvermind();
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        firstNameConfirmation: '',
        lastNameConfirmation: '',
        code: ''
    });
    const [formErrors, setFormErrors] = useState({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
        firstNameConfirmation: null,
        lastNameConfirmation: null,
        code: null
    });
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [isPasswordConstraintsOpen, setIsPasswordConstraintsOpen] = useState(false);
    const [passwordConstraintsError, setPasswordConstraintsError] = useState(null);
    const passwordFieldRef = useRef(null);
    const [termsAndCondition, setTermsAndCondition] = useState(false);
    /*const [isGoogleValidation, setIsGoogleValidation] = useState(false);
    const [googleInfo, setGoogleInfo] = useState({});
    const inputRefFirst = useRef("");
    const inputRefLast = useRef("");*/

    useEffect(() => {
        actions.topbar.hideShadow();
        actions.topbar.hideLogo();
    }, []);

    useEffect(() => {
        if(inputs.password !== '') {
            setFormErrors((prevState) => {return {...prevState, password: validateTextInput(inputs.password, {isMandatory: true}, [passwordConstraintsError])}})
        }
    }, [passwordConstraintsError]);

    const handleInputChange = (e, dataObj) => {
        setInputs((prevState) => {return {...prevState, [dataObj.field]: e.target.value}});
    }

    const validateField = (userField) => {
        const error = validateTextInput(inputs[userField], {isMandatory: true, isEmail: userField === 'email'});
        setFormErrors((prevState) => { return {...prevState, [userField]: error} });
        return error;
    }

    const validatePassword = (error) => {
        setPasswordConstraintsError(error)
    }

    const validateForm = () => {
        setFormErrors({
            firstName: null,
            lastName: null,
            email: null,
            password: null,
            confirmPassword: null,
            firstNameConfirmation: null,
            lastNameConfirmation: null,
            code: null
        });

        const newErrors = {
            firstName: validateTextInput(inputs.firstName, {isMandatory: true}),
            lastName: validateTextInput(inputs.lastName, {isMandatory: true}),
            email: validateTextInput(inputs.email, {isMandatory: true, isEmail: true}),
            password: validateTextInput(inputs.password, {isMandatory: true}, [passwordConstraintsError]),
            confirmPassword: validateTextInput(inputs.confirmPassword, {isMandatory: true}, [inputs.password !== inputs.confirmPassword ? 'Passwords don\'t match' : null]),
            code: validateTextInput(inputs.code, {isMandatory: true}),
        }
        
        setFormErrors(newErrors);
        
        return Object.values(newErrors).every((value) => value === false);
    }

    // Handle normal signup
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(validateForm()) {
            try {
                const body = {
                    firstName: inputs.firstName, 
                    lastName: inputs.lastName, 
                    email: inputs.email, 
                    password: inputs.password, 
                    code: inputs.code
                };

                // Request to create a user
                const response = await fetch(
                process.env.REACT_APP_SERVER_URL + 'auth/signup',
                {
                    method: 'POST',
                    headers: {'Content-Type' : 'application/json'},
                    body: JSON.stringify(body)
                });

                // Check errors and display them
                const parseRes = await response.json();
                if(parseRes.hasOwnProperty('errors')) {
                    for( let i = 0; i < parseRes.errors.length; i++) {
                        if(['email', 'password', 'firstName', 'lastName', 'code'].includes(parseRes.errors[i].param)) {
                            setFormErrors((prevState) => {return {...prevState, [parseRes.errors[i].param]: parseRes.errors[i].msg}});
                        } else {
                            console.log('Server Error');
                        }
                    }
                }
                
                // Check Token and authenticate
                localStorage.setItem('token', parseRes.token);
                if(localStorage.token !== 'undefined') {
                    const decodedToken = jwt_decode(parseRes.token);
                    actions.currentUser.setUserId(decodedToken.user.userId);
                    actions.currentUser.setUserType(decodedToken.user.level);
                    setAuth(true);
                }
            } catch (err) {
                console.error(err.message);
            }
        }
    };

    /*
    // Handle Google signup
    const handleValidation = async (e) => {
        e.preventDefault();
        try {
            // Save user input into state
            let tempGoogleInfo = googleInfo;
            tempGoogleInfo.firstName = firstNameConfirmation;
            tempGoogleInfo.lastName = lastNameConfirmation;
            setGoogleInfo(tempGoogleInfo);
            
            // Create user 
            const res = await fetch(
                process.env.REACT_APP_SERVER_URL + 'google/signup',
                {
                    method: 'POST',
                    headers: {'Content-Type' : 'application/json'},
                    body: JSON.stringify(googleInfo)
                }
            );
            
            // Error checking on response
            const parseRes = await res.json();
            if(parseRes.hasOwnProperty('errors')){
                for( let i = 0; i < parseRes.errors.length; i++){
                    if( parseRes.errors[i].param === 'firstName') {
                        setFirstNameError(parseRes.errors[i].msg);
                    } else if ( parseRes.errors[i].param === 'lastName') {
                        setLastNameError(parseRes.errors[i].msg);
                    } else {
                        console.log("Server Error");
                    }
                }
            }

            // Check token and authenticate
            localStorage.setItem('token', parseRes.token);
            if(localStorage.token !== 'undefined') {
                const decodedToken = jwt_decode(parseRes.token);
                actions.currentUser.setUserId(decodedToken.user.userId);
                actions.currentUser.setUserType(decodedToken.user.level);
                setAuth(true);
            }

        }   catch (err) {
            console.error(err.message);
        }
    }

    // Response from googles authentication
    const responseGoogle = async (response) => {

        try { 
            
            // Check if user has google name then display if so
            if( response.profileObj.givenName ) {
                inputRefFirst.current = response.profileObj.givenName;
                setInputs({...inputs, firstNameConfirmation
                    : inputRefFirst.current});
            }
            if( response.profileObj.familyName ) {
                inputRefLast.current = response.profileObj.familyName;
                setInputs({...inputs, lastNameConfirmation
                    : inputRefLast.current});
            }
            setGoogleInfo({
                firstName: response.profileObj.givenName,
                lastName: response.profileObj.familyName,
                email: response.profileObj.email, 
                tokenId: response.tokenId
            });

            // Change state in order to display form
            setIsGoogleValidation(true);
            
        } catch (err) {
            console.error(err.message);
        }
    };
    */

    const openTermsAndCondition = (event) => {
        event.preventDefault();
        setTermsAndCondition(true);
    }

    const handleTermsCheckbox = () => {
        setAcceptTerms(!acceptTerms);
    }

    return (
        <>
        {/* { !isGoogleValidation ?  */}
        <div className="auth__container">
            <h1>Create your account</h1>
            <p className="p--muted p--centered">Get deep marketing insights on your real-estate projects now.</p>
            <form className="auth__form" onSubmit={handleSubmit}>
                <div className="form__input-row">
                    <TextInput label="First name" 
                        value={inputs.firstName}
                        setValue={handleInputChange}
                        dataObj={{field: 'firstName'}} 
                        error={formErrors.firstName}
                        placeholder="John"
                    />
                    <TextInput label="Last name" 
                        value={inputs.lastName}
                        setValue={handleInputChange}
                        dataObj={{field: 'lastName'}} 
                        error={formErrors.lastName}
                        placeholder="Smith"
                    />
                </div>
                <TextInput label="Email address" 
                    value={inputs.email}
                    setValue={handleInputChange}
                    dataObj={{field: 'email'}} 
                    error={formErrors.email}
                    placeholder="name@example.com"
                    options={{isEmail: true}}
                />
                <TextInput label="Password" 
                    value={inputs.password}
                    setValue={handleInputChange}
                    dataObj={{field: 'password'}} 
                    error={formErrors.password}
                    placeholder="********"
                    options={{isPassword: true}}
                    events={{onFocus: () => {setIsPasswordConstraintsOpen(true)}, onBlur: () => {setIsPasswordConstraintsOpen(false)}}}
                >
                    <PasswordConstraints value={inputs.password} isOpen={isPasswordConstraintsOpen} setError={validatePassword}/>
                </TextInput>
                <TextInput label="Confirm password" 
                    value={inputs.confirmPassword}
                    setValue={handleInputChange}
                    dataObj={{field: 'confirmPassword'}} 
                    error={formErrors.confirmPassword}
                    placeholder="********"
                    options={{isPassword: true}}
                />
                {process.env.REACT_APP_USE_SIGNUP_CODE &&
                <TextInput label="Access code" 
                    value={inputs.code}
                    setValue={handleInputChange}
                    dataObj={{field: 'code'}} 
                    error={formErrors.code}
                    placeholder="Access code"
                />
                }
                <div className="form__input-group">
                    <label htmlFor="input__terms">
                        <input id="input__terms" type="checkbox" name="terms" checked={acceptTerms} onClick={handleTermsCheckbox}/> I agree with the <a href="/" onClick={openTermsAndCondition}>terms and conditions.</a>
                    </label>
                </div>
                <button className="btn-primary" disabled={!acceptTerms}>Create my account</button>
            </form>
            {/* <div className="GoogleLogin">
                <GoogleLogin
                    clientId="110737638975-kr4ctnugscjaierd1dfc0vo1te27vtbv.apps.googleusercontent.com"
                    buttonText="Sign up with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                />
            </div> */}
            <p className="p--small p--muted">Already have an account? <Link to='/login'>Login</Link></p>
        </div> 
        {/*: 
        <div className="auth__container">
            <h1>Complete your account</h1>
            <form className="auth__form" onSubmit={handleValidation}>
                <div className="form__input-row">
                    <div className="form__input-group">
                        <label htmlFor="input__firstName">First name</label>
                        <input ref={inputRefFirst} id="input__firstName" type="text" name="firstNameConfirmation" value={firstNameConfirmation} onChange={e => onChange(e)} placeholder="John" maxLength="64" />
                        <p className="errorMsg">{firstNameError}</p>
                    </div>
                    <div className="form__input-group">
                        <label htmlFor="input__lastName">Last name</label>
                        <input ref={inputRefLast} id="input__lastName" type="text" name="lastNameConfirmation" value={lastNameConfirmation} onChange={e => onChange(e)} placeholder="Smith" maxLength="64"/>
                        <p className="errorMsg">{lastNameError}</p>
                    </div>
                </div>
                <button className="btn-primary">Confirm</button>
            </form>
        </div> }
         <Modal isOpen={termsAndCondition} setIsOpen={setTermsAndCondition}>
             <TermsAndCondition setAcceptTerms={setAcceptTerms} setTermsAndCondition={setTermsAndCondition}/>
        </Modal>*/}
        </>
    )
}

export default Signup;
