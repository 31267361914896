require('dotenv').config();

export const fetchMarketTests = async () => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'marketTests/user/' + localStorage.userId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
    
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const fetchMarketTestFields = async (marketTestId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'marketTests/' + marketTestId + '/fields',
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

// fetch all market tests
export const fetchAllMarketTests = async () => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'marketTests/',
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
        
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const deleteMarketTest = async (marketTestId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'marketTests/' + marketTestId,
            {
                method: 'DELETE',
                headers: {'token' : localStorage.token}
            });
        
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}