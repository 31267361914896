export const loadVsoFields = async ({state, effects}, id) => {
    state.currentVSO.vsoId = id;
    const vsoFieldsAndUnits = await effects.currentVSO.fetchVsoFieldsAndUnits(id);
    
    const jsonRegex = /^{"/;
    for(const fieldName in vsoFieldsAndUnits.fields) {
        if(vsoFieldsAndUnits.fields[fieldName]) {
            if(jsonRegex.test(vsoFieldsAndUnits.fields[fieldName])) {
                vsoFieldsAndUnits.fields[fieldName] = JSON.parse(vsoFieldsAndUnits.fields[fieldName]);
            }
            if(vsoFieldsAndUnits.fields[fieldName].array) {
                for(let i = 0; i < vsoFieldsAndUnits.fields[fieldName].array.length; i++) {
                    if(jsonRegex.test(vsoFieldsAndUnits.fields[fieldName].array[i])) {
                        vsoFieldsAndUnits.fields[fieldName].array[i] = await JSON.parse(vsoFieldsAndUnits.fields[fieldName].array[i]);
                    }
                }
                vsoFieldsAndUnits.fields[fieldName] = vsoFieldsAndUnits.fields[fieldName].array;
            }
        }
    }
    state.currentVSO.fields = vsoFieldsAndUnits.fields;
    state.currentVSO.units = vsoFieldsAndUnits.units;
    state.currentVSO.units.forEach( async (unit) => {
        unit.extra_content = await JSON.parse(unit.extra_content);
        if(Object.keys(unit.extra_content).length > 0) { 
            let keys = Object.keys(unit.extra_content);
            let values = Object.values(unit.extra_content);
            keys.forEach((key, index) => {
                unit[`extraContent_${key}`] = values[index]; 
            });
        }
    });
    state.currentVSO.units.forEach( async (unit) => {
        unit.outline_content = await JSON.parse(unit.outline_content);
    });

    state.currentVSO.deposit_amount = vsoFieldsAndUnits.deposit_amount;
    state.currentVSO.reservation_time = vsoFieldsAndUnits.reservation_time;
    state.currentVSO.optionalColumns = JSON.parse(vsoFieldsAndUnits.units_columns);
    state.currentVSO.enable_reservations = vsoFieldsAndUnits.enable_reservations;
    state.currentVSO.show_prices = vsoFieldsAndUnits.show_prices;
    state.currentVSO.lead_gen_url = vsoFieldsAndUnits.lead_gen_url;

    setDiaporamaPlanSrc({state});
    calculateUnitsInfo({state});
}

export const setDiaporamaPlanSrc = ({state}) => {
    for(const unit of state.currentVSO.units) {
        if(unit.plan) {
            state.currentVSO.diaporamaPlan = unit.plan;
            break;
        }
    }
}

export const calculateUnitsInfo = ({state}) => {
    state.currentVSO.fields.num_studios = 0;
    state.currentVSO.fields.num_1_bdr = 0;
    state.currentVSO.fields.num_2_bdr = 0;
    state.currentVSO.fields.num_3_bdr = 0;
    state.currentVSO.fields.num_4_bdr = 0;
    state.currentVSO.fields.num_loft = 0;
    state.currentVSO.fields.num_penthouse = 0;
    state.currentVSO.fields.num_townhouse = 0;
    state.currentVSO.fields.min_area_studios = Infinity;
    state.currentVSO.fields.min_area_1_bdr = Infinity;
    state.currentVSO.fields.min_area_2_bdr = Infinity;
    state.currentVSO.fields.min_area_3_bdr = Infinity;
    state.currentVSO.fields.min_area_4_bdr = Infinity;
    state.currentVSO.fields.min_area_loft = Infinity;
    state.currentVSO.fields.min_area_penthouse = Infinity;
    state.currentVSO.fields.min_area_townhouse = Infinity;
    state.currentVSO.fields.max_area_studios = 0;
    state.currentVSO.fields.max_area_1_bdr = 0;
    state.currentVSO.fields.max_area_2_bdr = 0;
    state.currentVSO.fields.max_area_3_bdr = 0;
    state.currentVSO.fields.max_area_4_bdr = 0;
    state.currentVSO.fields.max_area_loft = 0;
    state.currentVSO.fields.max_area_penthouse = 0;
    state.currentVSO.fields.max_area_townhouse = 0;
    state.currentVSO.fields.min_area_studios = Infinity;
    state.currentVSO.fields.min_price_1_bdr = Infinity;
    state.currentVSO.fields.min_price_2_bdr = Infinity;
    state.currentVSO.fields.min_price_3_bdr = Infinity;
    state.currentVSO.fields.min_price_4_bdr = Infinity;
    state.currentVSO.fields.min_price_loft = Infinity;
    state.currentVSO.fields.min_price_penthouse = Infinity;
    state.currentVSO.fields.min_price_townhouse = Infinity;
    state.currentVSO.fields.max_price_studios = 0;
    state.currentVSO.fields.max_price_1_bdr = 0;
    state.currentVSO.fields.max_price_2_bdr = 0;
    state.currentVSO.fields.max_price_3_bdr = 0;
    state.currentVSO.fields.max_price_4_bdr = 0;
    state.currentVSO.fields.max_price_loft = 0;
    state.currentVSO.fields.max_price_penthouse = 0;
    state.currentVSO.fields.max_price_townhouse = 0;

    for(const unit of state.currentVSO.units) {
        if(unit.bedroom == 'studio') {
            state.currentVSO.fields.num_studios += 1; 
            if(Number(unit.price) < Number(state.currentVSO.fields.min_price_studios)) {
                state.currentVSO.fields.min_price_studios = unit.price;
            }     
            if(Number(unit.price) > Number(state.currentVSO.fields.max_price_studios)) {
                state.currentVSO.fields.max_price_studios = unit.price;
            }
            if(Number(unit.sqft) < Number(state.currentVSO.fields.min_area_studios)) {
                state.currentVSO.fields.min_area_studios = unit.sqft;
            }     
            if(Number(unit.sqft) > Number(state.currentVSO.fields.max_area_studios)) {
                state.currentVSO.fields.max_area_studios = unit.sqft;
            }
        }
        if(unit.bedroom == 'oneBedroom') {
            state.currentVSO.fields.num_1_bdr += 1; 
            if(Number(unit.price) < Number(state.currentVSO.fields.min_price_1_bdr)) {
                state.currentVSO.fields.min_price_1_bdr = unit.price;
            }     
            if(Number(unit.price) > Number(state.currentVSO.fields.max_price_1_bdr)) {
                state.currentVSO.fields.max_price_1_bdr = unit.price;
            }
            if(Number(unit.sqft) < Number(state.currentVSO.fields.min_area_1_bdr)) {
                state.currentVSO.fields.min_area_1_bdr = unit.sqft;
            }     
            if(Number(unit.sqft) > Number(state.currentVSO.fields.max_area_1_bdr)) {
                state.currentVSO.fields.max_area_1_bdr = unit.sqft;
            }
        }
        if(unit.bedroom == 'twoBedroom') {
            state.currentVSO.fields.num_2_bdr += 1; 
            if(Number(unit.price) < Number(state.currentVSO.fields.min_price_2_bdr)) {
                state.currentVSO.fields.min_price_2_bdr = unit.price;
            }     
            if(Number(unit.price) > Number(state.currentVSO.fields.max_price_2_bdr)) {
                state.currentVSO.fields.max_price_2_bdr = unit.price;
            }
            if(Number(unit.sqft) < Number(state.currentVSO.fields.min_area_2_bdr)) {
                state.currentVSO.fields.min_area_2_bdr = unit.sqft;
            }     
            if(Number(unit.sqft) > Number(state.currentVSO.fields.max_area_2_bdr)) {
                state.currentVSO.fields.max_area_2_bdr = unit.sqft;
            }
        }
        if(unit.bedroom == 'threeBedroom') {
            state.currentVSO.fields.num_3_bdr += 1; 
            if(Number(unit.price) < Number(state.currentVSO.fields.min_price_3_bdr)) {
                state.currentVSO.fields.min_price_3_bdr = unit.price;
            }     
            if(Number(unit.price) > Number(state.currentVSO.fields.max_price_3_bdr)) {
                state.currentVSO.fields.max_price_3_bdr = unit.price;
            }
            if(Number(unit.sqft) < Number(state.currentVSO.fields.min_area_3_bdr)) {
                state.currentVSO.fields.min_area_3_bdr = unit.sqft;
            }     
            if(Number(unit.sqft) > Number(state.currentVSO.fields.max_area_3_bdr)) {
                state.currentVSO.fields.max_area_3_bdr = unit.sqft;
            }
        }
        if(unit.bedroom == 'fourBedroom') {
            state.currentVSO.fields.num_4_bdr += 1; 
            if(Number(unit.price) < Number(state.currentVSO.fields.min_price_4_bdr)) {
                state.currentVSO.fields.min_price_4_bdr = unit.price;
            }     
            if(Number(unit.price) > Number(state.currentVSO.fields.max_price_4_bdr)) {
                state.currentVSO.fields.max_price_4_bdr = unit.price;
            }
            if(Number(unit.sqft) < Number(state.currentVSO.fields.min_area_4_bdr)) {
                state.currentVSO.fields.min_area_4_bdr = unit.sqft;
            }     
            if(Number(unit.sqft) > Number(state.currentVSO.fields.max_area_4_bdr)) {
                state.currentVSO.fields.max_area_4_bdr = unit.sqft;
            }
        }  
    }
}

export const setUserFirstName = ({ state }, value) => {
    state.currentVSO.currentUser.firstName = value;
};

export const setUserLastName = ({ state }, value) => {
    state.currentVSO.currentUser.lastName = value;
};

export const setUserEmail = ({ state }, value) => {
    state.currentVSO.currentUser.email = value;
};

export const setUserPhone = ({ state }, value) => {
    state.currentVSO.currentUser.phone = value;
};

export const setUserAddress = ({ state }, value) => {
    state.currentVSO.currentUser.address = value;
};

export const setUserCity = ({ state }, value) => {
    state.currentVSO.currentUser.city = value;
};

export const setUserZip = ({ state }, value) => {
    state.currentVSO.currentUser.zip = value;
};

export const setUserCountry = ({ state }, value) => {
    state.currentVSO.currentUser.country = value;
};

export const setUserState = ({ state }, value) => {
    state.currentVSO.currentUser.state = value;
};

export const setUserAuth = ({state}, value) => {
    state.currentVSO.currentUser.isAuth = value;
}

export const loadCurrentUser = ({state}) => {
    if(sessionStorage.getItem('isVSOAuth')){
        state.currentVSO.currentUser.firstName = sessionStorage.getItem('firstName');
        state.currentVSO.currentUser.lastName = sessionStorage.getItem('lastName');
        state.currentVSO.currentUser.email = sessionStorage.getItem('email');
        state.currentVSO.currentUser.isAuth = sessionStorage.getItem('isVSOAuth');
    }
}

export const clearCurrentVSOUser = ({state}) => {
    state.currentVSO.currentUser.firstName = ""
    state.currentVSO.currentUser.lastName = ""
    state.currentVSO.currentUser.email = ""
    state.currentVSO.currentUser.isAuth = false 
}

export const setReservationUnit = ({state}, value) => {
    state.currentVSO.reservation.unitId = Number(value);
}

export const setReservationStep = ({state}, value) => {
    state.currentVSO.reservation.currentStep = value;
}

export const incrementReservationStep = ({state}) => {
    state.currentVSO.reservation.currentStep += 1;
}

export const decrementReservationStep = ({state}) => {
    state.currentVSO.reservation.currentStep -= 1;
}

export const setCurrentFloor = ({state}, value) => {
    state.currentVSO.currentFloor = value;
}

export const addVisitor = async ({state, effects}) => {
    effects.currentVSO.addVisitor({
        firstName: state.currentVSO.currentUser.firstName,
        lastName: state.currentVSO.currentUser.lastName,
        email: state.currentVSO.currentUser.email,
        vsoId: state.currentVSO.vsoId
    });
}

export const setIsContactFormOpen = ({state}, value) => {
    state.currentVSO.isContactFormOpen = value;
}

export const checkOptionalColumns = ({state}) => {
    let tempObj = {
        ...state.currentVSO.filterTable,
        status: {name: 'Status', selectOptions: state.currentVSO.fields.project_type === 'rent' ? ['available', 'reserved', 'rented'] : ['available', 'reserved', 'sold'], filters: []},
        ...(state.currentVSO.optionalColumns[state.currentVSO.optionalColumns.findIndex((el) => el.name === 'parking')]?.isShown) && {parking: {name: 'Parking', selectOptions: ['oneInterior', 'twoInterior', 'threePlusInterior', 'oneExterior', 'twoExterior', 'threePlusExterior'], filters: []}},
        ...(state.currentVSO.optionalColumns[state.currentVSO.optionalColumns.findIndex((el) => el.name === 'orientation')]?.isShown) && {orientation: {name: 'Orientation', selectOptions: ['north', 'south', 'east',  'west', 'oneEightyDegrees', 'twoSeventyDegrees', 'threeSixtyDegrees'], filters: []}},
        ...(state.currentVSO.optionalColumns[state.currentVSO.optionalColumns.findIndex((el) => el.name === 'position')]?.isShown) && {position: {name: 'Position', selectOptions: ['corner', 'center'], filters: []}},
        ...(state.currentVSO.optionalColumns[state.currentVSO.optionalColumns.findIndex((el) => el.name === 'view')]?.isShown) && {view: {name: 'View', selectOptions: ['city', 'garden', 'mountain', 'park', 'street', 'water'], filters: []}},
    }
    state.currentVSO.filterTable = tempObj;
}

export const pushToFilterArray = ({state}, {dataObj, value}) => {
    let contentId = dataObj.contentId;
    state.currentVSO.filterTable[contentId].filters.push(value);
}

export const removeFromFilterArray = ({state}, {dataObj, index}) => {
    let contentId = dataObj.contentId;;
    state.currentVSO.filterTable[contentId].filters.splice(index, 1);
}

export const setFilterRange = ({state}, {dataObj, value}) => {
    let contentId = dataObj.contentId
    state.currentVSO.filterTable[contentId].filters = value;
}

export const sendMessage = ({state, effects}, messageObj) => {
    if(state.currentVSO.lead_gen_url) {
        effects.currentVSO.sendLeadGenReq(state.currentVSO.lead_gen_url, messageObj);    
    }
    effects.currentVSO.sendMessage(state.currentVSO.vsoId, messageObj);
}