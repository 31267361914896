import React from "react";
import SvgIcons from "../svgIcons";
import "./errorMessage.css";

const ErrorMessage = ({onClick, items, errorCode}) => {
    return(
        <div className="errorMessage__container">
            <div className="errorMessage__errorbar"></div>
            <div className="errorMessage__icon-and-text">
                <SvgIcons type="error_stop"/>
                <div className="errorMessage__code-text">
                    <div className="errorMessage__code">Error: #{errorCode}</div>
                        {items.map((el, index) => {
                            return(
                                <div key={index} className={`errorMessage__text`}>
                                    {el.content}
                                </div>
                            )
                        })}
                </div>
            </div>
            <div className="errorMessage__close-container">
                <div className="errorMessage__seperator"/>
                <div className="errorMessage__close" onClick={onClick}>Close</div>
            </div>
        </div>
    );
}

export default ErrorMessage; 