import React, { useState, useEffect, useRef} from 'react';
import { Line, Text, Layer } from 'react-konva';
import { flatten, findCenterOfPolygon } from 'src/utils/canvas';
import { Html} from 'react-konva-utils';
import Tooltip from '../../tooltip';
import { useTranslation } from 'react-i18next';

const UnitPoly = ({
    unitData, 
    index, 
    editMode, 
    scale,   
    isHovered,
    isActive,
    activeFloor,
    windowMousePos,  
    onMouseUnitEnter, 
    onMouseUnitLeave,
    handleUnitClick
}) => {

    const { t } = useTranslation('buildingView');
    const [centerCoords, setCenterCoords] = useState([0, 0]);
    const [fillColor, setFillColor] = useState('');
    const textRef = useRef('');

    useEffect(() => {
        if(editMode) {
            setFillColor('rgb(24 169 87 / 35%)');
            return;
        } else {
            if(isActive && unitData.status == 'available') {
                setFillColor('rgb(24 169 87 / 35%)');
                return; 
            }
            if(isActive && unitData.status == 'reserved') {
                setFillColor('rgb(255 228 175 / 45%)');
                return;
            }
            if(isActive && unitData.status == 'sold' || unitData.status == 'rented') {
                setFillColor('rgb(242 162 179 / 60%)');
                return;
            }
            if(isHovered && unitData.status == 'available') {
                setFillColor('rgb(24 169 87 / 55%)');
                return;
            }
            if(isHovered && unitData.status == 'reserved') {
                return;
            }
            if(isHovered && unitData.status == 'sold') {
                return;
            }
        }
        setFillColor('');
    }, [editMode, isHovered, activeFloor]);

    useEffect(() => {
        setCenterCoords(findCenterOfPolygon(unitData?.outline_content?.points));
    }, [activeFloor]);

    return(
        <Layer>
            <Line
                closed={true}
                name="Poly"
                fill={fillColor}
                points={flatten(unitData?.outline_content?.points)}
                onMouseEnter={(e) => !editMode && onMouseUnitEnter(e, unitData, index)}
                onMouseLeave={() => !editMode && onMouseUnitLeave(unitData, index)}
                onClick={() => !editMode && handleUnitClick(unitData)}
            />
            {!editMode && isHovered && unitData.status == 'available' ?
                <>
                <Html>
                    <Tooltip 
                        text={
                            <div className="drawingCanvas__tooltip-container">
                                <div className="drawingCanvas__tooltip-title">
                                    {t(`unitInfo.unit`)}: {unitData.unit_number}  
                                </div>
                                <div className="drawingCanvas__tooltip-info">
                                    {t(`unitInfo.${unitData.bedroom}`)} - {t(`unitInfo.${unitData.bathroom}`)} - {unitData.sqft} FT2
                                </div>
                            </div>
                        } 
                        isOpen={isHovered} 
                        options={{coords: {left: windowMousePos[0], top: windowMousePos[1]}, maxWidth: 'none'}}/>
                </Html>
                </>
                :
                <Text
                    ref={textRef}
                    x={centerCoords[0]}
                    y={centerCoords[1]}
                    text={unitData.unit_number}
                    fontSize={36}
                    fill="#fff"
                    shadowColor="black" 
                    shadowBlur={5} 
                    shadowOffset={5}
                    shadowOpacity={0.9}
                />
            }
        </Layer>
    )
}

export default UnitPoly;