import React, {useState, useEffect, useRef} from 'react';
import Button from '../../button';
import SvgIcons from '../../svgIcons';
import './fileInput.scss';
import DragAndDrop from './dragAndDrop';
import { checkFileSize, checkFileValid, findFileSize } from 'src/utils/fileUpload';
import SortableImage from './projectImages/sortableImage';
import SortableDocument from './projectDocuments/sortableDocument';
import { arrayMove } from '@dnd-kit/sortable';
import ErrorMessage from '../../errorMessage';
import Modal from '../../modal';
import InfoPopover from '../../infoPopover';

const FileInput = ({
    label, info, description,
    caption, value, setValue, status = null,
    dataObj, error, validateField, removeFile, 
    options={sizeLimit: 9000000, acceptedFormat: '', isMultiple: false, 
            isImageInput: false, hasCoverIcon: false, isMandatory: false,
            fileLimit: 10 
            }
    }) => {

    const filesRef = useRef(null);
    const [fieldStatus, setFieldStatus] = useState(status);
    const [fieldClasses, setFieldClasses] = useState('');
    const [fileSizeError, setFileSizeError] = useState(false);
    const [fileTypeError, setFileTypeError] = useState(false);
    const [fileLimitError, setFileLimitError] = useState(false);
    
    useEffect(() => {
        let classes = ['fileInput__files-container'];
        if(fieldStatus) {
            classes.push(`--${fieldStatus}`);
        }
        if(typeof error === 'string') {
            if(classes.indexOf(`--denied`) === -1) {
                classes.push(`--denied`);
            }
        } else if(error === false) {
            if(classes.indexOf(`--valid`) === -1) {
                classes.push(`--valid`);
            }
        }
        setFieldClasses(classes.join(' '));
    }, [error, fieldStatus]);

    const handleRemoveFile = async (element) => {
        filesRef.current.value = '';
        await removeFile(element);
        if(validateField) {
            if(!validateField(dataObj.fieldId)){
                setFieldStatus('valid');
            } else {
                setFieldStatus('denied');
            };
        }
    }

    const handleRefClick = () => {
        filesRef.current.click();
    }

    const handleFilesUpload = () => {
        let tempArr = [];
        if(options.fileLimit >= filesRef.current.files.length) {
            setFileLimitError(false);
            if(checkFileValid(filesRef.current.files, setFileTypeError, options.isImageInput)) {
                for(let i = 0; i < filesRef.current.files.length; i++){
                    if(checkFileSize(filesRef.current.files[i], options.sizeLimit)) {
                        setFileSizeError(true);
                        return;
                    }
                    setFileSizeError(false);
                    let tempObj = {}
                    tempObj['id'] = i + (value?.length ? value.length : 0) + 1
                    tempObj['src'] = URL.createObjectURL(filesRef.current.files[i]);
                    tempObj['name'] = filesRef.current.files[i].name;
                    tempObj['size'] = filesRef.current.files[i].size;
                    tempObj['type'] = filesRef.current.files[i].type;
                    tempObj['publicId'] = '';
                    tempArr.push(tempObj);
                }
                if(options.isMultiple) {
                    tempArr.push(...value);
                    //Filter the array if the same files gets uploaded.
                    let filteredArr = tempArr.filter((element, index, self) =>
                        index === self.findIndex((el) => (
                        el.name === element.name
                        ))
                    ).sort((a, b) => a.id - b.id)
                    setValue(filteredArr);
                } else {
                    setValue(
                        {
                            fieldId: dataObj.fieldId,
                            value: {array: tempArr}
                        }
                    )
                }
            }
        } else {
            setFileLimitError(true);
            return;
        }
        if(validateField) {
            if(!validateField(dataObj.fieldId)){
                setFieldStatus('valid');
            } else {
                setFieldStatus('denied');
            };
        }
    }

    let onSortEnd = ({active, over}) => {
        const oldIndex = value.findIndex(element => element.id == active.id);
        const newIndex = value.findIndex(element => element.id == over.id);
        setValue(arrayMove(value, oldIndex, newIndex)); 
    }

    const handleTypeError = () => {
        setFileTypeError(!fileTypeError);
        filesRef.current.value = '';
    }

    const handleSizeError = () => {
        setFileSizeError(!fileSizeError);
        filesRef.current.value = '';
    }

    const handleLimitError = () => {
        setFileLimitError(!fileLimitError);
        filesRef.current.value = '';

    }

    return(
        <div className="fileInput__container">
            <div className="fileInput__label-caption-container">
                <div className="fileInput__label-info-container">
                    <label>{label} {options.isMandatory && <span>*</span>}</label>
                    {info && <InfoPopover content={info} />}
                </div>
                {caption && <div className="fileInput__caption">{caption}</div>}
            </div>
            <div className={`fileInput__preview-upload-container ${options.isMultiple ? '--vertical' : '--horizontal'}`}>
                <div className={`fileInput__single-upload-preview ${!options.isMultiple && value !== null && value !== undefined ? '--active' : ''}` }>
                    <img src={value}/>
                    <div className="fileInput__upload-delete" onClick={handleRemoveFile}>
                        <SvgIcons type="trash_can" />
                    </div>
                </div>
                <div className={`${fieldClasses} ${fileLimitError || fileSizeError || fileTypeError ? '--fileError' : ''}`}>
                    <input
                        ref={filesRef} 
                        type='file' 
                        style={{display: "none"}}
                        multiple={options.isMultiple}  
                        accept={options.acceptedFormat}
                        onChange={handleFilesUpload}
                    />
                    {fileLimitError || fileSizeError || fileTypeError ?
                    <SvgIcons type="cabana_warning_sign" /> :
                    <SvgIcons type="cabana_upload_icon" />
                    }
                    <div className="fileInput__upload-text">
                        {fileLimitError ? `Cannot exceed more than ${options.fileLimit} files` :
                        fileSizeError ? `File size cannot exceed more than ${options.sizeLimit === 9000000 ? 9 : 2} MB` :
                        fileTypeError ? 'File format not supported' :
                        'Drag & Drop your file'
                        }
                    </div>
                    <Button type="primary" text="Browse to upload" onClick={handleRefClick}/>
                    {typeof error === 'string' && 
                    <div className="fileInput__error">{error}</div>
                    }
                </div>
                <div className={`fileInput__multiple-upload ${options.isMultiple && '--active'}`}>
                    <DragAndDrop value={value} onSortEnd={onSortEnd}>
                        {
                            <div className="fileInput__drag-files-container">
                                {options.isMultiple && value?.map((element, index) => {
                                    if(options.isImageInput) {
                                        return(<SortableImage key={index} id={element.id} url={element.src} firstIndex={index} element={element} onRemove={handleRemoveFile} />)
                                    } else {
                                        return(<SortableDocument id={element.id} url={element.src} firstIndex={index} element={element} onRemove={handleRemoveFile} />)
                                    }    
                                })}
                            </div>
                        }
                    </DragAndDrop>
                </div>
            </div>
            {description && <div className="fileInput__description">{description}</div>}
        </div>
    )
}

export default FileInput;