import React, { useEffect, useRef, useState } from "react";
import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import { getToken, createMeeting } from "./api";

import './videoCall.css';

/*
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
*/
/*
const MeetingChat = ({ tollbarHeight }) => {
  const { sendChatMessage, messages } = useMeeting();
  const [message, setMessage] = useState("");

  return (
    <div
      style={{
        marginLeft: borderRadius,
        width: 400,
        backgroundColor: primary,
        overflowY: "scroll",
        borderRadius,
        height: `calc(100vh - ${tollbarHeight + 2 * borderRadius}px)`,
        padding: borderRadius,
      }}
    >
      <Title title={"Chat"} />

      <div style={{ display: "flex" }}>
        <input
          value={message}
          onChange={(e) => {
            const v = e.target.value;
            setMessage(v);
          }}
        />
        <button
          className={"button default"}
          onClick={() => {
            const m = message;

            if (m.length) {
              sendChatMessage(m);

              setMessage("");
            }
          }}
        >
          Send
        </button>
      </div>
      <div>
        {messages?.map((message, i) => {
          const { senderId, senderName, text, timestamp } = message;

          return (
            <div
              style={{
                margin: 8,
                backgroundColor: "darkblue",
                borderRadius: 8,
                overflow: "hidden",
                padding: 8,
                color: "#fff",
              }}
              key={i}
            >
              <p style={{ margin: 0, padding: 0, fontStyle: "italic" }}>
                {senderName}
              </p>
              <h3 style={{ margin: 0, padding: 0, marginTop: 4 }}>{text}</h3>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  opacity: 0.6,
                  marginTop: 4,
                }}
              >
                {formatAMPM(new Date(parseInt(timestamp)))}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
*/

const ParticipantView = ({ participantId }) => {
  const webcamRef = useRef(null);
  const micRef = useRef(null);

  const onStreamEnabled = (stream) => {};
  const onStreamDisabled = (stream) => {};

  const {
    displayName,
    participant,
    webcamStream,
    micStream,
    webcamOn,
    micOn,
    isLocal,
    isActiveSpeaker,
    isMainParticipant,
    pinState,

    setQuality,
    enableMic,
    disableMic,
    enableWebcam,
    disableWebcam,
    pin,
    unpin,
  } = useParticipant(participantId, {
    onStreamEnabled,
    onStreamDisabled,
  });

  useEffect(() => {
    if (webcamRef.current) {
      if (webcamOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);

        webcamRef.current.srcObject = mediaStream;
        webcamRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        webcamRef.current.srcObject = null;
      }
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  return (
    <div className="videoCall__participant">
      <audio ref={micRef} autoPlay />

      <div className="videoCall__participant-video-container">
        <video className="videoCall__participant-video" height={"100%"} width={"100%"} ref={webcamRef} autoPlay />
        <div className="videoCall__participant-name">{displayName}</div>
      </div>
    </div>
  );
};

const ParticipantsView = () => {
  const { participants } = useMeeting();

  return (
    <div className="videoCall__participants-container">
      <ParticipantView key={[...participants.keys()][0]} participantId={[...participants.keys()][0]} />
      <ParticipantView key={[...participants.keys()][1]} participantId={[...participants.keys()][1]} />
    </div>
  );
};

function MeetingView({stopVideoCall}) {
  function onParticipantJoined(participant) {
    console.log(" onParticipantJoined", participant);
  }
  function onParticipantLeft(participant) {
    console.log(" onParticipantLeft", participant);
  }
  const onSpeakerChanged = (activeSpeakerId) => {
    console.log(" onSpeakerChanged", activeSpeakerId);
  };
  function onPresenterChanged(presenterId) {
    console.log(" onPresenterChanged", presenterId);
  }
  function onMainParticipantChanged(participant) {
    console.log(" onMainParticipantChanged", participant);
  }
  function onEntryRequested(participantId, name) {
    console.log(" onEntryRequested", participantId, name);
  }
  function onEntryResponded(participantId, name) {
    console.log(" onEntryResponded", participantId, name);
  }
  function onRecordingStarted() {
    console.log(" onRecordingStarted");
  }
  function onRecordingStopped() {
    console.log(" onRecordingStopped");
  }
  function onChatMessage(data) {
    console.log(" onChatMessage", data);
  }
  function onMeetingJoined() {
    console.log("onMeetingJoined");
  }
  function onMeetingLeft() {
    console.log("onMeetingLeft");
  }
  const onLiveStreamStarted = (data) => {
    console.log("onLiveStreamStarted example", data);
  };
  const onLiveStreamStopped = (data) => {
    console.log("onLiveStreamStopped example", data);
  };

  const onVideoStateChanged = (data) => {
    console.log("onVideoStateChanged", data);
  };
  const onVideoSeeked = (data) => {
    console.log("onVideoSeeked", data);
  };

  const onWebcamRequested = (data) => {
    console.log("onWebcamRequested", data);
  };
  const onMicRequested = (data) => {
    console.log("onMicRequested", data);
  };
  const onPinStateChanged = (data) => {
    console.log("onPinStateChanged", data);
  };

  const {
    meetingId,
    meeting,
    localParticipant,
    participants,
    localMicOn,
    localWebcamOn,
    messages,
    //
    join,
    leave,
    end,
    //
    sendChatMessage,
    respondEntry,
    //
    muteMic,
    unmuteMic,
    toggleMic,
    //
    disableWebcam,
    enableWebcam,
    toggleWebcam,
    //
    getMics,
    getWebcams,
    changeWebcam,
    changeMic,
  } = useMeeting({
    onParticipantJoined,
    onParticipantLeft,
    onSpeakerChanged,
    onPresenterChanged,
    onMainParticipantChanged,
    onEntryRequested,
    onEntryResponded,
    onRecordingStarted,
    onRecordingStopped,
    onChatMessage,
    onMeetingJoined,
    onMeetingLeft,
    onLiveStreamStarted,
    onLiveStreamStopped,
    onVideoStateChanged,
    onVideoSeeked,
    onWebcamRequested,
    onMicRequested,
    onPinStateChanged,
  });

  const handleLeaveCall = () => {
    leave();
    stopVideoCall();
  }

  return (
    <div className="videoCall__container">
      <div>
        <button onClick={join}>
          JOIN
        </button>
      </div>

      <ParticipantsView />

      <div className="videoCall__toolbar">
        <div className="videoCall__toolbar-item" onClick={toggleMic}>Mic {localMicOn ? 'On' : 'Off'}</div>
        <div className="videoCall__toolbar-item" onClick={toggleWebcam}>Webcam {localWebcamOn ? 'On' : 'Off'}</div>
        <div className="videoCall__toolbar-item" >Open chat</div>
        <div className="videoCall__toolbar-item" onClick={handleLeaveCall}>Leave call</div>
      </div>
      {/* <MeetingChat tollbarHeight={tollbarHeight} /> */}
    </div>
  );
}

const VideoCall = ({participantName, stopVideoCall, sendSocketReq, currentMeetingId}) => {
  const [token, setToken] = useState(null);
  const [meetingId, setMeetingId] = useState(null);

  useEffect(() => {  
    const getMeetingAndToken = async () => {
      let meetingId;
      const token = await getToken();

      if(currentMeetingId) {
        meetingId = currentMeetingId;
      } else {
        meetingId = await createMeeting({ token })
        sendSocketReq(meetingId);
      }

      setToken(token);
      setMeetingId(meetingId);
    };

    getMeetingAndToken();
  }, []);

  return token && meetingId ? (
    <MeetingProvider
      config={{
        meetingId,
        micEnabled: true,
        webcamEnabled: true,
        name: participantName,
      }}
      token={token}
    >
      <MeetingConsumer
        {...{
          onParticipantJoined: (participant) => {
            console.log(" onParticipantJoined", participant);
          },
          onParticipantLeft: (participant) => {
            console.log(" onParticipantLeft", participant);
          },
          onSpeakerChanged: (activeSpeakerId) => {
            console.log(" onSpeakerChanged", activeSpeakerId);
          },
          onPresenterChanged: (presenterId) => {
            console.log(" onPresenterChanged", presenterId);
          },
          onMainParticipantChanged: (participant) => {
            console.log(" onMainParticipantChanged", participant);
          },
          onEntryRequested: (participantId, name) => {
            console.log(" onEntryRequested", participantId, name);
          },
          onEntryResponded: (participantId, name) => {
            console.log(" onEntryResponded", participantId, name);
          },
          onRecordingStarted: () => {
            console.log(" onRecordingStarted");
          },
          onRecordingStopped: () => {
            console.log(" onRecordingStopped");
          },
          onChatMessage: (data) => {
            console.log(" onChatMessage", data);
          },
          onMeetingJoined: () => {
            console.log("onMeetingJoined");
          },
          onMeetingLeft: () => {
            console.log("onMeetingLeft");
          },
        }}
      >
        {() => <MeetingView stopVideoCall={stopVideoCall}/>}
      </MeetingConsumer>
    </MeetingProvider>
  ) : (
    <p>loading...</p>
  );
};

export default VideoCall;