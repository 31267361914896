import React, {useState, useEffect} from "react";
import Divider from "src/components/shared/divider";
import FormGroup from "src/components/shared/formGroup";
import RadioInput from "src/components/shared/inputs/radioInput";
import MultiSelect from "src/components/shared/inputs/multiSelect";
import NumberInput from "src/components/shared/inputs/numberInput";
import { useOvermind } from "src/overmind";
import "../steps.scss";
import BackNextBar from "src/components/shared/backNextBar";
import { validateTextInput } from "src/utils/fieldValidation";

const unitDetailFields = [
    'units_furnishing', 'units_appliances', 'units_furnitures',
    'units_utilities', 'units_flooring', 'units_amenities', 
    'units_heating_system', 'units_air_conditioning', 'units_general', 
    'units_ceiling_min','units_ceiling_max', 'kitchen_backsplash', 
    'kitchen_cabinet', 'kitchen_sink_basin', 'kitchen_sink_faucet', 'kitchen_countertops', 
    'kitchen_storage', 'kitchen_inclusions', 'kitchen_countertop_thickness',
    'bathroom_shower_shape', 'bathroom_shower_materials', 'bathroom_shower_door', 
    'bathroom_shower_head', 'bathroom_bathtub', 'bathroom_countertops',
    'bathroom_sink_basin','bathroom_sink_shape', 'bathroom_sink_materials', 
    'bathroom_cabinet', 'bathroom_vanity_materials', 'bathroom_backsplash',
    'bathroom_toilet_type', 'bathroom_toilet_style', 'bathroom_toilet_bidet',
]

const UnitDetails = () => {

    const {state, actions, effects} = useOvermind();
    const [stepFields, setStepFields] = useState({
        units_furnishing: {type: 'text', error: true},
    });

    const validateStep = (isFirstLoad) => {
        for (const field in stepFields) {
            if(stepFields[field]?.type === 'text') {
                let newError = validateTextInput(state.productForm.newProduct.fields[field].value, {isMandatory: true});
                if(typeof newError === 'string' && isFirstLoad) {
                    newError = true;
                }
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [field]: {
                            ...prev[field], 
                            error: newError
                        }
                    }
                });
            }
        }
    }

    const validateField = (fieldName) => {
        if(stepFields[fieldName]?.type === 'text') {
            let newError = validateTextInput(state.productForm.newProduct.fields[fieldName].value, {isMandatory: true});
            setStepFields((prev) => {
                return {
                    ...prev, 
                    [fieldName]: {
                        ...prev[fieldName], 
                        error: newError
                    }
                }
            });
        }
    }

    const calculateCompletedFields = () => {
        return Object.values(stepFields).filter((field) => field.error === false).length;
    }

    useEffect(() => {
        validateStep(state.productForm.newProduct.fields.form_steps_data.value.array[3] === 0);
    }, []);

    useEffect(() => {
        actions.productForm.setStepCompletedFields({step: 4, completedFields: calculateCompletedFields()});
    }, [stepFields]);

    const handleMultiSelect = (newValue, dataObj) => {
        actions.productForm.pushToFieldArray(
            {
                fieldId: dataObj.fieldId,
                value: newValue
            }
        )
    }

    const handleMultiDeselect = (index, dataObj) => {
        actions.productForm.removeFromFieldArray(
            {
                fieldId: dataObj.fieldId,
                index: index
            }
        )
    }

    const handleNumberInputChange = (e, dataObj) => {
        if(e) {
            e.currentTarget.value = parseInt(e.currentTarget.value, 10);
            actions.productForm.setProductField(
                {
                    fieldId: dataObj.fieldId,
                    value: e.currentTarget.value,
                }
            );
        } else {
            actions.productForm.setProductField(
                {
                    fieldId: dataObj.fieldId,
                    value: dataObj.value,
                }
            );
        }
    }

    const handleRadioInputChange = (e, dataObj) => {
        actions.productForm.setProductField(
            {
                fieldId: dataObj.fieldId,
                value: e.currentTarget.getAttribute('data-value'),
            }
        )
    }

    const handleNextClick = () => {
        validateField(false);
        actions.productForm.updateProductFields(unitDetailFields).then(() => { 
            actions.productForm.incrementFormStep();
        })
    }

    const handleBackClick = () => {
        actions.productForm.updateProductFields(unitDetailFields).then(() => { 
            actions.productForm.decrementFormStep();
        })
    }

    return(
        <div className="steps__container">
            <div className="steps__title-desc-container">
                <div className="steps__title">Unit details</div>
                <div className="steps__desc">Select all unit inclusions that apply to your project.</div>
            </div>
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup
                title="Appliances & Furniture"
                fields={[
                    <RadioInput 
                        label="Furnished Type"
                        validateField={validateField}
                        error={stepFields.units_furnishing.error}
                        value={state.productForm.newProduct.fields.units_furnishing?.value}
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'units_furnishing'}} 
                        auditorComment={state.productForm.newProduct.fields.units_furnishing?.comment} 
                        status={state.productForm.newProduct.fields.units_furnishing?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['not_furnished', 'Not furnished'],
                            ['semi_furnished', 'Semi-furnished'],
                            ['fully_furnished', 'Fully furnished'],
                        ]}
                    />,
                    ... ['semi_furnished', 'fully_furnished'].includes(state.productForm.newProduct.fields.units_furnishing?.value) ?
                    [
                        <MultiSelect 
                            label="Appliances included" 
                            placeholder="Select included appliances"
                            value={state.productForm.newProduct.fields.units_appliances?.value?.array} 
                            handleMultiSelect={handleMultiSelect} 
                            handleMultiDeselect={handleMultiDeselect} 
                            dataObj={{fieldId: 'units_appliances'}} 
                            auditorComment={state.productForm.newProduct.fields.units_appliances?.comment} 
                            status={state.productForm.newProduct.fields.units_appliances?.status}
                            translationsNamespace='overview'
                            options={{hasAlphabet: true, translationKey: 'unitsAppliances', maxWidth: '400px'}}
                            selectOptions={[
                                'refrigerator',
                                'stoveOven',
                                'kitchenFan',
                                'dishwasher',
                                'clothesWasher',
                                'clothesDryer',
                                'microwave'
                            ]}
                        />
                    ]
                    :
                    [],
                    ... state.productForm.newProduct.fields.units_furnishing?.value === 'fully_furnished' ? 
                    [
                        <MultiSelect 
                            label="Furnitures included"
                            placeholder="Select included furnitures"
                            value={state.productForm.newProduct.fields.units_furnitures?.value?.array} 
                            handleMultiSelect={handleMultiSelect} 
                            handleMultiDeselect={handleMultiDeselect} 
                            dataObj={{fieldId: 'units_furnitures'}} 
                            auditorComment={state.productForm.newProduct.fields.units_furnitures?.comment}
                            translationsNamespace={'overview'} 
                            status={state.productForm.newProduct.fields.units_furnitures?.status}
                            options={{hasAlphabet: true, isVso: true, isModal: true, translationKey: 'unitsFurnitures', maxWidth: '400px'}}
                            selectOptions={[
                                {
                                    name: 'Seating',
                                    options:[
                                        'chair',
                                        'liftChair',
                                        'beanBag',
                                        'longChair',
                                        'ottoman',
                                        'recliner',
                                        'stool',
                                        'barStool',
                                        'footstool',
                                        'tuffet',
                                        'faintingCouch',
                                        'rockingChair',
                                        'barChair',
                                        'poufy',
                                        'armChair',
                                        'bench',
                                        'couch',
                                        'accubita',
                                        'canapé',
                                        'davenport',
                                        'klinai',
                                        'divan',
                                        'loveSeat',
                                        'chesterfield' 
                                    ]
                                },
                                {
                                    name: 'Sleep',
                                    options: [
                                        'bed',
                                        'bunkBed',
                                        'canopyBed',
                                        'fourPosterBed',
                                        'murphyBed',
                                        'platformBed',
                                        'sleighBed',
                                        'waterbed',
                                        'daybed',
                                        'futon',
                                        'hammock',
                                        'headboard',
                                        'infantBed',
                                        'sofaBed',
                                        'toddlerBed'
                                    ]
                                },
                                {
                                    name: 'Entertainments',
                                    options: [
                                        'billiardTable',
                                        'chessTable',
                                        'entertainmentCenter',
                                        'gramophone',
                                        'hifi',
                                        'jukebox',
                                        'pinballMachine',
                                        'radiogram',
                                        'homeBar',
                                        'radioReceiver',
                                        'piano'
                                    ]
                                },
                                {
                                    name: 'Tables',
                                    options: [
                                        'countertop',
                                        'chabudai',
                                        'changingTable',
                                        'desk',
                                        'davenportDesk',
                                        'drawingBoard',
                                        'computerDesk',
                                        'writingDesk',
                                        'kotatsu',
                                        'korsi',
                                        'lowboy',
                                        'monksBench',
                                        'pedestal',
                                        'table',
                                        'gameTable',
                                        'coffeeTable',
                                        'diningTable',
                                        'refectoryTable',
                                        'dropleafTable',
                                        'endTable',
                                        'foldingTable',
                                        'gatelegTable',
                                        'pokerTable',
                                        'trestleTable',
                                        'wineTable',
                                        'washstand',
                                        'workbench'
                                    ]
                                },
                                {
                                    name: 'Storage',
                                    options: [
                                        'bakersRack',
                                        'bookcase',
                                        'cabinetry',
                                        'bathroomCabinet',
                                        'chifforobe',
                                        'closet',
                                        'cupboard',
                                        'curioCabinet',
                                        'gunCabinet',
                                        'hutch',
                                        'hoosierCabinet',
                                        'kitchenCabinet',
                                        'liquorCabinet',
                                        'pantry',
                                        'pieSafe',
                                        'stipoABambocci',
                                        'chestOfDrawersOrDresser',
                                        'chest',
                                        'cellarette',
                                        'hopeChest',
                                        'coatRack',
                                        'drawer',
                                        'hallTree',
                                        'hatstand',
                                        'barCabinet',
                                        'filingCabinet',
                                        'floatingShelf',
                                        'nightstand',
                                        'ottoman',
                                        'planChest',
                                        'plantStand',
                                        'shelving',
                                        'sideboardOrBuffet',
                                        'umbrellaStand',
                                        'wardrobeOrArmoire',
                                        'wineRack'
                                    ]
                                },
                                {
                                    name: 'Small Appliances',
                                    options: [
                                        'toasterOven',
                                        'toaster',
                                        'coffeeMaker',
                                        'vacuum',
                                        'blender',
                                        'foodProcessor',
                                        'instantPot',
                                        'riceCooker',
                                        'slowCooker',
                                        'standMixer',
                                        'juicer',
                                        'waffleIron',
                                        'breadMachine',
                                        'iceCreamMaker',
                                        'yogurtMaker',
                                        'coffeeGrinder',
                                        'popcornMachine',
                                        'paniniMaker',
                                        'deepFryer',
                                        'foodDehydrator',
                                        'garbageDisposal',
                                        'waterPurifier',
                                        'smallAppliances',
                                        'sewingMachine',
                                        'iron',
                                        'steamCleaner',
                                        'airConditioner',
                                        'waterHeater',
                                        'waterAppliancesForYourHome',
                                        'humidifier',
                                        'dehumidifier',
                                        'airPurifier',
                                        'spaceHeater',
                                        'ceilingFan',
                                        'smoker',
                                        'generator',
                                        'smartHomeTech',
                                        'domesticRobot'
                                    ]
                                },
                            ]}
                        />
                    ]
                    :
                    [],
                ]} 
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="Unit Inclusions"
                fields={[
                    <MultiSelect 
                        label="Utilities included"
                        placeholder="Select utilities" 
                        value={state.productForm.newProduct.fields.units_utilities?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'units_utilities'}} 
                        auditorComment={state.productForm.newProduct.fields.units_utilities?.comment}
                        status={state.productForm.newProduct.fields.units_utilities?.status}
                        translationsNamespace='overview' 
                        options={{hasAlphabet: true, translationKey: 'unitsUtilities', maxWidth: '400px'}}
                        selectOptions={[
                            'cable',
                            'heating',
                            'hydroElectricity',
                            'internet',
                            'hotWater'
                        ]}
                    />,
                    <MultiSelect 
                        label="Flooring"
                        placeholder="Select flooring" 
                        value={state.productForm.newProduct.fields.units_flooring?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'units_flooring'}} 
                        auditorComment={state.productForm.newProduct.fields.units_flooring?.comment} 
                        status={state.productForm.newProduct.fields.units_flooring?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'flooring', maxWidth: '400px'}}
                        selectOptions={[
                            'bamboo',
                            'carpeting',
                            'ceramic',
                            'concrete',
                            'customizableByClient',
                            'cork',
                            'hardwood',
                            'heatedFloor',
                            'laminate',
                            'manufacturedWood',
                            'marble',
                            'stone',
                            'tile',
                            'vinylLinoleum',
                            'waterproofVinylPlanks',
                            'wood'
                        ]}
                    />,
                    <MultiSelect 
                        label="Heating system" 
                        placeholder="Select heating system type" 
                        value={state.productForm.newProduct.fields.units_heating_system?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'units_heating_system'}} 
                        auditorComment={state.productForm.newProduct.fields.units_heating_system?.comment} 
                        status={state.productForm.newProduct.fields.units_heating_system?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'heatingSystem', maxWidth: '400px'}}
                        selectOptions={[
                            'electricHeating',
                            'floorHeating',
                            'gasHeating',
                            'geothermalSystem',
                            'heatPump',
                            'oilOrFuelHeating',
                            'solarHeating',
                            'woodHeating'
                        ]}
                    />,
                    <MultiSelect 
                        label="Air conditioning"
                        placeholder="Select air conditioning type" 
                        value={state.productForm.newProduct.fields.units_air_conditioning?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'units_air_conditioning'}} 
                        auditorComment={state.productForm.newProduct.fields.units_air_conditioning?.comment} 
                        status={state.productForm.newProduct.fields.units_air_conditioning?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'airConditioning', maxWidth: '400px'}}
                        selectOptions={[
                            'airConditioningConsole',
                            'central',
                            'notIncluded',
                            'wallMountedAirConditioner'
                        ]}
                    />,
                    <NumberInput
                        label="Ceiling height"
                        placeholder="Height"
                        options={{minVal: 0, suffix: 'ft', maxWidth: '104px'}} 
                        value={state.productForm.newProduct.fields.units_ceiling_min?.value} 
                        setValue={handleNumberInputChange} 
                        dataObj={{fieldId: 'units_ceiling_min'}}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="Kitchen details"
                fields={[
                    <MultiSelect 
                        label="Backsplash"
                        placeholder="Select backsplash type" 
                        value={state.productForm.newProduct.fields.kitchen_backsplash?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'kitchen_backsplash'}} 
                        auditorComment={state.productForm.newProduct.fields.kitchen_backsplash?.comment} 
                        status={state.productForm.newProduct.fields.kitchen_backsplash?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'kitchenBacksplash', maxWidth: '400px'}}
                        selectOptions={[
                            'ceramicTile',
                            'chalkboardPaint',
                            'glassTile',
                            'peelAndSteelTile',
                            'porcelainTile',
                            'stainlessSteel',
                            'stoneTile',
                            'thermoplastic',
                            'wallpaper',
                            'wood'
                        ]}
                    />,
                    <MultiSelect 
                        label="Cabinet materials"
                        placeholder="Select cabinet materials" 
                        value={state.productForm.newProduct.fields.kitchen_cabinet?.value?.array} 
                        handleMultiSelect={handleMultiSelect} handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'kitchen_cabinet'}} 
                        auditorComment={state.productForm.newProduct.fields.kitchen_cabinet?.comment} 
                        status={state.productForm.newProduct.fields.kitchen_cabinet?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'kitchenCabinetMaterials', maxWidth: '400px'}}
                        selectOptions={[
                            'glassFront',
                            'hardwood',
                            'laminate',
                            'mdf',
                            'melamine',
                            'metal',
                            'particleboard',
                            'plywood',
                            'thermofoil'
                        ]}
                    />,
                    <MultiSelect 
                        label="Sink"
                        placeholder="Select sink type" 
                        value={[state.productForm.newProduct.fields.kitchen_sink_basin?.value?.array,
                            state.productForm.newProduct.fields.kitchen_sink_faucet?.value?.array]} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: ['kitchen_sink_basin', 'kitchen_sink_faucet']}} 
                        status={[state.productForm.newProduct.fields.kitchen_sink_basin?.status,
                            state.productForm.newProduct.fields.kitchen_sink_faucet?.status]}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, isVso: true, isModal: true, hasManyContents: true, translationKey: 'kitchenSink', maxWidth: '400px'}}
                        selectOptions={[
                            {
                                name: 'Sink basin',
                                options:[
                                    'double',
                                    'drainboard',
                                    'farmhouse',
                                    'integrated',
                                    'lowDividerDouble',
                                    'prepSink',
                                    'single',
                                    'topMount',
                                    'undermount',
                                    'includesGarbageDisposal'
                                ]
                            },
                            {
                                name: 'Kitchen Faucets',
                                options: [
                                    'pullDownFaucet',
                                    'pullOutFaucet',
                                    'singleHandleFaucet',
                                    'dualHandleFaucet',
                                    'commercialStyleFaucet',
                                    'seperateSprayFaucet',
                                    'potFillerFaucet',
                                    'motionDetectionFaucet'
                                ]
                            },
                        ]}
                    />,
                    <MultiSelect 
                        label="Countertops"
                        placeholder="Select countertops materials" 
                        value={state.productForm.newProduct.fields.kitchen_countertops?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'kitchen_countertops'}} 
                        auditorComment={state.productForm.newProduct.fields.kitchen_countertops?.comment} 
                        status={state.productForm.newProduct.fields.kitchen_countertops?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'kitchenCounterTops', maxWidth: '400px'}}
                        selectOptions={[
                            'butcherblock',
                            'concrete',
                            'laminate',
                            'lava',
                            'marble',
                            'melamine',
                            'naturalStone',
                            'porcelain',
                            'quarts',
                            'reclaimedWood',
                            'recycledGlass',
                            'resin',
                            'solidSurface',
                            'stainlessSteel',
                            'tile',
                            'wood'
                        ]}
                    />,
                    <NumberInput 
                        label="Kitchen countertop thickness"
                        placeholder="Thickness"
                        value={state.productForm.newProduct.fields.kitchen_countertop_thickness?.value} 
                        setValue={handleNumberInputChange} 
                        dataObj={{fieldId: 'kitchen_countertop_thickness'}}
                        options={{minVal: 0, suffix: 'inch', maxWidth: '104px'}} 
                    />,
                    <MultiSelect 
                        label="Storage" 
                        placeholder="Select included storage"
                        value={state.productForm.newProduct.fields.kitchen_storage?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'kitchen_storage'}} 
                        auditorComment={state.productForm.newProduct.fields.kitchen_storage?.comment} 
                        status={state.productForm.newProduct.fields.kitchen_storage?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, isVso: true, translationKey: 'kitchenStorage', maxWidth: '400px'}}
                        selectOptions={[
                            'abudantStorage',
                            'applianceGarage',
                            'cabinetStretchToCeiling',
                            'cookbookStorage',
                            'pantry',
                            'pullOutPantry',
                            'slidingPullOutDrawersAndShelves',
                            'spicesStorage',
                            'walkInPantry'
                        ]}
                    />,
                    <MultiSelect 
                        label="Other kitchen inclusions"
                        placeholder="Select other kitchen inclusions" 
                        value={state.productForm.newProduct.fields.kitchen_inclusions?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'kitchen_inclusions'}} 
                        auditorComment={state.productForm.newProduct.fields.kitchen_inclusions?.comment} 
                        status={state.productForm.newProduct.fields.kitchen_inclusions?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'kitchenInclusions', maxWidth: '400px'}}
                        selectOptions={[
                            'builtInAppliances',
                            'builtInRefrigerator',
                            'cabinetLighting',
                            'cellar',
                            'coffeeBar',
                            'compostBin',
                            'dishwasher',
                            'filteredWater',
                            'handsFreeFaucet',
                            'heatedKitchenFloor',
                            'kitchenLadder',
                            'largeWorkSurfaces',
                            'lunchCounter',
                            'potFiller',
                            'recyclingBin',
                            'rollOutSpiceRacks',
                            'trashBin',
                            'trashCompactor',
                            'twoDishwasher',
                            'twoHoven',
                            'warmingDrawer',
                            'wasteAndRecyclingCenter',
                            'wineChiller',
                            'wineRefrigerator'
                        ]}
                    />,
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px" />
            <FormGroup 
                title="Bathroom details"
                fields={[
                    <MultiSelect 
                        label="Shower"
                        placeholder="Select shower type" 
                        value={[state.productForm.newProduct.fields.bathroom_shower_shape?.value?.array, 
                            state.productForm.newProduct.fields.bathroom_shower_materials?.value?.array, 
                            state.productForm.newProduct.fields.bathroom_shower_door?.value?.array, 
                            state.productForm.newProduct.fields.bathroom_shower_head?.value?.array]} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: ['bathroom_shower_shape', 'bathroom_shower_materials', 'bathroom_shower_door', 'bathroom_shower_head']}}  
                        status={[state.productForm.newProduct.fields.bathroom_shower_shape?.status, 
                            state.productForm.newProduct.fields.bathroom_shower_materials?.status, 
                            state.productForm.newProduct.fields.bathroom_shower_door?.status, 
                            state.productForm.newProduct.fields.bathroom_shower_head?.status]}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, isVso: true, isModal: true, hasManyContents: true, translationKey: 'bathroomShower', maxWidth: '400px'}}
                        selectOptions={[
                            {
                                name: 'Shower shape and style',
                                options:[
                                    'cornerEnclosureShower',
                                    'curvedEnclosureShower',
                                    'neoAngleEnclosureShower',
                                    'quadrantEnclosureShower'
                                ]
                            },
                            {
                                name: 'Shower materials',
                                options: [
                                    'ceramic',
                                    'fibreglassAndAcrylic',
                                    'glass',
                                    'stone',
                                    'tile'
                                ]
                            },
                            {
                                name: 'Shower doors',
                                options: [
                                    'hingedDoor',
                                    'pivotDoor',
                                    'slidingDoor',
                                    'glassDoor'
                                ]
                            },
                            {
                                name: 'Shower head type',
                                options: [
                                    'ceilingShowerHead',
                                    'dualShowerHead',
                                    'filteredShowerHead',
                                    'handheldShowerHead',
                                    'highPressureShowerHead',
                                    'ledShowerHead',
                                    'lowPressureShowerHead',
                                    'multiSettingShowerHead',
                                    'rainfallRainShowerHead',
                                    'rvShowerHead',
                                    'showerPanel',
                                    'showerTower',
                                    'singleSettingShowerHead',
                                    'wallMountedShowerHead'
                                ]
                            },
                        ]}
                    />,
                    <MultiSelect 
                        label="Bathtub"
                        placeholder="Select bathtub type" 
                        value={state.productForm.newProduct.fields.bathroom_bathtub?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'bathroom_bathtub'}} 
                        auditorComment={state.productForm.newProduct.fields.bathroom_bathtub?.comment} 
                        status={state.productForm.newProduct.fields.bathroom_bathtub?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'bathroomBathtub', maxWidth: '400px'}}
                        selectOptions={[
                            'noBathtub',
                            'tubShowerCombo',
                            'soupBowlFreestandingTub',
                            'alcoveTub',
                            'clawfootAndPedestalBath',
                            'cornerBath',
                            'dropInBath',
                            'freeStandingBath',
                            'recessedOrAlcoveBath',
                            'walkInOrGatedBath',
                            'whirlpoolBathOrJacuzzi'
                        ]}
                    />,
                    <MultiSelect 
                        label="Countertops"
                        placeholder="Select countertop materials" 
                        value={state.productForm.newProduct.fields.bathroom_countertops?.value?.array} 
                        handleMultiSelect={handleMultiSelect} handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'bathroom_countertops'}} 
                        auditorComment={state.productForm.newProduct.fields.bathroom_countertops?.comment} 
                        status={state.productForm.newProduct.fields.bathroom_countertops?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, translationKey: 'bathroomCounterTops', maxWidth: '400px'}}
                        selectOptions={[
                            'concrete',
                            'granite',
                            'laminate',
                            'lava',
                            'marble',
                            'naturalStone',
                            'porcelain',
                            'quartz',
                            'reclaimedWood',
                            'recycledGlass',
                            'resin',
                            'solidSurface',
                            'stainlessSteel',
                            'tile'
                        ]}
                    />,
                    <MultiSelect 
                        label="Sink"
                        placeholder="Select sick type" 
                        value={[state.productForm.newProduct.fields.bathroom_sink_basin?.value?.array, 
                                state.productForm.newProduct.fields.bathroom_sink_shape?.value?.array, 
                                state.productForm.newProduct.fields.bathroom_sink_materials?.value?.array]} 
                        handleMultiSelect={handleMultiSelect} handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: ['bathroom_sink_basin', 'bathroom_sink_shape', 'bathroom_sink_materials']}} 
                        status={[state.productForm.newProduct.fields.bathroom_sink_basin?.status, 
                            state.productForm.newProduct.fields.bathroom_sink_shape?.status, 
                            state.productForm.newProduct.fields.bathroom_sink_materials?.status,]}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, isVso: true, isModal: true, hasManyContents: true, translationKey: 'bathroomSink', maxWidth: '400px'}}
                        selectOptions={[
                            {
                                name: 'Sink basin',
                                options:[
                                    'console',
                                    'corner',
                                    'dropIn',
                                    'pedestal',
                                    'through',
                                    'underMount',
                                    'vessel',
                                    'wallMounted'
                                ]
                            },
                            {
                                name: 'Sink shapes',
                                options: [
                                    'round',
                                    'oval',
                                    'rectangular'
                                ]
                            },
                            {
                                name: 'Sink materials',
                                options: [
                                    'ceramic',
                                    'glass',
                                    'resinWashbasin',
                                    'stainlessSteel',
                                    'wood'
                                ]
                            },
                        ]}
                    />,
                    <MultiSelect 
                        label="Cabinet materials"
                        placeholder="Select cabinet materials" 
                        value={state.productForm.newProduct.fields.bathroom_cabinet?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'bathroom_cabinet'}} 
                        auditorComment={state.productForm.newProduct.fields.bathroom_cabinet?.comment} 
                        status={state.productForm.newProduct.fields.bathroom_cabinet?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, isVso: true, translationKey: 'bathroomCabinetMaterials', maxWidth: '400px'}}
                        selectOptions={[
                            'glassFront',
                            'hardwood',
                            'plywood',
                            'particleboard',
                            'mdf',
                            'metal',
                            'laminate',
                            'melamine',
                            'termofoil'
                        ]}
                    />,
                    <MultiSelect 
                        label="Toilet & bidet"
                        placeholder="Select toilet and bidet type" 
                        value={[state.productForm.newProduct.fields.bathroom_toilet_type?.value?.array, 
                            state.productForm.newProduct.fields.bathroom_toilet_style?.value?.array, 
                            state.productForm.newProduct.fields.bathroom_toilet_bidet?.value?.array]} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: ['bathroom_toilet_type', 'bathroom_toilet_style', 'bathroom_toilet_bidet']}} 
                        status={[state.productForm.newProduct.fields.bathroom_toilet_type?.status, 
                            state.productForm.newProduct.fields.bathroom_toilet_style?.status, 
                            state.productForm.newProduct.fields.bathroom_toilet_bidet?.status]}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, isVso: true, isModal: true, hasManyContents: true, translationKey: 'bathroomToilet', maxWidth: '400px'}}
                        selectOptions={[
                            {
                                name: 'Toilet types',
                                options:[
                                    'composting',
                                    'doubleCycloneFlush',
                                    'dualFlushValve',
                                    'flushometerValve',
                                    'gravityFlush',
                                    'pressureAssisted',
                                    'singleFlush',
                                    'touchlessFlushing'
                                ]
                            },
                            {
                                name: 'Toilet style',
                                options: [
                                    'backToWall',
                                    'highLevel',
                                    'lowLevel',
                                    'onePiece',
                                    'twoPiece',
                                    'wallHung',
                                ]
                            },
                            {
                                name: 'Bidet',
                                options: [
                                    'ceramicStandAloneBidet',
                                    'noBidet',
                                    'sprayOrHandheldBidet',
                                    'toiletSeatOrBuiltInBidet',
                                    'electricBidetSeat'
                                ]
                            },
                        ]}
                    />,
                    <MultiSelect 
                        label="Vanity materials"
                        placeholder="Select vanity materials" 
                        value={state.productForm.newProduct.fields.bathroom_vanity_materials?.value?.array} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'bathroom_vanity_materials'}} 
                        auditorComment={state.productForm.newProduct.fields.bathroom_vanity_materials?.comment} 
                        status={state.productForm.newProduct.fields.bathroom_vanity_materials?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, isVso: true, translationKey: 'vanityMaterials', maxWidth: '400px'}}
                        selectOptions={[
                            'glassFront',
                            'hardwood',
                            'plywood',
                            'particleboard',
                            'mdf',
                            'metal',
                            'laminate',
                            'melamine',
                            'thermofoil'
                        ]}
                    />,
                    <MultiSelect 
                        label="Backsplash"
                        placeholder="Select backsplash type" 
                        value={state.productForm.newProduct.fields.bathroom_backsplash?.value?.array} 
                        handleMultiSelect={handleMultiSelect} handleMultiDeselect={handleMultiDeselect} 
                        dataObj={{fieldId: 'bathroom_backsplash'}} 
                        auditorComment={state.productForm.newProduct.fields.bathroom_backsplash?.comment} 
                        status={state.productForm.newProduct.fields.bathroom_backsplash?.status}
                        translationsNamespace='overview'
                        options={{hasAlphabet: true, isVso: true, translationKey: 'bathroomBacksplash', maxWidth: '400px'}}
                        selectOptions={[
                            'ceramicTile',
                            'glassTile',
                            'marble',
                            'porcelainTile',
                            'peelAndStickTile',
                            'stainlessSteel',
                            'thermoplastic',
                            'wallpaper',
                            'wood'
                        ]}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px" />
            <BackNextBar onBackClick={handleBackClick} onNextClick={handleNextClick}/>
        </div>
    )
}

export default UnitDetails;