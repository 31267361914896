import React, { useState, useEffect, useRef} from 'react';
import { Line, Text, Layer } from 'react-konva';
import { flatten, findCenterOfPolygon } from 'src/utils/canvas';
import { Html } from 'react-konva-utils';
import Tooltip from '../../tooltip';

const FloorPoly = ({
    floorData, 
    index, 
    editMode, 
    scale, 
    isActive, 
    setActiveFloor, 
    isHovered,
    windowMousePos, 
    units, 
    onMouseBuildingEnter, 
    onMouseBuildingLeave,
    activeFloor
}) => {
    const [centerCoords, setCenterCoords] = useState([0, 0]);
    const [fillColor, setFillColor] = useState('');
    const polyRef = useRef(null);

    const animateFloors = (index) => {
        setTimeout(() => {
            setFillColor('#3D53F54D');
            polyRef.current?.to({
                opacity: 1,
                onFinish: () => {
                    polyRef.current?.to({
                        opacity: 0,
                        onFinish: () => {
                            polyRef.current?.to({
                                opacity: 1
                            });
                            setFillColor('');
                            if((index + 1) == activeFloor) {
                                setFillColor('#3D53F58D');
                            }
                        }
                    })
                }
            });
        }, index * 300);
    }

    useEffect(() => {
        setCenterCoords(findCenterOfPolygon(floorData.points));
    }, []);

    useEffect(() => {
        if(editMode) {
            setFillColor('#18A95799');
            return;
        } else {
            if(isActive) {
                setFillColor('#3D53F58D');
                return; 
            }
            if(isHovered) {
                setFillColor('#3D53F54D');
                return;
            }
        }
        setFillColor('');
    }, [editMode, isActive, isHovered]);

    useEffect(() => {
        if(!editMode) {
            animateFloors(index);
        }
    }, []);

    const getNumberOfAvailableUnits = (index) => {
        let floor = index + 1;
        if(!Array.isArray(units)) return [];
        let availableUnits = units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == floor : unit.unit_number.toString().slice(0, 2) == floor)
        .sort((unitA, unitB) => {
            if(unitA.unit_number > unitB.unit_number) {
                return 1;
            } else if(unitA.unit_number < unitB.unit_number) {
                return -1;
            }
            return 0;
        }).filter(unit => unit.status == 'available').length;
        return availableUnits;
    }

    return(
        <Layer>
            <Line
                ref={polyRef}
                closed={true}
                stroke={isActive ? '#fff' : ''}
                strokeWidth={2 / scale}
                name="Poly"
                fill={fillColor}
                points={flatten(floorData.points)}
                onMouseEnter={() => !editMode && onMouseBuildingEnter(index)}
                onMouseLeave={() => !editMode && onMouseBuildingLeave(index)}
                onClick={() => !editMode && setActiveFloor(index + 1)}
            />
            {!editMode && isHovered ?
                <Html>
                    <Tooltip 
                        text={
                            <div className="drawingCanvas__tooltip-container">
                                <div className="drawingCanvas__tooltip-title">
                                    {floorData?.name}
                                </div>
                                <div className="drawingCanvas__tooltip-info">
                                    Units available: {getNumberOfAvailableUnits(index)}
                                </div>
                            </div>
                        } 
                        isOpen={isHovered} 
                        options={{coords: {left: windowMousePos[0], top: windowMousePos[1]}, maxWidth: 'none'}}
                    />
                </Html>
                :
                <Text
                    x={centerCoords[0]}
                    y={centerCoords[1]}
                    text={!editMode && isHovered ? floorData.name : editMode ? floorData.name : ''}
                    fontSize={64}
                    fill="#fff"
                /> 
            }
        </Layer>
    )
}

export default FloorPoly;