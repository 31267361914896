import React, { useState } from 'react';

import { useOvermind } from 'src/overmind';
import { useTranslation } from 'react-i18next';
import { roundTo } from 'round-to';

import '../reservationForm.css';
import TextInput from 'src/components/shared/inputs/textInput';
import Button from 'src/components/shared/button';
import UnitCard from '../unitCard';
import { validateTextInput } from 'src/utils/fieldValidation';

const IdentityForm = () => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation();

    const [formErrors, setFormErrors] = useState({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        address: null,
        zip: null,
        city: null,
        state: null,
        country: null
    });

    const getPrice = () => {
        let depositPrice = state.currentVSO.deposit_amount;
        if(depositPrice === '1stMonth') {
            depositPrice = state.currentVSO.reservation.currentUnit?.price;
        }
        depositPrice = roundTo(depositPrice * 1.15, 2);

        return depositPrice;
    }

    const handleTextInputChange = (e, dataObj) => {
        if(dataObj.stateKey === 'firstName') {
            actions.currentVSO.setUserFirstName(e.target.value);
        } else if(dataObj.stateKey === 'lastName') {
            actions.currentVSO.setUserLastName(e.target.value);
        } else if(dataObj.stateKey === 'email') {
            actions.currentVSO.setUserEmail(e.target.value);
        } else if(dataObj.stateKey === 'phone') {
            actions.currentVSO.setUserPhone(e.target.value);
        } else if(dataObj.stateKey === 'address') {
            actions.currentVSO.setUserAddress(e.target.value);
        } else if(dataObj.stateKey === 'city') {
            actions.currentVSO.setUserCity(e.target.value);
        } else if(dataObj.stateKey === 'zip') {
            actions.currentVSO.setUserZip(e.target.value);
        } else if(dataObj.stateKey === 'state') {
            actions.currentVSO.setUserState(e.target.value);
        } else if(dataObj.stateKey === 'country') {
            actions.currentVSO.setUserCountry(e.target.value);
        }
    }

    const validateField = (userField) => {
        const error = validateTextInput(state.currentVSO.currentUser[userField], {isMandatory: true, isEmail: userField === 'email'});
        setFormErrors((prevState) => {
            return {
                ...prevState,
                [userField]: error
            }
        });
        return error;
    }

    const validateForm = () => {
        const newErrors = {
            firstName: validateTextInput(state.currentVSO.currentUser.firstName, {isMandatory: true}),
            lastName: validateTextInput(state.currentVSO.currentUser.lastName, {isMandatory: true}),
            email: validateTextInput(state.currentVSO.currentUser.email, {isMandatory: true, isEmail: true}),
            phone: validateTextInput(state.currentVSO.currentUser.phone, {isMandatory: true}),
            address: validateTextInput(state.currentVSO.currentUser.address, {isMandatory: true}),
            zip: validateTextInput(state.currentVSO.currentUser.zip, {isMandatory: true}),
            city: validateTextInput(state.currentVSO.currentUser.city, {isMandatory: true}),
            state: validateTextInput(state.currentVSO.currentUser.state, {isMandatory: true}),
            country: validateTextInput(state.currentVSO.currentUser.country, {isMandatory: true})
        }
        
        setFormErrors(newErrors);
        
        return Object.values(newErrors).every((value) => value === false);
    }

    const handleBackClick = () => {
        actions.currentVSO.setCurrentFloor(1);
        actions.currentVSO.decrementReservationStep();
    }

    const handleNextClick = async () => {
        if(validateForm()) {
            const response = await fetch(
                process.env.REACT_APP_SERVER_URL + 'leads/',
                {
                    method: 'POST',
                    headers: {'Content-Type' : 'application/json'},
                    body: JSON.stringify({
                        firstName: state.currentVSO.currentUser.firstName,
                        lastName: state.currentVSO.currentUser.lastName,
                        email: state.currentVSO.currentUser.email,
                        phoneNumber: state.currentVSO.currentUser.phone
                    })
                });

            actions.currentVSO.incrementReservationStep();
        }
    }

    return (
        <div className="reservationForm__dual-container">
            <div className="identityForm__column">
                <div className="identityForm__container">
                    <div className="identityForm__title">{t('salesOffice.reservationForm.personalDetails')}</div>
                    <div className="identityForm__row-half">
                        <TextInput 
                            label={t('salesOffice.loginForm.firstName')} value={state.currentVSO.currentUser.firstName} 
                            setValue={handleTextInputChange} placeholder={t('salesOffice.loginForm.firstName')}
                            dataObj={{stateKey: 'firstName'}} theme="vsoForm"
                            error={formErrors.firstName}
                            validateField={() => validateField('firstName')}
                        />
                        <TextInput 
                            label={t('salesOffice.loginForm.lastName')} value={state.currentVSO.currentUser.lastName} 
                            setValue={handleTextInputChange} placeholder={t('salesOffice.loginForm.lastName')}
                            dataObj={{stateKey: 'lastName'}} theme="vsoForm"
                            error={formErrors.lastName}
                            validateField={() => validateField('lastName')}
                        />
                    </div>
                    <TextInput 
                        label={t('salesOffice.loginForm.email')} value={state.currentVSO.currentUser.email} 
                        setValue={handleTextInputChange} placeholder={t('salesOffice.loginForm.email')}
                        dataObj={{stateKey: 'email'}} options={{isEmail: true}} theme="vsoForm"
                        error={formErrors.email}
                        validateField={() => validateField('email')}
                    />
                    <TextInput 
                        label={t('salesOffice.reservationForm.phone')} value={state.currentVSO.currentUser.phone} 
                        setValue={handleTextInputChange} placeholder={t('salesOffice.reservationForm.phone')}
                        dataObj={{stateKey: 'phone'}} options={{isPhone: true}} theme="vsoForm"
                        error={formErrors.phone}
                        validateField={() => validateField('phone')}
                    />
                </div>
                <div className="identityForm__container">
                    <div className="identityForm__title">{t('salesOffice.reservationForm.billingAddress')}</div>
                    <TextInput 
                        label={t('salesOffice.reservationForm.address')} value={state.currentVSO.currentUser.address} 
                        setValue={handleTextInputChange} placeholder={t('salesOffice.reservationForm.address')}
                        dataObj={{stateKey: 'address'}} theme="vsoForm"
                        error={formErrors.address}
                        validateField={() => validateField('address')}
                    />
                    <div className="form__input-row">                        
                        <TextInput 
                            label={t('salesOffice.reservationForm.postalCode')} value={state.currentVSO.currentUser.zip} 
                            setValue={handleTextInputChange} placeholder={t('salesOffice.reservationForm.postalCode')}
                            dataObj={{stateKey: 'zip'}} theme="vsoForm"
                            error={formErrors.zip}
                            validateField={() => validateField('zip')}
                        />
                        <TextInput 
                            label={t('salesOffice.reservationForm.city')} value={state.currentVSO.currentUser.city} 
                            setValue={handleTextInputChange} placeholder={t('salesOffice.reservationForm.city')}
                            dataObj={{stateKey: 'city'}} theme="vsoForm"
                            error={formErrors.city}
                            validateField={() => validateField('city')}
                        />
                    </div>
                    <div className="form__input-row">
                        <TextInput 
                            label={t('salesOffice.reservationForm.state')} value={state.currentVSO.currentUser.state} 
                            setValue={handleTextInputChange} placeholder={t('salesOffice.reservationForm.state')}
                            dataObj={{stateKey: 'state'}} theme="vsoForm"
                            error={formErrors.state}
                            validateField={() => validateField('state')}
                        />
                        <TextInput 
                            label={t('salesOffice.reservationForm.country')} value={state.currentVSO.currentUser.country} 
                            setValue={handleTextInputChange} placeholder={t('salesOffice.reservationForm.country')}
                            dataObj={{stateKey: 'country'}} theme="vsoForm"
                            error={formErrors.country}
                            validateField={() => validateField('country')}
                        />
                    </div>
                </div>
                <div className="reservationForm__button-container">
                    <Button options={{type: "secondary"}} text="Back" onClick={handleBackClick}/>
                    <Button options={{type: "primary"}} text="Next" onClick={handleNextClick}/>
                </div>
            </div>
            <UnitCard plan={state.currentVSO.reservation.currentUnit?.plan}
                unitNumber={state.currentVSO.reservation.currentUnit?.unit_number}
                area={state.currentVSO.reservation.currentUnit?.sqft}
                bedroom={state.currentVSO.reservation.currentUnit?.bedroom}
                bathroom={state.currentVSO.reservation.currentUnit?.bathroom}
                projectType={state.currentVSO.fields.project_type}
                projectName={state.currentVSO.fields.project_name}
                projectAddress={state.currentVSO.fields.project_address}
                price={getPrice()}
                reservationTime={state.currentVSO.reservation_time}
            />
        </div>
    );
}

export default IdentityForm;