import React, {useEffect, useState, useRef} from 'react';
import "../auditorComment/AuditorComment.css";
import TriangleMessageBox from "../../../assets/images/marketTestForm/TriangleMessageBox.svg";

const AuditorComment = ({comment, style}) => {
    const [isRectOpen, setRectOpen] = useState(false);
    const [delayComment, setDelayComment] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [notification, setNotification] = useState(true);
    const auditorCommentRef = useRef(null);
    const counter = useRef(0);
    
    useEffect(() => {
        function handleResize(){
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);

        const {height, width} = auditorCommentRef.current.getBoundingClientRect();
        auditorCommentRef.current.contentHeight = height;
        auditorCommentRef.current.contentWidth = width;
        auditorCommentRef.current.maxWidth = width;

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, []);

    const resizeBox = () => {
        const {width, x} = auditorCommentRef.current.getBoundingClientRect();
        if(width !== 16 && width !== 0) {
            if((width + x) > windowWidth - 64){
                auditorCommentRef.current.maxWidth = width - ((x + width + 64) - windowWidth);
                setTimeout(() => {
                    auditorCommentRef.current.contentWidth = auditorCommentRef.current.getBoundingClientRect().width;
                    auditorCommentRef.current.contentHeight = auditorCommentRef.current.getBoundingClientRect().height;
                }, 300);
            } else {
                auditorCommentRef.current.maxWidth = 'max-content';
                setTimeout(() => {
                    auditorCommentRef.current.contentWidth = auditorCommentRef.current.getBoundingClientRect().width;
                    auditorCommentRef.current.contentHeight = auditorCommentRef.current.getBoundingClientRect().height;
                }, 300);
            }
        }
    }

    useEffect(() => {
        if(counter.current < 10) {
            counter.current++;
        } else {
            counter.current = 0;
            resizeBox();
        }
        setTimeout(() => {
            if(auditorCommentRef.current.contentWidth != auditorCommentRef.current.getBoundingClientRect().width 
            || auditorCommentRef.current.contentHeight != auditorCommentRef.current.getBoundingClientRect().height) {
                counter.current = 0;
                resizeBox();
            }
        }, 1000);
    }, [isRectOpen, windowWidth]);

    useEffect(() => {
      if(isRectOpen){
          setTimeout(() => {
              setDelayComment(true);
          }, 300)
      }
    }, [isRectOpen, delayComment])

    const closeExpand = () => {
        setRectOpen(!isRectOpen);
        setDelayComment(false);
        setNotification(false);
    }
 
    return(
        <div className="auditor-comments__container">
            {/* <div className={`auditor-comments__rectangle`} style={isRectOpen ? {height: auditorCommentRef.current.getBoundingClientRect().height, width: auditorCommentRef.current.getBoundingClientRect().width} : {}} onClick={closeExpand}> */}
            <div className={`auditor-comments__rectangle`} style={isRectOpen ? {height: auditorCommentRef.current.contentHeight, width: auditorCommentRef.current.contentWidth } : {}} onClick={closeExpand}>
                <div className={`auditor-comment__content` + (isRectOpen && delayComment ? " --shown" : "")}
                    style={isRectOpen ? {maxWidth: auditorCommentRef.current.maxWidth}: {}}     
                    ref={auditorCommentRef}>
                        <div className="auditor-comment__title">Changes needed:</div>
                        <p>{comment}</p>
                </div> 
            {notification ? <span></span> : ""}
            </div>
            <img src={TriangleMessageBox} alt="auditor-comment" />
        </div>);
};

export default AuditorComment;