import React, { useEffect } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

const DrawingImage = ({url, setImageSize}) => {
    const [image] = useImage(url);

    useEffect(() => {
        if(image) { 
            setImageSize({width: image.naturalWidth, height: image.naturalHeight});
        }
    }, [image]);

    return(
        <Image width={image?.naturalWidth} height={image?.naturalHeight} image={image} />
    )
}

export default DrawingImage;