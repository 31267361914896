import React, { useState, useEffect, useRef } from "react";
import Button from "src/components/shared/button";
import SvgIcons from "src/components/shared/svgIcons";
import { useTranslation } from "react-i18next";
import "./planDetailViewer.scss";

const PlanDetailViewer = ({detailViewerHeight, item, type, moveDate, reservationClick}) => {
    const { t } = useTranslation('planView');
    const imageRef = useRef(null);
    const [imageStyle, setImageStyle] = useState({});
    const [containerStyle, setContainerStyle] = useState({});
    const [imageContainerStyle, setImageContainerStyle] = useState({});

    useEffect(() => {
            if(imageRef?.current.naturalWidth > imageRef?.current.naturalHeight) {
                setContainerStyle({
                    width: '95vw',
                });
                setImageContainerStyle({
                    alignItems: 'center',
                    padding: '24px 32px',
                    justifyContent: 'center' 
                });
                setImageStyle({
                    objectFit: 'contain',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    aspectRatio: `${imageRef?.current.naturalWidth} / ${imageRef?.current.naturalHeight}` 
                });
            } else {
                setImageStyle({
                    marginTop: '-64px',
                    width: 'auto',
                    height: 'calc(100% + 64px)',
                });
            }

    }, [imageRef.current?.naturalWidth, 
        imageRef.current?.naturalHeight]);

    return(
        <div className="planDetailViewer__container" style={{...containerStyle, height: detailViewerHeight}}>
            <div className="planDetailViewer__detail-container">
                <div className="planDetailViewer__unit-info">
                    <div className="planDetailViewer__unit-sqft-bed-bath-container">
                        <div className="planDetailViewer__unit-sqft">{item.sqft} {t('planDetailViewer.sqft')}</div>
                        <div className="planDetailViewer__unit-bed-bath">{t(`planDetailViewer.${item.bedroom}`)} - {t(`planDetailViewer.${item.bathroom}`)}</div>
                    </div>
                    <div className="planDetailViewer__unit-price">${item.price} {type === "rent" && <div> / mo</div>}</div>
                    <Button type="primary" 
                        text={`${t('planDetailViewer.reserveUnit')} ${item.unit_number}`} 
                        suffix={<SvgIcons type="cabana_circle_right_arrow_icon"/>} 
                        suffixGap="10px" 
                        onClick={() => reservationClick(item.unit_id)}
                        />
                </div>
                <div className="divider"/>
                <div className="planDetailViewer__detail-info-container">
                    <div className="planDetailViewer__detail-title">Details</div>
                    <div className="planDetailViewer__detail-info"><SvgIcons type="cabana_unit_floor_icon"/> {t('planDetailViewer.locatedFloors')} {item.unit_number.toString().charAt(0)}</div>
                    <div className="planDetailViewer__detail-info"><SvgIcons type="cabana_unit_parking_icon"/>{t(`planDetailViewer.${item.parking}`)}</div>
                    <div className="planDetailViewer__detail-info"><SvgIcons type="cabana_unit_locker_icon"/>{t(`planDetailViewer.${item.locker}`)}</div>
                    <div className="planDetailViewer__detail-info"><SvgIcons type="cabana_unit_orientation_icon"/>{t(`planDetailViewer.${item.orientation}`)}</div>
                    <div className="planDetailViewer__detail-info"><SvgIcons type="cabana_building_view_icon"/>{t(`planDetailViewer.${item.position}`)}</div>
                    <div className="planDetailViewer__detail-info"><SvgIcons type="cabana_unit_view_icon"/>{t(`planDetailViewer.${item.view}`)}</div>
                    <div className="planDetailViewer__detail-move-in"><SvgIcons type="cabana_unit_move_truck_icon"/>{t('planDetailViewer.moveIn')}: {moveDate}</div>
                </div>
            </div>
            <div className="planDetailViewer__image-container" style={imageContainerStyle}>
                {/* <div className="planDetailViewer__image">
                </div> */}
                <img ref={imageRef} src={item.plan} style={imageStyle}/>
            </div>
        </div>
    )
}

export default PlanDetailViewer;