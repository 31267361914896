import React from 'react';
import { useOvermind } from 'src/overmind';
import { useParams, useHistory } from 'react-router';
import SvgIcons from 'src/components/shared/svgIcons';
import './sidebar.scss';

const Sidebar = () => {
    const { state, actions } = useOvermind();
    const history = useHistory();
    const params = useParams();

    const handleChangePage = (newPage) => {
        history.push(`/vso/${params.id}/${newPage}`);
    }

    const handleBackToVsoList = () => {
        history.push(`/virtual-office-list/`);
    }
    
    const handleEditVso = () => {
        actions.productForm.clearProduct();
        history.push('/start-project-vso/' + params.id);
        actions.productForm.loadNewProduct().then(() => {
            actions.units.loadVsoInfo({vsoId: parseInt(params.id), projectId: state.productForm.newProduct.projectId});
        });
        actions.productForm.setProductType('vso');
    }

    return (
        <nav className="vsoDashSidebar__container">
            <div className="vsoDashSidebar__project-image" onClick={handleBackToVsoList}>
                <img src={state.VSOs.selectedVSO?.fields?.images.value.array[0].src} />
                <div className="vsoDashSidebar__item-label">Back to my VSOs list</div>
            </div>
            <div className={`vsoDashSidebar__item --edit-button`} onClick={handleEditVso}>
                EDIT
            </div>
            <div className={`vsoDashSidebar__item${state.vsoDashboard.currentPage === 'dash' ? ' --active': ''}`} onClick={() => handleChangePage('dash')}>
                <SvgIcons type="cabana_dash" />
                <div className="vsoDashSidebar__item-label">Dashboard</div>
            </div>
            <div className="vsoDashSidebar__divider" />
            <div className={`vsoDashSidebar__item${state.vsoDashboard.currentPage === 'units' ? ' --active': ''}`} onClick={() => handleChangePage('units')}>
                <SvgIcons type="cabana_house" />
                <div className="vsoDashSidebar__item-label">Units</div>
            </div>
            <div className={`vsoDashSidebar__item${state.vsoDashboard.currentPage === 'deals' ? ' --active': ''}`} onClick={() => handleChangePage('deals')}>
                <SvgIcons type="cabana_deals" />
                <div className="vsoDashSidebar__item-label">Deals & Reservations</div>
            </div>
            <div className="vsoDashSidebar__divider" />
            <div className={`vsoDashSidebar__item${state.vsoDashboard.currentPage === 'leads' ? ' --active': ''}`} onClick={() => handleChangePage('leads')}>
                <SvgIcons type="cabana_leads" />
                <div className="vsoDashSidebar__item-label">Leads</div>
            </div>
            <div className={`vsoDashSidebar__item${state.vsoDashboard.currentPage === 'stats' ? ' --active': ''}`} onClick={() => handleChangePage('stats')}>
                <SvgIcons type="cabana_stats" />
                <div className="vsoDashSidebar__item-label">Statistics</div>
            </div>
            <div className={`vsoDashSidebar__item${state.vsoDashboard.currentPage === 'settings' ? ' --active': ''}`} onClick={() => handleChangePage('settings')}>
                <SvgIcons type="cabana_settings" />
                <div className="vsoDashSidebar__item-label">Settings</div>
            </div>
        </nav>
    );
}

export default Sidebar;