import React, { useState, useRef } from 'react';
import SvgIcons from 'src/components/shared/svgIcons';
import Button from 'src/components/shared/button';
import Modal from 'src/components/shared/modal';
import DuplicateFloorMenu from './duplicateFloorMenu';

const DuplicateFloor = ({isDisabled}) => {
    const [isOpen, setIsOpen] = useState(false);
    const duplicateFloorButtonRef = useRef(null);

    const handleEditColumnsClick = () => {
        setIsOpen((prev) => !prev);
    }

    return(
        <>
        <Button type="secondary"
            size="small"
            text="Duplicate floor"
            options={{ref: duplicateFloorButtonRef}}
            onClick={handleEditColumnsClick}
            disabled={isDisabled}
        >
            <SvgIcons type="cabana_copy_floor" />
        </Button>
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <DuplicateFloorMenu setIsOpen={setIsOpen}/>
        </Modal>
        </>
    );
}

export default DuplicateFloor;