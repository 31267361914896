import React, {useState, useEffect} from 'react';
import { useOvermind } from 'src/overmind';
import { useHistory } from 'react-router-dom';
import Divider from 'src/components/shared/divider';
import FormGroup from 'src/components/shared/formGroup';
import TextInput from 'src/components/shared/inputs/textInput';
import '../steps.scss';
import AddressInput from 'src/components/shared/inputs/addressInput';
import RadioInput from 'src/components/shared/inputs/radioInput';
import SelectInput from 'src/components/shared/inputs/selectInput';
import NumberInput from 'src/components/shared/inputs/numberInput';
import DatePicker from 'src/components/shared/datePicker';
import BackNextBar from 'src/components/shared/backNextBar';
import FileInput from 'src/components/shared/inputs/fileInput';
import ImageGuide from 'src/components/shared/inputs/fileInput/projectImages/imagesGuide';
import ImageInfo from 'src/components/shared/inputs/fileInput/projectImages/imageInfo';
import LoadingBar from 'src/components/shared/loadingBar';
import Modal from 'src/components/shared/modal';
import { checkPublicId, convertUrltoFile } from 'src/utils/fileUpload';
import { validateAddressInput, validateTextInput, validateFileInput, validateNumberInput, validateBooleanInput } from 'src/utils/fieldValidation';

const projectIdentityFields = [
    'project_name', 'project_logo', 'project_type','project_address',
    'unit_type', 'num_buildings', 'num_floors',
    'construction_type', 'project_status', 'est_start_date',
    'est_delivery_date', 'lease_duration', 'images'
];

const ProjectIdentity = ({}) => {

    const {state, actions, effects} = useOvermind();
    const [showImagesLoaderBar, setShowImagesLoaderBar] = useState(false);
    const [isImagesLoaded, setIsImagesLoaded] = useState(false);
    const [uploadingText, setUploadingText] = useState("");
    const history = useHistory();
    const [stepFields, setStepFields] = useState({
        project_name: {type: 'text', error: true},
        project_address: {type: 'address', error: true},
        project_logo: {type: 'image', error: true},
        project_type: {type: 'text', error: true},
        unit_type: {type: 'text', error: true},
        num_buildings: {type: 'number', error: true},
        num_floors: {type: 'number', error: true},
        construction_type: {type: 'text', error: true},
        project_status: {type: 'text', error: true},
        est_delivery_date: {type: 'text', error: true},
        available_parking: {type: 'boolean', error: true},
        available_lockers: {type: 'boolean', error: true},
        images: {type: 'image', error: true},
        ... state.productForm.newProduct.fields.project_type?.value === 'rent' ? {lease_duration: {type: 'text', error: true}} : {}
    });

    const validateStep = (isFirstLoad) => {
        for (const field in stepFields) {
            if(stepFields[field]?.type === 'text') {
                let newError = validateTextInput(state.productForm.newProduct.fields[field]?.value, {isMandatory: true});
                if(typeof newError === 'string' && isFirstLoad) {
                    newError = true;
                }
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [field]: {
                            ...prev[field], 
                            error: newError
                        }
                    }
                });
            }
            if(stepFields[field]?.type === 'address') {
                let newError = validateAddressInput(state.productForm.newProduct.fields[field]?.value.full_string, {isMandatory: true});
                if(typeof newError === 'string' && isFirstLoad) {
                    newError = true;
                }
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [field]: {
                            ...prev[field], 
                            error: newError
                        }
                    }
                });
            }
            if(stepFields[field]?.type === 'image') {
                let newError = validateFileInput(state.productForm.newProduct.fields[field]?.value?.array, {isMandatory: true});
                if(typeof newError === 'string' && isFirstLoad) {
                    newError = true;
                }
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [field]: {
                            ...prev[field], 
                            error: newError
                        }
                    }
                });
            }
            if(stepFields[field]?.type === 'number') {
                let newError = validateNumberInput(state.productForm.newProduct.fields[field]?.value, {isMandatory: true});
                if(typeof newError === 'string' && isFirstLoad) {
                    newError = true;
                }
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [field]: {
                            ...prev[field], 
                            error: newError
                        }
                    }
                });
            }
            if(stepFields[field]?.type === 'boolean') {
                if(field === 'available_parking') {
                    let value = state.units.columns.filter((el) => el.name === 'parking')[0]?.isShown
                    let newError = validateBooleanInput(value, {isMandatory: true});
                    if(typeof newError === 'string' && isFirstLoad) {
                        newError = true;
                    }
                    setStepFields((prev) => {
                        return {
                            ...prev, 
                            [field]: {
                                ...prev[field], 
                                error: newError
                            }
                        }
                    });
                } else if (field === 'available_lockers') {
                    let value = state.units.columns.filter((el) => el.name === 'locker')[0]?.isShown
                    let newError = validateBooleanInput(value, {isMandatory: true});
                    if(typeof newError === 'string' && isFirstLoad) {
                        newError = true;
                    }
                    setStepFields((prev) => {
                        return {
                            ...prev, 
                            [field]: {
                                ...prev[field], 
                                error: newError
                            }
                        }
                    });
                }
            }
        }
    }

    const validateField = (fieldName) => {
        if(stepFields[fieldName]?.type === 'text') {
            let newError = validateTextInput(state.productForm.newProduct.fields[fieldName]?.value, {isMandatory: true});
            setStepFields((prev) => {
                return {
                    ...prev, 
                    [fieldName]: {
                        ...prev[fieldName], 
                        error: newError
                    }
                }
            });
        }
        if(stepFields[fieldName]?.type === 'address') {
            let newError = validateAddressInput(state.productForm.newProduct.fields[fieldName]?.value.full_string, {isMandatory: true});
            setStepFields((prev) => {
                return {
                    ...prev, 
                    [fieldName]: {
                        ...prev[fieldName], 
                        error: newError
                    }
                }
            });
        }
        if(stepFields[fieldName]?.type === 'image') {
            let newError = validateFileInput(state.productForm.newProduct.fields[fieldName]?.value.array, {isMandatory: true});
            setStepFields((prev) => {
                return {
                    ...prev, 
                    [fieldName]: {
                        ...prev[fieldName], 
                        error: newError
                    }
                }
            });
        }
        if(stepFields[fieldName]?.type === 'number') {
            let newError = validateNumberInput(state.productForm.newProduct.fields[fieldName]?.value, {isMandatory: true});
            setStepFields((prev) => {
                return {
                    ...prev, 
                    [fieldName]: {
                        ...prev[fieldName], 
                        error: newError
                    }
                }
            });
        }
        if(stepFields[fieldName]?.type === 'boolean') {
            if(fieldName === 'available_parking') {
                let value = state.units.columns.filter((el) => el.name === 'parking')[0]?.isShown
                let newError = validateBooleanInput(value, {isMandatory: true});
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [fieldName]: {
                            ...prev[fieldName], 
                            error: newError
                        }
                    }
                });
            } else if (fieldName === 'available_lockers') {
                let value = state.units.columns.filter((el) => el.name === 'locker')[0]?.isShown
                let newError = validateBooleanInput(value, {isMandatory: true});
                setStepFields((prev) => {
                    return {
                        ...prev, 
                        [fieldName]: {
                            ...prev[fieldName], 
                            error: newError
                        }
                    }
                });
            }
        }
    }

    const calculateCompletedFields = () => {
        return Object.values(stepFields).filter((field) => field.error === false).length;
    }

    useEffect(() => {
        validateStep(state.productForm.newProduct.fields.form_steps_data.value.array[0] === 0);
    }, []);

    useEffect(() => {
        actions.productForm.setStepCompletedFields({step: 1, completedFields: calculateCompletedFields()});
    }, [stepFields]);

    useEffect(() => {
        if(state.productForm.newProduct.fields.project_type?.value == 'sell') {
            delete stepFields.lease_duration
            actions.productForm.setStepCompletedFields({step: 1, completedFields: calculateCompletedFields()});
        }
        if(state.productForm.newProduct.fields.project_type?.value == 'rent' && stepFields.lease_duration === undefined) {
            setStepFields((prev) => {
                return {
                    ...prev,
                    ['lease_duration']: {type: 'text', error: true}
                }

            });
            actions.productForm.setStepCompletedFields({step: 1, completedFields: calculateCompletedFields()});
        }
    }, [state.productForm.newProduct.fields.project_type?.value]);

    const handleTextInputChange = (e, dataObj) => {
        actions.productForm.setProductField(
            {
                fieldId: dataObj.fieldId,
                value: e.target.value,
            }
        )
    }

    const handleRadioInputChange = (e, dataObj) => {
        if(dataObj.fieldId == 'project_type' && e.currentTarget.getAttribute('data-value') == 'sell') {
            let tempArray = ['lease_duration', 'policies_credit_check', 'policies_criminal_check'];
            for (const field of tempArray) {
                actions.productForm.setProductField(
                    {
                        fieldId: field,
                        value: '',
                    }
                );
            }
        }
        actions.productForm.setProductField(
            {
                fieldId: dataObj.fieldId,
                value: e.currentTarget.getAttribute('data-value'),
            }
        )
    }

    const handleSelectInputChange = (e, dataObj) => {
        actions.productForm.setProductField(
            {
                fieldId: dataObj.fieldId,
                value: e.target.value,
            }
        )
    }

    const handleNumberInputChange = (e, dataObj) => {
        if(e) {
            e.currentTarget.value = parseInt(e.currentTarget.value, 10);
            actions.productForm.setProductField(
                {
                    fieldId: dataObj.fieldId,
                    value: e.currentTarget.value,
                }
            );
        } else {
            actions.productForm.setProductField(
                {
                    fieldId: dataObj.fieldId,
                    value: dataObj.value,
                }
            );
        }
    }

    const handleDatePickerValue = (dateValue, dataObj) => {
        actions.productForm.setProductField(
            {
                fieldId: dataObj.fieldId,
                value: dateValue
            }
        )
    }

    const handleOptionalColumnChange = (e, dataObj) => {
        let value = e.currentTarget.getAttribute('data-value');
        if(dataObj.fieldId === 'available_parking') {
            actions.units.setOptionalColumns({name: 'parking', isShown: value === 'true' ? true : false});
        } else if (dataObj.fieldId === 'available_lockers') {
            actions.units.setOptionalColumns({name: 'locker', isShown: value === 'true' ? true : false});
        }
    }

    const handleBackClick = async () => {
        if(state.productForm.newProduct.fields.project_logo?.value?.array[0]?.src) {
            if(checkPublicId(state.productForm.newProduct.fields.project_logo?.value?.array)) {
                let formData = new FormData();
                let convertedFile = await convertUrltoFile(state.productForm.newProduct.fields.project_logo?.value?.array[0]?.src, state.productForm.newProduct.fields.project_logo?.value?.array[0]?.name);
                formData.append('logo', convertedFile);
                effects.productForm.uploadImageVso(state.productForm.newProduct.id, 'project_logo', formData).then((res) => {
                    actions.productForm.setProductField(
                        {
                            fieldId: 'project_logo',
                            value: {array: [{...state.productForm.newProduct.fields.project_logo?.value?.array[0], src: res}]}
                        }
                        )
                    });  
            }
        }
        if(state.productForm.newProduct.fields.images.value.array.length > 0) { 
            if(checkPublicId(state.productForm.newProduct.fields.images.value.array)) {
                let formData = new FormData();
                for(let f of state.productForm.newProduct.fields.images.value.array) {
                    formData.append('imageInfo', JSON.stringify(f));
                    let convertedFile = await convertUrltoFile(f.src, f.name)
                    formData.append('images', convertedFile);
                }
                setUploadingText('Saving Images...');
                setShowImagesLoaderBar(true);
                await actions.productForm.updateProjectImages(formData).then(() => {
                    setIsImagesLoaded(true);
                })
            }
        }
        actions.productForm.updateProductFields(projectIdentityFields).then(() => {
            actions.productForm.clearProduct();
            history.push('/virtual-office-list');
        });
    }

    const handleNextClick = async () => {
        if(state.productForm.newProduct.fields.project_logo?.value?.array[0]?.src) {
            if(checkPublicId(state.productForm.newProduct.fields.project_logo?.value?.array)) {
                let formData = new FormData();
                let convertedFile = await convertUrltoFile(state.productForm.newProduct.fields.project_logo?.value?.array[0]?.src, state.productForm.newProduct.fields.project_logo?.value?.array[0]?.name);
                formData.append('logo', convertedFile);
                effects.productForm.uploadImageVso(state.productForm.newProduct.id, 'project_logo', formData).then((res) => {
                    actions.productForm.setProductField(
                        {
                            fieldId: 'project_logo',
                            value: {array: [{...state.productForm.newProduct.fields.project_logo?.value?.array[0], src: res}]}
                        }
                        )
                    });  
            }
        }
        if(state.productForm.newProduct.fields.images.value.array.length > 0) { 
            if(checkPublicId(state.productForm.newProduct.fields.images.value.array)) {
                let formData = new FormData();
                for(let f of state.productForm.newProduct.fields.images.value.array) {
                    formData.append('imageInfo', JSON.stringify(f));
                    let convertedFile = await convertUrltoFile(f.src, f.name)
                    formData.append('images', convertedFile);
                }
                setUploadingText('Saving Images...');
                setShowImagesLoaderBar(true);
                await actions.productForm.updateProjectImages(formData).then(() => {
                    setIsImagesLoaded(true);
                })
            }
        }
        await actions.units.sendOptionalColumnsData(state.productForm.newProduct.id);
        actions.productForm.updateProductFields(projectIdentityFields).then(() => {
            validateStep(false);
            actions.productForm.incrementFormStep();
        });
    }

    return (
        <div className="steps__container">
            <div className="steps__title-desc-container">
                <div className="steps__title">Project Identity</div>
                <div className="steps__desc">To start building your virtual sales office, you need to enter information about your project.</div>
            </div>
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="General Information"
                fields={[
                    <TextInput 
                        label="Project Name"
                        validateField={validateField}
                        error={stepFields.project_name.error} 
                        value={state.productForm.newProduct.fields.project_name?.value} 
                        setValue={handleTextInputChange} 
                        dataObj={{fieldId: 'project_name'}}
                        placeholder="Enter Your project name" 
                        auditorComment={state.productForm.newProduct.fields.project_name?.comment} 
                        status={state.productForm.newProduct.fields.project_name?.status}
                        options={{isVso: true, isMandatory: true, maxWidth: '400px'}}
                    />,
                    <AddressInput
                        dataObj={{fieldId: 'project_address'}}
                        validateField={validateField}
                        error={stepFields.project_address.error} 
                        label="Address"
                        placeholder="Enter your project address" 
                        mapHeight="400px"
                        options={{isMandatory: true, containsMap: true}}
                    />,
                    <FileInput
                        label="Logo"
                        description={
                            <>
                                Clients will see your logo when navigating throughout your virtual sales office.<br/>
                                The logo must be a maximum of 256x256 pixel in PNG image format with no <br/> background. <br/>
                                Please do not use a white on black logo. 
                            </>
                            }
                        dataObj={{fieldId: 'project_logo'}}
                        validateField={validateField}
                        error={stepFields.project_logo.error} 
                        value={state.productForm.newProduct.fields?.project_logo?.value?.array[0]?.src}
                        setValue={actions.productForm.setProductField}
                        removeFile={actions.productForm.removeProjectLogo}
                        options={{isMandatory: true, isMultiple: false, sizeLimit: 2097152, fileLimit: 1,  
                                isImageInput: true, acceptedFormat: 'image/png, image/jpeg, image/gif',
                                hasCoverIcon: false}}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="Project Details"
                fields={[
                    <RadioInput 
                        label="Project type"
                        error={stepFields.project_type.error}
                        validateField={validateField}  
                        value={state.productForm.newProduct.fields.project_type?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'project_type'}} 
                        auditorComment={state.productForm.newProduct.fields.project_type?.comment} 
                        status={state.productForm.newProduct.fields.project_type?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['rent', 'Rent'],
                            ['sell', 'Sale'],
                         ]}
                    />,
                    <SelectInput 
                        label="Unit Type(s)"
                        error={stepFields.unit_type.error}
                        validateField={validateField}  
                        value={state.productForm.newProduct.fields.unit_type?.value} 
                        setValue={handleSelectInputChange} 
                        dataObj={{fieldId: 'unit_type'}} 
                        placeholder="Select type"
                        auditorComment={state.productForm.newProduct.fields.unit_type?.comment} 
                        status={state.productForm.newProduct.fields.unit_type?.status}
                        options={{isMandatory: true, maxWidth: '300px'}}
                        selectOptions={[
                            ['app_condo', 'Apartment or condo'],
                            ['country_house', 'Country house'],
                            ['cottage', 'Cottage'],
                            ['duplex', 'DuPlex'],
                            ['plex', 'Plex'],
                            ['row_house', 'Row house'],
                            ['semi_detached_house', 'Semi-detached house'],
                            ['single_detached_house', 'Single-detached house'],
                        ]}
                    />,
                    <NumberInput 
                        label="Number of buildings"
                        error={stepFields.num_buildings.error}
                        validateField={validateField}  
                        value={state.productForm.newProduct.fields.num_buildings?.value}
                        placeholder="Type the number of buildings"
                        setValue={handleNumberInputChange} 
                        dataObj={{fieldId: 'num_buildings'}}
                        auditorComment={state.productForm.newProduct.fields.num_buildings?.comment}
                        status={state.productForm.newProduct.fields.num_buildings?.status}
                        options={{horizontal: true, minVal: 1, isMandatory: true, maxWidth: '300px'}} 
                    />,
                    <NumberInput 
                        label="Number of floors"
                        error={stepFields.num_floors.error}
                        validateField={validateField}  
                        value={state.productForm.newProduct.fields.num_floors?.value}
                        placeholder="Type the number of units"
                        setValue={handleNumberInputChange} dataObj={{fieldId: 'num_floors'}}
                        auditorComment={state.productForm.newProduct.fields.num_floors?.comment}
                        status={state.productForm.newProduct.fields.num_floors?.status}
                        options={{horizontal: true, minVal: 1, isMandatory: true, maxWidth: '300px'}} 
                    />,
                    <RadioInput 
                        label="Construction Type"
                        error={stepFields.construction_type.error}
                        validateField={validateField}  
                        value={state.productForm.newProduct.fields.construction_type?.value} 
                        setValue={handleRadioInputChange} 
                        dataObj={{fieldId: 'construction_type'}} 
                        auditorComment={state.productForm.newProduct.fields.construction_type?.comment} 
                        status={state.productForm.newProduct.fields.construction_type?.status}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['new', 'New'],
                            ['renovation', 'Renovation'],
                        ]}
                    />,
                    <RadioInput 
                        label="Project Status"
                        error={stepFields.project_status.error}
                        validateField={validateField}  
                        value={state.productForm.newProduct.fields.project_status?.value} 
                        setValue={handleRadioInputChange} dataObj={{fieldId: 'project_status'}} 
                        auditorComment={state.productForm.newProduct.fields.project_status?.comment} 
                        status={state.productForm.newProduct.fields.project_status?.status}
                        options={{isMandatory: true}}
                        radioOptions={ 
                            state.productForm.newProduct.fields.construction_type?.value === "new" ? 
                            [
                                ['pre-construction', 'Pre-construction'],
                                ['in construction', 'In Construction'],
                                ['built', 'Built']
                            ] 
                            : 
                            [
                                ['pre-renovation', 'Pre-renovation'],
                                ['in renovation', 'In Renovation'],
                                ['renovated', 'Renovated']

                            ]
                        }
                    />,
                    <DatePicker 
                        label="Start of construction"
                        value={state.productForm.newProduct.fields.est_start_date?.value} 
                        setValue={handleDatePickerValue} 
                        dataObj={{fieldId: 'est_start_date'}} 
                        placeholder="Select construction date" 
                        auditorComment={state.productForm.newProduct.fields.est_start_date?.comment} 
                        status={state.productForm.newProduct.fields.est_start_date?.status}
                        options={{isVso: true, maxWidth: '300px'}}
                    />,
                    <DatePicker
                        label="Move-in date"
                        error={stepFields.est_delivery_date.error}
                        validateField={validateField}  
                        value={state.productForm.newProduct.fields.est_delivery_date?.value} 
                        setValue={handleDatePickerValue} 
                        dataObj={{fieldId: 'est_delivery_date'}} 
                        placeholder="Select move-in date" 
                        auditorComment={state.productForm.newProduct.fields.est_delivery_date?.comment} 
                        status={state.productForm.newProduct.fields.est_delivery_date?.status}
                        options={{isVso: true, isMandatory: true, maxWidth: '300px'}}
                    />,
                    ... state.productForm.newProduct.fields.project_type?.value === 'rent' ? 
                            [
                                <SelectInput 
                                    label="Lease Duration"
                                    error={stepFields.lease_duration?.error}
                                    validateField={validateField} 
                                    placeholder="Select lease duration" 
                                    value={state.productForm.newProduct.fields.lease_duration?.value} 
                                    setValue={handleSelectInputChange} 
                                    dataObj={{fieldId: 'lease_duration'}} 
                                    auditorComment={state.productForm.newProduct.fields.lease_duration?.comment} 
                                    status={state.productForm.newProduct.fields.lease_duration?.status}
                                    options={{maxWidth: '300px', isMandatory: true}}
                                    selectOptions={[
                                        ['12', '12 months'],
                                        ['18', '18 months'],
                                        ['24', '24 months'],
                                        ['36', '36 months'],
                                    ]}
                                />
                            ] 
                            : 
                            [],
                    <RadioInput 
                        label="Is there available parking in the project"
                        error={stepFields.available_parking.error}
                        validateField={validateField}  
                        value={state.units.columns?.filter((el) => el.name == 'parking')[0]?.isShown} 
                        setValue={handleOptionalColumnChange} 
                        dataObj={{fieldId: 'available_parking'}} 
                        options={{isMandatory: true}}
                        radioOptions={[
                            [true, 'Yes'],
                            [false, 'No'],
                        ]}
                    />,
                    <RadioInput 
                        label="Is there available locker in the project"
                        error={stepFields.available_lockers.error}
                        validateField={validateField}  
                        value={state.units.columns?.filter((el) => el.name == 'locker')[0]?.isShown} 
                        setValue={handleOptionalColumnChange} 
                        dataObj={{fieldId: 'available_lockers'}} 
                        options={{isMandatory: true}}
                        radioOptions={[
                            [true, 'Yes'],
                            [false, 'No'],
                        ]}
                    />,
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px" />
            <FormGroup 
                title="Project Images"
                description={[<ImageGuide />]}
                fields={[
                    <FileInput 
                        label="Upload Images"
                        info={<ImageInfo />}
                        dataObj={{fieldId: 'images'}}
                        error={stepFields.images.error}
                        validateField={validateField}  
                        removeFile={actions.productForm.removeProjectImage} 
                        caption="Upload your high quality 3D-renderings or real images of your project for clients to browse through."
                        value={state.productForm.newProduct.fields?.images?.value?.array}
                        setValue={actions.productForm.setProjectImages}
                        options={{isMandatory: true, isMultiple: true, sizeLimit: 9000000, fileLimit: 10,
                                isImageInput: true, acceptedFormat: 'image/png, image/jpeg, image/gif',
                                hasCoverIcon: true}}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px" />
            <BackNextBar onBackClick={handleBackClick} onNextClick={handleNextClick} />
            <Modal isOpen={showImagesLoaderBar} setIsOpen={setShowImagesLoaderBar} options={{contentCenter: true, disableBackdropClick: true}}>
                <div className="loadingBar__container">
                    <div className="loadingBar__text">{uploadingText}</div>
                    <LoadingBar isLoaded={isImagesLoaded} setIsOpen={setShowImagesLoaderBar} /> 
                </div>
            </Modal>
        </div>
    );
}

export default ProjectIdentity;