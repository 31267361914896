require('dotenv').config();

export const getToken = async () => {
  const res = await fetch(`${process.env.REACT_APP_SERVER_URL}video-call/get-token`, {
    method: "GET",
  });

  const { token } = await res.json();
  return token;
};

export const createMeeting = async ({ token }) => {
  const res = await fetch(`${process.env.REACT_APP_SERVER_URL}video-call/create-meeting`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  });

  const { meetingId } = await res.json();

  return meetingId;
};

export const validateMeeting = async ({ meetingId, token }) => {
  await fetch(`${process.env.REACT_APP_SERVER_URL}video-call/validate-meeting/${meetingId}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  });
};
