import React, {useState, useEffect} from "react";
import Divider from "src/components/shared/divider";
import FormGroup from "src/components/shared/formGroup";
import RadioInput from "src/components/shared/inputs/radioInput";
import { useOvermind } from "src/overmind";
import "../steps.scss";
import BackNextBar from "src/components/shared/backNextBar";
import TextInput from "src/components/shared/inputs/textInput";
import NumberInput from "src/components/shared/inputs/numberInput";
import Modal from "src/components/shared/modal";
import ErrorMessage from "src/components/shared/errorMessage";
import { validateTextInput, validateNumberInput } from "src/utils/fieldValidation";
import { useHistory } from "react-router-dom";

const ContactCustom = ({completedFields, totalFields, currentAddress}) => {

    const {state, actions, effects} = useOvermind();
    let history = useHistory();
    const [urlModal, setUrlModal] = useState(false);
    const [formErrors, setFormErrors] = useState({
        email: null,
        show_prices: null,
        reservation_deposit: null,
        reservation_days: null, 
    });

    const validateField = (fieldName) => {
        if(fieldName === 'email') {
            const error = validateTextInput(state.productForm.contactInfo.email, {isMandatory: true, isEmail: true});
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    [fieldName]: error
                }
            });
            return error;
        } else if(fieldName === 'show_prices') {
            const error = validateTextInput(state.productForm.newProduct.show_prices, {isMandatory: true});
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    [fieldName]: error
                }
            });
            return error;
        } else if(fieldName === 'reservation_deposit') {
            const error = validateNumberInput(state.productForm.reservationDeposit.value, {isMandatory: true});
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    [fieldName]: error
                }
            });
            return error;
        } else if(fieldName === 'reservation_days') {
            const error = validateNumberInput(state.productForm.reservationDays.value, {isMandatory: true});
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    [fieldName]: error
                }
            });
            return error;
        }
    }

    const validateForm = (isFirstLoad) => {
        if(!isFirstLoad) {
            const newErrors = {
                email: validateTextInput(state.productForm.contactInfo.email, {isMandatory: true, isEmail: true}),
                show_prices: validateTextInput(state.productForm.newProduct.show_prices, {isMandatory: true}),
                reservation_deposit: validateNumberInput(state.productForm.reservationDeposit.value, {isMandatory: true}),
                reservation_days: validateNumberInput(state.productForm.reservationDays.value, {isMandatory: true})
            }
            setFormErrors(newErrors);
            return Object.values(newErrors).every((value) => value === false);
        }
    }

    const calculateCompletedFields = () => {
        return Object.values(formErrors).filter((field) => field === false).length;
    }

    useEffect(() => {
        validateForm(state.productForm.newProduct.fields.form_steps_data.value.array[4] === 0);
    }, []);

    useEffect(() => {
        actions.productForm.setStepCompletedFields({step: 5, completedFields: calculateCompletedFields()});
    }, [formErrors]);

    const handleTextInputChange = (e, dataObj) => {
        actions.productForm.setContactInfoValue({key: dataObj.fieldId, value: e.target.value}); 
    }

    const handleNumberInputChange = (e, dataObj) => {
        let value = e.target.value = parseInt(e.target.value, 10);
        if(dataObj.fieldId === 'reservation_deposit') {
            actions.productForm.setReservationDeposit(value);
        } else if(dataObj.fieldId === 'reservation_days') {
            actions.productForm.setReservationDays(value);
        }
    }

    const handleRadioInputChange = (e, dataObj) => {
        let value = e.currentTarget.getAttribute('data-value')
        actions.productForm.setShowPrices(value);
    }

    const handleBackClick = () => {
        actions.productForm.sendContactInfo();
        actions.productForm.updateReservationDays();
        actions.productForm.updateReservationDeposit();
        actions.productForm.decrementFormStep();
    }

    const handleNextClick = async () => {
        validateForm(false);
        actions.productForm.sendContactInfo();
        actions.productForm.updateReservationDays();
        actions.productForm.updateReservationDeposit();
        if(totalFields == completedFields && state.productForm.vsoStatus == 'active') {
            if((currentAddress !== state.productForm.newProduct.fields.project_address?.value?.full_string && currentAddress !== '') || state.productForm.newProduct.fields.nearby_places?.value == undefined) {
                let addressData = {
                    coords: {lat: state.productForm.newProduct.fields.project_address?.value?.coords?.lat, lng: state.productForm.newProduct.fields.project_address?.value?.coords?.lng},
                    full_string: state.productForm.newProduct.fields.project_address?.value?.full_string,
                    address_components: state.productForm.newProduct.fields.project_address.value?.address_components
                }
                await effects.productForm.putNearbyPlacesVso(state.productForm.newProduct.id, addressData);
            }
            history.push(`/virtual-sales-office/${state.productForm.newProduct.id}`);
        } else {
            actions.productForm.incrementFormStep();
        }
    }

    return(
        <div className="steps__container">
            <div className="steps__title-desc-container">
                <div className="steps__title">Contact & Customization</div>
                <div className="steps__desc">Select all building features that apply to your project.</div>
            </div>
            <Divider orientation="horizontal" margin="0px -24px"/>
            <FormGroup 
                title="Contact Information"
                fields={[
                    <TextInput 
                        label="Email address of the project"
                        info="placeholder"
                        placeholder="Enter project email address"
                        validateField={validateField}
                        error={formErrors.email}
                        setValue={handleTextInputChange}
                        value={state.productForm.contactInfo?.email}  
                        dataObj={{fieldId: 'email'}}
                        options={{isEmail: true, isMandatory: true, maxWidth: '300px'}}
                    />, 
                    <TextInput 
                        label="Phone number of the project"
                        placeholder="Enter project phone number"
                        info="placeholder"
                        setValue={handleTextInputChange}
                        value={state.productForm.contactInfo?.number} 
                        dataObj={{fieldId: 'number'}}
                        options={{isPhone: true,  maxWidth: '300px'}}
                    />,
                    <TextInput 
                        label="Calendar link"
                        info="placeholder"
                        placeholder="Enter project calender link"
                        setValue={handleTextInputChange}
                        value={state.productForm.contactInfo?.booking_link}
                        dataObj={{fieldId: "booking_link"}}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px" />
            <FormGroup
                title="Pricing details"
                fields={[
                    <RadioInput
                        label="What prices should be displayed"
                        error={formErrors.show_prices}
                        validateField={validateField}
                        value={state.productForm.newProduct.show_prices}
                        setValue={handleRadioInputChange}
                        dataObj={{fieldId: 'show_prices'}}
                        options={{isMandatory: true}}
                        radioOptions={[
                            ['yes', 'All prices'],
                            ['no', 'None'],
                            ['from', 'Starting at']
                        ]}
                    />
                ]} 
            />
            <Divider orientation="horizontal" margin="0px -24px" />
            <FormGroup 
                title="Reservation"
                fields={[
                    <NumberInput 
                        label="What's the deposit amount needed for every reservation"
                        placeholder="Enter deposit"
                        validateField={validateField}
                        dataObj={{fieldId: 'reservation_deposit'}}
                        error={formErrors.reservation_deposit} 
                        value={state.productForm.reservationDeposit?.value} 
                        setValue={handleNumberInputChange} 
                        options={{suffix: '$', maxWidth: '140px', isMandatory: true}}
                    />,
                    <NumberInput
                        label="For how long will the unit be reserved after deposit"
                        validateField={validateField}
                        error={formErrors.reservation_days}
                        dataObj={{fieldId: 'reservation_days'}}  
                        placeholder="Number of"
                        value={state.productForm.reservationDays?.value} 
                        setValue={handleNumberInputChange} 
                        options={{suffix: 'day(s)', maxWidth: '140px', isMandatory: true}}
                    />
                ]}
            />
            <Divider orientation="horizontal" margin="0px -24px" />
            <BackNextBar onBackClick={handleBackClick} onNextClick={handleNextClick} />
            <Modal isOpen={urlModal} setIsOpen={setUrlModal} options={{hasCloseButton: false, hasWhiteBackdrop: false, animation: true, isFullPage: false, disableBackdropClick: true, contentCenter: true, backDropAlignCenter: true}}>
                <ErrorMessage 
                    onClick={() => setUrlModal(false)} 
                    items={[
                        {content: <div>Appointment link is invalid</div>}
                    ]}
                    errorCode={'1320'}/>
            </Modal>
        </div>
    )
}

export default ContactCustom;