import React from 'react';
import "./subSteps.css";

const SubSteps = ({items, stepState}) => {
    return(
        <div className="subSteps__tabs-container">
            {
                items.map((el, index) => {
                    return(
                        <div className={`subSteps__tabs-item ${index === (stepState - 1) ? 'active' : ''}` }>{el}</div>
                    )
                })
            }
        </div>
    )
}

export default SubSteps;
