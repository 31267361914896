import React, {useState, useEffect} from "react";
import "./textAreaInput.css";
import AuditorComment from '../../auditorComment';

const TextAreaInput = ({label, isLabelStrong, onKeyDown, value, setValue, placeholder, dataObj, validateField, status = 'pending', auditorComment, error, options}) => {
    const [fieldStatus, setFieldStatus] = useState(status);
    const [fieldId, setFieldId] = useState('');

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(label + Math.floor(Math.random() * Date.now())));
    }, []);
/*
    const validateField = () => {
        if(validationRules.mandatory) {
            if(value === '' || value === null) {
                error = 'This field is mandatory.';
                return false;
            }
        }
        if(validationRules.limit) {
            if(value.length > validationRules.limit) {
                error = 'Please enter a shorter value.';
                return false;
            }
        } else {
            if(value.length > 1000) {
                error = 'Please enter a shorter value.'
                return false;
            }
        }
        return true;
    }
*/
    const handleValueChange = (e) => {
        let debounce = require('lodash.debounce');

        setValue(e, dataObj);

        debounce(() => {
            console.log('debounce');
            if(validateField()){
                setFieldStatus('pending');
            } else {
                setFieldStatus('denied');
            };
        }, 300);
    }

    return(
    <div className="form__input-group">
        <label htmlFor={`input__${fieldId}}`} style={isLabelStrong ? {color: '#333333', fontWeight: '600'} : {}}>{label}</label>
        {auditorComment && 
        <AuditorComment comment={auditorComment}/>
        }
        <textarea ref={options?.ref} className={`input__textarea--${fieldStatus}`} id={`input__${fieldId}`} type="text" value={value} onChange={handleValueChange} placeholder={placeholder} onKeyDown={onKeyDown}/>
        {error && 
        <div className="input__error">{error}</div>
        }
    </div>
    )
}

export default TextAreaInput;