import React, { useState, useEffect, useRef } from 'react';
import { useOvermind } from 'src/overmind';
import Button from 'src/components/shared/button';
import { checkOutlineContentExists, getActiveFloorUnits } from 'src/utils/units';
import { checkFileSize, checkFileValid } from 'src/utils/fileUpload';
import { useHistory } from 'react-router-dom';
import Modal from 'src/components/shared/modal';
import { batchUploadFloorPlanText } from 'src/utils/batchUploadTexts';
import ErrorMessage from 'src/components/shared/errorMessage';
import FloorItems from './floorItems';
import FloorPlan from './floorPlan';
import './uploadFloorPlans.scss';
import BatchUpload from 'src/components/shared/batchUpload';
import WarningMessage from 'src/components/shared/warningMessage';

const UploadFloorPlans = ({changeStep, steps, validateField, formErrors, validateForm}) => {

    const {state, actions, effects} = useOvermind();
    const [currentFloor, setCurrentFloor] = useState(0);
    const [fileNameErrorModal, setFileNameErrorModal] = useState(false);
    const [fileSizeErrorModal, setFileSizeErrorModal] = useState(false);
    const [warningMessage, setWarningMessage] = useState(false);
    const [warningType, setWarningType] = useState('');
    const [batchUpload, setBatchUpload] = useState(false);
    const [batchUploadFiles, setBatchUploadFiles] = useState([]);
    const [dragHover, setDragHover] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const floorPlanRef = useRef(null);
    const batchfloorPlanRef = useRef(null);
    const history = useHistory();

    const initFloorPlans = () => {
        let tempArray = []
        for (let i = 0; i < state.productForm.newProduct.fields.num_floors?.value; i++) {
            let tempObj = {
                id: i,
                name: `floor ${i + 1}`,
                plan: "",
                isUploaded: false,
            }
            tempArray.push(tempObj)
        }
        actions.productForm.initializeFloorPlans(tempArray);
        actions.productForm.updateProductFields(['floor_plans']);
    }

    const checkPlansUploaded = () => {
        for (const floor of state.productForm.newProduct.fields.floor_plans?.value?.array) {
            if(!floor.isUploaded) {
                setCurrentFloor(floor.id);
                return
            } else {
                setEditMode(true);
            }
        }
    }

    useEffect(() => {
        if(state.productForm.newProduct.fields.floor_plans?.value?.array.length === 0) {
            initFloorPlans();
        } else {
            checkPlansUploaded();
        }
    }, []);

    const batchUploadClick = () => {
        batchfloorPlanRef.current.click();
    }

    const handleUploadClick = (index) => {
        setCurrentFloor(index);
        setEditMode(false);
        floorPlanRef.current.click();
    }

    const handleEditClick = (index) => {
        setEditMode(true);
        setCurrentFloor(index);
    }

    const uploadNewFloorPlan = () => {
        let tempArray = getActiveFloorUnits(state.units.list, currentFloor + 1);
        if(checkOutlineContentExists(tempArray)) {
            setWarningMessage(true);
        } else {
            floorPlanRef.current.click();
        }
    }

    const handleClearClick = (index) => {
        setCurrentFloor(index);
        let tempArray = getActiveFloorUnits(state.units.list, index + 1);
        if(checkOutlineContentExists(tempArray)) {
            setWarningType('clearFloorPlan');
            setWarningMessage(true);
        } else {
            handleRemoveFloorPlan(index);
        }
    }

    const handleRemoveFloorPlan = (index) => {
        setEditMode(false);
        setCurrentFloor(index);
        let floorName = state.productForm.newProduct.fields.floor_plans?.value?.array[index].name.split(" ").join("_");
        effects.productForm.removeFloorPlan(state.productForm.newProduct.id, floorName).then((res) => {
            if(res.result == 'ok') {
                let tempObj = {
                    ...state.productForm.newProduct.fields.floor_plans?.value?.array[index],
                    plan: "",
                    isUploaded: false
                };
                actions.productForm.setFloorPlan(tempObj);
                actions.productForm.updateProductFields(['floor_plans']);
            }
        });
    }

    const handleBatchUploadDrop = (e) => {
        e.preventDefault()
        setDragHover(false);
        batchfloorPlanRef.current.files = e.dataTransfer.files;
        if(checkFileValid(batchfloorPlanRef.current.files, setFileNameErrorModal, true)) {
            for (const file of batchfloorPlanRef.current.files) {
                if (checkFileSize(file)) {
                   setFileSizeErrorModal(true); 
                   return
                }
            }
        }
    }

    const handleBatchUploadSave = () => {
        if(batchUploadFiles.length > 0) {
            for (const [index, file] of Array(...batchUploadFiles).entries()) {;
                let floorName = state.productForm.newProduct.fields.floor_plans?.value?.array[index]?.name.split(" ").join("");
                let fileName = file.name.split(".")[0];
                if(fileName === floorName) {
                    let formData = new FormData()
                    formData.append('floorPlan', file);
                    formData.append('floorId', state.productForm.newProduct.fields.floor_plans?.value?.array[index]?.name)
                    effects.productForm.uploadFloorPlan(state.productForm.newProduct.id, formData)
                    .then((res) => {
                        let tempObj = {
                            ...state.productForm.newProduct.fields.floor_plans?.value?.array[index],
                            plan: res,
                            isUploaded: true
                        };
                        actions.productForm.setFloorPlan(tempObj);
                        setCurrentFloor(index);
                    });
                }
            }
            actions.productForm.updateProductFields(['floor_plans']);
            setBatchUpload(false);
            batchfloorPlanRef.current.value = "";
            setBatchUploadFiles({});
            setEditMode(true);
        }  
    }

    const handleSaveFloorsAndExit = () => {
        actions.productForm.updateProductFields(['floor_plans']).then(() => {
            history.push('/virtual-office-list');
        })
    }

    const handleBatchUploadChange = () => {
        if(batchfloorPlanRef.current.files.length > 0) {
            if(checkFileValid(batchfloorPlanRef.current.files, setFileNameErrorModal, true)) {
                for (const file of batchfloorPlanRef.current.files) {
                    if (checkFileSize(file)) {
                       setFileSizeErrorModal(true);
                       batchfloorPlanRef.current.files = "" 
                       return
                    }
                }
                setBatchUploadFiles(batchfloorPlanRef.current.files);
            }
        }
    }

    const handleChange = () => {
        if(floorPlanRef.current.files.length > 0) {
            if(checkFileValid([floorPlanRef.current.files[0]], setFileNameErrorModal, true)){
                if(!checkFileSize(floorPlanRef.current.files[0])) {
                    let tempArray = getActiveFloorUnits(state.units.list, currentFloor + 1);
                    if(checkOutlineContentExists(tempArray)) { 
                        for (const item of tempArray) {
                            let tempObj = {
                                points: [],
                                isFinished: false,
                            }
                            actions.units.setUnitPoints({updatedContents: tempObj, unit: item});
                        }
                    }
                    setTimeout(() => {
                        let formData = new FormData();
                        formData.append('floorPlan', floorPlanRef.current.files[0]);
                        formData.append('floorId', state.productForm.newProduct.fields.floor_plans?.value?.array[currentFloor]?.name)
                        effects.productForm.uploadFloorPlan(state.productForm.newProduct.id, formData)
                        .then((res) => {
                            let tempObj = {
                                ...state.productForm.newProduct.fields.floor_plans?.value?.array[currentFloor],
                                plan: res,
                                isUploaded: true
                            };
                            actions.productForm.setFloorPlan(tempObj);
                            actions.productForm.updateProductFields(['floor_plans']);
                            setEditMode(true);
                        });
                    }, 500);
                } else {
                    floorPlanRef.current.value = null;
                    setFileSizeErrorModal(true);
                }
            }
        } else {
            return;
        }
    }

    const handleDropFile = (e) => {
        e.preventDefault()
        setDragHover(false);
        floorPlanRef.current.files = e.dataTransfer.files;
        if(floorPlanRef.current.files.length > 0) {
            if(checkFileValid([floorPlanRef.current.files[0]], setFileNameErrorModal, true)){
                if(!checkFileSize(floorPlanRef.current.files[0])) {
                    setTimeout(() => {
                        let formData = new FormData();
                        formData.append('floorPlan', floorPlanRef.current.files[0]);
                        formData.append('floorId', state.productForm.newProduct.fields.floor_plans?.value?.array[currentFloor]?.name)
                        effects.productForm.uploadFloorPlan(state.productForm.newProduct.id, formData)
                        .then((res) => {
                            let tempObj = {
                                ...state.productForm.newProduct.fields.floor_plans?.value?.array[currentFloor],
                                plan: res,
                                isUploaded: true
                            };
                            actions.productForm.setFloorPlan(tempObj);
                            actions.productForm.updateProductFields(['floor_plans']);
                            setEditMode(true);
                        });
                    }, 500);
                } else {
                    floorPlanRef.current.value = null;
                    setFileSizeErrorModal(true);
                }
            }
        }
    }

    const handleTypeError = () => {
        setFileNameErrorModal(!fileNameErrorModal);
        batchfloorPlanRef.current.value = "";
        floorPlanRef.current.value = "";
    }

    const handleFileSizeError = () => {
        setFileSizeErrorModal(!fileSizeErrorModal);
        batchfloorPlanRef.current.value = "";
        floorPlanRef.current.value = "";
    }

    const handleBackClick = () => {
        actions.productForm.updateProductFields(['floor_plans']).then(() => {
            changeStep(steps - 1);
        });
    }

    const handleNextClick = () => {
        actions.productForm.updateProductFields(['floor_plans']).then(() => {
            validateForm(false);
            changeStep(steps + 1);
        });
    }

    const handleWarningConfirmClick = () => {
        if(warningType == 'clearFloorPlan') {
            handleRemoveFloorPlan(currentFloor);
            let tempArray = getActiveFloorUnits(state.units.list, currentFloor + 1);
            for (const item of tempArray) {
                let tempObj = {
                    points: [],
                    isFinished: false,
                }
                actions.units.setUnitPoints({updatedContents: tempObj, unit: item});
            }
            setWarningMessage(false);
            setWarningType('');
        } else {
            setWarningMessage(false);
            floorPlanRef.current.click();
        }
    }

    return(
        <div className="uploadFloorPlans__container">
            <div className="uploadFloorPlans__split-container">
                <div className="uploadFloorPlans__upload-container">
                    <FloorPlan 
                        currentFloorData={state.productForm.newProduct.fields.floor_plans?.value?.array}
                        editMode={editMode}
                        currentFloor={currentFloor}
                        setBatchUpload={setBatchUpload}
                        batchfloorPlanRef={batchfloorPlanRef}
                        handleBatchUploadChange={handleBatchUploadChange}
                        uploadNewFloorPlan={uploadNewFloorPlan}
                        handleUploadClick={handleUploadClick}
                        setDragHover={setDragHover}
                        dragHover={dragHover}
                        handleDropFile={handleDropFile}
                    />
                </div>
                <div className={`uploadFloorsPlans__floors-container ${typeof formErrors.floor_plans === 'string' ? '--error' : ''}`}>
                    <div className="uploadFloorPlans__button-error">
                        <Button type="primary" onClick={handleSaveFloorsAndExit}>Save floors and exit</Button>
                        {typeof formErrors.floor_plans === 'string' && <div className="uploadFloorPlans__error">{formErrors.floor_plans}</div>}
                    </div>
                    <div className="uploadFloorsPlans__floors-guide-container">
                        <div className="uploadFloorsPlans__floors-guide-title">Upload floor plans</div>
                        <div className="uploadFloorsPlans__floors-guide-info">Upload every bulding floor plans of the project</div>
                    </div>
                    <div className="uploadFloorsPlans__floors-items-container">
                        {
                            state.productForm.newProduct.fields.floor_plans?.value?.array?.map((el, index) => {
                                return(
                                    <FloorItems
                                        floorData={el}
                                        index={index}
                                        isActive={index == currentFloor}
                                        floorPlanRef={floorPlanRef}
                                        handleChange={handleChange}
                                        handleClearClick={handleClearClick}
                                        handleEditClick={handleEditClick}
                                        handleUploadClick={handleUploadClick} 
                                    />
                                )
                            })
                        }
                    </div>
                    <div className="uploadFloorsPlans__options-container">
                        <div className="uploadFloorsPlans__divider" />
                        <div className="uploadFloorsPlans__button-options">
                            <Button type="ternary" onClick={handleBackClick}>Back</Button>
                            <Button type="primary" onClick={handleNextClick}>Continue</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={batchUpload} setIsOpen={setBatchUpload} options={{contentCenter: true}}>
                <BatchUpload
                    uploadFilesRef={batchfloorPlanRef}
                    headerBannerType={{vsoForm: true}}
                    headerBannerCloseClick={setBatchUpload}
                    batchUploadClick={batchUploadClick}
                    saveClick={handleBatchUploadSave}
                    batchUploadText={batchUploadFloorPlanText}
                    handleBatchUploadDrop={handleBatchUploadDrop}
                    setDragHover={setDragHover}
                    dragHover={dragHover} 
                />
            </Modal>
            <Modal isOpen={fileNameErrorModal} setIsOpen={setFileNameErrorModal} options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage 
                    onClick={handleTypeError}
                    items={[
                        {content: <div>The file format for one or multiple uploaded floor plan is invalid.</div>},
                        {content: <div>Suported files include: .jpg, .jpeg, .png</div>}
                    ]}
                    errorCode={'1306'}
                />
            </Modal>
            <Modal isOpen={fileSizeErrorModal} setIsOpen={setFileSizeErrorModal} options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage 
                    onClick={handleFileSizeError}
                    items={[
                        {content: <div>The file size for one or multiple uploaded floor plan is too big.</div>},
                        {content: <div>File size should be less than 9MB</div> }
                    ]}
                    errorCode={'1305'}
                />
            </Modal>
            <Modal isOpen={warningMessage} setIsOpen={setWarningMessage}>
                <WarningMessage 
                    title="Are you sure you want to change the floor plan?"
                    infoMessage="Outline progress is going to be lost."
                    cancelClick={() => setWarningMessage(false)}
                    confirmClick={handleWarningConfirmClick}
                />
            </Modal>
        </div>
    )
}

export default UploadFloorPlans;