import { namespaced } from 'overmind/config';
import { createHook } from 'overmind-react';
import * as topbar from './topbar';
import * as sidebar from './sidebar';
import * as currentUser from './currentUser';
import * as projects from './projects';
import * as marketTests from './marketTests';
import * as marketTestForm from './marketTestForm';
import * as currentMarketTest from './currentMarketTest';
import * as listing from './listing';
import * as auditor from './auditor';
import * as currentVSO from './currentVSO';
import * as VSOs from './VSOs'
import * as vsoForm from './vsoForm';
import * as productForm from './productForm';
import * as vsoDashboard from './vsoDashboard';
import * as units from './units';

export const config = namespaced({
    topbar,
    sidebar,
    currentUser,
    projects,
    marketTests,
    marketTestForm,
    currentMarketTest,
    listing,
    auditor,
    currentVSO,
    VSOs,
    vsoForm,
    productForm,
    vsoDashboard,
    units
});


export const useOvermind = createHook();