import React, { useRef } from 'react';
import DrawingCanvas from 'src/components/shared/drawingCanvas';
import { useOvermind } from 'src/overmind';
import { useResizeContainer } from 'src/utils/hooks';
import './buildingViewFloorPlan.scss';

const BuildingViewFloorPlan = ({handleUnitClick}) => {

    const {state, actions} = useOvermind();
    const floorPlanRef = useRef(null);

    const {containerWidth, containerHeight} = useResizeContainer(floorPlanRef)

    return(
        <div ref={floorPlanRef} className="buildingViewFloorPlan__container">
            <DrawingCanvas 
                type="floor"
                editMode={false}
                isClosed={true}
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                imageUrl={state.currentVSO.fields?.floor_plans[state.currentVSO.currentFloor - 1]?.plan}
                data={state.currentVSO.sortedFloorUnitsList}
                activeFloor={state.currentVSO.currentFloor}
                setActiveFloor={actions.currentVSO.setCurrentFloor}
                handleUnitClick={handleUnitClick}
            />
        </div>
    )
}

export default BuildingViewFloorPlan;