import { pipe, mutate } from 'overmind';
import { putUnitsArray } from './effects';
import { mutateAndReturn, debounceUnitValuesChanges } from '../operators';

export const incrementFormStep = ({state}) => {
    let currentStep = state.productForm.formStep;
    currentStep++;
    state.productForm.formStep = currentStep;
}

export const decrementFormStep = ({state}) => {
    let currentStep = state.productForm.formStep;
    currentStep--;
    state.productForm.formStep = currentStep;
}

export const setCurrentFormStep = ({state}, step) => {
    state.productForm.formStep = step;
}

export const setStepCompletedFields = async ({state, actions}, completedFieldsObj) => {
    state.productForm.newProduct.fields.form_steps_data.value.array[completedFieldsObj.step - 1] = completedFieldsObj.completedFields;
    await actions.productForm.updateProductFields(['form_steps_data']);
}

export const clearProduct = ({state, actions}) => {
    actions.productForm.setUnitPlans([])
    actions.productForm.setReservationDays("");
    actions.productForm.setReservationDeposit("");
    actions.productForm.setContactInfoObj({title: "", firstName: "", lastName: "", email: "", number: ""})
    state.productForm.newProduct.id = '';
    state.productForm.newProduct.project_id = '';
    state.productForm.newProduct.fields = {form_steps_data: {value: {array: [0, 0, 0, 0, 0]}}};
    state.productForm.formStep = state.productForm.firstFormStep;
    actions.productForm.clearFormType();
    actions.productForm.setPlansUploaded(false);
}

export const clearFormType = ({state}) => {
    state.productForm.formType = "";
}

export const setProductType = ({state}, type) => {
    state.productForm.formType = type;
}

export const setProductId = ({state}, id) => {
    state.productForm.newProduct.id = id
}

export const createProduct = async ({state, effects}) => {
    if(state.productForm.formType === 'mt') {
        const marketTestId = await effects.productForm.postMarketTest(state.currentUser.userId);
        state.productForm.newProduct.id = marketTestId;
    } else if(state.productForm.formType === 'vso') {
        const vso = await effects.productForm.postVso(state.currentUser.userId);
        state.productForm.newProduct.id = vso.virtual_sales_office_id;
        state.productForm.newProduct.project_id = vso.project_id;
    }
}

export const updateProductFields = async ({state, effects}, fieldIds) => {
    let fields = {};
    let index = 0;
    if(state.productForm.formType === 'mt') {
        for(const contentId of fieldIds) {
            fields[index] = {
                contentId: contentId,
                value: state.productForm.newProduct.fields[contentId]?.value
            }
            index++;
        }
        const updatedFieldsMt = await effects.productForm.putMarketTestFields(state.productForm.newProduct.id, fields);

    }else if(state.productForm.formType === 'vso') {
        for(const contentId of fieldIds) {
            fields[index] = {
                contentId: contentId,
                value: state.productForm.newProduct.fields[contentId]?.value
            }
            index++;
        }
        const updatedFieldsVso = await effects.productForm.putVsoFields(state.productForm.newProduct.id, fields);
    }
}

export const setProductField = ({state}, field) => {
    state.productForm.newProduct.fields[field.fieldId] = {
        ...state.productForm.newProduct.fields[field.fieldId],
        value: field.value
    }
}

export const pushToFieldArray = ({state}, {fieldId, value}) => {
    state.productForm.newProduct.fields[fieldId].value.array.push(value);
    state.productForm.newProduct.fields[fieldId].value.array.sort(function(a, b){
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    })
}

export const removeFromFieldArray = ({state}, {fieldId, index}) => {
    state.productForm.newProduct.fields[fieldId].value.array.splice(index, 1);
}

export const loadNewProduct = async ({state, effects}) => {
    try { 
        if(state.productForm.formType === "mt") {
            let fieldsObj = {}
            const marketTestFields = await effects.marketTests.fetchMarketTestFields(state.productForm.newProduct.id);
            for(const f of marketTestFields) {
                if(f.field_value != null) {
                    fieldsObj[f.field_content_id] = 
                    {
                        value: f.field_value.includes('{"') ? JSON.parse(f.field_value) : f.field_value === "true" ? true : f.field_value === "false" ? false : f.field_value,
                        comment: f.auditor_comment,
                        status: f.status,
                    }
                }
            }
            state.productForm.newProduct.fields = fieldsObj;
        } else if (state.productForm.formType === "vso") {
            let fieldsObj = {
                form_steps_data: {value: {array: [0, 0, 0, 0, 0]}},
                project_name: {value: ''},
                project_address: {
                    value: {
                        coords: {lat: null, lng: null},
                        full_string: null,
                        address_components: null
                    }
                },
                project_logo: {value: {array: []}},
                project_type: {value: ''},
                unit_type: {value: ''},
                num_buildings: {value: ''},
                num_floors: {value: ''},
                construction_type: {value: ''},
                project_status: {value: ''},
                est_start_date: {value: ''},
                est_delivery_date: {value: ''},
                lease_duration: {value: ''},
                images: {value:  { array: [] }},
                units_furnishing: {value: ''},
                units_appliances: {value: {array: []}},
                units_furnitures: {value: {array: []}},
                units_utilities: {value: {array: []}},
                units_flooring: {value: {array: []}},
                units_amenities: {value: {array: []}},
                units_general: {value: {array: []}},
                units_heating_system: {value: {array: []}},
                units_air_conditioning: {value: {array: []}},
                units_ceiling_min: {value: ''},
                units_ceiling_max: {value: ''},
                kitchen_backsplash: {value: {array: []}},
                kitchen_cabinet: {value: {array: []}},
                kitchen_sink_basin: {value: {array: []}},
                kitchen_sink_faucet: {value: {array: []}},
                kitchen_countertops: {value: {array: []}},
                kitchen_storage: {value: {array: []}},
                kitchen_inclusions: {value: {array: []}},
                kitchen_countertop_thickness: {value: ''},
                bathroom_shower_shape: {value: {array: []}},
                bathroom_shower_materials: {value: {array: []}},
                bathroom_shower_door: {value: {array: []}},
                bathroom_shower_head: {value: {array: []}},
                bathroom_bathtub: {value: {array: []}},
                bathroom_countertops: {value: {array: []}},
                bathroom_sink_basin: {value: {array: []}},
                bathroom_sink_shape: {value: {array: []}},
                bathroom_sink_materials: {value: {array: []}},
                bathroom_cabinet: {value: {array: []}},
                bathroom_vanity_materials: {value: {array: []}},
                bathroom_backsplash: {value: {array: []}},
                bathroom_toilet_type: {value: {array: []}},
                bathroom_toilet_style: {value: {array: []}},
                bathroom_toilet_bidet: {value: {array: []}},
                common_spaces: {value: {array: []}}, 
                project_amenities: {value: {array: []}},
                parking_type: {value: ''},
                parking_price_included: {value: ''}, 
                extra_parking_fee_type: {value: ''}, 
                extra_int_fee: {value: ''},
                extra_ext_fee: {value: ''},
                electric_parking: {value: ''},
                electric_parking_price: {value: ''}, 
                visitor_parking: {value: ''}, 
                street_parking: {value: ''},
                parking_with_permit: {value: ''},
                locker_price_included: {value: ''}, 
                extra_locker_fee_type: {value: ''},
                extra_locker_fee: {value: ''}, 
                structure: {value: {array: []}}, 
                exterior_features: {value: {array: []}},
                roof: {value: {array: []}}, 
                leed: {value: ''}, 
                heritage_building: {value: ''}, 
                policies_dog_tolerance: {value: ''},
                policies_dog_weigth: {value: ''},
                policies_cat_tolerance: {value: ''},
                policies_smoking_tolerance: {value: ''},
                policies_bbq_tolerance: {value: ''},
                policies_credit_check: {value: ''},
                policies_criminal_check: {value: ''},
                documents: {value: {array: []}},
                pricing_display_type: {value: ''},
                buildingView_image: {value: ''},
                buildingView_floors: {value: { array: [] }},
                floor_plans: {value: { array: [] }},
            }
            const vsoFields = await effects.VSOs.fetchVsoFields(state.productForm.newProduct.id);
            for(const f of vsoFields) {
                if(f.field_value != null) {
                    fieldsObj[f.field_content_id] = 
                    {
                        value: f.field_value.includes('{"') ? JSON.parse(f.field_value) : f.field_value === "true" ? true : f.field_value === "false" ? false : f.field_value,
                        comment: f.auditor_comment,
                        status: f.status,
                    }
                }
            }
            const vso = await effects.VSOs.fetchVso(state.productForm.newProduct.id);
            state.productForm.newProduct.fields = fieldsObj;
            state.productForm.newProduct.id = vso.virtual_sales_office_id;
            state.productForm.newProduct.project_id = vso.project_id;
            state.productForm.newProduct.show_prices = vso.show_prices;
        }
    } catch (err) {
        console.error(err.message);
        return err
    }
}

export const setShowPrices = ({state, effects}, newValue) => {
    state.productForm.newProduct.show_prices = newValue;
    effects.productForm.putShowPrices(state.productForm.newProduct.id, newValue);
}

export const setEnableReservations = ({state, effects}, newValue) => {
    state.productForm.newProduct.enable_reservations = newValue;
    effects.productForm.putEnableReservations(state.productForm.newProduct.id, newValue);
}

/*Units Details*/
export const decrementUnitsNumber = ({state}, startNumber = 0) => {
    state.productForm.unitDetails.units.forEach(unit => {
        if(unit.unit_number.toString().slice(0, 1) === state.productForm.unitDetails.activeFloor.toString()) {
            if(Number(unit.unit_number) >= startNumber) {
                unit.unit_number = Number(unit.unit_number) - 1;
            }
        }
    });
}

export const setActiveFloor = ({state}, floor) => {
    state.productForm.unitDetails.activeFloor = floor
}

export const setUnitValue = ({state}, {unitId, valueKey, newValue, isCustom}) => {
    let unitIndex = state.productForm.unitDetails.units.findIndex(e => e.unit_id == unitId);
    state.productForm.unitDetails.units[unitIndex][valueKey] = newValue;

    if(isCustom) {
        let originalKey = valueKey.split('_')[1];
        state.productForm.unitDetails.units[unitIndex]['extra_content'][originalKey] = newValue
        return {unitId: unitId, valueKey: 'extra_content', newValue: JSON.stringify(state.productForm.unitDetails.units[unitIndex]['extra_content']), isCustom: isCustom};
    } else {
        return {unitId: unitId, valueKey: valueKey, newValue: newValue};
    }
}

export const addUnit = async ({state, actions, effects}) => {
    let highestNumberOnFloor = 0;

    state.productForm.unitDetails.units.forEach((unit) => {
        if(unit.unit_number.toString().slice(0, 1) === state.productForm.unitDetails.activeFloor.toString()) {
            if(Number(unit.unit_number) > highestNumberOnFloor) {
                highestNumberOnFloor = Number(unit.unit_number);
            }
        }
    });
    if(highestNumberOnFloor === 0) {
        highestNumberOnFloor = Number(state.productForm.unitDetails.activeFloor.toString() + '00');
    }

    const newUnit =  await effects.productForm.createUnit(state.productForm.newProduct.project_id, (highestNumberOnFloor + 1));
    state.productForm.unitDetails.units.push(newUnit);
    actions.productForm.loadUnits();
}

export const addMultiUnits = async ({state, actions, effects}, numberOfUnits) => {
    let multipleUnitsInfo = await effects.productForm.createMultipleUnits(state.productForm.newProduct.project_id, numberOfUnits, state.productForm.unitDetails.selectedVSOsortedFloorList, state.productForm.unitDetails.activeFloor);
    actions.productForm.loadUnits();
}

export const removeUnit = async ({state, actions, effects}, unitId) => {
    const res = await effects.productForm.deleteUnit(unitId);
    if(res == unitId) {
        let unitIndex = state.productForm.unitDetails.units.findIndex(e => e.unit_id == unitId);
        let unitNumber = state.productForm.unitDetails.units[unitIndex].unit_number;
        state.productForm.unitDetails.units.splice(unitIndex, 1);
        decrementUnitsNumber({state: state}, unitNumber);
    }
    actions.productForm.loadUnits();
}

export const changeUnitsOrder = ({state}, {oldPos, newPos}) => {
    const oldNumber = state.productForm.unitDetails.activeFloor + ('0' + (Number(oldPos) + 1)).slice(-2);
    const newNumber = state.productForm.unitDetails.activeFloor + ('0' + (Number(newPos) + 1)).slice(-2);
    const oldIndex = state.productForm.unitDetails.units.findIndex(unit => unit.unit_number == oldNumber);
    if(oldIndex === -1) {
        return;
    }
    const oldId = state.productForm.unitDetails.units[oldIndex].unit_id;
    
    state.productForm.unitDetails.units[oldIndex].unit_number = newNumber;
    let modifiedUnits = [state.productForm.unitDetails.units[oldIndex]];
    
    if(newNumber > oldNumber) {
        state.productForm.unitDetails.units.forEach((unit) => {
            if(unit.unit_number <= newNumber && unit.unit_number > oldNumber && unit.unit_id !== oldId) {
                unit.unit_number = Number(unit.unit_number) - 1;
                modifiedUnits.push(unit);
            }
        });
    } else if(newNumber < oldNumber) {
        state.productForm.unitDetails.units.forEach((unit) => {
            if(unit.unit_number >= newNumber && unit.unit_number < oldNumber  && unit.unit_id !== oldId) {
                unit.unit_number = Number(unit.unit_number) + 1;
                modifiedUnits.push(unit);
            }
        });
    }

    return JSON.stringify(modifiedUnits); 
}

export const loadUnits = async ({state, effects}) => {
    state.productForm.unitDetails.units = await effects.productForm.getUnits(state.productForm.newProduct.id);
    state.productForm.unitDetails.units.forEach( async (unit) => {
        unit.extra_content = await JSON.parse(unit.extra_content);
        if(Object.keys(unit.extra_content).length > 0) { 
            let keys = Object.keys(unit.extra_content);
            let values = Object.values(unit.extra_content);
            keys.forEach((key, index) => {
                unit[`extraContent_${key}`] = values[index]; 
            });
        }
    });

    state.productForm.unitDetails.units.forEach( async (unit) => {
        unit.outline_content = await JSON.parse(unit.outline_content);
    });
}

export const clearUnits = ({state}) => {
    state.productForm.unitDetails = {};
}

export const getOptionalColumns = async ({state, effects}) => {
    let results = await effects.productForm.getOptionalColumnsData(state.productForm.newProduct.id);
    if(JSON.parse(results.units_columns)?.length > 0) { 
        state.productForm.unitDetails.optionalColumns = JSON.parse(results.units_columns);
    }
}

export const setOptionalColumns = ({state}, obj) => {
    let index = state.productForm.unitDetails?.optionalColumns.findIndex((el) => el.isCustom ? el.name.en === obj.name : el.name === obj.name);
    state.productForm.unitDetails.optionalColumns[index]["isShown"] = obj.isShown; 
}

/* Sends optional column data when pressing "next" in the productForm */
export const sendOptionalColumnsData = async ({state, effects}) => {
    let results = await effects.productForm.sendOptionalColumnsData(state.productForm.newProduct.id, state.productForm.unitDetails.optionalColumns);
    if(JSON.parse(results.units_columns).length > 0) {
        state.productForm.unitDetails.optionalColumns = JSON.parse(results.units_columns);
    }
}

export const addColumnsData = async ({state, actions, effects}, customColumnObj) => {
    state.productForm.unitDetails.optionalColumns.push(customColumnObj.extraColumnObj);
    let results = await effects.productForm.setOptionalColumnsData(state.productForm.newProduct.id, state.productForm.unitDetails.optionalColumns, customColumnObj.extraContentObj);
    if(JSON.parse(results.units_columns).length > 0) {
        state.productForm.unitDetails.optionalColumns = JSON.parse(results.units_columns);
    }
    actions.productForm.loadUnits();
}

export const editColumnData = async ({state, actions, effects}, columnDataObj) => {
    let results = await effects.productForm.setEditColumnData(state.productForm.newProduct.id, columnDataObj);
    if(JSON.parse(results.units_columns).length > 0) {
        state.productForm.unitDetails.optionalColumns = JSON.parse(results.units_columns);
    }
}

export const removeCustomColumn = async ({state, effects}, element) => {
    let removedResults = await effects.productForm.removeCustomColumn(state.productForm.newProduct.id, element);
    state.productForm.unitDetails.optionalColumns = await JSON.parse(removedResults.units_columns);
}

/* Unit Plans */
export const setUnitPlans = ({state}, data) => {
    state.productForm.unitPlans = data
}

export const setPlansUploaded = ({state}, value) => {
    state.productForm.isPlansUploaded = value;
}

export const createUnitPlans = ({state, effects}, data) => {
    if(state.productForm.unitPlans.length > 0){
        effects.productForm.uploadUnitPlans(state.productForm.newProduct.project_id, data);
    }
}

export const getUnitPlans = async ({state, actions, effects}) => {
    const tempPlans = await effects.productForm.getUnitPlans(state.productForm.newProduct.project_id);
    let unitPlansArr = []
    let tempObj = {}
    tempPlans?.map((element) => {
        if(element.plan !== null && element.plan !== ""){
           tempObj = {}
           tempObj.src = element.plan;
           tempObj.name = "Unit " + element.unit_number;
           unitPlansArr.push(tempObj);
        }
    })
    actions.productForm.setUnitPlans(unitPlansArr);
}

export const uploadUnitPlan = async ({state, effects}, {unitId, formData}) => {
    const planURL = await effects.VSOs.putUnitPlan(unitId, formData);
    if(planURL) {
        let unitIndex = state.productForm.unitDetails.units.findIndex(e => e.unit_id == unitId);
        state.productForm.unitDetails.units[unitIndex].plan = planURL;
    }
}

export const removePlan = async ({state, effects}, unitId) => {
    const res = await effects.VSOs.deleteUnitPlan(unitId);
    if(res == unitId) {
        let unitIndex = state.productForm.unitDetails.units.findIndex(e => e.unit_id == unitId);
        state.productForm.unitDetails.units[unitIndex].plan = null;
    }
}

/* Project Logo */

export const removeProjectLogo = async ({state, actions, effects}) => {
    const res = await effects.productForm.removeImageVso(state.productForm.newProduct.id, 'project_logo')
    actions.productForm.setProductField( 
        {
            fieldId: 'project_logo',
            value: {array: []}
        }
    );
}

/* Project Images */ 
export const setProjectImages = ({state}, data) => {
    // state.productForm.projectImages = data;
    state.productForm.newProduct.fields.images.value.array = data;
}


export const updateProjectImages = async ({state, effects}, formData) => {
    if(state.productForm.newProduct.fields.images.value.array.length > 0) {
        let response =  await effects.productForm.uploadProjectImages(state.productForm.newProduct.id, 'images', formData)
        let imageArray = JSON.parse(response.field_value);
        state.productForm.newProduct.fields.images.value = imageArray;
        return response;
    }
}

export const removeProjectImage = async ({state, actions, effects}, image) => {
    let imageIndex = state.productForm.newProduct.fields.images.value.array.findIndex(el => el.id == image.id)
    state.productForm.newProduct.fields.images.value.array.splice(imageIndex, 1);
    state.productForm.newProduct.fields.images.value.array.forEach((element, index) => {
        element.id = index + 1;
    });
    if(image.publicId != '') {
        let response = await effects.productForm.removeProjectImage(state.productForm.newProduct.id, 'images', image);
        if(response) {
            actions.productForm.updateProductFields(['images']);
        }
    }
}

export const copyTable = async ({state, actions, effects}, copyTableInfo) => {
    const copiedResults = await effects.productForm.copyTable(state.productForm.newProduct.project_id, copyTableInfo)
    if(copiedResults.length > 0) { 
        actions.productForm.loadUnits();
        return copiedResults;
    }
}

/* Building View */

export const loadField = async ({state, effects}, fieldContent) => {
    const result = await effects.productForm.loadField(fieldContent, state.productForm.newProduct.project_id);
    if(result) {
        let parsedResults = JSON.parse(result.field_value);
        state.productForm.newProduct.fields.images.value = parsedResults;
    }
}

export const setBuildingViewImage = ({state}, image) => {
    state.productForm.newProduct.fields.buildingView_image.value = image;
}

export const initializeBuildingFloors = ({state}, data) => {
    state.productForm.newProduct.fields.buildingView_floors.value.array = data; 
}

export const setBuildingViewFloor = ({state}, data) => {
    let tempArray = [
                        ...state.productForm.newProduct.fields.buildingView_floors.value.array.slice(0, data.id),
                        Object.assign({}, state.productForm.newProduct.fields.buildingView_floors.value.array[data.id], {
                            id: data.id,
                            points: data.points,
                            isFinished: data.isFinished,
                        }),
                        ...state.productForm.newProduct.fields.buildingView_floors.value.array.slice(data.id + 1)
                    ]

    state.productForm.newProduct.fields.buildingView_floors.value.array = tempArray;
}

export const deleteBuildingViewFloor = ({state}) => {
    if(state.productForm.newProduct.fields.buildingView_floors != null) {
        state.productForm.newProduct.fields.buildingView_floors.value.array = [];
    }
}

export const initializeFloorPlans = ({state}, data) => {
    state.productForm.newProduct.fields.floor_plans.value.array = data;
}

export const setFloorPlan = ({state}, data) => {
    let tempArray = [
        ...state.productForm.newProduct.fields.floor_plans.value.array.slice(0, data.id),
        Object.assign({}, state.productForm.newProduct.fields.floor_plans.value.array[data.id], {
            ...data
        }),
        ...state.productForm.newProduct.fields.floor_plans.value.array.slice(data.id + 1)
    ]
    state.productForm.newProduct.fields.floor_plans.value.array = tempArray;
}

export const setUnitPoints = async ({state, effects, actions}, data) => {
   let response = await effects.productForm.setUnitPoints(data)
   if(response) {
       actions.productForm.loadUnits();
   }
}

export const copyOutlineFloors = async ({state, effects, actions}, data) => {
    let sortedChosenFloorsList = [] 
    let selectedFloorList = state.productForm.unitDetails.units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == data.selectedFloor : unit.unit_number.toString().slice(0, 2) == data.selectedFloor)
        .sort((unitA, unitB) => {
        if(unitA.unit_number > unitB.unit_number) {
        return 1;
        } else if(unitA.unit_number < unitB.unit_number) {
        return -1;
        }
        return 0;
        });

    for (const item of data.floorsArr) {
        let tempArr = state.productForm.unitDetails.units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == item : unit.unit_number.toString().slice(0, 2) == item)
            .sort((unitA, unitB) => {
                if(unitA.unit_number > unitB.unit_number) {
                    return 1;
                } else if(unitA.unit_number < unitB.unit_number) {
                    return -1;
                }
                return 0;
            });
        sortedChosenFloorsList.push(...tempArr);
    }

    let tempObj = {
        sortedChosenFloorsList : sortedChosenFloorsList ,
        selectedFloorList: selectedFloorList
    }

    let response = await effects.productForm.setCopyOutlineData(state.productForm.newProduct.project_id, tempObj);
    if(response) {
        actions.productForm.loadUnits();
    }
}

/*Project Documents */
export const setProjectDocuments = ({state}, data) => {
    state.productForm.newProduct.fields.documents.value.array = data;
}

export const updateProjectDocuments = async ({state, effects}, formData) => {
    if(state.productForm.newProduct.fields.documents.value.array.length > 0) {
        let response = await effects.productForm.uploadProjectDocuments(state.productForm.newProduct.id, 'documents', formData)
        let documentsArr = JSON.parse(response.field_value);
        state.productForm.newProduct.fields.documents.value = documentsArr;
        return response;
    }
}

export const removeProjectDocument = async ({state, actions, effects}, document) => {
    let documentIndex = state.productForm.newProduct.fields.documents.value.array.findIndex(el => el.id == document.id)
    state.productForm.newProduct.fields.documents.value.array.splice(documentIndex, 1);
    state.productForm.newProduct.fields.documents.value.array.forEach((element, index) => {
        element.id = index + 1;
    });
    if(document.publicId != '') {
        let response = await effects.productForm.removeProjectDocument(state.productForm.newProduct.id, 'documents', document);
        if(response) {
            actions.productForm.updateProductFields(['documents']);
        }
    }
}

/* Reservation */
export const setReservationDays = ({state}, value) => {
    state.productForm.reservationDays.value = value;
}

export const setReservationDeposit = ({state}, value) => {
    state.productForm.reservationDeposit.value = value;
}

export const getReservationInfo = async ({state, actions, effects}) => {
    const reservationDataArr = await effects.productForm.getReservationInfo(state.productForm.newProduct.id);
    actions.productForm.setReservationDays(reservationDataArr.reservation_time);
    actions.productForm.setReservationDeposit(reservationDataArr.deposit_amount);
}

export const updateReservationDays = async ({state, effects}) => {
    if(state.productForm.reservationDays?.value){
        const reservationDays = await effects.productForm.putReservationDays(state.productForm.newProduct.id, state.productForm.reservationDays?.value)
    }
}

export const updateReservationDeposit = async ({state, effects}) => {
    if(state.productForm.reservationDeposit?.value){
        const reservationDeposit = await effects.productForm.putReservationDeposit(state.productForm.newProduct.id, state.productForm.reservationDeposit?.value)
    }
}

/* Contact Info */

export const setContactInfoValue = ({state}, {key, value}) => {
    state.productForm.contactInfo[key] = value;
}

export const setContactInfoObj = ({state}, obj) => {
    let defaultVals = {
        title: "",
        firstName: "",
        lastName: "",
        number: "",
        email: "",
        booking_link: "",
    }
    state.productForm.contactInfo = {
        ...defaultVals, 
        ...obj
    };
}

export const getContactInfo = async ({state, actions, effects}) => {
    let contactInfo = await effects.productForm.getContactInfo(state.productForm.newProduct.id);
    contactInfo = JSON.parse(contactInfo);
    actions.productForm.setContactInfoObj(contactInfo);
}

export const sendContactInfo = async ({state, effects}) => {
    let contactInfoObj = {
        title: state.productForm.contactInfo.title,
        firstName: state.productForm.contactInfo.firstName,
        lastName: state.productForm.contactInfo.lastName,
        email: state.productForm.contactInfo.email,
        number: state.productForm.contactInfo.number,
        booking_link: state.productForm.contactInfo.booking_link
    }
    const contactInfo = await effects.productForm.putContactInfo(state.productForm.newProduct.id, contactInfoObj);
}

/* Virtual Sales office Status */

export const changeStatus = async ({state, effects}, status) => {
    let currentStatus = await effects.productForm.changeStatus(state.productForm.newProduct.id, status)
}

export const refreshUnits = ({state}, units) => {
    for(const unit of units) {
        let unitIndex = state.VSOs.selectedVSO.units.findIndex(e => e.unit_id == unit.unitId);
        state.VSOs.list[state.VSOs.selectedVSOid].units[unitIndex] = unit;
    }
}

export const saveUnitValue = pipe(
    mutateAndReturn(setUnitValue),
    debounceUnitValuesChanges(2000),
    putUnitsArray,
    // filter((_, value) => {console.log('filter', value);return Array.isArray(value)}),
    mutate(refreshUnits)
);

export const saveUnitsOrder = pipe(
    mutateAndReturn(changeUnitsOrder),
    putUnitsArray,
    // filter((_, value) => Array.isArray(value)),
    mutate(refreshUnits)
)