import React from 'react';
import './formGroup.scss';

const FormGroup = ({title, description, fields}) => {
    return(
        <div className="formGroup__container">
            <div className="formGroup__title-desc-container">
                <div className="formGroup__title">{title}</div>
                <div className="formGroup__desc">
                    {Array.isArray(description) ? 
                        description.map((el) => {
                            return(el);
                        })
                        :
                        description
                    }
                </div>
            </div>
            <div className="formGroup__fields-container">
                {fields.map((el) => {
                    if(Array.isArray(el)) {
                       return(
                           <div className="formGroup__fields-row">
                                {el.map((element) => {
                                    return(element);    
                                })}
                            </div>
                        );
                    } else {
                        return(el);
                    }
                })}
            </div>
        </div>
    )
}

export default FormGroup;