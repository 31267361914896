import React from 'react';
import ReactDOM from 'react-dom';

import './i18n/config';

import { Provider } from 'overmind-react';
import { createOvermind } from 'overmind';
import { config } from './overmind';

import App from './components/app';

import './normalize.css';
import './scss/main.scss';

const overmind = createOvermind(config,
  {
    devtools: true,
  });


ReactDOM.render(
  <React.StrictMode>
    <Provider value={overmind}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
