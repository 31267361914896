import React from 'react';
import DatePicker from '../../datePicker';
import SvgIcons from '../../svgIcons';
import NumberInput from '../tableInputs/numberInput';
import RadioInput from '../tableInputs/radioInput';
import SelectInput from '../tableInputs/selectInput';
import TextInput from '../tableInputs/textInput';
import { useTranslation } from 'react-i18next';

import './cell.scss';

const Cell = ({id, content, prefix, suffix, isFixed = false, editData = false, isCustom}) => {
    const { t } = useTranslation('unitTable');

    const checkUnitStatus = (status) => {
        switch (status) {
            case 'available':
                return <SvgIcons type='cabana_unit_available_icon' />
            case 'reserved':
                return <SvgIcons type='cabana_unit_reserved_icon' />
            case 'sold':
                return <SvgIcons type='cabana_unit_sold_icon' />
            case 'rented':
                return <SvgIcons type='cabana_unit_sold_icon' />
            default:
                break;
        }
    }

    let contentMarkup;

    if(!editData) {
        if(content === '' || content === null) {
            contentMarkup = '-';
        } else if(!isNaN(content)) {
            contentMarkup = parseInt(content).toLocaleString();
        } else if(content == 'available' || content == 'reserved' || content == 'canceled' || content == 'sold' || content == 'rented' || content == 'notAvailable') {
            contentMarkup = 
                <div className="unitsTable__cell-status">
                    {checkUnitStatus(content)} {t(`selectOptions.${content}`)}
                </div>
        } else {
            if(id != 'unit_number' && id != 'plan' && id != 'sqft' && id != 'price' && !isCustom) {
                contentMarkup = t(`selectOptions.${content}`);
            } else {
                contentMarkup = content;
            }
        }
    } else {
        if(editData.type === 'number' || editData.type === 'price' || editData.type === "dimension") {
            contentMarkup = 
                <NumberInput value={content} setValue={editData.setValue} suffix={suffix}
                dataObj={{unitId: editData.unitId, valueKey: editData.valueKey, isCustom: editData.isCustom}} />
        } else if(editData.type === 'text') {
            contentMarkup = 
                <TextInput value={content} setValue={editData.setValue} 
                dataObj={{unitId: editData.unitId, valueKey: editData.valueKey, isCustom: editData.isCustom}} />
        } else if(editData.type === 'select') {
            contentMarkup = 
                <SelectInput value={content} setValue={editData.setValue} 
                dataObj={{unitId: editData.unitId, valueKey: editData.valueKey, isCustom: editData.isCustom}}
                selectOptions={editData.options} translationsNamespace={editData.translationsNamespace}/>
        } else if(editData.type === 'radio') {
            contentMarkup = 
                <RadioInput value={content} setValue={editData.setValue} 
                dataObj={{unitId: editData.unitId, valueKey: editData.valueKey, isCustom: editData.isCustom}} 
                radioOptions={editData.options} translationsNamespace={editData.translationsNamespace}/>
        } else if (editData.type === 'date') {
            contentMarkup = 
                <DatePicker value={content} setValue={editData.setValue} placeholder={"Select date"} 
                options={{isVso: true, isUnitTable: true}} 
                dataObj={{unitId: editData.unitId, valueKey: editData.valueKey, isCustom: editData.isCustom}}/>
        }
    }

    return (
        <div className={`cell${isFixed ? ' --fixed' : ''}${editData ? ' --editMode' : ''}`}>
            <div>{!editData ? prefix : ''}{contentMarkup}{!editData ? suffix : ''}</div>
        </div>
    );
}

export default Cell;