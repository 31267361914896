import { useState, useEffect } from 'react';
import React from 'react';
import './app.css';
import { BrowserRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useOvermind } from '../../overmind';
import Topbar from '../topbar';
import Sidebar from '../sidebar';
import Signup from '../signup';
import Login from '../login';
import Dashboard from '../dashboard';
import Projects from '../projects';
import MarketTests from '../marketTests';
import MarketTestForm from '../marketTests/marketTestForm';
import Listing from '../listing';
import MarketTest from '../marketTest';
import AuditorMarketTest from '../auditorMarketTest';
import SalesOffice from '../salesOffice';
import VSOs from '../VSOs';
import VsoDashboard from '../vsoDashboard';
import ProductForm from '../productForm';
import ReservationConfirmation from '../salesOffice/reservationForm/steps/reservationConfirmation';

const App = () => {
  const { state } = useOvermind();
  const [isAuth, setIsAuth] = useState(false);

  const setAuth = (boolean) => {
    setIsAuth(boolean);
  };

  useEffect(() => {
    setAuth(localStorage.isAuth);
  }, [localStorage.isAuth]);

  /*
  let location = useLocation();

  React.useEffect(() => {
    console.log(location);
  }, [location]);
  */

  return (
    <>
    <BrowserRouter>
      <div className={"main-container main-container--dashboard"} style={{height: state.listing.marketTest.id == '' ? '100%' : 'fit-content'}}>
      {/* { isAuth && (state.currentUser.type === "developer" || state.currentUser.type === "tester") && state.sidebar.isShown ? <Sidebar /> : <></>} */}
        <Topbar setAuth={setAuth} isAuth={isAuth}/>
        <Switch>
          {/* <Route exact path="/" render={ props => isAuth ? <Redirect to="/dashboard" /> : <Redirect to="/login" />} /> */}
          <Route exact path="/" render={ props => isAuth ? <Redirect to="/virtual-office-list" /> : <Redirect to="/login" />} />
          <Route exact path="/login" render={ props => !isAuth ? <Login {...props} setAuth={setAuth} /> : <Redirect to="/dashboard" />} />
          <Route exact path="/signup" render={ props => !isAuth ? <Signup {...props} setAuth={setAuth} /> : <Redirect to="/dashboard" />} />
          {/* <Route exact path="/dashboard" render={ props => isAuth ? <Dashboard {...props} setAuth={setAuth} /> : <Redirect to="/login" />} /> */}
          <Route exact path="/dashboard" render={ props => isAuth ? <Redirect to="/virtual-office-list" /> : <Redirect to="/login" />} />
          {/* <Route exact path="/projects" render={ props => isAuth ? <Projects {...props} setAuth={setAuth} /> : <Redirect to="/login" />} /> */}
          {/* <Route exact path="/market-tests" render={ props => isAuth ? <MarketTests {...props} setAuth={setAuth} /> : <Redirect to="/login" />} /> */}
          {/* <Route exact path="/start-market-test/:id?" render={ props => isAuth ? <MarketTestForm {...props} setAuth={setAuth} /> : <Redirect to="/login" />} /> */}
          {/* <Route exact path="/listing/:id"><Listing/></Route> */}
          {/* <Route exact path="/listing/"><Listing/></Route> */}
          <Route exact path="/start-project-vso/:id?" render={ props => isAuth ? <ProductForm {...props} setAuth={setAuth} /> : <Redirect to="/login" />} />
          <Route path="/virtual-sales-office/:id"><SalesOffice/></Route>
          {/* <Route exact path="/:id/reservation-complete"><ReservationConfirmation /></Route> */}
          <Route exact path="/virtual-office-list/" render={ props => isAuth ? <VSOs {...props} setAuth={setAuth} /> : <Redirect to="/login" />}/>
          {/* <Route exact path="/vso-dashboard/:id" render={ props => isAuth ? <VsoDashboard {...props} setAuth={setAuth} /> : <Redirect to="/login" />}/> */}
          <Route path="/vso/:id" render={ props => isAuth ? <VsoDashboard {...props} setAuth={setAuth} /> : <Redirect to="/login" />} />
          {/* <Route exact path="/market-test/:id" render={ props => isAuth ? <MarketTest {...props} setAuth={setAuth} /> : <Redirect to="/login" />} /> */}
          {/* <Route exact path="/auditor-market-test/:id" render={ props => isAuth ? <AuditorMarketTest {...props} setAuth={setAuth} /> : <Redirect to="/login" />} /> */}
          {/* <Route exact path="/pricing-guide" render={ props => isAuth ? <div {...props} setAuth={setAuth}/> : <Redirect to="/login"/>}/> */}
          {/* <Route exact path="/payments" render={ props => isAuth ? <div {...props} setAuth={setAuth}/> : <Redirect to="/login"/>}/> */}
          {/* <Route exact path="/settings" render={ props => isAuth ? <div {...props} setAuth={setAuth}/> : <Redirect to="/login"/>}/> */}
          {/* <Route exact path="/support" render={ props => isAuth ? <div {...props} setAuth={setAuth}/> : <Redirect to="/login"/>}/> */}
        </Switch>
      </div>
    </BrowserRouter>
    </>
  );
}

export default App;
