import React, {useState, useEffect} from 'react';
import MultiSelect from 'src/components/shared/inputs/multiSelect';
import SelectInput from 'src/components/shared/inputs/selectInput';
import { useOvermind } from 'src/overmind';
import SvgIcons from 'src/components/shared/svgIcons'
import SubSteps from 'src/components/shared/subSteps';
import Button from 'src/components/shared/button';
import './duplicateFloorMenu.css';

const DuplicateFloorMenu = ({setIsOpen}) => {
const { state, actions } = useOvermind();
const [subStep, setSubStep] = useState(1);
const [selectedFloor, setSelectedFloor] = useState(1);
const [selectedFloorsArray, setSelectedFloorsArray] = useState([])

useEffect(() => {
    let selectFloor = getSelectOptions();
    setSelectedFloor(selectFloor[0][0]);
}, [])

useEffect(() => {

}, [selectedFloor])

const getSelectOptions = () => {
    let selectedFloorArr = [];
    for (let i = 1; i <= parseInt(state.productForm.newProduct.fields.num_floors?.value); i++) { 
        let tempArr = Array.from(state.units.list.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == i : unit.unit_number.toString().slice(0, 2) == i))
        if(tempArr.length > 0){
            selectedFloorArr.push([i, getFloorWord(i)])
        }
    }
    return selectedFloorArr;
}

const handleSelectInputChange = (e) => {
    setSelectedFloor(e.target.value)
}

const chooseFloors = () => {
    if(selectedFloor){
        let tempArr = []
        for (let i = 1; i <= parseInt(state.productForm.newProduct.fields.num_floors?.value); i++) { 
            tempArr.push([i]);
        }
        let finalArray = tempArr.filter(element => element[0] != selectedFloor);
        return finalArray;
    }else {
        return []
    }
}

const getFloorWord = (floor) => {
    switch(floor) { 
        case 1:
            return "1st floor";
        case 2:
            return "2nd floor";
        case 3:
            return "3rd floor";
        case 4:
            return "4th floor";
        case 5:
            return "5th floor";
        case 6:
            return "6th floor";
        case 7:
            return "7th floor";
        case 8:
            return "8th floor";
        case 9:
            return "9th floor";
        case 10:
            return "10th floor";
    }
}

const getNumberPrefix = (floor) => {
    switch(floor) { 
        case 1:
            return floor + "st";
        case 2:
            return floor + "nd";
        case 3:
            return floor + "rd";
        default:
            return floor + "th";
    }
}

const changeStep = async (direction) => {
    if(direction == 'back') {
        if(subStep === 2) {
            setSelectedFloorsArray([]);
            setSubStep(subStep - 1);
        } else if(subStep === 3) { 
            setSubStep(subStep - 1);
        } else {
            setIsOpen(false);
            setSelectedFloor(1);
        }
    }
    if(direction == 'next') {
        if(subStep < 3) {
            setSubStep(subStep + 1);
        } else {
            let tempArr = Array.from(state.units.list.filter(unit => unit.floor == selectedFloor)
              .sort((unitA, unitB) => {
                  if(unitA.unit_number > unitB.unit_number) {
                      return 1;
                  } else if(unitA.unit_number < unitB.unit_number) {
                      return -1;
                  }
                  return 0;
              }))

            let tempObj = {
                selectedFloor: selectedFloor,
                selectedFloorUnitInfo: tempArr, 
                floorsToCopy: selectedFloorsArray,
            }
            await actions.units.copyTable(tempObj);
            setSelectedFloorsArray([]);
            setIsOpen(false);
        }
    }
}

const handleMultiSelect = (newValue, dataObj) => {
    setSelectedFloorsArray([...selectedFloorsArray, newValue]);
}

const handleMultiDeselect = (index, dataObj) => {
    let tempArr = selectedFloorsArray.filter((_, i) => i !== index);
    setSelectedFloorsArray(tempArr);
}

    return(
        <div className="copyTable__main-container">
            <div className="product-form__step__title">
                <h1>Copy Table</h1>
            </div>
            <SubSteps items={["Select floor", "Copy to", "Confirm copy"]} stepState={subStep} />
            <div className={`product-form__substep-container ${subStep == 1 ? 'active' : ''}`}>
                <h2>Select the floor you want to copy</h2>
                <div className="copyTable__multiselect-container">
                    <SelectInput 
                        options={{isVso: true}} 
                        selectOptions={getSelectOptions()} 
                        setValue={handleSelectInputChange}
                        value={selectedFloor}
                    />
                </div>
            </div>
            <div className={`product-form__substep-container ${subStep == 2 ? 'active' : ''}` }>
                <h2>Choose the floors that needs to be copied</h2>
                <div className="copyTable__multiselect-container">
                    <MultiSelect
                        placeholder="Choose floors" 
                        selectOptions={chooseFloors()}
                        value={selectedFloorsArray}
                        dataObj={{contentId: 'selected_floors'}} 
                        options={{isVso: true, hasAlphabet: false}} 
                        handleMultiSelect={handleMultiSelect} 
                        handleMultiDeselect={handleMultiDeselect}
                    />
                </div>
            </div>
            <div className={`product-form__substep-container ${subStep == 3 ? 'active' : ''}` }>
                <h2>Confirm the following action:</h2>
                <div className="copyTable__confirm-copy-container">
                    <div className="copyTable__selected-floor-text">
                        <p>{getNumberPrefix(parseInt(selectedFloor))} floor</p>
                    </div>
                    <div className="copyTable__confirm-copy-icons">
                        <SvgIcons type="confirm_copy_table"/>
                        <SvgIcons type="right_arrow_long"/>
                        <p>will be copied to</p>
                    </div>
                    <div className="copyTable__copy-to-floor-text">
                        {selectedFloorsArray.sort((a, b) => parseInt(a) - parseInt(b)).map((element, index) => {
                            return(
                                <div key={index} className="copyTable__copy-to-floor-items">
                                    <SvgIcons type="copy_table_checkmark" />
                                    <p>{element === "All" ? "All Floors" : getNumberPrefix(parseInt(element))} floor</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="product-form__next-container">
                <Button type="ternary" onClick={() => changeStep('back')} text="Back" />
                <Button type="primary" onClick={() => changeStep('next')} text="Next" disabled={subStep === 2 && selectedFloorsArray.length === 0} />
            </div>
        </div>
    )
}

export default DuplicateFloorMenu;