require('dotenv').config();

export const fetchProjects = async () => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'projects/user/' + localStorage.userId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
    
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const storeNewProjectData = (newProjectData) => {
    localStorage.setItem('newProject', JSON.stringify(newProjectData));
}

export const createProject = async (newProjectData) => {
    try {
        const body = newProjectData;

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'projects/create',
            {
                method: 'POST',
                headers: {
                    'token' : localStorage.token,
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(body)
            }
        );

    } catch (err) {
        console.error(err.message);
        return err;
    }
}