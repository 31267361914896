import React from 'react';
import SvgIcons from 'src/components/shared/svgIcons';
import "./stepsCard.scss";

const StepsCard = ({stepNumber, title, totalSubSteps, currentSubStep, isCurrent, setCurrentStep}) => {

    let trackWidth = (currentSubStep / totalSubSteps) * 100;

    return(
        <div className={`stepsCard__container ${isCurrent ? '--active' : ''}`} onClick={setCurrentStep}>
            <div className="stepsCard__step-container">
                <div className="stepsCard__step">STEP {stepNumber}</div>
                {currentSubStep === totalSubSteps && <SvgIcons type="green_checkmark" />}
            </div>
            <div className="stepsCard__title">{title}</div>
            <div className="stepsCard__progess-container">
                <div className="stepsCard__progress-text">{currentSubStep} / {totalSubSteps}</div>
                <div className="stepsCard__progress-bar">
                    <div className="stepsCard__progress-bar-track" style={{width: `${trackWidth}%`}}></div>
                </div>
            </div>
        </div>
    )
}

export default StepsCard;