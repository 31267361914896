import React, { useEffect } from 'react';
import { 
    GoogleMap,
    Marker,
    OverlayView
} from '@react-google-maps/api';
import './map.scss';

const Map = ({mapOptions, onMapLoad, addressState, mapHeight}) => {
    return(
        <div className="map__google-maps-container" style={{height: mapHeight}}>
            <GoogleMap 
                mapContainerStyle={{ position: 'relative', width: '100%', height: '100%', border: '2px solid #dddddd', borderTop: 'none'}} 
                options={mapOptions}
                onLoad={onMapLoad}
            >
                {addressState?.value.coords?.lat !== null ? 
                <>
                <OverlayView
                position={{lat: addressState?.value.coords?.lat, lng: addressState?.value.coords?.lng}}
                mapPaneName={OverlayView.OVERLAY_LAYER}
                >
                    <div className="map__listing-map-address-container">
                        {addressState?.value.address_components[0]?.long_name} {addressState?.value.address_components[1]?.long_name},
                        <br/>
                        {addressState?.value.address_components[3]?.long_name}, {addressState?.value.address_components[5]?.short_name} {addressState?.value.address_components[7]?.long_name}
                    </div>
                </OverlayView>
                {/* <Marker position={{ lat: state.productForm.newProduct.fields.project_address?.value.coords?.lat, lgn: state.productForm.newProduct.fields.project_address?.value.coords?.lng }} /> */}
                <Marker position={new window.google.maps.LatLng(addressState?.value.coords?.lat, addressState?.value.coords?.lng)} />
                </> : <></>}
            </GoogleMap> 
        </div>  
    )
}

export default Map;