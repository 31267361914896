import React, {useState, useEffect, useRef} from 'react';
import './multiSelect.scss';
import AuditorComment from '../../auditorComment';
import Modal from 'src/components/shared/modal/index.js';
import MultiSelectList from './list';
import Button from '../../button';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../checkboxInput';

const MultiSelect = ({
    label, value, handleMultiSelect, handleMultiDeselect, dataObj, validateField, placeholder,
    status = 'pending', auditorComment, error, translationsNamespace, options={hasManySelects: false, hasManyContents: false, isManditory: false, maxWidth: ''}, selectOptions}) => {
    // const [fieldStatus, setFieldStatus] = useState(status);
    const [fieldId, setFieldId] = useState('');
    const [isSelectorOpen, setIsSelectorOpen] = useState(false);
    const [totalOptionsSelected, setTotalOptionsSelected] = useState(0);
    /* TO DO - Reverse dropdown when it's overflowing at the bottom of the window
    const [isOverflowingBottom, setIsOverflowingBottom] = useState(false);
    */
    const { t } = useTranslation(translationsNamespace)
    const dropdownContainer = useRef(null);
    
    const sortedOptions = selectOptions.sort((a, b) => a - b);
    const numOptions = sortedOptions.filter(o => new RegExp('^[0-9]').test(t(`${options.translationKey}.${o}`)));
    const aOptions = sortedOptions.filter(o => new RegExp('^a|^A').test(t(`${options.translationKey}.${o}`)));
    const bOptions = sortedOptions.filter(o => new RegExp('^b|^B').test(t(`${options.translationKey}.${o}`)));
    const cOptions = sortedOptions.filter(o => new RegExp('^c|^C').test(t(`${options.translationKey}.${o}`)));
    const dOptions = sortedOptions.filter(o => new RegExp('^d|^D').test(t(`${options.translationKey}.${o}`)));
    const eOptions = sortedOptions.filter(o => new RegExp('^e|^E').test(t(`${options.translationKey}.${o}`)));
    const fOptions = sortedOptions.filter(o => new RegExp('^f|^F').test(t(`${options.translationKey}.${o}`)));
    const gOptions = sortedOptions.filter(o => new RegExp('^g|^G').test(t(`${options.translationKey}.${o}`)));
    const hOptions = sortedOptions.filter(o => new RegExp('^h|^H').test(t(`${options.translationKey}.${o}`)));
    const iOptions = sortedOptions.filter(o => new RegExp('^i|^I').test(t(`${options.translationKey}.${o}`)));
    const jOptions = sortedOptions.filter(o => new RegExp('^j|^J').test(t(`${options.translationKey}.${o}`)));
    const kOptions = sortedOptions.filter(o => new RegExp('^k|^K').test(t(`${options.translationKey}.${o}`)));
    const lOptions = sortedOptions.filter(o => new RegExp('^l|^L').test(t(`${options.translationKey}.${o}`)));
    const mOptions = sortedOptions.filter(o => new RegExp('^m|^M').test(t(`${options.translationKey}.${o}`)));
    const nOptions = sortedOptions.filter(o => new RegExp('^n|^N').test(t(`${options.translationKey}.${o}`)));
    const oOptions = sortedOptions.filter(o => new RegExp('^o|^O').test(t(`${options.translationKey}.${o}`)));
    const pOptions = sortedOptions.filter(o => new RegExp('^p|^P').test(t(`${options.translationKey}.${o}`)));
    const qOptions = sortedOptions.filter(o => new RegExp('^q|^Q').test(t(`${options.translationKey}.${o}`)));
    const rOptions = sortedOptions.filter(o => new RegExp('^r|^R').test(t(`${options.translationKey}.${o}`)));
    const sOptions = sortedOptions.filter(o => new RegExp('^s|^S').test(t(`${options.translationKey}.${o}`)));
    const tOptions = sortedOptions.filter(o => new RegExp('^t|^T').test(t(`${options.translationKey}.${o}`)));
    const uOptions = sortedOptions.filter(o => new RegExp('^u|^U').test(t(`${options.translationKey}.${o}`)));
    const vOptions = sortedOptions.filter(o => new RegExp('^v|^V').test(t(`${options.translationKey}.${o}`)));
    const wOptions = sortedOptions.filter(o => new RegExp('^w|^W').test(t(`${options.translationKey}.${o}`)));
    const xOptions = sortedOptions.filter(o => new RegExp('^x|^X').test(t(`${options.translationKey}.${o}`)));
    const yOptions = sortedOptions.filter(o => new RegExp('^y|^Y').test(t(`${options.translationKey}.${o}`)));
    const zOptions = sortedOptions.filter(o => new RegExp('^z|^Z').test(t(`${options.translationKey}.${o}`)));
    const alphabetOrder = [
        ...aOptions, ...bOptions, ...cOptions, ...dOptions,
        ...eOptions, ...fOptions, ...gOptions, ...hOptions,
        ...iOptions, ...jOptions, ...kOptions, ...lOptions,
        ...mOptions, ...nOptions, ...oOptions, ...pOptions,
        ...qOptions, ...rOptions, ...sOptions, ...tOptions,
        ...uOptions, ...vOptions, ...wOptions,  ...xOptions,
        ...yOptions, ...zOptions
    ];

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(label + Math.floor(Math.random() * Date.now())));
    }, []);

    /* TO DO - Reverse dropdown when it's overflowing at the bottom of the window
    useEffect(() => {
        window.addEventListener('scroll', checkBottomOverflow);
        window.addEventListener('resize', checkBottomOverflow);

        return () => {
            window.removeEventListener('scroll', checkBottomOverflow);
            window.removeEventListener('resize', checkBottomOverflow);
        }
    });
    */

    useEffect(() => {
        dropdownContainer.current?.scrollTo(0,0);
    }, [isSelectorOpen]);

    useEffect(() => {
        if(options.hasManyContents) {
            let totalLength = 0;
            value.forEach((contentIdArray) => {
                totalLength += contentIdArray?.length;
            })
            setTotalOptionsSelected(totalLength);
        }
    }, [value]);

    // const handleAlphabetClick = (e) => {
    //     document.getElementById(`multi-select-${fieldId}__letter--${e.currentTarget.innerText}`).scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'center',
    //         inline: 'start'
    //     });
    // }
    
    /* TO DO - Reverse dropdown when it's overflowing at the bottom of the window
    const checkBottomOverflow = (e) => {
        const bounds = dropdownContainer.current.getBoundingClientRect();
        console.log('B : ' + (window.scrollY + bounds.y + bounds.height));
        console.log('T : ' + window.innerHeight);

        if(window.scrollY + bounds.y + bounds.height > window.innerHeight) {
            setIsOverflowingBottom(true);
        } else {
            setIsOverflowingBottom(false);
        }
    }
    */

    const checkValueArray = () => {
        let counter = 0;
        if(Array.isArray(value)) {
            value.forEach(el => {
                if(el.length > 0) {
                    counter++;
                }
            })
            if(counter > 0) {
                return true
            } else {
                return false
            }
        } else if (!Array.isArray(value) && value.length > 0) {
            return true;
        }
    }

    const handleValueChange = (e) => {
        const index = value.findIndex((elem) => {
            return elem === e.target.getAttribute('data-value');
        });
        if(index === -1) {
            handleMultiSelect(e.target.getAttribute('data-value'), dataObj);
        } else {
            handleMultiDeselect(index, dataObj);
        }
    }
    
    if(options.isModal) {
        return(
            <div className="multi-select__container">
            <label htmlFor={`input__${fieldId}`} className="multi-select__label">{label} {options.isManditory && <span>*</span>}</label>
            {auditorComment && <AuditorComment comment={auditorComment}/>}

            <div className="multi-select__inner-container" style={{maxWidth: options.maxWidth}}>
                <div className={`multi-select__input-box ${isSelectorOpen ? `--open` : ''}`} onClick={() => {setIsSelectorOpen(!isSelectorOpen)}}>
                    <div className="multi-select__current-selection">
                        {checkValueArray() ?
                            <>
                                <span className={`${options.isVso ? 'bg-blue' : ''}`}>{options.hasManyContents ? totalOptionsSelected : value?.length}</span>
                                <div>option{options.hasManyContents ? totalOptionsSelected > 1 ? 's' : '' : value?.length > 1 ? 's' :  ''} selected</div>
                            </>
                            :
                            <div className="multi-select__placeholder">{placeholder}</div>
                        }
                    </div>
                </div>
            </div>
            <Modal isOpen={isSelectorOpen} setIsOpen={setIsSelectorOpen}>
                <div className="multi-select__modal">
                    <div className="multi-select__modal-title">{label}</div>
                    <div className="multi-select__lists-container">
                        {selectOptions.map((list, index) => {
                            return(
                            <div key={`${list.name}`} className="multi-select__list-item">
                                <div className="multi-select__list-name">{list.name}</div>
                                <div className="multi-select__list-wrapper">
                                    <MultiSelectList 
                                        listName={list.name}
                                        value={options.hasManyContents ? value[index] : value}
                                        handleMultiSelect={handleMultiSelect}
                                        handleMultiDeselect={handleMultiDeselect}
                                        dataObj={options.hasManyContents ? {fieldId: dataObj.fieldId[index]} : dataObj}
                                        translationsNamespace={translationsNamespace}
                                        isShown={isSelectorOpen}
                                        options={{...options}} 
                                        selectOptions={list.options} />
                                </div>
                            </div>
                            )
                        })}
                    </div>
                    <div className="multi-select__next-container">
                        <Button type="primary" text="Save" onClick={() => setIsSelectorOpen(false)} />
                    </div>
                </div>
            </Modal>
            </div>
        )
    }

    return(
        <div className="multi-select__container">
            <label htmlFor={`input__${fieldId}`} className="multi-select__label">{label} {options.isManditory && <span>*</span>}</label>
            {auditorComment && <AuditorComment comment={auditorComment}/>}

            <div className="multi-select__inner-container" style={{maxWidth: options.maxWidth}}>
                <div className={`multi-select__input-box ${isSelectorOpen ? `--open` : ''}`} onClick={() => {setIsSelectorOpen(!isSelectorOpen)}}>
                    <div className="multi-select__current-selection">
                        {value?.length > 0 ?
                            <>
                                <span>{value?.length}</span>
                                <div>option{value?.length > 1 ? 's' :  ''} selected</div>
                            </>
                            :
                            <div className="multi-select__placeholder">{placeholder}</div>
                        }
                    </div>
                </div>
                <div className={`multi-select__dropdown-backdrop ${isSelectorOpen ? '' : '--hidden'}`} onClick={() => setIsSelectorOpen(false)}></div>
                {/* TO DO - Reverse dropdown when it's overflowing at the bottom of the window
                 <div className={`multi-select__dropdown${isSelectorOpen ? '' : ' --closed'}${isOverflowingBottom ? ' --reversed' : ''}`} ref={dropdownContainer}> 
                 */}
                <div className={`multi-select__dropdown${isSelectorOpen ? '' : ' --closed'}`} ref={dropdownContainer}>
                    {/* <div className="multi-select__sticky-container">
                        <div className="multi-select__alphabet">
                            <div className={`multi-select__alphabet-item ${numOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>#</div>
                            <div className={`multi-select__alphabet-item ${aOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>A</div>
                            <div className={`multi-select__alphabet-item ${bOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>B</div>
                            <div className={`multi-select__alphabet-item ${cOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>C</div>
                            <div className={`multi-select__alphabet-item ${dOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>D</div>
                            <div className={`multi-select__alphabet-item ${eOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>E</div>
                            <div className={`multi-select__alphabet-item ${fOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>F</div>
                            <div className={`multi-select__alphabet-item ${iOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>I</div>
                            <div className={`multi-select__alphabet-item ${jOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>J</div>
                            <div className={`multi-select__alphabet-item ${kOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>K</div>
                            <div className={`multi-select__alphabet-item ${lOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>L</div>
                            <div className={`multi-select__alphabet-item ${mOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>M</div>
                            <div className={`multi-select__alphabet-item ${nOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>N</div>
                            <div className={`multi-select__alphabet-item ${oOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>O</div>
                            <div className={`multi-select__alphabet-item ${pOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>P</div>
                            <div className={`multi-select__alphabet-item ${qOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>Q</div>
                            <div className={`multi-select__alphabet-item ${rOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>R</div>
                            <div className={`multi-select__alphabet-item ${sOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>S</div>
                            <div className={`multi-select__alphabet-item ${tOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>T</div>
                            <div className={`multi-select__alphabet-item ${uOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>U</div>
                            <div className={`multi-select__alphabet-item ${vOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>V</div>
                            <div className={`multi-select__alphabet-item ${wOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>W</div>
                            <div className={`multi-select__alphabet-item ${xOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>X</div>
                            <div className={`multi-select__alphabet-item ${yOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>Y</div>
                            <div className={`multi-select__alphabet-item ${zOptions.length > 0 ? '' : '--disabled'}`} onClick={handleAlphabetClick}>Z</div>
                        </div>
                    </div> */}
                    <div className="multi-select__list">
                        {
                            options.hasAlphabet ? 
                            <div className="multi-select__item-container">
                                {alphabetOrder.map((o, index) => {
                                        return(
                                            <CheckboxInput label={t(`${options.translationKey}.${o}`)} setValue={handleValueChange} value={value?.find(elem => elem === o)} options={{disabled: false, dataValue: o, isMultiSelect: true}}/>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className="multi-select__item-container">
                                {
                                    sortedOptions.map((o, index) => {
                                        return(
                                            <CheckboxInput label={o} setValue={handleValueChange} value={value?.find(elem => elem === o)} options={{disabled: false, dataValue: o, isMultiSelect: true}}/>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>                            
            </div>
        </div>
        
    )
}

export default MultiSelect;