import React from "react";
import "./filterMultiSelect.scss";
import { useTranslation } from "react-i18next";

const FilterMultiSelect = ({value, handleMultiSelect, handleMultiDeselect, selectOptions, dataObj, translationsNamespace}) => {
    const { t } = useTranslation('filterTable');
    
    const CheckboxInput = ({dataValue, checked, onClick, label}) => {
        return(
            <label className="checkbox__input-label">
                <input id={`input__checkbox`} type="checkbox" data-value={dataValue} checked={checked} onClick={onClick} />
                {label}
            </label>
        )
    }
    
    const handleValueChange = (e) => {
        const index = value.findIndex((elem) => {
            return elem === e.target.getAttribute('data-value');
        });
        if(index === -1) {
            handleMultiSelect(e.target.getAttribute('data-value'), dataObj);
        } else {
            handleMultiDeselect(index, dataObj);
        }
    }
    
    return(
        <div className="filterMultiSelect__container">
            {selectOptions?.map((o) => {
                return(
                    <CheckboxInput 
                        dataValue={o}
                        checked={value?.find(elem => elem === o) ? "checked" : ""} 
                        onClick={handleValueChange}
                        label={t(`filterOptions.${o}`)}
                        />
                )
            })}
        </div>
    );

}

export default FilterMultiSelect;