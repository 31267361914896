import { pipe, mutate } from 'overmind';
import { putUnitsArray } from './effects';
import { mutateAndReturn, debounceUnitValuesChanges } from '../operators';

export const setSelectedVSOid = ({state}, id) => {
    state.VSOs.selectedVSOid = id;
}

export const changeStatus = async ({state, actions, effects}, statusObj) => {
    let currentStatus = await effects.VSOs.changeStatus(statusObj.id, statusObj.status)
    if(currentStatus.status) { 
        actions.VSOs.loadVSOs();
    }
}

export const decrementUnitsNumber = ({state}, startNumber = 0) => {
    state.VSOs.list[state.VSOs.selectedVSOid].units.forEach(unit => {
        if(unit.unit_number.toString().slice(0, 1) === state.VSOs.selectedFloor.toString()) {
            if(Number(unit.unit_number) >= startNumber) {
                unit.unit_number = Number(unit.unit_number) - 1;
            }
        }
    });
}

export const incrementUnitsNumber = ({state}, startNumber = 0) => {
    state.VSOs.list[state.VSOs.selectedVSOid].units.forEach(unit => {
        if(unit.unit_number.toString().slice(0, 1) === state.VSOs.selectedFloor.toString()) {
            if(Number(unit.unit_number) >= startNumber) {
                unit.unit_number = Number(unit.unit_number) + 1;
            }
        }
    });
}

export const loadVSOs = async ({state, effects}) => {
    try{
        const VSOs = await effects.VSOs.fetchVSOs();
        for(const v in VSOs){
            let fieldsObj = {};
            for(const f of VSOs[v].fields) {
                if(f.field_value !== null) {
                    let tempVal = f.field_value;
                    if(f.field_value.includes('{"')) {
                        tempVal = JSON.parse(f.field_value);
                    }
                    fieldsObj[f.field_content_id] = 
                    {
                        value: tempVal,
                    }
                }
            }
            VSOs[v].fields = fieldsObj;
            VSOs[v].units_columns = JSON.parse(VSOs[v].units_columns);
        }
        state.VSOs.list = VSOs;
        state.VSOs.isLoading = false;
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const loadSelectedVsoReservations = async ({state, effects}) => {
    try{
        const reservations = await effects.VSOs.fetchVsoReservations(state.VSOs.selectedVSOid);
        state.VSOs.list[state.VSOs.selectedVSOid].reservations = reservations;
        return true;
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const loadVsoLeads = async ({state, effects}, id) => {
    let leadsList = await effects.VSOs.fetchVsoLeads(id);
    if(leadsList){
        state.VSOs.leads = leadsList;
    }
}

export const deleteVso = async ({state, effects}, id) => {
    try {
        const result = await effects.VSOs.deleteVso(id);
        if(result.success) {
            delete state.VSOs.list[id];
        }
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const loadUnits = async ({state, effects}, projectId) => {
    if(state.VSOs.selectedVSOid !== null) {
        state.VSOs.list[state.VSOs.selectedVSOid].units = await effects.VSOs.fetchUnits(projectId);
        state.VSOs.list[state.VSOs.selectedVSOid].units.forEach( async (unit) => {
            unit.extra_content = await JSON.parse(unit.extra_content);
            if(Object.keys(unit.extra_content).length > 0) { 
                let keys = Object.keys(unit.extra_content);
                let values = Object.values(unit.extra_content);
                keys.forEach((key, index) => {
                    unit[`extraContent_${key}`] = values[index]; 
                });
            }
        });
    }
}

export const setUnitValue = ({state}, {unitId, valueKey, newValue, isCustom}) => {
    let unitIndex = state.VSOs.selectedVSO.units.findIndex(e => e.unit_id == unitId);
    if(isCustom) {
        state.VSOs.list[state.VSOs.selectedVSOid].units[unitIndex][valueKey] = newValue;
        return {unitId: unitId, valueKey: 'extra_content', newValue: JSON.stringify(state.VSOs.list[state.VSOs.selectedVSOid].units[unitIndex]['extra_content']), isCustom: isCustom};
    } else {
        state.VSOs.list[state.VSOs.selectedVSOid].units[unitIndex][valueKey] = newValue;
        return {unitId: unitId, valueKey: valueKey, newValue: newValue};
    }
}

export const removeUnit = async ({state, actions, effects}, unitId) => {
    const res = await effects.VSOs.deleteUnit(unitId);
    if(res == unitId) {
        let unitIndex = state.VSOs.selectedVSO.units.findIndex(e => e.unit_id == unitId);
        let unitNumber = state.VSOs.list[state.VSOs.selectedVSOid].units[unitIndex].unit_number;
        state.VSOs.list[state.VSOs.selectedVSOid].units.splice(unitIndex, 1);
        decrementUnitsNumber({state: state}, unitNumber);
    }
    actions.VSOs.loadUnits(state.VSOs.selectedVSO.project_id);
}

export const changeUnitsOrder = ({state}, {oldPos, newPos}) => {
    const oldNumber = state.VSOs.selectedFloor + ('0' + (Number(oldPos) + 1)).slice(-2);
    const newNumber = state.VSOs.selectedFloor + ('0' + (Number(newPos) + 1)).slice(-2);
    const oldIndex = state.VSOs.list[state.VSOs.selectedVSOid].units.findIndex(unit => unit.unit_number == oldNumber);
    if(oldIndex === -1) {
        return;
    }
    const oldId = state.VSOs.list[state.VSOs.selectedVSOid].units[oldIndex].unit_id;
    
    state.VSOs.list[state.VSOs.selectedVSOid].units[oldIndex].unit_number = newNumber;
    let modifiedUnits = [{
        unit_id: state.VSOs.list[state.VSOs.selectedVSOid].units[oldIndex].unit_id,
        values: {unit_number: state.VSOs.list[state.VSOs.selectedVSOid].units[oldIndex].unit_number}
    }];
    
    if(newNumber > oldNumber) {
        state.VSOs.list[state.VSOs.selectedVSOid].units.forEach((unit) => {
            if(unit.unit_number <= newNumber && unit.unit_number > oldNumber && unit.unit_id !== oldId) {
                unit.unit_number = Number(unit.unit_number) - 1;
                modifiedUnits.push({
                    unit_id: unit.unit_id,
                    values: {unit_number: unit.unit_number}
                });
            }
        });
    } else if(newNumber < oldNumber) {
        state.VSOs.list[state.VSOs.selectedVSOid].units.forEach((unit) => {
            if(unit.unit_number >= newNumber && unit.unit_number < oldNumber  && unit.unit_id !== oldId) {
                unit.unit_number = Number(unit.unit_number) + 1;
                modifiedUnits.push({
                    unit_id: unit.unit_id,
                    values: {unit_number: unit.unit_number}
                });
            }
        });
    }

    return modifiedUnits; 
}

export const setCurrentFloor = ({state}, floorNumber) => {
    state.VSOs.selectedFloor = Number(floorNumber);
}

export const addUnit = async ({state, effects}) => {
    let highestNumberOnFloor = 0;

    state.VSOs.list[state.VSOs.selectedVSOid].units.forEach((unit) => {
        if(unit.unit_number.toString().slice(0, 1) === state.VSOs.selectedFloor.toString()) {
            if(Number(unit.unit_number) > highestNumberOnFloor) {
                highestNumberOnFloor = Number(unit.unit_number);
            }
        }
    });
    if(highestNumberOnFloor === 0) {
        highestNumberOnFloor = Number(state.VSOs.selectedFloor.toString() + '00');
    }

    const newUnit =  await effects.VSOs.createUnit(state.VSOs.selectedVSO.project_id, (highestNumberOnFloor + 1));
    state.VSOs.list[state.VSOs.selectedVSOid].units.push(newUnit);
}

export const addMultiUnits = async ({state, actions, effects}, numberOfUnits) => {
    let multipleUnitsInfo = await effects.VSOs.createMultipleUnits(state.VSOs.selectedVSO.project_id, numberOfUnits, state.VSOs.selectedVSOsortedFloorList, state.VSOs.selectedFloor);
    actions.VSOs.loadUnits(state.VSOs.selectedVSO.project_id);
}

export const uploadUnitPlan = async ({state, effects}, {unitId, formData}) => {
    const planURL = await effects.VSOs.putUnitPlan(unitId, formData);
    if(planURL) {
        let unitIndex = state.VSOs.selectedVSO.units.findIndex(e => e.unit_id == unitId);
        state.VSOs.list[state.VSOs.selectedVSOid].units[unitIndex].plan = planURL;
    }
}

export const removePlan = async ({state, effects}, unitId) => {
    const res = await effects.VSOs.deleteUnitPlan(unitId);
    if(res == unitId) {
        let unitIndex = state.VSOs.selectedVSO.units.findIndex(e => e.unit_id == unitId);
        state.VSOs.list[state.VSOs.selectedVSOid].units[unitIndex].plan = null;
    }
}

export const refreshUnits = ({state}, units) => {
    for(const unit of units) {
        let unitIndex = state.VSOs.selectedVSO.units.findIndex(e => e.unit_id == unit.unitId);
        state.VSOs.list[state.VSOs.selectedVSOid].units[unitIndex] = unit;
    }
}

export const saveUnitValue = pipe(
    mutateAndReturn(setUnitValue),
    debounceUnitValuesChanges(2000),
    putUnitsArray,
    mutate(refreshUnits)
);

export const saveUnitsOrder = pipe(
    mutateAndReturn(changeUnitsOrder),
    putUnitsArray,
    // filter((_, value) => Array.isArray(value)),
    mutate(refreshUnits)
)