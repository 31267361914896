import React, { useState, useEffect } from 'react';
import SvgIcons from 'src/components/shared/svgIcons';

const SummaryBlock = ({title, value, progress}) => {

    return(
        <div className="vsoDashStats__summary-item">
            <div className="vsoDashStats__summary-title">
                {title}
            </div>
            <div className="vsoDashStats__summary-value">{value}</div>
            <div className="vsoDashStats__summary-progress">
                <SvgIcons type={progress > 0 ? 'cabana_up_green' : 'cabana_down_red'} />
                <span>{progress > 0 ? 'Up ' : 'Down '}{Math.abs(progress)}% this week</span>
            </div>
        </div>
    )
}

export default SummaryBlock;