import React, { Children, useEffect, useState } from 'react';

import './diaporama.css';
import imagesOverlay from 'src/assets/images/salesOffice/images_overlay.png';
import plansOverlay from 'src/assets/images/salesOffice/plans_overlay.png';

const Diaporama = ({images, element}) => {
    const [currentImage, setCurrentImage] = useState(0);
    
    const nextImage = () => {
        setCurrentImage(currentImage === images?.length - 1 ? 0 : currentImage + 1);
    }

    useEffect(() => {
        let diaporamaTimeout = setTimeout(nextImage, 5000);

        return () => {
            clearTimeout(diaporamaTimeout)
        }
    }, [currentImage]);

    let itemOverlay = `url(${element === 'images' ? imagesOverlay : element === 'plans' ? plansOverlay : ''})`;

    return (
        <div className={`diaporama__container --${element}`}>
            <div className="diaporama__shadow"></div>
            <div className="diaporama__overlay" style={{backgroundImage: itemOverlay}}></div>
            {images?.map((image, index) => {
                if(element === 'images') {
                    return (
                        <div className={`diaporama__item --images ${currentImage !== index ? '--hidden' : ''}`} style={{backgroundImage: `url(${image.src})`}} key={`images-diaporama__${index}`}></div>
                    )
                }
                else if(element === 'plans') {
                    return (
                        <div className={`diaporama__item --plans ${currentImage !== index ? '--hidden' : ''}`} key={`images-diaporama__${index}`}>
                            <div className="diaporama__plan-table-container">
                                <div className="diaporama__plan-table">
                                    <div className="diaporama__plan-table-header"></div>
                                    <div className={`diaporama__plan-table-line --0 ${currentImage === index ? '--active' : ''}`}></div>
                                    <div className={`diaporama__plan-table-line --1 ${currentImage === index ? '--active' : ''}`}></div>
                                    <div className={`diaporama__plan-table-line --2 ${currentImage === index ? '--active' : ''}`}></div>
                                    <div className={`diaporama__plan-table-line --0 ${currentImage === index ? '--active' : ''}`}></div>
                                    <div className={`diaporama__plan-table-line --2 ${currentImage === index ? '--active' : ''}`}></div>
                                    <div className={`diaporama__plan-table-line --1 ${currentImage === index ? '--active' : ''}`}></div>
                                </div>
                                <div className="diaporama__plan-image" style={{backgroundImage: `url(${image.src})`}}></div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    );
}

export default Diaporama;