import { FormGroup } from '@material-ui/core';
import React from 'react';
import Divider from 'src/components/shared/divider';
import InCompleteForm from './inCompleteForm';
import OfficeImage from 'src/assets/images/salesOffice/background.png';
import "./campaignLaunch.scss";
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';
import { useOvermind } from 'src/overmind';
import { useHistory } from 'react-router-dom';

const CampaignLaunch = ({completedFields, totalFields, currentAddress}) => {

    const { state, actions, effects } = useOvermind();
    let history = useHistory();

    const handleBackClick = () => {
        actions.productForm.decrementFormStep(state.productForm.formStep - 1);
    }

    const handleNextClick = async () => {
        if((currentAddress !== state.productForm.newProduct.fields.project_address?.value?.full_string && currentAddress !== '') || state.productForm.newProduct.fields.nearby_places?.value == undefined) {
            let addressData = {
                coords: {lat: state.productForm.newProduct.fields.project_address?.value?.coords?.lat, lng: state.productForm.newProduct.fields.project_address?.value?.coords?.lng},
                full_string: state.productForm.newProduct.fields.project_address?.value?.full_string,
                address_components: state.productForm.newProduct.fields.project_address.value?.address_components
            }
           await effects.productForm.putNearbyPlacesVso(state.productForm.newProduct.id, addressData);
        }
        actions.productForm.changeStatus("active");
        history.push(`/virtual-sales-office/${state.productForm.newProduct.id}`);
        actions.productForm.clearProduct();
    }

    return(
        <div className="steps__container">
            <div className="steps__title-desc-container">
                <div className="steps__title">Wrapping up</div>
                <div className="steps__desc">Finalizing your virtual sales office</div>
            </div>
            <Divider orientation="horizontal" margin="0px -24px"/>
            {completedFields != totalFields ?
                <InCompleteForm 
                    completedFields={completedFields}
                    totalFields={totalFields}
                />
                :
             completedFields == totalFields && state.productForm.vsoStatus === 'draft' ?
                <div className="campaignLaunch__container">
                    <div className="campaignLaunch__text">Congratulations!</div>
                    <div className="campaignLaunch__image-container">
                        <img src={OfficeImage} alt="sales-office-image" />
                    </div>
                    <div className="campaignLaunch__setup-text">Your virtual sales office is all set up!</div>
                </div>
                :
                <>
                </>
            }
            <Divider orientation="horizontal" margin="0px -24px"/>
            <div className="campaignLaunch__buttons-bar">
                {
                    completedFields != totalFields ?
                    <Button type='ternary' text="Back" onClick={handleBackClick} /> :
                    <Button type='primary' text="Go to vso" suffix={<SvgIcons type="cabana_right_arrow"/>} onClick={handleNextClick} /> 
                }
            </div>
        </div>
    );
}

export default CampaignLaunch;