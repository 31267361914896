import React, {forwardRef, useRef, useState, useEffect} from 'react';
import { useOvermind } from 'src/overmind';

import Cell from '../cell';
import Tooltip from '../../tooltip';
import Dropdown from '../../dropdown';
import Button from 'src/components/shared/button';

import './row.css';
import planIcon from 'src/assets/images/unitsTable/plan.svg';
import uploadIcon from 'src/assets/images/unitsTable/upload_plan.svg';
import removeIcon from 'src/assets/images/unitsTable/remove_plan.svg';
import cogIcon from 'src/assets/images/unitsTable/cog.svg';
import deleteIcon from 'src/assets/images/unitsTable/delete.svg';
import duplicateIcon from 'src/assets/images/unitsTable/duplicate.svg';
import grabArea from 'src/assets/images/unitsTable/grab_area.svg';
import Modal from '../../modal';
import PlanModal from '../../planModal';
import { checkFileSize } from 'src/utils/fileUpload';
import ErrorMessage from '../../errorMessage';
import SvgIcons from '../../svgIcons';

const UnitRow = forwardRef(({
        cells, 
        cellProps, 
        rowAttributes,
        listeners,
        attributes,
        isEditMode, 
        projectType,
        isDragging = false,
        deleteUnit,
        uploadPlan,
        removePlan,
        style,
        unitId,
        handleRowClick,
        activeFloor,
        unitStatus,
        showPrices
    }, ref) => {
    const [isPlanHovered, setIsPlanHovered] = useState(false);
    const [isRemovePlanHovered, setIsRemovePlanHovered] = useState(false);
    const [isUploadPlanHovered, setIsUploadPlanHovered] = useState(false);
    const [isGrabAreaHovered, setIsGrabAreaHovered] = useState(false);
    const [isCogDropdownOpen, setIsCogDropdownOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [plan, setPlan] = useState("");
    const [coords, setCoords] = useState({});
    const [dropDownCoords, setDropDownCoords] = useState({})
    const planInputRef = useRef(null);

    useEffect(() => {

    }, [plan, modal])

    const handleHoverInPlan = (e) => {
        setIsPlanHovered(true);
        const rect = e.target.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY
          });
    }

    const handleHoverOutPlan = () => {
        setIsPlanHovered(false);
    }

    const handleHoverInUploadPlan = (e) => {
        setIsUploadPlanHovered(true);
        const rect = e.target.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY
          })
    }

    const handleHoverOutUploadPlan = () => {
        setIsUploadPlanHovered(false);
    }

    const handleHoverInRemovePlan = (e) => {
        setIsRemovePlanHovered(true);
        const rect = e.target.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY
          })
    }

    const handleHoverOutRemovePlan = () => {
        setIsRemovePlanHovered(false);
    }

    const handleHoverInGrabArea = () => {
        setIsGrabAreaHovered(true);
    }

    const handleHoverOutGrabArea = () => {
        setIsGrabAreaHovered(false);
    }

    const handleClickPlan = (e) => {
        e.stopPropagation();
        let planIndex = cells.findIndex(el => el.column["Header"] == "Plan");
        if(cells[planIndex].value !== null && cells[planIndex].value !== "") {
            setPlan(cells[planIndex].value);
            setModal(true);
        }
    }

    const handleClickUploadPlan = () => {
        planInputRef.current.click();
    }

    const handleChangePlan = () => {
        if(planInputRef.current.files.length > 0) {
            if(checkFileSize(planInputRef.current.files[0])){
                setFileSizeError(true);
                return
            }
            setTimeout(() => {
                let formData = new FormData();
                formData.append('plan', planInputRef.current.files[0]);
                uploadPlan({unitId, formData});
            }, 500);
        }
    }

    const handleClickDelete = async () => {
        await deleteUnit(unitId);
    }

    const handleClickCogwheel = (e) => {
        setIsCogDropdownOpen(!isCogDropdownOpen);
        const rect = e.target.getBoundingClientRect();
        setDropDownCoords({
            left: rect.x + window.scrollX,
            top: rect.y + window.scrollY,
            width: rect.width,
            height: rect.height
            });
    }

    const handleClickDuplicate = () => {
        // TODO
    }

    const handleClickRemovePlan = () => {
        removePlan(unitId);
        setIsRemovePlanHovered(false);
    }

    const handleFileSizeError = () => {
        setFileSizeError(!fileSizeError);
        planInputRef.current.value = "";
    }

    const getDimensionSuffix = (dataType) => {
        switch (dataType) {
            case "Inch":
                return "inch"
            case "Feet":
                return "ft"
            case "SQFT²":
                return "ft²"
            case "Meter":
                return "m"
            default:
                break;
        }
    }

    let markup = cells?.map((cell, index) => {
        let tempPrefix = null;
        let tempSuffix = null;
        let tempContent = cell.value;
        if(cell.column.editData?.type === 'price') {
            tempSuffix = '$';
        }
        if(cell.column.editData?.type === 'dimension' || cell.column?.dimensionType) {
            tempSuffix = getDimensionSuffix(cell.column?.dimensionType); 
        }
        if(cell.column.id === 'price') {
            if(showPrices === 'from') {
                tempPrefix = 'From '
            }
            if(projectType === 'rent') {
                tempSuffix = '$ / mo';
            } else {
                tempSuffix = '$';
            }
            if (unitStatus != 'available' && !isEditMode) {
                tempSuffix = '';
                tempContent = '-';
            }
        } else if(cell.column.id === 'plan') {
            if(!isEditMode) {
                if(cell.value !== '' && cell.value !== null && unitStatus == 'available') {
                    tempContent = (
                        <div className="unitsTable__cell-plan" onMouseEnter={handleHoverInPlan} onMouseLeave={handleHoverOutPlan} onClick={handleClickPlan}>
                            <SvgIcons type="cabana_plan_view_icon" />
                        </div>
                    )
                } else {
                    tempContent = (
                        <>-</>
                    )
                }
            } else {
                tempContent = (
                    <>
                    <div className="unitsTable__cell-plan" onMouseEnter={handleHoverInUploadPlan} onMouseLeave={handleHoverOutUploadPlan} onClick={handleClickUploadPlan}>
                        <Button type="secondary"><img src={uploadIcon}/></Button>
                        <input ref={planInputRef} style={{display: 'none'}} type="file" accept="image/png, image/jpeg, image/svg" onChange={handleChangePlan}/>
                    </div>
                    {cell.value !== '' && cell.value !== null && 
                    <>
                    <div className="unitsTable__cell-plan" onMouseEnter={handleHoverInPlan} onMouseLeave={handleHoverOutPlan} onClick={handleClickPlan}>
                        <Button type="secondary"><img src={planIcon}/></Button>
                        
                    </div>
                    <div className="unitsTable__cell-plan" onMouseEnter={handleHoverInRemovePlan} onMouseLeave={handleHoverOutRemovePlan} onClick={handleClickRemovePlan}>
                        <Button type="secondary"><img src={removeIcon}/></Button>
                        
                    </div>
                    </>
                    }
                    </>
                )
            }
        } else if(cell.column.id === 'unit_number') {
            if(isEditMode) {
                tempContent = (
                    <>
                        {activeFloor !== "" && activeFloor !== 0 && <img className="unitRow__drag-handle" src={grabArea} {...attributes} {...listeners} onMouseEnter={handleHoverInGrabArea} onMouseLeave={handleHoverOutGrabArea}/>}
                        <div style={{flexGrow: '1', fontWeight: 'bold', paddingLeft: '12px'}}>{cell.value}</div>
                        <div className="unitRow__cogwheel-button" onClick={handleClickCogwheel}>
                            <Dropdown isOpen={isCogDropdownOpen} setIsOpen={setIsCogDropdownOpen} position="se" coords={dropDownCoords} items={[
                                // {content: <><img src={duplicateIcon}/>Duplicate</>, clickAction: handleClickDuplicate},
                                {content: <div className="unitRow__delete-unit"><img src={deleteIcon}/>Delete</div>, classesString: '--red', clickAction: handleClickDelete},
                                ]} />
                            <img src={cogIcon}/>
                        </div>
                    </>
                )
            } else {
                tempContent = (
                    <span>{cell.value}</span>
                )
            }
        }
        return (
            <div ref={ref} {...cell.getCellProps(cellProps)} className="unitsTable__cell" onClick={() => handleRowClick(unitId)}>
                <Cell
                    id={cell.column.id} 
                    content={tempContent} 
                    isCustom={cell.column.editData?.isCustom}
                    editData={isEditMode && cell.column.editData ? {...cell.column.editData, unitId: unitId, valueKey: cell.column.id} : false} 
                    prefix={tempPrefix}
                    suffix={tempSuffix}
                />
            </div>
        )
    })

    return(
        <>
        <Tooltip text="View Unit Plan" isOpen={isPlanHovered}  options={{coords: coords}} />
        <Tooltip text="Upload plan" isOpen={isUploadPlanHovered} options={{coords: coords}} />
        <Tooltip text="Remove plan" isOpen={isRemovePlanHovered}  options={{coords: coords}} />
        <PlanModal isOpen={modal} setIsOpen={setModal} options={{hasCloseButton: true, disableBackdropClick: true}}>
            <img src={plan} className="unitRow__view-plan" alt="unit plan" />
        </PlanModal>
        <Modal isOpen={fileSizeError} setIsOpen={setFileSizeError} options={{contentCenter: true}}>
            <ErrorMessage onClick={handleFileSizeError} 
                items={[
                    {content: <div>The file size of one or multiple uploaded file is bigger than 9MB</div>},
                    {content: <div>File size should be less than 9MB</div>}
                ]}
                errorCode={'1305'}/>    
        </Modal>
        <div ref={ref} {...rowAttributes} style={{
            ...style,
            transition: '0.15s',
            zIndex: isCogDropdownOpen || isGrabAreaHovered ? '10' : '5',
            border: isDragging ? '5px solid red' : 'none',
            boxShadow: isGrabAreaHovered ? '0 4px 16px rgb(0 0 0 / 8%)' : null,
            backgroundColor: isGrabAreaHovered ? '#ffffff' : null
            }}>
            {markup}
        </div>
    </>);
});

export default UnitRow;