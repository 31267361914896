export const fetchVsoFieldsAndUnits = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/fieldsAndUnits/' + vsoId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const fetchOptionalColumns = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/columnData/' + vsoId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const addVisitor = async (visitor) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'visitors/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(visitor)
            });

        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const sendMessage = async (vsoId, messageObj) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}virtualSalesOffices/${vsoId}/message`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(messageObj)
            });

        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const sendLeadGenReq = async (url, messageObj) => {
    try {
        const response = await fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(messageObj)
            });

        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

