import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './langSelector.css';

const LangSelector = () => {
    const { i18n } = useTranslation();

    return(
        <div className="langSelector__container">
            <div className={`langSelector__item ${i18n.language === 'fr' ? 'active' : ''}`} onClick={() => {i18n.changeLanguage('fr')}}>FR</div>
            <div className="langSelector__divider"></div>
            <div className={`langSelector__item ${i18n.language === 'en' ? 'active' : ''}`} onClick={() => {i18n.changeLanguage('en')}}>EN</div>
        </div>
    )
}

export default LangSelector;