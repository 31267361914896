import React from "react";
import { useTranslation } from 'react-i18next';
import "./planViewCard.scss";

const PlanViewCard = ({item, type, setExpandPlan}) => {
    const { t } = useTranslation('planView');

    const imageStyle = {
        background: `url(${item.plan}) center / cover no-repeat`,
    }

    return(
        <div className="planViewCard__container" onClick={() => setExpandPlan(item)}>
            <div className="planViewCard__unit-plan" style={imageStyle}/>
            <div className="planViewCard__unit-info">
                <div className="planViewCard__unit-number-price">
                    <div className="planViewCard__unit-number">{t('planViewCard.unit')} {item.unit_number}</div>
                    <div className="planViewCard__unit-price">${item.price} {type === "rent" && <p>/mo</p>}</div>
                </div>
                <div className="planViewCard__unit-essentials">{t(`planViewCard.${item.bedroom}`)} • {t(`planViewCard.${item.bathroom}`)} • {item.sqft} {t('planViewCard.sqft')}</div>
            </div>
        </div>
    )
}

export default PlanViewCard;