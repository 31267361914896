import React from 'react';
import ExampleImage1 from 'src/assets/images/productForm/exampleImage1.jpg';
import ExampleImage2 from 'src/assets/images/productForm/exampleImage2.jpg';
import ExampleImage3 from 'src/assets/images/productForm/exampleImage3.jpg';
import './imageInfo.scss';

const ImageInfo = () => {
    return(
        <div className="imageInfo__container">
            <img src={ExampleImage3} />
            <img src={ExampleImage2} />
            <div className="imageInfo__info-container">
                <div className="imageInfo__info-title">These are great examples!</div>
                <div className="imageInfo__info-items-title">We recommend to: </div>
                <div className="imageInfo__info-items">
                    <ul>
                        <li>Add at least 3 images of your project</li>
                        <li>Add exterior images</li>
                        <li>Upload high-quality renderings</li>
                    </ul>
                </div>
            </div>
            <img src={ExampleImage1} />
        </div>
    )
}

export default ImageInfo;