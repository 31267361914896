import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOvermind } from 'src/overmind';

import UnitsTable from 'src/components/shared/unitsTable';
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';
import Popover from 'src/components/shared/popover';
import EditColumns from 'src/components/shared/unitsTable/editColumns';
import FilterSingleSelect from 'src/components/shared/filterTable/filterSingleSelect';
import BatchUploadPlans from 'src/components/shared/unitsTable/batchUploadPlans';
import DuplicateFloor from 'src/components/shared/unitsTable/duplicateFloor';
import './unitTable.scss';
import BackNextBar from 'src/components/shared/backNextBar';

const UnitTable = ({unitListStep, setUnitListStep, validateForm, validateField, formErrors}) => {

    const { t, i18n } = useTranslation(['translations', 'filterTable']);
    const { state, actions } = useOvermind();
    const [floorSelectorBtnCoords, setfloorSelectorBtnCoords] = useState({});
    const [isFloorSelectorOpen, setIsFloorSelectorOpen] = useState(false);
    const allFloorsButtonRef = useRef(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    
    const handleUnitValueChange = (dataObj) => {
        actions.units.saveUnitValue({unitId: dataObj.unitId, valueKey: dataObj.valueKey, newValue: dataObj.newValue, isCustom: dataObj.isCustom});
    }

    const columns = useMemo(() => 
    [
        {accessor: 'unit_number', Header: t('unitTable.unit'), minWidth: 80},
        {accessor: 'bedroom', Header: t('unitTable.bedroom'), minWidth: 96, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['studio', 'oneBedroom', 'twoBedroom', 'threeBedroom', 'fourBedroom', 'fiveBedroom']}},
        {accessor: 'bathroom', Header: t('unitTable.bathroom'), editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['oneBathroom', 'twoBathroom', 'threeBathroom', 'fourBathroom', 'fiveBathroom']}},
        {accessor: 'sqft', Header: <div className="columnHeader__sqft">{t('unitTable.sqft')} <strong>(net)</strong></div>, minWidth: 96, editData: {setValue: handleUnitValueChange, type: 'number'}},
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'orientation')]?.isShown ? [{accessor: 'orientation', Header: t('unitTable.orientation'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['north', 'east', 'south', 'west', 'oneEightyDegrees', 'twoSeventyDegrees', 'threeSixtyDegrees', 'notAvailable']}}] : [],
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'position')]?.isShown ? [{accessor: 'position', Header: t('unitTable.position'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['corner', 'center', 'notAvailable']}}] : [],
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'view')]?.isShown ? [{accessor: 'view', Header: t('unitTable.view'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['city', 'garden', 'mountain', 'park', 'street', 'water', 'notAvailable']}}] : [],
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'parking')]?.isShown ? [{accessor: 'parking', Header: t('unitTable.parking'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: ['oneInterior', 'twoInterior', 'threePlusInterior', 'oneExterior', 'twoExterior', 'threePlusExterior', 'notAvailable']}}] : [],
        ... state.units.columns?.[state.units.columns?.findIndex((el) => el.name === 'locker')]?.isShown ? [{accessor: 'locker', Header: t('unitTable.locker'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'radio', translationsNamespace: 'unitTable', options: ['included', 'notIncluded']}}] : [],
        {accessor: 'price', Header: <div className="columnHeader__price">{t('unitTable.price')} <strong>(tx incl.)</strong></div>, minWidth: state.productForm.newProduct.fields.project_type.value === 'rent' ? 160 : 132, editData: {setValue: handleUnitValueChange, type: 'number'}},
        {accessor: 'plan', Header: t('unitTable.plan'), minWidth: state.productForm.newProduct.fields.project_type.value === 'rent' ? 128 : 132},
        ... state.units.columns?.filter((element) => element.isCustom === true).length > 0 ?
            state.units.columns?.filter((element) => element.isCustom === true && element.isShown)
                .map((element) => (
                    element.type === 'text' || element.type === 'date' || element.type ==='number' || element.type === 'price' || element.type === 'dimension' ?
                    {accessor: `extraContent_${element.key}`, Header: element.name.en,  minWidth: element.type === 'date' ? 160 : 132, editData: {setValue: handleUnitValueChange, type: element.type, isCustom: element.isCustom}, dimensionType: element.type === 'dimension' ? element.dimensionType : ''}
                    :
                    {accessor: `extraContent_${element.key}`, Header: element.name.en, minWidth: 132, editData: {setValue: handleUnitValueChange, type: element.type === 'toggle' ? 'radio' : element.type === 'single_select' ? 'select' : element.type, options: [...element.selectOptions], isCustom: element.isCustom}}
                )) : [],
        {accessor: 'status', Header: t('unitTable.status'), minWidth: 104, editData: {setValue: handleUnitValueChange, type: 'select', translationsNamespace: 'unitTable', options: state.productForm.newProduct.fields.project_type?.value === 'rent' ? ['available', 'reserved', 'rented'] : ['available', 'reserved', 'sold']}},
    ], [i18n.language, JSON.stringify(state.units.columns)]);

    useEffect(() => {
        actions.units.loadUnits(state.productForm.newProduct.id);
    }, []);

    useEffect(() => {
        if(state.units.isLoading === false) {
            setIsFirstLoad(false);
        }
    }, [state.units.isLoading]);

    const getFloors = () => {
        let floors = [];
        for (let i = 0; i <= state.productForm.newProduct?.fields.num_floors.value; i++) {
            if(i === 0 ) {
                floors.push(['', `${t('floors.all', {ns: 'filterTable'})} ${t('floors.floors', {ns: 'filterTable'})}`]);
            // } else if (filteredUnitsFloorLength.length > 0) {
            } else { 
                floors.push([i, 
                    (i === 1 ? `${t('floors.1st', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`:
                    i === 2 ? `${t('floors.2nd', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`:
                    i === 3 ? `${t('floors.3rd', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`:
                    i + `${t('floors.th', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`)]);
            }
        }
        return floors;
    }

    const handleRowClick = () => {
        return;
    }

    const handleAllFloorDropdownContainer = () => {
        setIsFloorSelectorOpen(!isFloorSelectorOpen);
        const rect = allFloorsButtonRef.current.getBoundingClientRect();
        setfloorSelectorBtnCoords({
            left: rect.left + window.scrollX,
            top: rect.y + window.scrollY,
            height: rect.height,
            width: rect.width
        });
    }

    const handleChangeUnitsOrder = (oldPos, newPos) => {
        actions.units.saveUnitsOrder({oldPos: oldPos, newPos: newPos});
    }

    const handleBackClick = () => {
        actions.productForm.decrementFormStep();
    }

    const handleNextClick = () => {
        validateForm(false);
        actions.units.setCurrentFloor('1');
        setUnitListStep(unitListStep + 1);
    }
    
    return(
        <div className="unitTable__container">
            <div className="unitTable__buttons-"></div>
            <div className="unitTable__buttons-bar">
                <div className="unitTable__bar-left">
                    <Button 
                        type="secondary" 
                        size="small"
                        suffix={<SvgIcons type="cabana_dropdown_icon" />} 
                        suffixGap={'36px'}
                        options={{activeState: state.units.selectedFloor !== '' || isFloorSelectorOpen, ref: allFloorsButtonRef}}
                        onClick={handleAllFloorDropdownContainer}
                        text={state.units.selectedFloor === '' ? `${t('floors.all', {ns: 'filterTable'})} ${t('floors.floors', {ns: 'filterTable'})}` : 
                            state.units.selectedFloor == 1 ? `${t('floors.1st', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`:
                            state.units.selectedFloor == 2 ? `${t('floors.2nd', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}` :
                            state.units.selectedFloor == 3 ? `${t('floors.3rd', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}` :
                            state.units.selectedFloor + `${t('floors.th', {ns: 'filterTable'})} ${t('floors.floor', {ns: 'filterTable'})}`}
                    />
                    <Popover position="se" isOpen={isFloorSelectorOpen} setIsOpen={setIsFloorSelectorOpen} coords={floorSelectorBtnCoords} visibleBackdrop>
                        <div className="filterTable__choose-floor-container">
                            <div className="filterTable__choose-floor-header">
                                <div className="filterTable__choose-floor-text">{t('floors.floors', {ns: 'filterTable'})}</div>
                                <div className="filterTable__choose-floor-info">{t('floorSearch', {ns: 'filterTable'})}</div>
                            </div>
                            <div className="filterTable__choose-floor">
                                <FilterSingleSelect value={state.units.selectedFloor} selectOptions={getFloors()} setValue={actions.units.setCurrentFloor}/>
                            </div>
                        </div>
                    </Popover>
                    <EditColumns 
                        optionalColumns={state.units.columns} 
                        setOptionalColumns={actions.units.setOptionalColumns} 
                        setCustomColumn={actions.units.setCustomColumns} 
                        removeCustomColumn={actions.units.removeCustomColumn} 
                        addColumnsData={actions.units.addColumnsData}
                        sendEditColumnData={actions.units.sendColumnData}
                        sendAllColumnsData={actions.units.sendAllColumnsData}
                    />
                    <BatchUploadPlans
                        isDisabled={state.units.list.length < 1}
                        unitPlans={state.units.plans}
                        setUnitPlans={actions.units.setUnitPlans}
                        uploadUnitPlans={actions.units.uploadUnitPlans}
                    />
                    <DuplicateFloor
                        isDisabled={state.units.list.length < 1}
                    />
                </div>
                <div className="unitTable__bar-right">
                    <div className="unitTable__error">{formErrors.units}</div>
                </div>
            </div>
            {isFirstLoad ? 'Loading .........'
            :
            <UnitsTable
                units={state.units.sortedFloorUnits}
                projectType={state.productForm.newProduct.fields.project_type?.value}
                isEditMode={true}
                headings={columns}
                activeFloor={state.units.selectedFloor}
                handleRowClick={handleRowClick}
                addUnit={actions.units.addUnit}
                addUnits={actions.units.addMultiUnits}
                deleteUnit={actions.units.removeUnit}
                uploadPlan={actions.units.uploadUnitPlan}
                removePlan={actions.units.removeUnitPlan}
                changeUnitsOrder={handleChangeUnitsOrder}
                // sortedActiveFloor={state.units.list}
                options={{maxHeight: '500px'}}
            />
            } 
            <div className="unitTable__buttons-bar">
                <BackNextBar onBackClick={handleBackClick} onNextClick={handleNextClick} />
            </div>
        </div>
    )
}
export default UnitTable;