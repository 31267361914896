import React from 'react';

import './cell.scss';

const Cell = ({content, prefix, suffix, isFixed = false}) => {

    let contentMarkup;

    if(content === '' || content === null) {
        contentMarkup = '-';
    } else if(!isNaN(content)) {
        contentMarkup = parseInt(content).toLocaleString();
    } else {
        contentMarkup = content;
    }

    return (
        <div className={`cell${isFixed ? ' --fixed' : ''}`}>
            <div>{prefix}{contentMarkup}{suffix}</div>
        </div>
    );
}

export default Cell;