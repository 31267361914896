export const hide = ({state}) => {
    state.sidebar.isShown = false;
}

export const show = ({state}) => {
    state.sidebar.isShown = true;
}

export const setPage = ({state, actions}, page) => {
    actions.sidebar.show();
    state.sidebar.currentPage = page;
}