import { derived } from 'overmind';

export const state = {
    vsoId: null,
    isLoading: false,
    currentPage: 'dash',
    leadsMessages: {
        data: [],
        getMessageById: derived(state => id => {
            if(Array.isArray(state.data)) {
                return state.data.find(el => el.id == id);
            } else {
                return null;
            }
        })
    }
}