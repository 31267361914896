import React, {useState, useEffect} from "react";
import BuildingViewSteps from "./buildingViewSteps";
import UnitTable from "./unitTable";
import './unitList.scss';
import { useOvermind } from "src/overmind";
import { validateNumberOfUnits, validateFloorPlans, validateImageSelection, validateBuildingFloors } from "src/utils/fieldValidation";

const UnitList = () => {
    const {state, actions, effects} = useOvermind();
    const [subStep, setSubStep] = useState(1);
    const [formErrors, setFormErrors] = useState({
        units: null,
        buildingView_image: null,
        buildingView_floors: null,
        floor_plans: null
    });

    const validateField = (fieldName) => {
        if(fieldName === 'units') {
            const error = validateNumberOfUnits(state.units.list.length, {isMandatory: true});
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    [fieldName]: error
                }
            });
            return error;
        } else if (fieldName === 'buildingView_image') {
            const error = validateImageSelection(state.productForm.newProduct.fields.buildingView_image.value, {isMandatory: true})
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    [fieldName]: error
                }
            });
        } else if (fieldName === 'buildingView_floors') {
            const error = validateBuildingFloors(state.productForm.newProduct.fields.buildingView_floors.value.array, {isMandatory: true})
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    [fieldName]: error
                }
            });
        } else if (fieldName === 'floor_plans') {
            const error = validateFloorPlans(state.productForm.newProduct.fields.floor_plans.value.array, {isMandatory: true})
            setFormErrors((prevState) => {
                return {
                    ...prevState,
                    [fieldName]: error
                }
            });
        }
    }

    const validateForm = (isFirstLoad) => {
        if(!isFirstLoad) {
            const newErrors = {
                units: validateNumberOfUnits(state.units.list.length, {isMandatory: true}),
                buildingView_image: validateImageSelection(state.productForm.newProduct.fields.buildingView_image.value, {isMandatory: true}),
                buildingView_floors: validateBuildingFloors(state.productForm.newProduct.fields.buildingView_floors.value.array, {isMandatory: true}),
                floor_plans: validateFloorPlans(state.productForm.newProduct.fields.floor_plans.value.array, {isMandatory: true})
            }
            setFormErrors(newErrors);
            return Object.values(newErrors).every((value) => value === false);
        }
    }

    const calculateCompletedFields = () => {
        return Object.values(formErrors).filter((field) => field === false).length;
    }

    useEffect(() => {
        validateForm(state.productForm.newProduct.fields.form_steps_data.value.array[2] === 0);
    }, []);

    useEffect(() => {
        validateField('units');   
    }, [state.units.list.length]);

    useEffect(() => {
        validateField('buildingView_floors');
    }, [state.productForm.newProduct.fields.buildingView_floors.value.array]);

    useEffect(() => {
        validateField('floor_plans');
    }, [state.productForm.newProduct.fields.floor_plans.value.array]);

    useEffect(() => {
        actions.productForm.setStepCompletedFields({step: 3, completedFields: calculateCompletedFields()});
    }, [formErrors]);

    return(
        <div className="unitList__container">
            {subStep === 1 ?
                <UnitTable 
                    unitListStep={subStep} 
                    setUnitListStep={setSubStep} 
                    validateForm={validateForm} 
                    validateField={validateField}
                    formErrors={formErrors}
                /> : 
            subStep === 2 ? 
                <BuildingViewSteps 
                    unitListStep={subStep} 
                    setUnitListStep={setSubStep} 
                    validateForm={validateForm} 
                    validateField={validateField}
                    formErrors={formErrors}
                /> :
                <></>
            }
        </div>
    )
}

export default UnitList