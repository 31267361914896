
import React, { useEffect } from 'react';
import { useOvermind } from '../../overmind';
import '../app/app.css';
import '../dashboard/dashboard.css';
import VSOImage from 'src/assets/images/salesOffice/background.png';
import DiscoverImage from "../../assets/images/VSOList/play_button_vector_vso.svg";
import RightArrowImage from "../../assets/images/VSOList/icon-arrow-right-vso.svg";
import '../VSOs/VSOs.css';
import { useHistory } from 'react-router-dom';
import VSOItem from './vsoItem';

const VSOs = ({setAuth}) => {
    const { state, actions, effects } = useOvermind();
    // const [options, setOptions] = useState(false);
    const history = useHistory();

    useEffect(() => {
        actions.sidebar.setPage('virtual-office-list');
        actions.topbar.showShadow();
        actions.topbar.hideLogo();

        effects.currentUser.verifyToken().then((res) => {
            if(res) {
                actions.currentUser.loadCurrentUser();
                actions.VSOs.loadVSOs({state, effects});
            }
            else {
                setAuth(false);
            }
        });

    }, []);

    const formatUpdatedAt = (str) => {
        return str?.split("T")[0];
    }

    const typeOfText = (str) => {
        if(str === 'draft'){
            return "Complete virtual office";
        }
        if(str === 'active'){
            return "Access virtual office";
        }
        if(str === 'inactive'){
            return "Activate"
        }
    }

   const handleDeleteVso = (vsoId) => {
        actions.VSOs.deleteVso(vsoId)
   }

   const pushToLocation = (status, id) => {
        if(status === 'draft'){
            actions.productForm.clearProduct();
            actions.productForm.setProductType('vso');
            history.push("start-project-vso/" + id);
        }
        if(status === 'active'){
            actions.productForm.clearProduct();
            history.push("/vso/" + id);
        }
   }

   const handleCreateNewVso = () => {
        actions.productForm.clearProduct();
        actions.productForm.setProductType('vso');
        actions.productForm.createProduct().then(() => {
            history.push("start-project-vso/" + state.productForm.newProduct?.id);
        })
   }

    return(
        <>
        <div className={`dashboard-container-vso ${Object.keys(state.VSOs.list).length === 0 ? '--inactive' : ''}`}>
            <h2>My Virtual Sales Offices</h2>
            <div className="dashboard__split-container-vso">
                {state.VSOs.isLoading ? 'Loading...'
                :
                <>
                {Object.keys(state.VSOs.list).length === 0 && 
                <>
                <div className="projects__no-vso-container">
                    <div className="projects__no-vso-content">
                        <div>Build Your first real estate virtual sales office</div>
                        <div>In here, you can create your own virtual sales offices where your brookers and sales representatives can assist your future home buyers. 
                            In this virtual space, you can creat a project presentation, organize virtual tour, showcase plans and available units, 
                            host digital events and creat an engaging environnement to help your users reserve their next unit.</div>
                        <div className="projects__no-vso-button-container">
                            <img src={DiscoverImage}/>
                            <div>Discover how it works</div>
                        </div>
                    </div>
                    <div className="projects__no-vso-img-container">
                        <img src={VSOImage} />
                    </div>
                </div>
                </>    
                }
                {Object.keys(state.VSOs.list).length > 0 && 
                    <div>
                        {Object.values(state.VSOs.list).map((vso, index) => {
                            return(
                                <VSOItem
                                    key={index}
                                    vsoId={vso.virtual_sales_office_id} 
                                    projectImage={vso.fields.images?.value.array[0]}
                                    projectName={vso.fields.project_name?.value}
                                    projectAddress={vso.fields.project_address?.value.full_string}
                                    projectStatus={vso.status}
                                    changeStatus={actions.VSOs.changeStatus}
                                    handleDelete={handleDeleteVso}
                                    projectUpdatedAt={vso.updated_at}
                                    formatUpdatedAt={formatUpdatedAt}
                                    pushToLocation={pushToLocation}
                                    typeOfText={typeOfText}
                                />
                            )

                        })}
                    </div>
                }
                {Object.keys(state.VSOs.list).length === 0 && 
                    <div className="projects__no-vso-start" onClick={handleCreateNewVso}>
                        <div>Start</div>
                        <div>Building your office</div>
                        <img src={RightArrowImage} />
                    </div>
                }

                {Object.keys(state.VSOs.list).length > 0 && <div className="vso__start-project" onClick={handleCreateNewVso}>+</div>}
                </>}
            </div>
        </div>
        </>
    );
} 

export default VSOs;