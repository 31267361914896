import { derived } from "overmind";

export const state = {
    userId: '',
    type: '',
    firstName: '',
    lastName: '',
    lastLogin: '',
    fullName: derived((state) => {
        return `${state.firstName} ${state.lastName}`;
    }),
    initial: derived((state) => {
        return `${state.firstName[0] || ''}`;
    })
};