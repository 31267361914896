import React, {useState, useEffect} from "react";
import "./selectInput.scss";
import { useTranslation } from "react-i18next";

const SelectInput = ({value, setValue, dataObj, options, selectOptions, translationsNamespace}) => {
    const [fieldId, setFieldId] = useState('');
    const { t } = useTranslation(translationsNamespace); 

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(`sti-${Math.floor(Math.random() * Date.now())}`));
    }, []);

    const handleValueChange = (e) => {
        dataObj.newValue = e.currentTarget.value;
        setValue(dataObj);
    }

    return(
        <select className="tableInput__select" id={`input__${fieldId}`} value={value ? value : selectOptions[0]} onChange={handleValueChange}>
            {selectOptions.map((o, index) => {
                return(
                <option key={`option__${fieldId}-${index}`} value={o}>{dataObj.isCustom ? o : t(`selectOptions.${o}`)}</option>
                )
            })}
        </select>
    )
}

export default SelectInput;