
import React, { useEffect, useRef, useState } from 'react';
import Button from 'src/components/shared/button';
import Popover from 'src/components/shared/popover';
import SvgIcons from 'src/components/shared/svgIcons';
import EditColumnsMenu from './editColumnsMenu';

const EditColumns = ({optionalColumns, setOptionalColumns, setCustomColumn, removeCustomColumn, addColumnsData, sendEditColumnData, sendAllColumnsData}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [popoverCoords, setPopoverCoords] = useState({});
    const editColumnsButtonRef = useRef(null);

    // Saves all columns data when menu gets closed
    useEffect(() => {
        if(optionalColumns.length > 0) {
            if(!isOpen) {
                sendAllColumnsData();
            }
        }
    }, [isOpen]);

    const handleEditColumnsClick = () => {
        setIsOpen((prev) => !prev);
        const rect = editColumnsButtonRef.current.getBoundingClientRect();
        setPopoverCoords({
            left: rect.x + window.scrollX,
            top: rect.y + window.scrollY,
            height: rect.height,
            width: rect.width
        });
    }
    
    return(
        <>
        <Button type="secondary"
            size="small"
            text="Edit columns"
            options={{ref: editColumnsButtonRef, activeState: isOpen}}
            onClick={handleEditColumnsClick}
        >
            <SvgIcons type="cabana_edit_columns" />
        </Button>
        <Popover isOpen={isOpen} setIsOpen={setIsOpen} position="se" coords={popoverCoords} visibleBackdrop>
            <EditColumnsMenu optionalColumns={optionalColumns} 
                setOptionalColumns={setOptionalColumns} 
                setCustomColumn={setCustomColumn} 
                removeCustomColumn={removeCustomColumn} 
                addColumnsData={addColumnsData}
                sendEditColumnData={sendEditColumnData}
            />
        </Popover>
        </>
    )
}

export default EditColumns;