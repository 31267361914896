export const state = {
    data: {},
    fields: [],
    results: [],
    leads: [],
    bedroomPercentages: [],
    parkingPercentages: [],
    totalLeads: 0,
    peopleReached: "",
    linkClicks: "",
    budgetSpent: "",
    avgBudget: 0,
    lastUpdated: "",
    hotLeads: 0,
    graphMinMax: {
        global: {
            min: 0,
            max: 0,
            avg: 0,
        },
        studio: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr1: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr2: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr3: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr4: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
    },
};