import React from "react";
import Button from "../button";
import SvgIcons from "../svgIcons";
import "./warningMessage.scss";

const WarningMessage = ({cancelClick, confirmClick, title, infoMessage}) => {
    return(
        <div className="warningMessage__container">
            <SvgIcons type="cabana_warning_sign"/>
            <div className="warningMessage__text-container">
                <div className="warningMessage__title">{title}</div>
                <div className="warningMessage__info-text">{infoMessage}</div>
            </div>
            <div className="warningMessage__button-container">
                <Button type="ternary" text="Cancel" onClick={cancelClick}/>
                <Button type="primary" text="Confirm" onClick={confirmClick}/>
            </div>
        </div>
    )
}

export default WarningMessage;