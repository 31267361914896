import React, { useState, useEffect, useRef } from 'react';
import { useOvermind } from 'src/overmind';
import { useTranslation } from 'react-i18next';

import './overview.scss';
import addressIcon from 'src/assets/images/icons/address.svg';
import buildingIcon from 'src/assets/images/icons/building.svg';
import UnitBox from './unitBox';
import InclusionsList from './inclusionsList';
import { useScrollPosition } from 'src/utils/hooks';
import ProjectOverviewBox from './projectOverviewBox';
import OverviewTags from './overviewTags';
import SvgIcons from 'src/components/shared/svgIcons';

const Overview = () => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation('overview');
    
    const [activeTab, setActiveTab] = useState('');
    const projectDetailsRef = useRef(null);
    const projectInclusionsRef = useRef(null);
    const unitDetailsRef = useRef(null);
    const parkingRef = useRef(null);
    const appliancesRef = useRef(null);
    const policiesRef = useRef(null);
    
    let scrollPosition = useScrollPosition();
    
    useEffect(() => {
        if((projectDetailsRef.current?.offsetTop - 20) > scrollPosition) {
            setActiveTab('details');
        } else if((projectInclusionsRef.current?.offsetTop - 20) > scrollPosition){
            setActiveTab('project');
        } else if((parkingRef.current?.offsetTop - 20) > scrollPosition){
            setActiveTab('parking');
        } else if((unitDetailsRef.current?.offsetTop - 20) > scrollPosition){
            setActiveTab('unit');
        } else if((appliancesRef.current?.offsetTop - 20) > scrollPosition){
            setActiveTab('appliances');
        } else if ((policiesRef.current?.offsetTop - 20) > scrollPosition) {
            setActiveTab('policies');
        }

    }, [scrollPosition])

    useEffect(() => {
        actions.topbar.setCurrentPage('overview');
        
        return () => {
            actions.topbar.setCurrentPage('');
        }
    }, []);

    const handleClickNavItem = (ref, tabStatus) => {
        setActiveTab(tabStatus);
        ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
    }

    return (
        <div className="overview__container">
            <ProjectOverviewBox item={state?.currentVSO} />
            <div className="overview__units-grid">
                {state.currentVSO.fields.num_studios > 0 &&
                <UnitBox 
                    name={'Studio'} 
                    numberOfUnits={state.currentVSO.fields.num_studios} 
                    minArea={state.currentVSO.fields.min_area_studios} 
                    maxArea={state.currentVSO.fields.max_area_studios} 
                    minPrice={state.currentVSO.fields.min_price_studios} 
                    maxPrice={state.currentVSO.fields.max_price_studios}
                />
                }
                {state.currentVSO.fields.num_1_bdr > 0 &&
                <UnitBox 
                    name={'1 ' + t('overviewPage.bedroom')} 
                    numberOfUnits={state.currentVSO.fields.num_1_bdr} 
                    minArea={state.currentVSO.fields.min_area_1_bdr} 
                    maxArea={state.currentVSO.fields.max_area_1_bdr} 
                    minPrice={state.currentVSO.fields.min_price_1_bdr} 
                    maxPrice={state.currentVSO.fields.max_price_1_bdr}
                    projectType={state.currentVSO.fields.project_type}
                />
                }
                {state.currentVSO.fields.num_2_bdr > 0 &&
                <UnitBox 
                    name={'2 ' + t('overviewPage.bedroom')} 
                    numberOfUnits={state.currentVSO.fields.num_2_bdr} 
                    minArea={state.currentVSO.fields.min_area_2_bdr} 
                    maxArea={state.currentVSO.fields.max_area_2_bdr} 
                    minPrice={state.currentVSO.fields.min_price_2_bdr} 
                    maxPrice={state.currentVSO.fields.max_price_2_bdr}
                    projectType={state.currentVSO.fields.project_type}
                />
                }
                {state.currentVSO.fields.num_3_bdr > 0 &&
                <UnitBox 
                    name={'3 ' + t('overviewPage.bedroom')} 
                    numberOfUnits={state.currentVSO.fields.num_3_bdr} 
                    minArea={state.currentVSO.fields.min_area_3_bdr} 
                    maxArea={state.currentVSO.fields.max_area_3_bdr} 
                    minPrice={state.currentVSO.fields.min_price_3_bdr} 
                    maxPrice={state.currentVSO.fields.max_price_3_bdr}
                    projectType={state.currentVSO.fields.project_type}
                />
                }
                {state.currentVSO.fields.num_4_bdr > 0 &&
                <UnitBox 
                    name={'4 ' + t('overviewPage.bedroom')} 
                    numberOfUnits={state.currentVSO.fields.num_4_bdr} 
                    minArea={state.currentVSO.fields.min_area_4_bdr} 
                    maxArea={state.currentVSO.fields.max_area_4_bdr} 
                    minPrice={state.currentVSO.fields.min_price_4_bdr} 
                    maxPrice={state.currentVSO.fields.max_price_4_bdr}
                    projectType={state.currentVSO.fields.project_type}
                />
                }
                {state.currentVSO.fields.num_loft > 0 &&
                <UnitBox 
                    name={t('overviewPage.loft')} 
                    numberOfUnits={state.currentVSO.fields.num_loft} 
                    minArea={state.currentVSO.fields.min_area_loft} 
                    maxArea={state.currentVSO.fields.max_area_loft} 
                    minPrice={state.currentVSO.fields.min_price_loft} 
                    maxPrice={state.currentVSO.fields.max_price_loft}
                    projectType={state.currentVSO.fields.project_type}
                />
                }
                {state.currentVSO.fields.num_penthouse > 0 &&
                <UnitBox 
                    name={t('overviewPage.penthouse')} 
                    numberOfUnits={state.currentVSO.fields.num_penthouse} 
                    minArea={state.currentVSO.fields.min_area_penthouse} 
                    maxArea={state.currentVSO.fields.max_area_penthouse} 
                    minPrice={state.currentVSO.fields.min_price_penthouse} 
                    maxPrice={state.currentVSO.fields.max_price_penthouse}
                    projectType={state.currentVSO.fields.project_type}
                />
                }
                {state.currentVSO.fields.num_townhouse > 0 &&
                <UnitBox 
                    name={t('overviewPage.townhouse')} 
                    numberOfUnits={state.currentVSO.fields.num_townhouse} 
                    minArea={state.currentVSO.fields.min_area_townhouse} 
                    maxArea={state.currentVSO.fields.max_area_townhouse} 
                    minPrice={state.currentVSO.fields.min_price_townhouse} 
                    maxPrice={state.currentVSO.fields.max_price_townhouse}
                    projectType={state.currentVSO.fields.project_type}
                />
                }
            </div>
            {(state.currentVSO.fields.common_spaces.length > 0 || state.currentVSO.fields.project_amenities.length) > 0 &&
                <InclusionsList title={t('overviewPage.projectInclusion').toUpperCase()} 
                            lists={[state.currentVSO.fields.common_spaces?.map((el) => t(`commonSpace.${el}`)), 
                                state.currentVSO.fields.project_amenities?.map((el) => t(`projectAmenities.${el}`))]} 
                            listNames={[t('overviewPage.commonSpace'), t('overviewPage.amenities')]}
                            options={{gridStyle: {gridTemplateColumns: 'repeat(2, 1fr)'}}}/>
            }
            {(state.currentVSO.fields.parking_type || state.currentVSO.fields.electric_parking || 
            state.currentVSO.fields.visitor_parking) && 
                <InclusionsList title={t('overviewPage.parkingDetails').toUpperCase()} 
                                lists={[
                                        [
                                            `${t('overviewPage.parkingInfo.electricParking')}: ${t(`overviewPage.parkingInfo.${state.currentVSO.fields?.electric_parking}`)}`,  
                                            `${t('overviewPage.parkingInfo.visitorParking')}: ${t(`overviewPage.parkingInfo.${state.currentVSO.fields?.visitor_parking}`)}`,  
                                            `${t('overviewPage.parkingInfo.parkingWithPermit')}: ${t(`overviewPage.parkingInfo.${state.currentVSO.fields?.parking_with_permit}`)}`,   
                                        ],
                                        [
                                            ... state.currentVSO.fields.extra_int_fee != "0" && state.currentVSO.fields.parking_price_included == 'no' ? [`${t('overviewPage.parkingInfo.indoorParkingFee')}: $${state.currentVSO.fields.extra_int_fee} ${state.currentVSO.fields.extra_parking_fee_type === "monthlyFee" ? t(`${'overviewPage.parkingInfo.month'}`) : ""}`] : [],   
                                            ... state.currentVSO.fields.extra_ext_fee != "0" && state.currentVSO.fields.parking_price_included == 'no' ? [`${t('overviewPage.parkingInfo.outdoorParkingFee')}: $${state.currentVSO.fields.extra_ext_fee} ${state.currentVSO.fields.extra_parking_fee_type === "monthlyFee" ? t(`${'overviewPage.parkingInfo.month'}`) : ""}`] : [],   
                                            ... state.currentVSO.fields.electric_parking_price != "0" && state.currentVSO.fields.electric_parking == 'available' ? [`${t('overviewPage.parkingInfo.electricParkingFee')}: $${state.currentVSO.fields.electric_parking_price} ${state.currentVSO.fields.extra_parking_fee_type === "monthlyFee" ? t(`${'overviewPage.parkingInfo.month'}`) : ""}`] : [] 
                                        ]
                                        ]} 
                                listNames={[t('overviewPage.parkingOption'), t('overviewPage.parkingFee')]} 
                                options={{gridStyle: {gridTemplateColumns: 'repeat(2, 1fr)'}}}/>
                
            }
            {(state.currentVSO.fields.units_furnishing !== "not_furnished") &&
                <InclusionsList title={t('overviewPage.appliancesAndFurnitures').toUpperCase()} 
                                lists={[state.currentVSO.fields.units_appliances?.map((el) => t(`unitsAppliances.${el}`)), ... state.currentVSO.fields.units_furnishing === "fully_furnished" ? [state.currentVSO.fields.units_furnitures?.map((el) => t(`unitsFurnitures.${el}`))] : []]} 
                                listNames={[t('overviewPage.appliancesIncluded'), ... state.currentVSO.fields.units_furnishing === "fully_furnished" ? [t('overviewPage.furnituresIncluded')] : []]}
                                options={{gridStyle: {gridTemplateColumns: 'repeat(2, 1fr)'}}}/>
                
            }
            {(state.currentVSO.fields.units_amenities?.length > 0 || state.currentVSO.fields.units_utilities?.length > 0 ||
            state.currentVSO.fields.units_flooring?.length > 0) &&
                <InclusionsList title={t('overviewPage.unitDetails').toUpperCase()} 
                                lists={[
                                        [...state.currentVSO.fields.units_utilities?.map((el) => t(`unitsUtilities.${el}`))],
                                        [...state.currentVSO.fields.units_flooring?.map((el) => t(`flooring.${el}`))],
                                        [...state.currentVSO.fields.units_heating_system?.map((el) => t(`heatingSystem.${el}`)),
                                            ...state.currentVSO.fields.units_air_conditioning?.map((el) => t(`airConditioning.${el}`))],
                                    ]}
                                listNames={[t('overviewPage.utilities'), t('overviewPage.flooring'), t('overviewPage.energy')]} /> 
            }
            {(state.currentVSO.fields.kitchen_backsplash?.length > 0 || state.currentVSO.fields.kitchen_cabinet?.length > 0 ||
            state.currentVSO.fields.kitchen_sink_basin?.length > 0 || state.currentVSO.fields.kitchen_sink_faucet?.length > 0 ||
            state.currentVSO.fields.kitchen_countertops?.length > 0 || state.currentVSO.fields.kitchen_storage?.length > 0 ||
            state.currentVSO.fields.kitchen_inclusions?.length > 0) && 
                <InclusionsList title={t('overviewPage.kitchenDetails').toUpperCase()}
                                lists={[
                                        [
                                            ...state.currentVSO.fields.kitchen_backsplash?.map((el) => t(`kitchenBacksplash.${el}`)) 
                                        ],
                                        [
                                            ...state.currentVSO.fields.kitchen_cabinet?.map((el) => t(`kitchenCabinetMaterials.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.kitchen_sink_basin?.map((el) => t(`kitchenSink.${el}`)),
                                            ...state.currentVSO.fields.kitchen_sink_faucet?.map((el) => t(`kitchenSink.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.kitchen_countertops?.map((el) => t(`kitchenCounterTops.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.kitchen_storage?.map((el) => t(`kitchenStorage.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.kitchen_inclusions?.map((el) => t(`kitchenInclusions.${el}`))
                                        ],
                                ]}
                                listNames={[t('overviewPage.kitchenBacksplash'), t('overviewPage.kitchenCabinetMaterials'), t('overviewPage.kitchenSink'), 
                                            t('overviewPage.kitchenCountertops'), t('overviewPage.kitchenStorage'), t('overviewPage.kitchenInclusions')]}/>
            }
            {(state.currentVSO.fields.bathroom_shower_shape?.length > 0 ||
            state.currentVSO.fields.bathroom_shower_materials?.length > 0 ||
            state.currentVSO.fields.bathroom_shower_door?.length > 0 ||
            state.currentVSO.fields.bathroom_shower_head?.length > 0 ||
            state.currentVSO.fields.bathroom_bathtub?.length > 0 ||
            state.currentVSO.fields.bathroom_countertops?.length > 0 ||
            state.currentVSO.fields.bathroom_sink_basin?.length > 0 ||
            state.currentVSO.fields.bathroom_sink_shape?.length > 0 ||
            state.currentVSO.fields.bathroom_cabinet?.length > 0 ||
            state.currentVSO.fields.bathroom_toilet_type?.length > 0 ||
            state.currentVSO.fields.bathroom_toilet_style?.length > 0 ||
            state.currentVSO.fields.bathroom_toilet_bidet?.length > 0 ||
            state.currentVSO.fields.bathroom_backsplash?.length > 0 ||
            state.currentVSO.fields.bathroom_vanity_materials?.length > 0) &&
                <InclusionsList title={t('overviewPage.bathroomDetails').toUpperCase()} 
                                lists={[
                                        [
                                            ...state.currentVSO.fields.bathroom_shower_shape?.map((el) => t(`bathroomShower.${el}`)),
                                            ...state.currentVSO.fields.bathroom_shower_materials?.map((el) => t(`bathroomShower.${el}`)),
                                            ...state.currentVSO.fields.bathroom_shower_door?.map((el) => t(`bathroomShower.${el}`)),
                                            ...state.currentVSO.fields.bathroom_shower_head?.map((el) => t(`bathroomShower.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.bathroom_bathtub?.map((el) => t(`bathroomBathtub.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.bathroom_countertops?.map((el) => t(`bathroomCounterTops.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.bathroom_sink_basin?.map((el) => t(`bathroomSink.${el}`)),
                                            ...state.currentVSO.fields.bathroom_sink_shape?.map((el) => t(`bathroomSink.${el}`)),
                                            ...state.currentVSO.fields.bathroom_sink_materials?.map((el) => t(`bathroomSink.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.bathroom_cabinet?.map((el) => t(`bathroomCabinetMaterials.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.bathroom_toilet_type?.map((el) => t(`bathroomToilet.${el}`)),
                                            ...state.currentVSO.fields.bathroom_toilet_style?.map((el) => t(`bathroomToilet.${el}`)),
                                            ...state.currentVSO.fields.bathroom_toilet_bidet?.map((el) => t(`bathroomToilet.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.bathroom_backsplash?.map((el) => t(`bathroomBacksplash.${el}`))
                                        ],
                                        [
                                            ...state.currentVSO.fields.bathroom_vanity_materials?.map((el) => t(`vanityMaterials.${el}`))
                                        ]
                                ]}
                                listNames={[t('overviewPage.bathroomShower'), t('overviewPage.bathroomBathtub'), t('overviewPage.bathroomCountertops'), 
                                            t('overviewPage.bathroomSink'), t('overviewPage.bathroomCabinetMaterials'), t('overviewPage.bathroomToilet'), 
                                            t('overviewPage.bathroomBacksplash'), t('overviewPage.vanityMaterials')]}/>
            }
            {(state.currentVSO.fields.structure || state.currentVSO.fields.exterior_features || state.currentVSO.fields.roof) && 
                <InclusionsList title={t('overviewPage.buildingDetails').toUpperCase()} 
                                lists={[state.currentVSO.fields.structure?.map((el) => t(`structure.${el}`)), 
                                        state.currentVSO.fields.exterior_features?.map((el) => t(`facadeMaterials.${el}`)), 
                                        state.currentVSO.fields.roof?.map((el) => t(`roof.${el}`))]} 
                                listNames={[t('overviewPage.buildingStructure'), t('overviewPage.buildingFacadeMaterials'), t('overviewPage.roofDetails')]}/>
                
            }
            <div className="overview__policies-container">
                <OverviewTags text={t('overviewPage.projectPolicies.projectPolicies').toUpperCase()} />
                <div className="overview__policies-grid">
                    {
                        state.currentVSO.fields.policies_dog_weight > 0 && state.currentVSO.fields.policies_dog_tolerance  === 'size_limited' ?
                        <div className="overview__policy-item">
                                <SvgIcons type={state.currentVSO.fields.policies_dog_weightt > 0 ? "cabana_green_circle_checkmark" : "cabana_unit_sold_icon"}/> 
                                {`${t('overviewPage.projectPolicies.dogsUnder')} ${state.currentVSO.fields.policies_dog_weight} ${t('overviewPage.projectPolicies.poundsAllowed')}`} 
                        </div>
                        : 
                        <div className="overview__policy-item">
                            <SvgIcons type={state.currentVSO.fields.policies_dog_tolerance  === 'allowed'? "cabana_green_circle_checkmark" : "cabana_unit_sold_icon"}/> 
                             {`${t('overviewPage.projectPolicies.dogs')} ${t(`overviewPage.projectPolicies.${state.currentVSO.fields.policies_dog_tolerance}`)}`}
                        </div>
                    }
                    <div className="overview__policy-item">
                        <SvgIcons type={state.currentVSO.fields.policies_cat_tolerance === 'yes' ? "cabana_green_circle_checkmark" : "cabana_unit_sold_icon"}/> 
                        {`${t('overviewPage.projectPolicies.cats')} ${t(`overviewPage.projectPolicies.${state.currentVSO.fields.policies_cat_tolerance}`)}`}
                    </div>
                    <div className="overview__policy-item">
                        <SvgIcons type={state.currentVSO.fields.policies_smoking_tolerance === 'yes' ? "cabana_green_circle_checkmark" : "cabana_unit_sold_icon"}/> 
                        {`${t('overviewPage.projectPolicies.smoking')} ${t(`overviewPage.projectPolicies.${state.currentVSO.fields.policies_smoking_tolerance}`)}`}
                    </div>
                    <div className="overview__policy-item">
                        <SvgIcons type={state.currentVSO.fields.policies_bbq_tolerance === 'yes' ? "cabana_green_circle_checkmark" : "cabana_unit_sold_icon"}/> 
                        {`${t('overviewPage.projectPolicies.bbq')} ${t(`overviewPage.projectPolicies.${state.currentVSO.fields.policies_bbq_tolerance}`)}`}
                    </div>
                    {state.currentVSO.fields.project_type === 'rent' &&
                        <>                        
                            {state.currentVSO.fields.policies_credit_check === 'allowed' &&
                            <div className="overview__policy-item">
                                <SvgIcons type="cabana_green_circle_checkmark" /> 
                                {t('overviewPage.projectPolicies.creditCheck')}
                            </div>
                            }
                            {state.currentVSO.fields.policies_criminal_check === 'allowed' &&
                            <div className="overview__policy-item">
                                <SvgIcons type="cabana_green_circle_checkmark" /> 
                                {t('overviewPage.projectPolicies.criminalCheck')}
                            </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Overview;