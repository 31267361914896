import React from 'react';
import { floorNaming } from 'src/utils/units';
import './floorPlan.scss';
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';
import DragDropTemplate from 'src/components/shared/dragDropTemplate';

const FloorPlan = ({
    currentFloorData,
    editMode,
    currentFloor,
    setBatchUpload,
    batchfloorPlanRef,
    handleBatchUploadChange,
    uploadNewFloorPlan,
    handleUploadClick,
    setDragHover,
    dragHover,
    handleDropFile
}) => {
    return(
        <>
        <div className="floorPlan__upload-info-container">
            <div className="floorPlan__upload-text-container">
                <div className="floorPlan__upload-title">{editMode ? 'Edit' : 'Upload'} {floorNaming(parseInt(currentFloor) + 1)} floor plan</div>
                <div className="floorPlan__upload-text">{editMode ? 'Edit' : 'Upload'} the plan of the {floorNaming(parseInt(currentFloor) + 1)} floor in PNG or JPG.</div>
            </div>
            <Button type="secondary" text="Batch upload plans" onClick={() => setBatchUpload(true)}><SvgIcons type="cabana_batch_upload"/></Button>
            <input
                type="file"
                multiple="multiple"
                ref={batchfloorPlanRef}
                onChange={handleBatchUploadChange}
                style={{display: 'none'}} 
            />
        </div>
        {currentFloorData[currentFloor]?.isUploaded ? 
            <div className="floorPlan__floor-plan-container">
                <div className="floorPlan__floor-plan-image">
                    <img src={currentFloorData[currentFloor]?.plan} />
                </div>
                <Button type="secondary" text="Upload a new floor plan" onClick={uploadNewFloorPlan}><SvgIcons type="cabana_floor_plan_upload"/></Button>
            </div>
            :
            <DragDropTemplate 
                text={`Drag & Drop ${floorNaming(currentFloorData[currentFloor]?.name.split(' ')[1])} floor plan`}
                onClick={() => handleUploadClick(currentFloor)}
                setDragHover={setDragHover}
                dragHover={dragHover}
                filesDrop={handleDropFile}
            />
        }
        </>
    )
}

export default FloorPlan;