import React from 'react';
import { createPortal } from 'react-dom';

import './dropdown.css';

const Dropdown = ({isOpen, coords, setIsOpen, items, position = 's'}) => {

    const handleBackdropClick = () => {
        setIsOpen(false);
    }

    return(
        <>
        {
        coords ?
            createPortal(
                <div className="dropdown__portal" style={isOpen ? coords : {display: "none"}}>
                    {isOpen &&
                        <div className="dropdown__backdrop" onClick={handleBackdropClick}></div>
                    }
                    <div className={`dropdown__container ${isOpen ? '' : '--closed'} --${position}`} onClick={(e) => e.stopPropagation()}>
                        {items.map((item, index) => {
                            return (
                                <div key={`dropdown-${index}`} className={`dropdown__item ${item.classesString ? item.classesString : ''}`} onClick={item.clickAction ? item.clickAction : null}>
                                    {item.content}
                                </div>
                            )
                        })}
                    </div>
                </div>, document.querySelector('#dropdowns')
            )
            :
            <>
                {isOpen &&
                    <div className="dropdown__backdrop" onClick={handleBackdropClick}></div>
                }
                <div className={`dropdown__container ${isOpen ? '' : '--closed'} --${position}`}>
                    {items.map((item, index) => {
                        return (
                            <div key={`dropdown-${index}`} className={`dropdown__item ${item.classesString ? item.classesString : ''}`} onClick={item.clickAction ? item.clickAction : null}>
                                {item.content}
                            </div>
                        )
                    })}
                </div>
            </>
        }
        </>
    );
}

export default Dropdown;