export const setVsoId = ({state}, vsoId) => {
    state.vsoDashboard.vsoId = vsoId;
}

export const setCurrentPage = ({state}, page) => {
    state.vsoDashboard.currentPage = page;
}

export const getLeadsMessages = async ({state, effects}) => {
    const res = await effects.vsoDashboard.getLeadsMessages(state.vsoDashboard.vsoId);
    let messages = [];
    res.forEach((row) => {
        messages.push({
            id: row.vso_message_id,
            lead_id: row.lead_id,
            name: `${row.first_name} ${row.last_name}`,
            phone_number: row.phone_number,
            message: row.message,
            created_at: row.created_at
        });
    });
    state.vsoDashboard.leadsMessages.data = messages;
}