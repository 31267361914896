import React from 'react';
import "./overviewTags.scss";

const OverviewTags = ({text}) => {
    return(
        <div className="overViewTags__container">
            <span className='overViewTags__text'>{text}</span>
        </div>
    )
}

export default OverviewTags;