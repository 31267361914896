import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useOvermind } from '../../overmind';
// import { GoogleLogin } from 'react-google-login';
import TextInput from 'src/components/shared/inputs/textInput';
import { validateTextInput } from 'src/utils/fieldValidation';

require('dotenv').config();

const Login = ({setAuth}) => {
    const { actions } = useOvermind();
    const [inputs, setInputs] = useState({
        email: '',
        password: ''
    });
    const [formErrors, setFormErrors] = useState({
        email: null,
        password: null
    });

    useEffect(() => {
        actions.topbar.hideShadow();
        actions.topbar.hideLogo();
    }, []);
    
    const handleInputChange = (e, dataObj) => {
        setInputs((prevState) => {return {...prevState, [dataObj.field]: e.target.value}});
    }

    // Handle normal signin
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors({
            email: null,
            password: null
        });

        try {
            const body = {email: inputs.email, password: inputs.password};

            // Request to login user
            const response = await fetch(
                process.env.REACT_APP_SERVER_URL + 'auth/login',
                {
                    method: 'POST',
                    headers: {'Content-Type' : 'application/json'},
                    body: JSON.stringify(body)
                }
            );

            // Check errors and display them
            const parseRes = await response.json();
            if(parseRes.hasOwnProperty('errors')){
                for( let i = 0; i < parseRes.errors.length; i++){
                    if(['email', 'password'].includes(parseRes.errors[i].param)) {
                        setFormErrors((prevState) => {return {...prevState, [parseRes.errors[i].param]: parseRes.errors[i].msg}});
                    } else {
                        console.log("Server Error");
                    }
                }
            }

            // Check token and authenticate
            localStorage.setItem('token', parseRes.token);
            if(localStorage.token !== 'undefined') {
                setAuth(true);
            }

        } catch (err) {
            console.error(err.message);
        }
    } 

    /*
    // Handle google login
    const responseGoogle = async (response) => {
        try {
            const body = { 
                email: response.profileObj.email, 
                tokenId: response.tokenId
            };
            
            // Request for user login through google
            const res = await fetch(
                process.env.REACT_APP_SERVER_URL + 'google/login',
                {
                    method: 'POST',
                    headers: {'Content-Type' : 'application/json'},
                    body: JSON.stringify(body)
                }
            );

            // Check token and authenticate
            const parseRes = await res.json();
            localStorage.setItem('token', parseRes.token);
            if(localStorage.token !== 'undefined') {
                setAuth(true);
            }
            
        } catch (err) {
            console.error(err.message);
        }
    };*/
    
    return (
        <>
        <div className="auth__container">
            <h1>Login</h1>
            <p className="p--muted p--centered">Sign in to your account to continue.</p>
            <form className="auth__form" onSubmit={handleSubmit}>
                <TextInput label="Email address" 
                    value={inputs.email}
                    setValue={handleInputChange}
                    dataObj={{field: 'email'}} 
                    error={formErrors.email}
                    placeholder="name@example.com"
                    options={{isEmail: true}}
                />
                <TextInput label="Password" 
                    value={inputs.password}
                    setValue={handleInputChange}
                    dataObj={{field: 'password'}} 
                    error={formErrors.password}
                    placeholder="********"
                    options={{isPassword: true}}
                />
                <Link to="/" className="input__password-forgotten p--small p--muted">I forgot my password</Link>
                <button className="btn-primary">Login</button>
            </form>
            {/*<div className="GoogleLogin">
                <GoogleLogin
                    clientId="110737638975-kr4ctnugscjaierd1dfc0vo1te27vtbv.apps.googleusercontent.com"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                />
            </div>*/}
            <p className="p--small p--muted">Not registered yet? <Link to="/signup">Sign up</Link></p>
        </div>
        </>
    )
}

export default Login;
