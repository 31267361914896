export const state = {
    projectsList: [],
    newProject: {
        name: '',
        address: '',
        type: '',
        project_status: '',
        units_type: '',
        estd_construction_start: '',
        estd_delivery: '',
        nb_of_buildings: '',
        nb_of_floors: '',
        nb_of_units: '',
        specifications: '',
        heritage_restrictions: '',
        logo: '',
        units: {
            nb_of_studio: '',
            nb_of_1_br: '',
            nb_of_2_br: '',
            nb_of_3_br: '',
            nb_of_4_more_br: '',
            is_loft: '',
            is_penthouse: '',
            is_other: '',
            studio_areas: [],
            one_br_areas: [],
            two_br_areas: [],
            three_br_areas: [],
            four_more_br_areas: [],
            studio_prices: [],
            one_br_prices: [],
            two_br_prices: [],
            three_br_prices: [],
            four_more_br_prices: [],
        },
        inclusions: '',
        units_inclusions: '',
        policies: '',
        parking: {
            total_parking_spaces: '',
            nb_of_garage_spaces: '',
            nb_of_outdoor_spaces: '',
            has_street_parking: '',
            has_visitor_parking: '',
            has_electric_parking: '',
            nb_electric_spaces: '',
        },
        images: {
            images_urls: []
        },
        team: {
            architect: '',
            builder: '',
            developer: '',
            sales: '',
            marketing: '',
        },
        description: {
            content: '',
        },
        has_3d_submission: false,
        three_d_submission: {
            cad_plans: '',
            camera_position: [],
            materials_reference: '',
            site_plans: ''
        }
    },
    currentProject: {
        
    },
    firstFormStep: 1,
    formStep: 1,
};