import React, {useEffect, useState} from 'react';
import SvgIcons from 'src/components/shared/svgIcons';
import OverviewTags from '../overviewTags';

import './inclusionsList.scss';

const InclusionsList = ({title, lists, listNames, options={gridStyle: {}}}) => {
    const [displayList, setDisplayList] = useState(false);
    const [isProjectInclusionOpen, setIsProjectInclusionOpen] = useState(true);


    useEffect(() => {
        for(const list of lists) {
            if(list.length > 0) {
                setDisplayList(true);
                return;
            }
        }
    }, []);

    const handleSeeMoreClick = (e) => {
        setIsProjectInclusionOpen(!isProjectInclusionOpen);
    }


    if(!displayList) {
        return null;
    } else {
        return (
        <>
            <div className={`inclusionsList__container ${isProjectInclusionOpen ? '--open' : ''}`}>
                {/* <div className="inclusionsList__see-more" onClick={handleSeeMoreClick} data-list-name="project-inclusions">See more</div> */}
                <OverviewTags text={title} />
                <div className="inclusionsList__inner-container" style={options.gridStyle}>
                {lists.map((list, listIndex) => {
                    if(list.length > 0) {
                        return(                    
                            <div className="inclusionsList__wrapper" key={`${listNames[listIndex]}`}>
                                <div className="inclusionsList__title">{listNames[listIndex]}</div>
                                <div className="inclusionsList__divider" />
                                <div className="inclusionsList__list">
                                    {list.map((elem, index) => {
                                        return (
                                            <div className="inclusionsList__item" key={`${listNames[listIndex]}-${index}`}><div><SvgIcons type="cabana_circle_checkmark_icon"/></div>{elem}</div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }
                })}
                </div>
            </div>
        </>
        )
    }
}

export default InclusionsList;