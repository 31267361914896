import React from 'react';
import Button from '../button';
import SvgIcons from '../svgIcons';
import './dragDropTemplate.scss';

const DragDropTemplate = ({text, onClick, files, filesDrop, setDragHover, dragHover}) => {

    const dragOver = (e) => {
        e.preventDefault()
        setDragHover(true);
    }
    
    const dragEnter = (e) => {
        e.preventDefault()
        setDragHover(true);
    }
    
    const dragLeave = (e) => {
        e.preventDefault()
        setDragHover(false);
    }

    return(
        <div 
            className={`dragDropTemp__container ${dragHover && "--active"}`} 
            onDragEnter={dragEnter} 
            onDragOver={dragOver} 
            onDragLeave={dragLeave} 
            onDrop={filesDrop}>
            <div className="dragDropTemp__contents">
                {files?.length > 0 ?
                    <div className="dragDropTemp__batch-grid-items">
                        {Array(...files).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((el, index) => {
                            return(
                                <div className="dragDropTemp__batch-item">
                                    <SvgIcons type="cabana_upload_image_icon"/>
                                    <div className="dragDropTemp__batch-item-name">{el.name}</div>
                                </div>
                                ) 
                            })
                        }
                    </div>
                    :
                    <div className="dragDropTemp__file-upload-container">
                        <SvgIcons type="cabana_upload_files"/>
                        <div className={`dragDropTemp__text ${dragHover && "--active"}`}>{text}</div>
                        <Button type={`${dragHover ? "secondary" : "primary"}`} text="Browse to upload" onClick={onClick}/>
                    </div>
                }
            </div>
        </div>
    )
}

export default DragDropTemplate;