import React from "react";
import "./filterSingleSelect.scss";

const FilterSingleSelect = ({selectOptions, value, setValue}) => {
    
    const RadioInput = ({dataValue, checked, onClick, label}) => {
        return(
            <label className="checkbox__input-label">
                <input id={`input__checkbox`} data-value={dataValue} type="radio" checked={checked} onClick={onClick} />
                {label}
            </label>
        );
    }

    const handleValueChange = (e) => {
        setValue(e.target.getAttribute('data-value'));
    }

    return(
        <div className="filterSingleSelect__container">
            {selectOptions.map((el) => {
                return(
                    <RadioInput dataValue={el[0]} label={el[1]} checked={value == el[0] ? "checked" : ""} onClick={handleValueChange}/>
                )
            })}
        </div>
    )


}

export default FilterSingleSelect;