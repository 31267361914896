import React from 'react';
import HeaderBanner from '../headerBanner';
import DragDropTemplate from '../dragDropTemplate';
import InfoTemplate from '../infoTemplate';
import SvgIcons from '../svgIcons';
import Button from '../button';
import './batchUpload.scss';

const BatchUpload = ({
    uploadFilesRef, 
    headerBannerType,
    headerBannerInfoText,
    headerBannerCloseClick,
    batchUploadClick, 
    saveClick,
    batchUploadText,
    handleBatchUploadDrop,
    setDragHover,
    dragHover,
}) => {
    return(
        <div className="batchUpload__batch-container">
            <HeaderBanner 
                headerTitle="Batch upload plans"
                infoText={headerBannerInfoText ? headerBannerInfoText : "Let's upload your floor plans and match them to the floors."}
                options={headerBannerType}
                setIsOpen={() => headerBannerCloseClick(false)} 
            />
            <div className="batchUpload__batch-split-container">
                <div className="batchUpload__batch-drag-drop">
                    <DragDropTemplate 
                        text="Drag & Drop your floor plans here" 
                        onClick={batchUploadClick}
                        files={uploadFilesRef?.current?.files}
                        setDragHover={setDragHover}
                        dragHover={dragHover}
                        filesDrop={handleBatchUploadDrop}
                    />
                </div>
                <div className="batchUpload__batch-info">
                    <InfoTemplate textsArr={batchUploadText} />
                </div>
            </div>
            <div className="batchUpload__batch-save">
                <Button type="primary" text="Save" suffix={<SvgIcons type="cabana_right_arrow"/>} onClick={saveClick}></Button>
            </div>
        </div>
    )
}

export default BatchUpload;