export const getUnits = async (id) => {
    try {
        const response = await fetch (
            `${process.env.REACT_APP_SERVER_URL}units/vso/project/${id}`,
            {
                method: 'GET',
                headers: {'token': localStorage.token }
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const putUnitValue = async (_, {value: {unitId, valueKey, newValue}}) => {
    try{
        const body = {
            [valueKey]: newValue
        };

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/' + unitId,
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const deleteUnit = async (unitId) => {
    try{
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/' + unitId,
            {
                method: 'DELETE',
                headers: {
                    'token' : localStorage.token
                },
            }
        );
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const putUnitsArray = async (_, {value: units}) => {
    try{
        const body = {units: units};

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/batch/' + 1,
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const createUnit = async (projectId, unitNumber, floor) => {
    try{
        const body = {
            project_id: projectId,
            unit_number: unitNumber,
            floor: floor
        };

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/',
            {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const createMultipleUnits = async (projectId, numOfUnits, selectedFloorList, selectedFloor) => {
    try {
        const body = {
            projectId: projectId,
            numOfUnits: numOfUnits,
            selectedFloorList: selectedFloorList,
            selectedFloor: selectedFloor
        }
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/multiple/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const putUnitPlan = async (unitId, formData) => {
    try {
        const response = await fetch (
            `${process.env.REACT_APP_SERVER_URL}units/plan/${unitId}`,
            {
                method: 'PUT',
                headers: {
                    'token': localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch(err) {
        console.error(err.message);
        return err;  
    }
}

export const deleteUnitPlan = async (unitId) => {
    try{
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/plan/' + unitId,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
            });
        return response.json();
    } catch (err){
        console.log(err.message);
        return err;
    }
}

/** PLANS **/
export const copyTable = async (project_id, copyTableData) => {
    try {
        const response = await fetch (
            `${process.env.REACT_APP_SERVER_URL}units/copyTable/${project_id}`, 
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(copyTableData)
            }   
        );
        return response.json();
    } catch (err) {
        console.error(err.message)
        return err
    }
}

/** UNIT PLANS **/
export const uploadUnitPlans = async (projectId, formData) => {
    try {
        const response = await fetch (
            `${process.env.REACT_APP_SERVER_URL}units/plans/batch/${projectId}`,
            {
                method: 'PUT',
                headers: {
                    'token': localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch(err) {
        console.error(err.message);
        return err   
    }
}

/** COLUMNS  **/
export const getExtraColumnsContentData = async (projectId) => {
    try {
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/extraContent/' + projectId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const getOptionalColumnsData = async (vsoId) => {
    try {
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/columnData/' + vsoId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const getUnitPlans = async (projectId) => {
    try {
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/plans/' + projectId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const sendOptionalColumnsData = async (vsoId, columnData) => {
    try {
        const body = {
            vsoId: vsoId,
            columnData: columnData
        };
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/optionalColumnsData/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const setOptionalColumnsData = async (vsoId, unitColumnData, extraContent) => {
    try {
        const body = {
            vsoId: vsoId,
            unitColumnData: unitColumnData,
            extraContentData: extraContent
        };
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/columnData/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token 
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const setEditColumnData = async (vsoId, columnDataObj) => {
    try {
        const body = {
            vsoId: vsoId,
            columnDataObj: columnDataObj 
        };
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/editColumnData/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err
    }
}

export const removeCustomColumn = async (vsoId, customColumn) => {
    try {
        const body = {
            customColumn: customColumn
        };
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/removeExtraColumn/' + vsoId,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
        
    } catch (err) {
        console.error(err.message)
        return err
    }
}

/** BUILDING VIEW **/
export const setUnitPoints = async (data) => {
    try {
        const response = await fetch (
            `${process.env.REACT_APP_SERVER_URL}units/points/${data.unit.unit_id}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(data)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const setCopyOutlineData = async (projectId, data) => {
    try {
        const response = await fetch (
            `${process.env.REACT_APP_SERVER_URL}units/copyOutline/${projectId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(data)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}