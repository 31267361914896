import React, { useState, useRef } from 'react';
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';
import Modal from 'src/components/shared/modal';
import LoadingBar from 'src/components/shared/loadingBar';
import PlansUploadMenu from './plansUploadMenu';

const BatchUploadPlans = ({isDisabled, unitPlans, setUnitPlans, uploadUnitPlans}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loadingBar, setLoadingBar] = useState(false);
    const [isUploadFinished, setIsUploadFinished] = useState(false);
    const batchUploadButtonRef = useRef(null);

    const handleEditColumnsClick = () => {
        setIsOpen((prev) => !prev);
    }

    return(
        <>
        <Button type="secondary"
            size="small"
            text="Batch upload plans"
            options={{ref: batchUploadButtonRef}}
            onClick={handleEditColumnsClick}
            disabled={isDisabled}
        >
            <SvgIcons type="cabana_upload_plan" />
        </Button>
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <PlansUploadMenu
                setIsOpen={setIsOpen}
                unitPlans={unitPlans}
                setUnitPlans={setUnitPlans}
                uploadUnitPlans={uploadUnitPlans}
                isUploadFinished={isUploadFinished}
                setIsUploadFinished={setIsUploadFinished}
            />
        </Modal>
        <Modal isOpen={loadingBar} setIsOpen={setLoadingBar} options={{contentCenter: true, disableBackdropClick: true}}>
            <div className="loadingBar__container">
                <div className="loadingBar__text">Uploading unit plans...</div>
                <LoadingBar setIsOpen={setLoadingBar} isLoaded={isUploadFinished} />
            </div>
        </Modal>
        </>
    );
}

export default BatchUploadPlans;