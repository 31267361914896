import { t } from 'i18next';
import { derived } from 'overmind';

export const state = {
    vsoId: '',
    currentUser: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip: '',
        state: '',
        country: '',
        isAuth: false,
        fullName: derived((state) => {
            return `${state.firstName} ${state.lastName}`;
        })
    },
    availableUnits: derived((state) => {
        if(Array.isArray(state.units)) {
            return state.units.filter(u => u.status === 'available').length.toString();
        } else {
            return 0;
        }
    }),
    totalUnits: derived((state) => {
        if(Array.isArray(state.units)) {
            return state.units.length.toString();
        } else {
            return 0;
        }
    }),
    reservation: {
        currentStep: 0,
        unitId: null,
        currentUnit: derived((state, rootState) => {
            if(Array.isArray(rootState.currentVSO.units)) {
                return rootState.currentVSO.units.find(unit => unit.unit_id === rootState.currentVSO.reservation.unitId);
            } else {
                return null;
            }
        })
    },
    fields: {},
    units: [],
    sortedFloorUnitsList: derived((state) => {
      if(!Array.isArray(state.units)) return [];
        return state.units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == state.currentFloor : unit.unit_number.toString().slice(0, 2) == state.currentFloor)
        .sort((unitA, unitB) => {
            if(unitA.unit_number > unitB.unit_number) {
                return 1;
            } else if(unitA.unit_number < unitB.unit_number) {
                return -1;
            }
            return 0;
        });
    }),
    allSortedUnits: derived((state) => {
        if(!Array.isArray(state.units)) return [];
        let unitsArr = [...state.units];
        return unitsArr.sort((a, b) => {
            if(a?.unit_number > b?.unit_number) { 
                return 1;
            } else if (a?.unit_number < b?.unit_number) { 
                return -1
            }
            return 0
        })
    }),
    currentFloor: "",
    optionalColumns: [],
    filterTable: {
        bedroom: {name: 'Bedroom', selectOptions: ['studio', 'oneBedroom', 'twoBedroom', 'threeBedroom'], filters: []},
        bathroom: {name: 'Bathroom', selectOptions: ['oneBathroom', 'twoBathroom', 'threeBathroom', 'fourBathroom'], filters: []},
        sqft: {name: 'SQFT', filters: []}, 
        price: {name: 'Price', filters: []},
    }
};