import { pipe, mutate } from 'overmind';
import { putUnitsArray } from './effects';
import { mutateAndReturn, debounceUnitValuesChanges } from 'src/overmind/operators';

export const setCurrentFloor = ({state}, currentFloor) => {
    state.units.selectedFloor = currentFloor;
}

export const loadVsoInfo = ({state}, paramsObj) => {
    state.units.vsoInfo.vsoId = paramsObj.vsoId;
    state.units.vsoInfo.projectId = paramsObj.projectId;
}

export const loadUnits = async ({state, effects}, vsoId) => {
    state.units.isLoading = true;
    state.units.list = await effects.units.getUnits(vsoId);
    state.units.list.forEach(async (unit) => {
        unit.extra_content = await JSON.parse(unit.extra_content);
        if(Object.keys(unit.extra_content).length > 0) { 
            let keys = Object.keys(unit.extra_content);
            let values = Object.values(unit.extra_content);
            keys.forEach((key, index) => {
                unit[`extraContent_${key}`] = values[index]; 
            });
        }
    });
    state.units.list.forEach(async (unit) => {
        unit.outline_content = await JSON.parse(unit.outline_content);
    });
    state.units.isLoading = false;
}

export const refreshUnits = ({state}, units) => {
    let newArr = [...state.units.list];
    for(const unit of units) {
        let unitIndex = newArr.findIndex(e => e.unit_id == unit.unitId);
        newArr[unitIndex] = unit;
    }
    state.unit.list = newArr;
}


export const changeUnitsOrder = ({state}, {oldPos, newPos}) => {
    /* ============ OLD (unit_number permuation)
    const oldNumber = state.units.selectedFloor + ('0' + (Number(oldPos) + 1)).slice(-2);
    const newNumber = state.units.selectedFloor + ('0' + (Number(newPos) + 1)).slice(-2);
    const oldIndex = state.units.list.findIndex(unit => unit.unit_number == oldNumber);
    if(oldIndex === -1) {
        return;
    }
    const oldId = state.units.list[oldIndex].unit_id;
    
    state.units.list[oldIndex].unit_number = newNumber;
    let modifiedUnits = [state.units.list[oldIndex]];
    
    if(newNumber > oldNumber) {
        state.units.list.forEach((unit) => {
            if(unit.unit_number <= newNumber && unit.unit_number > oldNumber && unit.unit_id !== oldId) {
                unit.unit_number = Number(unit.unit_number) - 1;
                modifiedUnits.push(unit);
            }
        });
    } else if(newNumber < oldNumber) {
        state.units.list.forEach((unit) => {
            if(unit.unit_number >= newNumber && unit.unit_number < oldNumber  && unit.unit_id !== oldId) {
                unit.unit_number = Number(unit.unit_number) + 1;
                modifiedUnits.push(unit);
            }
        });
    }
    return JSON.stringify(modifiedUnits);
    */

    // ============ NEW (order permutation)
    const oldOrder = Number(oldPos) + 1;
    const newOrder = Number(newPos) + 1;
    const oldIndex = state.units.list.findIndex(unit => unit.order_in_floor == oldOrder);
    if(oldIndex === -1) {
        return;
    }
    const oldId = state.units.list[oldIndex].unit_id;
    
    let newArr = [...state.units.list];
    newArr[oldIndex].order_in_floor = newOrder;

    let modifiedUnits = [{unit_id: oldId, values: {order_in_floor: newOrder}}];
    
    if(newOrder > oldOrder) {
        newArr.forEach((unit) => {
            if(unit.floor == newArr[oldIndex].floor) {
                if(unit.order_in_floor <= newOrder && unit.order_in_floor > oldOrder && unit.unit_id !== oldId) {
                    unit.order_in_floor = Number(unit.order_in_floor) - 1;
                    modifiedUnits.push({unit_id: unit.unit_id, values: {order_in_floor: unit.order_in_floor}});
                }
            }
        });
    } else if(newOrder < oldOrder) {
        newArr.forEach((unit) => {
            if(unit.floor == newArr[oldIndex].floor) {
                if(unit.order_in_floor >= newOrder && unit.order_in_floor < oldOrder  && unit.unit_id !== oldId) {
                    unit.order_in_floor = Number(unit.order_in_floor) + 1;
                    modifiedUnits.push({unit_id: unit.unit_id, values: {order_in_floor: unit.order_in_floor}});
                }
            }
        });
    }

    state.units.list = newArr;

    return modifiedUnits;
}

export const setUnitValue = ({state}, {unitId, valueKey, newValue, isCustom}) => {
    let unitIndex = state.units.list.findIndex(e => e.unit_id == unitId);
    let newArr = [...state.units.list];
    newArr[unitIndex][valueKey] = newValue;
    state.units.list = newArr;

    if(isCustom) {
        let originalKey = valueKey.split('_')[1];
        state.units.list[unitIndex]['extra_content'][originalKey] = newValue;
        return {unitId: unitId, valueKey: 'extra_content', newValue: JSON.stringify(state.units.list[unitIndex]['extra_content']), isCustom: isCustom};
    } else {
        return {unitId: unitId, valueKey: valueKey, newValue: newValue};
    }
}

export const addUnit = async ({state, actions, effects}) => {
    let unitNumber;
    if(state.units.sortedFloorUnits.length === 0) {
        unitNumber = `${state.units.selectedFloor}${state.units.sortedFloorUnits.length < 9 ? 0 : ''}${state.units.sortedFloorUnits.length + 1}`
    } else {
       unitNumber = `${parseInt(state.units.sortedFloorUnits[state.units.sortedFloorUnits.length - 1].unit_number) + 1}`
    }
    
    const newUnit =  await effects.units.createUnit(state.units.vsoInfo.projectId, unitNumber, state.units.selectedFloor);
    state.units.list.push(newUnit);
    await actions.units.loadUnits(state.units.vsoInfo.vsoId);
}

export const addMultiUnits = async ({state, actions, effects}, numberOfUnits) => {
    await effects.units.createMultipleUnits(state.units.vsoInfo.projectId, numberOfUnits, state.units.sortedFloorUnits, state.units.selectedFloor);
    actions.units.loadUnits(state.units.vsoInfo.vsoId);
}

export const removeUnit = async ({state, actions, effects}, unitId) => {
    let res = await effects.units.deleteUnit(unitId);
    actions.units.loadUnits(state.units.vsoInfo.vsoId);
}

export const uploadUnitPlan = async ({state, actions, effects}, {unitId, formData}) => {
    const planURL = await effects.VSOs.putUnitPlan(unitId, formData);
    if(planURL) {
        let unitIndex = state.units.list.findIndex(e => e.unit_id == unitId);
        state.units.list[unitIndex].plan = planURL;
        actions.units.loadUnits(state.units.vsoInfo.vsoId);
    }
}

/*Unit Plans */

export const setUnitPlans = ({state}, data) => {
    state.units.plans = [...data];
}

export const uploadUnitPlans = async ({state, actions, effects}, data) => {
    if(state.units.plans.length > 0){
        await effects.units.uploadUnitPlans(state.units.vsoInfo.projectId, data).then(() => {
            actions.units.loadUnits(state.units.vsoInfo.vsoId);
        })
    }
}

export const removeUnitPlan = async ({state, actions, effects}, unitId) => {
    const res = await effects.VSOs.deleteUnitPlan(unitId);
    if(res == unitId) {
        let unitIndex = state.units.list.findIndex(e => e.unit_id == unitId);
        state.units.list[unitIndex].plan = null;
    }
    actions.units.loadUnits(state.units.vsoInfo.vsoId);
}

/* COPY TABLE */

export const copyTable = async ({state, actions, effects}, copyTableInfo) => {
    const copiedResults = await effects.units.copyTable(state.units.vsoInfo.projectId, copyTableInfo)
    if(copiedResults.length > 0) { 
        actions.units.loadUnits(state.units.vsoInfo.vsoId);
        return copiedResults;
    }
}

/*BUILDING VIEW UNIT POINTS*/

export const setUnitPoints = async ({state, effects, actions}, data) => {
    let response = await effects.units.setUnitPoints(data)
    if(response) {
        actions.units.loadUnits(state.units.vsoInfo.vsoId);
    }
 }

 export const copyOutlineFloors = async ({state, effects, actions}, data) => {
    let sortedChosenFloorsList = [] 
    let selectedFloorList = state.units.list.filter(unit => unit.floor == data.selectedFloor)
        .sort((unitA, unitB) => {
        if(unitA.order_in_floor > unitB.order_in_floor) {
        return 1;
        } else if(unitA.order_in_floor < unitB.order_in_floor) {
        return -1;
        }
        return 0;
        });

    for (const item of data.floorsArr) {
        let tempArr = state.units.list.filter(unit => unit.floor == item)
            .sort((unitA, unitB) => {
                if(unitA.order_in_floor > unitB.order_in_floor) {
                    return 1;
                } else if(unitA.order_in_floor < unitB.order_in_floor) {
                    return -1;
                }
                return 0;
            });
        sortedChosenFloorsList.push(...tempArr);
    }

    let tempObj = {
        sortedChosenFloorsList : sortedChosenFloorsList ,
        selectedFloorList: selectedFloorList
    }

    let response = await effects.units.setCopyOutlineData(state.units.vsoInfo.projectId, tempObj);
    if(response) {
        actions.units.loadUnits(state.units.vsoInfo.vsoId);
    }
}


/** COLUMNS **/
export const getOptionalColumns = async ({state, effects}) => {
    let results = await effects.units.getOptionalColumnsData(state.units.vsoInfo.vsoId);
    if(JSON.parse(results.units_columns)?.length > 0) { 
        state.units.columns = JSON.parse(results.units_columns);
    }
}

export const setOptionalColumns = ({state}, obj) => {
    let index = state.units.columns.findIndex((el) => el.isCustom ? el.name.en === obj.name : el.name === obj.name);
    state.units.columns[index]['isShown'] = obj.isShown; 
}

export const sendOptionalColumnsData = async ({state, effects}) => {
    let results = await effects.units.sendOptionalColumnsData(state.units.vsoInfo.vsoId, state.units.columns);
    if(JSON.parse(results.units_columns).length > 0) {
        state.units.columns = JSON.parse(results.units_columns);
    }
}

export const addColumnsData = async ({state, actions, effects}, customColumnObj) => {
    state.units.columns.push(customColumnObj.extraColumnObj);
    let results = await effects.units.setOptionalColumnsData(state.units.vsoInfo.vsoId, state.units.columns, customColumnObj.extraContentObj);
    if(JSON.parse(results.units_columns).length > 0) {
        state.units.columns = JSON.parse(results.units_columns);
    }
    actions.units.loadUnits(state.units.vsoInfo.vsoId);
}

export const sendColumnData = async ({state, actions, effects}, columnDataObj) => {
    let results = await effects.units.setEditColumnData(state.units.vsoInfo.vsoId, columnDataObj);
    if(JSON.parse(results.units_columns).length > 0) {
        state.units.columns = JSON.parse(results.units_columns);
    }
}

export const sendAllColumnsData = async ({state, effects}) => {
    let results = await effects.units.sendOptionalColumnsData(state.units.vsoInfo.vsoId, state.units.columns);
    if(JSON.parse(results.units_columns).length > 0) {
        state.units.columns = JSON.parse(results.units_columns);
    }
}

export const removeCustomColumn = async ({state, actions, effects}, element) => {
    let removedResults = await effects.units.removeCustomColumn(state.units.vsoInfo.vsoId, element);

    state.units.columns = await JSON.parse(removedResults.units_columns);
    actions.units.loadUnits(state.units.vsoInfo.vsoId);
}

export const saveUnitValue = pipe (
    mutateAndReturn(setUnitValue),
    debounceUnitValuesChanges(2000),
    putUnitsArray,
    mutate(refreshUnits)
);

export const saveUnitsOrder = pipe (
    mutateAndReturn(changeUnitsOrder),
    putUnitsArray,
    mutate(refreshUnits)
);