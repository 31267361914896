import React, { useEffect, useState } from 'react';
import './imageSelection.scss';
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';
import { useOvermind } from 'src/overmind';
import Modal from 'src/components/shared/modal';
import WarningMessage from 'src/components/shared/warningMessage';


const ImageSelection = ({changeStep, steps, unitListStep, setUnitListStep, validateField, formErrors, validateForm}) => {
    const { state, actions } = useOvermind();
    const [previousImage, setPreviousImage] = useState(null);
    const [warningMessage, setWarningMessage] = useState(false);

    const selectImage = async (buildingViewImage) => {
        await actions.productForm.setBuildingViewImage(buildingViewImage);
        validateField('buildingView_image');
    }

    useEffect(() => {
        if(state.productForm.newProduct.fields.buildingView_image?.value) {
            setPreviousImage(state.productForm.newProduct.fields.buildingView_image?.value);
        }
    }, []);

    const handleContinueClick = () => {
        if(previousImage != null && previousImage != state.productForm.newProduct.fields.buildingView_image?.value) {
            setWarningMessage(true);
        } else {
            actions.productForm.updateProductFields(['buildingView_image']).then(() => {
                changeStep(steps + 1);
            });
        }
        
    }

    const handleBackClick = () => {
        if(steps === 1) {
            actions.productForm.updateProductFields(['buildingView_image']).then(() => {
                setUnitListStep(unitListStep - 1);
            });
        }
    }

    const warningMessageConfirmClick = () => {
        actions.productForm.deleteBuildingViewFloor();
        actions.productForm.updateProductFields(['buildingView_image'])
        changeStep(steps + 1);
    }

    return(
        <div className={"imageSelection__container"}>
            <div className={`imageSelection__choose-image-container ${typeof formErrors?.buildingView_image === 'string' ? '--error' : ''}`}> 
                <div className="imageSelection__choose-image-text-container">
                    <div className="imageSelection__choose-image-title">Choose the right building Image</div>
                    <div className="imageSelection__choose-image-info">Select the image of your project that best fits the purpose of the building view.</div>
                </div>
                <div className="imageSelection__images-container">
                    {
                        state.productForm.newProduct.fields.images?.value?.array?.map((el, index) => {
                            return(
                                <div 
                                    className={`imageSelection__image-item ${state.productForm.newProduct.fields.buildingView_image?.value === el.src ? 'active' : ''}`} 
                                    style={{backgroundImage: `url(${el.src})`}}
                                    onClick={() => selectImage(el.src)}
                                />
                            )
                        })
                    }
                </div>
                {typeof formErrors?.buildingView_image === "string" && <div className="imageSelection__error">{formErrors?.buildingView_image}</div>}
                <div className="divider" />
                <div className="imageSelection__choose-image-buttons">
                    <Button type="ternary" text="Back" onClick={handleBackClick}/>
                    <Button 
                        type="primary" 
                        text="Continue"
                        disabled={state.productForm.newProduct.fields.buildingView_image?.value == '' || state.productForm.newProduct.fields.buildingView_image?.value == null} 
                        onClick={handleContinueClick}
                        suffix={<SvgIcons type="cabana_right_arrow"/>}
                        suffixGap={'12px'}
                    />
                </div>
            </div>
            <Modal isOpen={warningMessage} setIsOpen={setWarningMessage}>
                <WarningMessage 
                    title="Are you sure you want to change the image?"
                    infoMessage="Outline progress is going to be lost."
                    cancelClick={() => setWarningMessage(false)}
                    confirmClick={warningMessageConfirmClick}
                />
            </Modal>
        </div>
    )
}

export default ImageSelection;