export const setNewMarketTestId = ({state}, id) => {
    state.marketTestForm.newMarketTest.id = id;
}

export const clearNewMarketTest = ({state, actions}) => {
    state.marketTestForm.formStep = state.marketTestForm.firstFormStep;
    actions.projects.clearProjectForm();
    state.marketTestForm.newMarketTest.id = '';
    state.marketTestForm.newMarketTest.fields = [];
}

export const incrementFormStep = ({state}) => {
    let currentStep = state.marketTestForm.formStep;
    currentStep++;
    state.marketTestForm.formStep = currentStep;
}

export const decrementFormStep = ({state}) => {
    let currentStep = state.marketTestForm.formStep;
    currentStep--;
    state.marketTestForm.formStep = currentStep;
}

export const createMarketTest = async ({state, effects}) => {
    const marketTestId = await effects.marketTestForm.postMarketTest(state.currentUser.userId);
    state.marketTestForm.newMarketTest.id = marketTestId;
}

export const updateMarketTestFields = async ({state, effects}, fieldIds) => {
    let fields = {};
    let index = 0;
    for(const contentId of fieldIds) {
        fields[index] = {
            contentId: contentId,
            value: state.marketTestForm.newMarketTest.fields[contentId].value
        }
        index++;
    }
    
    const updatedFields = await effects.marketTestForm.putMarketTestFields(state.marketTestForm.newMarketTest.id, fields);
}

export const setMarketTestField = ({state}, field) => {
    state.marketTestForm.newMarketTest.fields[field.contentId] = {
        ...state.marketTestForm.newMarketTest.fields[field.contentId], 
        value: field.value
    };
}

export const pushToFieldArray = ({state}, {contentId, value}) => {
    state.marketTestForm.newMarketTest.fields[contentId].value.array.push(value);
    state.marketTestForm.newMarketTest.fields[contentId].value.array.sort(function(a, b){
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    })
}

export const removeFromFieldArray = ({state}, {contentId, index}) => {
    state.marketTestForm.newMarketTest.fields[contentId].value.array.splice(index, 1);
}

export const loadNewMarketTest = async ({state, effects}) => {
    try {
        const marketTestFields = await effects.marketTests.fetchMarketTestFields(state.marketTestForm.newMarketTest.id);
        let fieldsObj = {}
        for(const f of marketTestFields) {
            if(f.field_value != null) {
                fieldsObj[f.field_content_id] = 
                {
                    value: f.field_value.includes('{"') ? JSON.parse(f.field_value) : f.field_value === "true" ? true : f.field_value === "false" ? false : f.field_value,
                    comment: f.auditor_comment,
                    status: f.status,
                }
            }
        }
        state.marketTestForm.newMarketTest.fields = fieldsObj;
        /*
        state.marketTestForm.newMarketTest.fields.project_address.value = JSON.parse(state.marketTestForm.newMarketTest.fields.project_address.value);
        state.marketTestForm.newMarketTest.fields.project_desc_en.value = JSON.parse(state.marketTestForm.newMarketTest.fields.project_desc_en.value);
        state.marketTestForm.newMarketTest.fields.project_desc_fr.value = JSON.parse(state.marketTestForm.newMarketTest.fields.project_desc_fr.value); 
        */
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

/*
export const getNewMarketTestFromStorage = ({state}) => {
    state.marketTestForm.newMarketTest.fields = localStorage.newMarketTestFields;
}
export const getCurrentMarketTestFromStorage = ({state}) => {
    state.marketTestForm.currentMarketTest.fields = localStorage.currentMarketTestFields;
}

export const saveNewMarketTestFields = ({state}) => {
    localStorage.set('newMarketTestFields', state.marketTestForm.newMarketTest.fields);
}
export const saveCurrentMarketTestFields = ({state}) => {
    localStorage.set('newMarketTestFields', state.marketTestForm.newMarketTest.fields);
}

export const setNewMarketTestField = ({state}, idValue, value) => {
    state.marketTestForm.currentMarketTest.fields[idValue] = value;
}

export const storeNewMarketTestField = ({state, effects}, idValue) => {
    effects.marketTestForm.storeField(state.marketTestForm.newMarketTest.fields[idValue]);
}
*/