require('dotenv').config();

/****MARKET-TESTS****/

export const postMarketTest = async (id) => {
    try {
        const body = {userId: id};

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'marketTests',
            {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
    
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const putMarketTestFields = async (id, fields) => {
    try {
        const body = {
            marketTestId : id,
            fields: fields
        };

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'fields/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const putNearbyPlacesMt = async (id, addressData) => {
    try{
        let body = addressData;
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/address/markeTest/${id}`, 
            {
                method: 'PUT',
                headers: {
                    'token' : localStorage.token,
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    }
    catch(err) {
        console.error(err.message);
        return err;
    }
}

export const uploadImageMt = async (id, contentId, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/marketTest/image/${contentId}/${id}`,
            {
                method: 'PUT',
                headers: {
                    // 'Content-Type' : 'multipart/form-data',
                    'token' : localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const removeImageMt = async (id, contentId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/marketTest/image/${contentId}/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                }
            }
        );
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

/****VIRTUAL SALES OFFICE****/

/* GET */

export const loadField =  async (fieldContent, projectId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'fields/fieldContent/' + fieldContent + '/' + projectId,
            {
                method: 'GET',
                headers: {
                    'token': localStorage.token
                },
            }
        )
        return response.json();
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const getExtraColumnsContentData = async (projectId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'units/extraContent/' + projectId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            }
        )
        return response.json()
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const getOptionalColumnsData = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/columnData/' + vsoId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            }
        )
        return response.json()
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const getUnitPlans = async (projectId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'units/plans/' + projectId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            }
        )
        return response.json()
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const getReservationInfo = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/reservationInfo/' + vsoId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            }
        )
        return response.json();
    } catch (err) {
        console.error(err.message)
        return err
    }
}

export const getContactInfo = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/contactInfo/' + vsoId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}


/* POST */

export const postVso = async (id) => {
    try {
        const body = {userId: id};

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/',
            {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
    
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const putVsoFields = async (id, fields) => {
    try {
        const body = {
            vsoId : id,
            fields: fields
        };

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'fields/vso/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const putNearbyPlacesVso = async (id, addressData) => {
    try{
        let body = addressData;
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/address/vso/${id}`, 
            {
                method: 'PUT',
                headers: {
                    'token' : localStorage.token,
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    }
    catch(err) {
        console.error(err.message);
        return err;
    }
}

export const putReservationDays = async (id, reservationDays) => {
    try{
        let body = {
            vsoId: id,
            reservationDays: reservationDays
        };
        const response = await fetch(process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/reserveDays/', 
            {
                method: 'PUT',
                headers:{
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    }catch(err) {
        console.error(err.message);
        return err;
    }
}

export const putReservationDeposit = async (id, reservationDeposit) => {
    try{
        let body = {
            vsoId: id,
            reservationDeposit: reservationDeposit
        };
        const response = await fetch(process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/reservationDeposit/', 
            {
                method: 'PUT',
                headers:{
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    }catch(err) {
        console.error(err.message);
        return err;
    }
}

export const putContactInfo = async (id, contactInfo) => {
    try {
        let body = {
            vsoId: id,
            contactInfo: contactInfo
        }
        const response = await fetch(process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/contactInfo/',
            {
                method: 'PUT',
                headers:{
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message)
        return err;
    }
} 

export const putEnableReservations = async (id, enableReservations) => {
    try {
        let body = {
            vsoId: id,
            enableReservations: enableReservations
        }
        const response = await fetch(process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/enableReservations/',
            {
                method: 'PUT',
                headers:{
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message)
        return err;
    }
} 

export const putShowPrices = async (id, showPrices) => {
    try {
        let body = {
            vsoId: id,
            showPrices: showPrices
        }
        const response = await fetch(process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/showPrices/',
            {
                method: 'PUT',
                headers:{
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message)
        return err;
    }
} 

export const uploadFloorPlan = async (id, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/vso/floorPlan/${id}`,
            {
                method: 'PUT',
                headers: {
                    token: localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const uploadImageVso = async (id, contentId, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/vso/image/${contentId}/${id}`,
            {
                method: 'PUT',
                headers: {
                    // 'Content-Type' : 'multipart/form-data',
                    'token' : localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    } 
}

export const uploadProjectImages = async (id, contentId, formData) => {
    try {
        
     const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}virtualSalesOffices/projectImages/${contentId}/${id}`,
        {
            method: 'PUT',
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'token': localStorage.token
            },
            body: formData
        }
     )
     return response.json();
    }catch(err) {
        console.error(err.message);
        return err;
    }
}

export const getProjectImages = async (id) => {
    try {
     
     const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}virtualSalesOffices/projectImages/${id}`,
        {
            method: 'GET',
            headers: {'token': localStorage.token }
        }
     )
     return response.json();
    }catch(err) {
        console.error(err.message);
        return err;
    }
}

export const removeFloorPlan = async (id, public_id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/vso/floorPlan/${id}/${public_id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const removeProjectImage = async (id, contentId, image) => {
    try{
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}virtualSalesOffices/projectImages/${contentId}/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(image)
            }
         );
         return response.json();
    }catch(err){
        console.error(err.message);
        return err;
    }
}

export const uploadProjectDocuments = async (id, contentId, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}virtualSalesOffices/projectDocuments/${contentId}/${id}`,
            {
                method: 'PUT',
                headers: {
                    'token': localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    }catch(err) {
        console.error(err.message);
        return err   
    }
}

export const getProjectDocuments = async (id) => {
    try {
        const response = await fetch(
           `${process.env.REACT_APP_SERVER_URL}virtualSalesOffices/projectDocuments/${id}`,
           {
               method: 'GET',
               headers: {'token': localStorage.token }
           }
        )
        return response.json();
    }catch(err) {
        console.error(err.message);
        return err;
    }
}

export const removeProjectDocument = async (id, contentId, document) => {
    try{
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}virtualSalesOffices/projectDocuments/${contentId}/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(document)
            }
         );
         return response.json();
    }catch(err){
        console.error(err.message);
        return err;
    }
}

export const removeImageVso = async (id, contentId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}fields/vso/image/${contentId}/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                }
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

/*Units Details*/ 
export const getUnits = async (id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}units/vso/project/${id}`,
            {
                method: 'GET',
                headers: {'token': localStorage.token }
            }
         )

        return response.json();
    } catch (err) {
        console.error(err);
        return err
    }
}

export const putUnitValue = async (_, {value: {unitId, valueKey, newValue}}) => {
    try{
        const body = {
            [valueKey]: newValue
        };

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/' + unitId,
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const deleteUnit = async (unitId) => {
    try{
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/' + unitId,
            {
                method: 'DELETE',
                headers: {
                    'token' : localStorage.token
                },
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const putUnitsArray = async (_, {value: units}) => {
    try{
        const body = {units: units};

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/batch/' + 1,
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const createUnit = async (projectId, unitNumber) => {
    try{
        const body = {
            project_id: projectId,
            unit_number: unitNumber
        };

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/',
            {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const createMultipleUnits = async (projectId, numOfUnits, selectedFloorList, selectedFloor) => {
    try {
        const body = {
            projectId: projectId,
            numOfUnits: numOfUnits,
            selectedFloorList: selectedFloorList,
            selectedFloor: selectedFloor
        }
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'units/multiple/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        )
        response.json()
    } catch (err) {
        console.error(err.message);
        return err
    }
}

export const putUnitPlan = async (unitId, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}units/plan/${unitId}`,
            {
                method: 'PUT',
                headers: {
                    'token': localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch(err) {
        console.error(err.message);
        return err   
    }
}

export const setUnitPoints = async (data) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}units/points/${data.unit.unit_id}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(data)
            }
        )
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const setCopyOutlineData = async (projectId, data) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}units/copyOutline/${projectId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(data)
            }
        )
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

/* Sends optional columns data when clicking "next" in vsoForm */
export const sendOptionalColumnsData = async (vsoId, columnData) => {
    try {
        const body = {
            vsoId: vsoId,
            columnData: columnData
        }
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/optionalColumnsData/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const setOptionalColumnsData = async (vsoId, unitColumnData, extraContent) => {
    try {
        const body = {
            vsoId: vsoId,
            unitColumnData: unitColumnData,
            extraContentData: extraContent
        }
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/columnData/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token 
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const setEditColumnData = async (vsoId, columnDataObj) => {
    try {
        const body = {
            vsoId: vsoId,
            columnDataObj: columnDataObj 
        }

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/editColumnData/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        )
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err
    }
}

export const removeCustomColumn = async (vsoId, customColumn) => {
    try {
        const body = {
            customColumn: customColumn
        }
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/removeExtraColumn/' + vsoId,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        );
        return response.json();
        
    } catch (err) {
        console.error(err.message)
        return err
    }
}

export const copyTable = async (project_id, copyTableData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}units/copyTable/${project_id}`, 
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(copyTableData)
            }   
        );
        return response.json();
    } catch (err) {
        console.error(err.message)
        return err
    }
}

export const deleteUnitPlan = async (unitId, formData) => {
    return true;
}

/*Unit Plans */
export const uploadUnitPlans = async (projectId, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}units/plans/batch/${projectId}`,
            {
                method: 'PUT',
                headers: {
                    'token': localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch(err) {
        console.error(err.message);
        return err   
    }
}

/* Virtual Sales Office Status */

export const changeStatus = async (vsoId, status) => {
    try {
        let body = {
            vsoId: vsoId,
            status: status
        }
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/status/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        )
        return response.json();
    } catch (err) {
        console.error(err.message)
        return err
    }
}
