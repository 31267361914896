import React from "react";
import SvgIcons from "src/components/shared/svgIcons";
import OverviewTags from "../overviewTags";
import "./projectOverviewBox.scss";
import { useTranslation } from 'react-i18next';

const ProjectOverviewBox = ({item}) => {
    const { t } = useTranslation();
    return(
        <div className="projectOverviewBox__container">
            <div className="projectOverviewBox__split-container">
                <div className="projectOverviewBox__title-address-container">
                    <div className="projectOverviewBox__title">{item.fields?.project_name}</div>
                    <div className="projectOverviewBox__address"><SvgIcons type="cabana_overview_location" /> {item.fields.project_address?.full_string}</div>
                </div>
                <div className="projectOverviewBox__building-info-container">
                    <div className="projectOverviewBox__building-info"><SvgIcons type="cabana_overview_building" /> {item.fields?.num_buildings} {t('salesOffice.overviewPage.building')}</div>
                    <div className="projectOverviewBox__building-info"><SvgIcons type="cabana_overview_floors" /> {item.fields?.num_floors} {t('salesOffice.overviewPage.floors')}</div>
                    <div className="projectOverviewBox__building-info"><SvgIcons type="cabana_overview_units" /> {item?.totalUnits} {t('salesOffice.overviewPage.units')}</div>
                </div>
                <div className="projectOverviewBox__image-hero" style={{backgroundImage: `url(${item.fields?.images?.[0]?.src})`}}/>
            </div>
            <div className="projectOverviewBox__split-two-container">
                <OverviewTags text={t('salesOffice.overviewPage.projectOverview').toUpperCase()} />
                <div className="projectOverviewBox__description-text">{item.fields?.project_desc}</div>
                <div className="projectOverviewBox__divider" />
                <div className="projectOverviewBox__details-container">
                    <div className="projectOverviewBox__details-info">
                        <div className="projectOverviewBox__details-header">{t('salesOffice.overviewPage.unitAvail')}</div>
                        <div className="projectOverviewBox__details-answer">{item?.availableUnits} / {item?.totalUnits}</div>
                    </div>
                    <div className="projectOverviewBox__details-info">
                        <div className="projectOverviewBox__details-header">{t('salesOffice.overviewPage.projectType')}</div>
                        <div className="projectOverviewBox__details-answer">{item.fields?.project_type === "sell" ? t('salesOffice.overviewPage.sale') : t('salesOffice.overviewPage.rent')}</div>
                    </div>
                    {
                        item.fields.project_type === "rent" && 
                        <div className="projectOverviewBox__details-info">
                            <div className="projectOverviewBox__details-header">{t('salesOffice.overviewPage.leaseDuration')}</div>
                            <div className="projectOverviewBox__details-answer">{item.fields.lease_duration} {t('salesOffice.overviewPage.month')}</div>
                        </div>
                    }
                    <div className="projectOverviewBox__details-info">
                        <div className="projectOverviewBox__details-header">{t('salesOffice.overviewPage.furnishedType')}</div>
                        <div className="projectOverviewBox__details-answer">
                            {
                                item.fields.units_furnishing === 'fully_furnished' ? t('salesOffice.overviewPage.fullyFurnished') :
                                item.fields.units_furnishing === 'semi_furnished' ? t('salesOffice.overviewPage.semiFurnished') :
                                item.fields.units_furnishing === 'not_furnished' ? t('salesOffice.overviewPage.notFurnished') : ''
                            }
                        </div>
                    </div>
                    <div className="projectOverviewBox__details-info">
                        <div className="projectOverviewBox__details-header">{t('salesOffice.overviewPage.status')}</div>
                        <div className="projectOverviewBox__details-answer">
                            {item.fields.construction_type == 'new' ?   
                                item.fields.project_status == 'pre-construction' ? t('salesOffice.overviewPage.preConstruction') :
                                item.fields.project_status == 'in construction' ? t('salesOffice.overviewPage.inConstruction') :
                                item.fields.project_status == 'built' ? t('salesOffice.overviewPage.built') : ''
                             :
                             item.fields.project_status == 'pre-renovation' ? t('salesOffice.overviewPage.preRenovation') :
                             item.fields.project_status == 'in renovation' ? t('salesOffice.overviewPage.inRenovation') :
                             item.fields.project_status == 'renovated' ? t('salesOffice.overviewPage.renovated') : ''
                            }
                        </div>
                    </div>
                    <div className="projectOverviewBox__details-info">
                        <div className="projectOverviewBox__details-header">{t('salesOffice.overviewPage.occupancy')}</div>
                        <div className="projectOverviewBox__details-answer">{item?.fields.est_delivery_date}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectOverviewBox;