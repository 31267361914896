import React, { useState, useEffect, useRef } from 'react';
import SvgIcons from 'src/components/shared/svgIcons';
import { useOvermind } from 'src/overmind';
import Button from 'src/components/shared/button';
import HeaderBanner from 'src/components/shared/headerBanner';
import DragDropTemplate from 'src/components/shared/dragDropTemplate';
import InfoTemplate from 'src/components/shared/infoTemplate';
import Modal from 'src/components/shared/modal';
import ErrorMessage from 'src/components/shared/errorMessage';
import { checkFileSize, convertUrltoFile } from 'src/utils/fileUpload'; 

import './plansUploadMenu.scss';
import BatchUpload from 'src/components/shared/batchUpload';

const PlansUploadMenu = ({setIsOpen, unitPlans, setUnitPlans, uploadUnitPlans}) => {
    const { state, actions } = useOvermind();
    const [unitPlansArr, setUnitPlansArr] = useState([]);
    const [dragHover, setDragHover] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);
    let formData = new FormData();
    let isValid = false;
    const [modal, setModal] = useState(false);
    const inputRef = useRef(null);

    /*
    const LabelContainer = ({onDragOver, onDragEnter, onDragLeave, onDrop, dragHover}) => {
        return(
            <label htmlFor="uploadPlans__unit-plans-input">
                <div className={`uploadPlans__unit-plans-container ${dragHover && 'active'}`} onDragOver={onDragOver} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                    <div className="uploadPlans__project-bg-image">
                        <SvgIcons type="blueprint_frame" />
                    </div>
                    <input id="uploadPlans__unit-plans-input" ref={inputRef} type='file' accept="image/png, image/jpeg, image/gif" multiple onChange={handlePlansUpload}/>
                    <div className="uploadPlans__unit-plans-text">
                        <h3><strong>Upload plans</strong> or drag and drop files here.</h3>
                        <p>To match plans with units, the file name must correspond to the unit number</p>
                        <p>Ex: The file name of the plan for Unit 101 must be named <strong>101.png or 101.jpg</strong></p> 
                    </div>
                </div>
            </label>
        )
    }
    */

    /*
    const UnitPlansItems = ({id, fileName, onClick}) => {
        return(
            <div key={id} className="uploadPlans__unit-plan-items">
                <SvgIcons type="plan_upload" />
                <p>{fileName}</p>
            </div>
        )
    }
    */

    const checkFileName = (unitPlans) => {
        let regex = /^[0-9]{3}$/g;
        for(const file of unitPlans) {
            if(!regex.test(file.name) && file.name.split(".")[0].length !== 3) {
                setModal(!modal);
                isValid = false;
                return;
            }
        }
        isValid = true;
    }

    const handleInputFiles = async (e) => {
        e.preventDefault();
        setDragHover(false); 
        inputRef.current.files = e.dataTransfer.files;
        let filteredArr = Array.from(inputRef.current.files).filter((element, index, self) =>
            index === self.findIndex((el) => (el.name === element.name))
        );
        checkFileName(filteredArr);
        if(isValid){
            let tempArr = [];
            for(const file of filteredArr) {
                if(checkFileSize(file)) { 
                    setFileSizeError(true);
                    return;
                }
                let tempObj={};
                tempObj.name = file.name.split(".")[0];
                tempObj.unit_number = parseInt(file.name.split(".")[0]);
                tempObj.src = URL.createObjectURL(file);
                tempArr.push(tempObj);
            }
            await setUnitPlans([...unitPlans, ...tempArr]);
            let filterUnitPlans = unitPlans.filter((element, index, self) =>
                    index === self.findIndex((el) => (
                    el.name === element.name
                ))
            ).sort((a, b) => a.unit_number - b.unit_number);
            await setUnitPlans([...filterUnitPlans]);
        }
    }

    const nextStep = async () => {
        for (const file of unitPlans) {
            let convertedFile = await convertUrltoFile(file.src, file.name);
            formData.append('plans', convertedFile);
        }
        await uploadUnitPlans(formData);
        setUnitPlans([]);
        setIsOpen(false);
    }

    const handleNameError = () => {
        setModal(!modal);
        inputRef.current.value = "";
    }

    const handleFileSizeError = () => {
        setFileSizeError(!fileSizeError);
        inputRef.current.value = "";
    }

    const handlePlansUpload = async () => {
        let filteredArr = Array.from(inputRef.current.files).filter((element, index, self) =>
        index === self.findIndex((el) => (
            el.name === element.name
            ))
        )
        checkFileName(filteredArr);
        if(isValid) {
            let tempArr = [];
            for(const file of filteredArr) {
                if(checkFileSize(file)) { 
                    setFileSizeError(true);
                    return;
                }
                let tempObj={};
                tempObj.name = file.name.split(".")[0];
                tempObj.unit_number = parseInt(file.name.split(".")[0]);
                tempObj.src = URL.createObjectURL(file);
                tempArr.push(tempObj);
            }
            await setUnitPlans([...unitPlans, ...tempArr]);
            let filterUnitPlans = unitPlans.filter((element, index, self) =>
                    index === self.findIndex((el) => (
                    el.name === element.name
                ))
            ).sort((a, b) => a.unit_number - b.unit_number);
            await setUnitPlans([...filterUnitPlans]);
        }
    }

    // useEffect(() => {
    //     setUnitPlans([]);
    // }, [isOpen])

    return(
        <>
        <input id="uploadPlans__unit-plans-input" ref={inputRef} type='file' accept="image/png, image/jpeg, image/gif" multiple onChange={handlePlansUpload}/>
        <BatchUpload 
            uploadFilesRef={inputRef}
            headerBannerInfoText="Let's upload your units plans and match them to the units you entered in your unit list."
            headerBannerCloseClick={() => setIsOpen(false)}
            batchUploadClick={() => {inputRef?.current?.click()}}
            saveClick={nextStep}
            batchUploadText={[
                {
                    title: '1. Separate each plan individualy',
                    text: 'Make sure to separate each unit plan file into individual image files. Supported format: JPG, PNG.'
                },
                {
                    title: '2. Name each file after the unit',
                    text: 'Name every image files after the unit number. Example: The PDF file for unit 101 must be named 101.jpg'
                },
                {
                    title: '3. Upload all the unit files',
                    text: 'Select all the unit plan image files then drag them into the drop area on the right.'
                },
                {
                    title: '4. Press save when complete',
                    text: 'Press the Save button to upload your plans. You will be able to make correction as needed by removing or reuploading a unit\'s plan through its plan column.'
                }
            ]}
            handleBatchUploadDrop={handleInputFiles}
            setDragHover={setDragHover}
            dragHover={dragHover}
        />
        {/*
        <div className="uploadPlans__upload-plans-container">
            <div className="product-form__step__title">
                <h1>Upload your project plans</h1>
            </div>
            <LabelContainer onDragOver={dragOver} 
                onDragEnter={dragEnter} 
                onDragLeave={dragLeave} 
                onDrop={saveUnitPlans} 
                dragHover={dragHover}
                />
            <div className="uploadPlans__unit-plans-view-how" style={{flexDirection: unitPlans.length > 0 ? 'row' : 'column'}}>
                { unitPlans.length > 0 ?
                unitPlans.map((element, index) => {
                   return(<UnitPlansItems id={index} fileName={element.name} />)
                })        
                :
                <>
                    <SvgIcons type="view_how" />
                    <p>View How</p>
                </>
                }
            </div>
            <div className="product-form__next-container">
                <Button type="primary" onClick={nextStep} disabled={unitPlans.length === 0 ? true : false} text="Done" />
            </div>
            <Modal isOpen={modal} 
                setIsOpen={setModal} 
                options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={handleNameError} 
                        items={[
                            {content: <div>The file name of one or multiple uploaded file is incorrect. Please rename the file to correspond to the unit number.</div>}, 
                            {content: <div>Ex. The file name of the plan for the Unit 101 must be named 101.jpeg or 101.png</div>}
                        ]}
                        errorCode={'1304'}/>
            </Modal>
            <Modal isOpen={fileSizeError} 
                setIsOpen={setFileSizeError}
                options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={handleFileSizeError} 
                        items={[
                            {content: <div>The file size of one or multiple uploaded file is bigger than 9MB</div>}, 
                            {content: <div>File size should be less than 9MB</div> }     
                        ]}
                        errorCode={'1305'}/>
            </Modal>
        </div>
        */}
        </>
    );
}

export default PlansUploadMenu;