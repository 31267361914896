import React from 'react';
import './nameTag.css';
import nameTagIcon from 'src/assets/images/salesOffice/user-icon.svg';

const NameTag = ({text, isClient}) => {
    return (
        <div className={`nameTag__container ${isClient ? '--client' : '--agent'}`}>
            <img className="nameTag__icon" src={nameTagIcon}/>
            <div className="nameTag__text">
                {text}
            </div>
        </div>
    )

}
export default NameTag;