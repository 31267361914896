import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import { useOvermind } from '../../overmind';
import '../app/app.css';
import '../dashboard/dashboard.css';
import './marketTest.css';
import waves from '../../assets/images/waves.svg';
import blueMarker from '../../assets/images/blueMarker.svg';
import orangeMarker from '../../assets/images/orangeMarker.svg';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    OverlayView
} from '@react-google-maps/api';
require('dotenv').config();

const MarketTest = ({setAuth}) => {
    const { state, actions, effects } = useOvermind();
    const [percentage, setPercentage] = useState(25);
    const [totalLeads, setTotalLeads] = useState(0);
    const [hotLeads, setHotLeads] = useState(0);
    const [avgBudget, setAvgBudget] = useState(0);
    const params = useParams();
    const [currentPricePage, setCurrentPricePage] = useState("global");
    const [currentProductPage, setCurrentProductPage] = useState("bdrmDmnd");
    const [currentPage, setCurrentPage] = useState("overview");
    const [currentMTResults, setCurrentMTResults] = useState([]);
    const {isLoaded, loadError} = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
  });
    const [graphData, setGraphData] = useState({
        global: {
            min: 0,
            max: 0,
            avg: 0,
        },
        studio: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr1: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr2: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr3: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr4: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
    });
    const [apexGraph, setApexGraph] = useState({
        lastUpdated: "",
        options: {
          chart: {
            id: "rangeBar",
            toolbar: {
                show: false,
            },
            stacked: true
          },
          xaxis: {
            position: "top",
            type: "Category",
            categories: ["Studio", "1 Bedroom", "2 Bedroom", "3 Bedroom"]
          },
          yaxis: {
              min: 0,
              max: 100
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '40%',
              rangeBarGroupRows: true,
              rangeBarOverlap: true,
            }
          },
          fill: {
            type: 'solid',
            colors: ["#c4c4c4", "#ff7600"],
          },
          legend: {
            show: false,
          },
          grid: {
            borderColor: "#E6E6E6",
          },
          states: {
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
          },
          tooltip: {
            enabled: false,
          }
        },
        series: [
          {
            name: "Span",
            data: [
            {
                x: "Studio",
                y: [
                    0,
                    0
                ]
            },
            {
                x: "1 Bedroom",
                y: [
                    0,
                    0
                ]
            },
            {
                x: "2 Bedroom",
                y: [
                    0,
                    0
                ]
            },
            {
                x: "3 Bedroom",
                y: [
                    0,
                    0
                ]
            },
            ]
          },
          {
            name: "Average",
            data: [
                {
                    x: "Studio",
                    y: [
                        0,
                        0
                    ]
                },
                {
                    x: "1 Bedroom",
                    y: [
                        0,
                        0
                    ]
                },
                {
                    x: "2 Bedroom",
                    y: [
                        0,
                        0
                    ]
                },
                {
                    x: "3 Bedroom",
                    y: [
                        0,
                        0
                    ]
                },
            ]
          }
        ],
    });
    const [apexGraphStudio, setApexGraphStudio] = useState(
        {
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: ["0 $", "100 $", "200 $", "300 $", "400 $"],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: 5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#F4A208"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#ff7600"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    
                  ]
                }
              ],
        }
    );
    const [apexGraphBdr1, setApexGraphBdr1] = useState(
        {
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: ["0 $", "100 $", "200 $", "300 $", "400 $"],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: 5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#F4A208"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#ff7600"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    
                  ]
                }
              ],
        }
    );
    const [apexGraphBdr2, setApexGraphBdr2] = useState(
        {
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: ["0 $", "100 $", "200 $", "300 $", "400 $"],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: 5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#F4A208"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#ff7600"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    
                  ]
                }
              ],
        }
    );
    const [apexGraphBdr3, setApexGraphBdr3] = useState(
        {
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: ["0 $", "100 $", "200 $", "300 $", "400 $"],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: 5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#F4A208"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#ff7600"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    
                  ]
                }
              ],
        }
    );
    const [apexGraphBdrDmnd, setApexGraphBdrDmnd] = useState(
        {
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: ["Studio", "1 Bedroom", "2 Bedroom", "3 Bedroom"],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: 5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#1551e8"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#1551e8"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    
                  ]
                }
              ],
        }
    );
    const [apexGraphParkDmnd, setApexGraphParkDmnd] = useState(
        {
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: ["No Parking", "1 Parking", "2 Parking", "3 Parking"],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: 5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#1551e8"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#1551e8"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    
                  ]
                }
              ],
        }
    );

      const [marker, setMarker] = useState(null);
      const mapRef = useRef(null);
      const onMapLoad = useCallback((map) => {
          mapRef.current = map;
      }, []);
      const mapOptions = {
        center: {
            lat: 45.5438845,
            lng: -73.6883796
        },
        zoom: 11,
        disableDefaultUI: true,
      }

    const selectedPrice = {
        backgroundColor: "#ff7600",
        color: " #ffffff"
    }
    const unselectedPrice = {
        backgroundColor: " #E6E6E6",
        color: "black"
    }
    const selectedProduct = {
        backgroundColor: "#1551e8",
        color: " #ffffff"
    }
    const unselectedProduct = {
        backgroundColor: " #E6E6E6",
        color: "black"
    }

    useEffect(() => {
        actions.sidebar.setPage('market-tests');
        actions.topbar.hideShadow();
        actions.topbar.hideLogo();

        effects.currentUser.verifyToken().then((res) => {
            if(res) {
                actions.currentUser.loadCurrentUser();
                actions.currentMarketTest.getCurrentMarketTest(params.id).then( () => {
                    actions.currentMarketTest.calcBedroomPercentages();
                    actions.currentMarketTest.calcParkingPercentages();
                    actions.currentMarketTest.sortBedroomPercentages();
                    actions.currentMarketTest.getAvgRoom();
                    actions.currentMarketTest.parkingDemand();
                    actions.currentMarketTest.updateGraphData();
                    setCurrentMTResults(state.currentMarketTest.results);
                    setTotalLeads(state.currentMarketTest.results.length);
                });
            }
            else {
                setAuth(false);
            }
        });
    },[]);

    useEffect(() => {
        if(state.currentMarketTest.graphMinMax == undefined) {
            return 
        }
        setGraphData(state.currentMarketTest.graphMinMax);

        setHotLeads(state.currentMarketTest.hotLeads);

        setAvgBudget(state.currentMarketTest.avgBudget);

        setApexGraph({
            lastUpdated: "",
            options: {
              chart: {
                id: "rangeBar",
                toolbar: {
                    show: false,
                },
                stacked: true
              },
              xaxis: {
                position: "top",
                type: "Category",
                categories: ["Studio", "1 Bedroom", "2 Bedroom", "3 Bedroom"]
              },
              yaxis: {
                  min: state.currentMarketTest.graphMinMax?.global.min-100,
                  max: state.currentMarketTest.graphMinMax?.global.max+100
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: '40%',
                  rangeBarGroupRows: true,
                  rangeBarOverlap: true,
                }
              },
              fill: {
                type: 'solid',
                colors: ["#c4c4c4", "#ff7600"],
              },
              legend: {
                show: false,
              },
              grid: {
                borderColor: "#E6E6E6",
              },
              states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
              },
              tooltip: {
                enabled: false,
              }
            },
            series: [
              {
                name: "Span",
                data: [
                {
                    x: "Studio",
                    y: [
                        state.currentMarketTest.graphMinMax?.studio.min,
                        state.currentMarketTest.graphMinMax?.studio.max
                    ]
                },
                {
                    x: "1 Bedroom",
                    y: [
                        state.currentMarketTest.graphMinMax?.bdr1.min,
                        state.currentMarketTest.graphMinMax?.bdr1.max
                    ]
                },
                {
                    x: "2 Bedroom",
                    y: [
                        state.currentMarketTest.graphMinMax?.bdr2.min,
                        state.currentMarketTest.graphMinMax?.bdr2.max
                    ]
                },
                {
                    x: "3 Bedroom",
                    y: [
                        state.currentMarketTest.graphMinMax?.bdr3.min,
                        state.currentMarketTest.graphMinMax?.bdr3.max
                    ]
                },
                ]
              },
              {
                name: "Average",
                data: [
                    {
                        x: "Studio",
                        y: [
                            state.currentMarketTest.graphMinMax?.studio.avg-2,
                            state.currentMarketTest.graphMinMax?.studio.avg+2
                        ]
                    },
                    {
                        x: "1 Bedroom",
                        y: [
                            state.currentMarketTest.graphMinMax?.bdr1.avg-2,
                            state.currentMarketTest.graphMinMax?.bdr1.avg+2
                        ]
                    },
                    {
                        x: "2 Bedroom",
                        y: [
                            state.currentMarketTest.graphMinMax?.bdr2.avg-2,
                            state.currentMarketTest.graphMinMax?.bdr2.avg+2
                        ]
                    },
                    {
                        x: "3 Bedroom",
                        y: [
                            state.currentMarketTest.graphMinMax?.bdr3.avg-2,
                            state.currentMarketTest.graphMinMax?.bdr3.avg+2
                        ]
                    },
                ]
              }
            ],
        });

        setApexGraphStudio({
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: [`${state.currentMarketTest.graphMinMax?.studio.bar1Rng}-${state.currentMarketTest.graphMinMax?.studio.bar2Rng-1} $`, `${state.currentMarketTest.graphMinMax?.studio.bar2Rng}-${state.currentMarketTest.graphMinMax?.studio.bar3Rng-1} $`, `${state.currentMarketTest.graphMinMax?.studio.bar3Rng}-${state.currentMarketTest.graphMinMax?.studio.bar4Rng-1} $`, `${state.currentMarketTest.graphMinMax?.studio.bar4Rng}-${state.currentMarketTest.graphMinMax?.studio.bar5Rng-1} $`, `${state.currentMarketTest.graphMinMax?.studio.bar5Rng}-${state.currentMarketTest.graphMinMax?.studio.max} $`],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: state.currentMarketTest.graphMinMax.studio.barMax+5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#F4A208"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#ff7600"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    state.currentMarketTest.graphMinMax?.studio.bar1, state.currentMarketTest.graphMinMax?.studio.bar2, state.currentMarketTest.graphMinMax?.studio.bar3, state.currentMarketTest.graphMinMax?.studio.bar4, state.currentMarketTest.graphMinMax?.studio.bar5
                  ]
                }
              ],
        });

        setApexGraphBdr1({
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: [`${state.currentMarketTest.graphMinMax?.bdr1.bar1Rng}-${state.currentMarketTest.graphMinMax?.bdr1.bar2Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr1.bar2Rng}-${state.currentMarketTest.graphMinMax?.bdr1.bar3Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr1.bar3Rng}-${state.currentMarketTest.graphMinMax?.bdr1.bar4Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr1.bar4Rng}-${state.currentMarketTest.graphMinMax?.bdr1.bar5Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr1.bar5Rng}-${state.currentMarketTest.graphMinMax?.bdr1.max} $`],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: state.currentMarketTest.graphMinMax.bdr1.barMax+5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#F4A208"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#ff7600"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    state.currentMarketTest.graphMinMax?.bdr1.bar1, state.currentMarketTest.graphMinMax?.bdr1.bar2, state.currentMarketTest.graphMinMax?.bdr1.bar3, state.currentMarketTest.graphMinMax?.bdr1.bar4, state.currentMarketTest.graphMinMax?.bdr1.bar5
                  ]
                }
              ],
        });

        setApexGraphBdr2({
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: [`${state.currentMarketTest.graphMinMax?.bdr2.bar1Rng}-${state.currentMarketTest.graphMinMax?.bdr2.bar2Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr2.bar2Rng}-${state.currentMarketTest.graphMinMax?.bdr2.bar3Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr2.bar3Rng}-${state.currentMarketTest.graphMinMax?.bdr2.bar4Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr2.bar4Rng}-${state.currentMarketTest.graphMinMax?.bdr2.bar5Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr2.bar5Rng}-${state.currentMarketTest.graphMinMax?.bdr2.max} $`],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: state.currentMarketTest.graphMinMax.bdr2.barMax+5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#F4A208"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#ff7600"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    state.currentMarketTest.graphMinMax?.bdr2.bar1, state.currentMarketTest.graphMinMax?.bdr2.bar2, state.currentMarketTest.graphMinMax?.bdr2.bar3, state.currentMarketTest.graphMinMax?.bdr2.bar4, state.currentMarketTest.graphMinMax?.bdr2.bar5
                  ]
                }
              ],
        });

        setApexGraphBdr3({
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: [`${state.currentMarketTest.graphMinMax?.bdr3.bar1Rng}-${state.currentMarketTest.graphMinMax?.bdr3.bar2Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr3.bar2Rng}-${state.currentMarketTest.graphMinMax?.bdr3.bar3Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr3.bar3Rng}-${state.currentMarketTest.graphMinMax?.bdr3.bar4Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr3.bar4Rng}-${state.currentMarketTest.graphMinMax?.bdr3.bar5Rng-1} $`, `${state.currentMarketTest.graphMinMax?.bdr3.bar5Rng}-${state.currentMarketTest.graphMinMax?.bdr3.max} $`],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: state.currentMarketTest.graphMinMax.bdr3.barMax+5,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#F4A208"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#ff7600"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: [
                    state.currentMarketTest.graphMinMax?.bdr3.bar1, state.currentMarketTest.graphMinMax?.bdr3.bar2, state.currentMarketTest.graphMinMax?.bdr3.bar3, state.currentMarketTest.graphMinMax?.bdr3.bar4, state.currentMarketTest.graphMinMax?.bdr3.bar5
                  ]
                }
              ],
        });

        setApexGraphBdrDmnd({
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: ["Studio", "1 Bedroom", "2 Bedroom", "3 Bedroom"],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: state.currentMarketTest.bedroomPercentages.length > 0 ? state.currentMarketTest.bedroomPercentages[0].count+2 : 2,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#1551e8"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#1551e8"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: state.currentMarketTest.bedroomPercentages.length > 0 ?
                    [
                      state.currentMarketTest.bedroomPercentages[0].count,
                      state.currentMarketTest.bedroomPercentages[1].count,
                      state.currentMarketTest.bedroomPercentages[2].count,
                      state.currentMarketTest.bedroomPercentages[3].count
                    ] :
                    [
                      0,0,0,0
                    ]
                }
              ],
        });

        setApexGraphParkDmnd({
            options: {
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false,
                    }
                },
                tooltip: {
                    enabled: false,
                },
                xaxis: {
                  categories: ["No Parking", "1 Parking", "2 Parking", "3 Parking"],
                  labels: {
                    style: {
                        fontWeight: 550,
                        fontSize: "12px"
                    },
                }
                },
                yaxis: {
                    min: 0,
                    max: state.currentMarketTest.parkingPercentages.length > 0 ? state.currentMarketTest.parkingPercentages[0].count+2 : 2,
                    forceNiceScale: true,
                    labels: {
                        style: {
                            fontWeight: 550,
                            fontSize: "12px"
                        }
                    },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                        position: 'top',
                    },
                    colors : {
                        ranges: [{
                            from: 0,
                            to: 34,
                            color: "#1551e8"
                        }]
                    }
                  }
                },
                fill: {
                  type: 'solid',
                  colors: ["#1551e8"],
                },
                legend: {
                  show: false,
                },
                grid: {
                  borderColor: "#E6E6E6",
                },
              },
              series: [
                {
                  name: "Span1",
                  data: state.currentMarketTest.parkingPercentages.length > 0 ?
                  [
                    state.currentMarketTest.parkingPercentages[0].count,
                    state.currentMarketTest.parkingPercentages[1].count,
                    state.currentMarketTest.parkingPercentages[2].count,
                    state.currentMarketTest.parkingPercentages[3].count
                  ] :
                  [
                    0,0,0,0
                  ]
                }
              ],
        });

    },[state.currentMarketTest.lastUpdated]);

    const updateContent = (e) => {
        setCurrentPricePage(e.target.getAttribute('newDisplay'));
    };
    const updateContents = (e) => {
        setCurrentProductPage(e.target.getAttribute('newDisplay'));
    };

    const ProgressBar = () => {
        return (
            <div className="progress-bar">
                <Filler></Filler>
            </div>
        )
    };
    const Filler = () => {
        return <div className="filler" style={{ width: `${percentage}%`}}/>
    };

    const styles = {
      borderBottom:"1px solid #D1D1D1",
      fontWeight:"505"
    }

    //TODO
    // legend
    return (
        <>
            <div className="mt-overview-container">
                <div className="mt-o__banner">
                    <div className="mt-o__banner-bg"></div>
                    <div className="mt-o__tab-container">
                        <div className={ currentPage == "overview" ? "mt-o__tab-item mt-o__tab-item--active" : "mt-o__tab-item"} onClick={ () => {
                            setCurrentPage("overview");
                        }}>Overview</div>
                        <div className={ currentPage == "price" ? "mt-o__tab-item mt-o__tab-item--active" : "mt-o__tab-item"} onClick={ () => {
                           setCurrentPage("price");
                        }}>Price</div>
                        <div className={ currentPage == "product" ? "mt-o__tab-item mt-o__tab-item--active" : "mt-o__tab-item"} onClick={ () => {
                            setCurrentPage("product");
                        }}>Product</div>
                        <div className={ currentPage == "leads" ? "mt-o__tab-item mt-o__tab-item--active" : "mt-o__tab-item"} onClick={ () => {
                            setCurrentPage("leads");
                        }}>Leads</div>
                        <div className={ currentPage == "lifestyle" ? "mt-o__tab-item mt-o__tab-item--active" : "mt-o__tab-item"} onClick={ () => {
                            setCurrentPage("lifestyle");
                        }}>Lifestyle</div>
                    </div>
                    <div className="mt-o__banner-info">
                        <h3>Market Test</h3>
                        <h1>{state.currentMarketTest.fields.find(e => e.field_content_id === 'project_address')?.field_value}</h1>
                        <div className="mt-o__banner-info-arrow"></div>
                        <br></br>
                        <br></br>
                        <div className="mt-o__banner-dates-container">
                            <div>
                                <b>STARTING DATE</b>
                                <div>{state.currentMarketTest.start_date}</div>
                            </div>
                            <div>
                                <b>ENDING DATE</b>
                                <div>{state.currentMarketTest.completion_date}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-o__tab-pane-container">
                    { currentPage == "overview" ? 
                    <div className="mt-o__tab-pane-item mt-o__overview">
                        <div className="mt-o__overview-reg_leads">
                            <small>REGISTERED LEADS</small>
                            <div>
                                <div className="mt-o__overview-reg_leads-top">
                                    <br></br>
                                    <br></br>
                                    <div className="mt-o__overview-reg_leads-tophalf">
                                        <div className="mt-o__overview-current_leads" style={ {marginLeft: {percentage}}}>
                                            <strong><span className="current_leads_num">{hotLeads}</span> HOT LEADS <div className="arrow-down_o"></div></strong>
                                        </div>
                                        <div className="mt-o__overview-total_leads">
                                            <span className="current_leads_num total_leads_num"><b>{totalLeads}</b></span>
                                            <div className="total_leads-text">
                                                TOTAL <b>LEADS</b><br></br><b>GENERATED</b>
                                                <div class="arrow-down_b"></div></div>
                                            
                                        </div>
                                    </div>
                                    <ProgressBar></ProgressBar>
                                </div>
                                <div className="mt-o__overview-reg_leads-bottom">
                                    <div className="mt-o__overview-ppl_reached">
                                        <small># People reached</small>
                                        <div className="mt-o__overview-text">0</div>
                                    </div>
                                    <div className="mt-o__overview-link_clicks">
                                        <small># Link clicks</small>
                                        <div className="mt-o__overview-text">0</div>
                                    </div>
                                    <div className="mt-o__overview-budget_spent">
                                        <small>Budget Spent</small>
                                        <div className="mt-o__overview-text">0 $</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <small className="mt-o__overview-location">LOCATION OF THE LEADS</small>
                        <div className="mt-o__overview-budget-demand">
                            <div className="mt-o__overview-budget">
                                <div className="mt-o__overview-budget_text">AVERAGE BUDGET</div>
                                <div className="mt-o__overview-budget_info-container">
                                    <div className="mt-o__overview-budget_info">{avgBudget} $</div>
                                    <div className="mt-o__overview-budget_info-triangle"></div>
                                    <div className="mt-o__overview-budget_info-circle"></div>
                                </div>
                                <div className="mt-o__overview-waves_container" ></div>
                            </div>
                            <small className="mt-o__overview-demand">
                                <div className="mt-o__overview-demand-info">
                                    <div className="mt-o__overview-demand-info-all">
                                        <div className="mt-o__overview-demand-info-title">BEDROOM DEMAND</div>
                                        <div >
                                            {
                                                typeof state.currentMarketTest.bedroomPercentages !== 'undefined' ? 
                                                state.currentMarketTest.bedroomPercentages.map((b, index) => {
                                                    return (
                                                        index > 2 ? <></> :
                                                        <div key={index} className="row">
                                                            <div className={`box orange-${index+1}`}></div>
                                                            <span>{b.name}</span>
                                                        </div>
                                                    );
                                                }) : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-o__overview-demand-circles"> 
                                        {
                                            typeof state.currentMarketTest.bedroomPercentages !== 'undefined' ? 
                                            state.currentMarketTest.bedroomPercentages.map((b, index) => {
                                                return (
                                                    index > 2 ? <></> :
                                                    <div key={index} className={`mt-o__overview-demand-circle circle${index+1} orange-${index+1}`} style={{ transform: index == 0 ? "scale(1.2)" : index == 1 ? "scale(0.9)" : "scale(0.79)"}}>
                                                        <div className="mt-o__overview-demand-circle-percent">{b.value}%</div>
                                                    </div>
                                                );
                                            }) : <></>
                                        }
                                    </div>
                                </div>
                            </small>
                        </div>
                        <small className="mt-o__overview-actions">
                            ACTIONS
                            <div className="mt-o__overview-full_listing" type="button" onClick>View Full Listing <i className="arrow">&#8594;</i></div>
                            <div className="mt-o__overview-leads_list" type="button" onClick>Download Leads List <i className="arrow2">&#8595;</i></div>
                            <div className="mt-o__overview-results_report" type="button" onClick>Download Results Report <i className="arrow2">&#8595;</i></div>
                        </small>
                    </div> :
                    currentPage == "price" ? 
                    <div className="mt-o__tab-pane-item mt-o__price">
                        { currentPricePage == "global" ? 
                            <div>
                                <b>Global Prices</b>
                                <div className="chart_table">
                                    <Chart
                                        options={apexGraph.options}
                                        series={apexGraph.series}
                                        type="rangeBar"
                                        height="278"
                                    />
                                    <table>
                                        <tr>
                                            <th className="col1">Min</th>
                                            <th className="col2">Max</th>
                                            <th className="col3">Average</th>
                                        </tr>
                                        <tr>
                                            <td className="col1">{state.currentMarketTest.fields.find(e => e.field_content_id == 'min_price_studios')?.field_value} $</td>
                                            <td className="col2">{state.currentMarketTest.fields.find(e => e.field_content_id == 'max_price_studios')?.field_value} $</td>
                                            <td className="col3">{graphData?.studio.avg} $</td>
                                        </tr>
                                        <tr>
                                            <td className="col1">{state.currentMarketTest.fields.find(e => e.field_content_id == 'min_price_1_bdr')?.field_value} $</td>
                                            <td className="col2">{state.currentMarketTest.fields.find(e => e.field_content_id == 'max_price_1_bdr')?.field_value} $</td>
                                            <td className="col3">{graphData?.bdr1.avg} $</td>
                                        </tr>
                                        <tr>
                                            <td className="col1">{state.currentMarketTest.fields.find(e => e.field_content_id == 'min_price_2_bdr')?.field_value} $</td>
                                            <td className="col2">{state.currentMarketTest.fields.find(e => e.field_content_id == 'max_price_2_bdr')?.field_value} $</td>
                                            <td className="col3">{graphData?.bdr2.avg} $</td>
                                        </tr>
                                        <tr>
                                            <td className="col1">{state.currentMarketTest.fields.find(e => e.field_content_id == 'min_price_3_bdr')?.field_value} $</td>
                                            <td className="col2">{state.currentMarketTest.fields.find(e => e.field_content_id == 'max_price_3_bdr')?.field_value} $</td>
                                            <td className="col3">{graphData?.bdr3.avg} $</td>
                                        </tr>
                                    </table>
                                </div>
                            </div> 
                        : currentPricePage == "studio" ? 
                            <div>
                                <div className="mt-o__price-title">
                                    <b>Studio Prices</b>
                                    <div className="mt-o__price-title_avg">
                                        AVERAGE STUDIO PRICE
                                        <br></br>
                                        <b className="mt-o__price-title_avg_price">{graphData?.studio.avg}$/month</b>
                                    </div>
                                </div>
                                <Chart
                                    options={apexGraphStudio.options}
                                    series={apexGraphStudio.series}
                                    type="bar"
                                    height="255"
                                />
                            </div> 
                        : currentPricePage == "1bedroom" ? 
                            <div>
                                <div className="mt-o__price-title">
                                    <b>1 Bedroom Prices</b>
                                    <div className="mt-o__price-title_avg">
                                        AVERAGE 1 BEDROOM PRICE
                                        <br></br>
                                        <b className="mt-o__price-title_avg_price">{graphData?.bdr1.avg}$/month</b>
                                    </div>
                                </div>
                                <Chart
                                    options={apexGraphBdr1.options}
                                    series={apexGraphBdr1.series}
                                    type="bar"
                                    height="255"
                                />
                            </div> 
                        : currentPricePage == "2bedroom" ? 
                            <div>
                                <div className="mt-o__price-title">
                                    <b>2 Bedroom Prices</b>
                                    <div className="mt-o__price-title_avg">
                                        AVERAGE 2 BEDROOM PRICE
                                        <br></br>
                                        <b className="mt-o__price-title_avg_price">{graphData?.bdr2.avg}$/month</b>
                                    </div>
                                </div>
                                <Chart
                                    options={apexGraphBdr2.options}
                                    series={apexGraphBdr2.series}
                                    type="bar"
                                    height="255"
                                />
                            </div> 
                        : <div>
                            <div className="mt-o__price-title">
                                    <b>3 Bedroom Prices</b>
                                    <div className="mt-o__price-title_avg">
                                        AVERAGE 3 BEDROOM PRICE
                                        <br></br>
                                        <b className="mt-o__price-title_avg_price">{graphData?.bdr3.avg}$/month</b>
                                    </div>
                                </div>
                                <Chart
                                    options={apexGraphBdr3.options}
                                    series={apexGraphBdr3.series}
                                    type="bar"
                                    height="255"
                                />
                        </div>  
                        }
                        <div className="mt-o__price-tabs">
                            <div className="mt-o__price-tab-global" style={currentPricePage === "global" ? selectedPrice : unselectedPrice} type="button" onClick={updateContent} newDisplay="global">Global</div>
                            <div className="mt-o__price-tab-studio" style={currentPricePage === "studio" ? selectedPrice : unselectedPrice} type="button" onClick={updateContent} newDisplay="studio">Studio</div>
                            <div className="mt-o__price-tab-1bedroom" style={currentPricePage === "1bedroom" ? selectedPrice : unselectedPrice} type="button" onClick={updateContent} newDisplay="1bedroom">1 Bedroom</div>
                            <div className="mt-o__price-tab-2bedroom" style={currentPricePage === "2bedroom" ? selectedPrice : unselectedPrice} type="button" onClick={updateContent} newDisplay="2bedroom">2 Bedroom</div>
                            <div className="mt-o__price-tab-3bedroom" style={currentPricePage === "3bedroom" ? selectedPrice : unselectedPrice} type="button" onClick={updateContent} newDisplay="3bedroom">3 Bedroom</div>
                        </div>
                        {/* <div className="mt-o__price-results_report" type="button" onClick>Download Results Report</div> */}
                    </div> :
                    currentPage == "product" ? 
                    <div className="mt-o__tab-pane-item mt-o__product">
                        <div>
                            <b>Product Demand</b>
                            <div className="mt-o__product-tabs">
                                <div className="mt-o__product-tab-bdrmDmnd" style={currentProductPage === "bdrmDmnd" ? selectedProduct : unselectedProduct} type="button" onClick={updateContents} newDisplay="bdrmDmnd">Bedroom Demand</div>
                                <div className="mt-o__product-tab-parkDmnd" style={currentProductPage === "parkDmnd" ? selectedProduct : unselectedProduct} type="button" onClick={updateContents} newDisplay="parkDmnd">Parking Demand</div>
                            </div>
                            { currentProductPage == "bdrmDmnd" ?
                            <div>
                            <Chart
                                    options={apexGraphBdrDmnd.options}
                                    series={apexGraphBdrDmnd.series}
                                    type="bar"
                                    height="255"
                            />
                            <table>
                                <tr>
                                    <th className="coll1">Number of Bedrooms</th>
                                    <th className="coll2">Number of Leads</th>
                                    <th className="coll3">Percentage</th>
                                </tr>
                                {
                                  typeof state.currentMarketTest.bedroomPercentages !== 'undefined' ? 
                                  state.currentMarketTest.bedroomPercentages.map((b, index) => {
                                      return (
                                          index > 3 ? <></> :
                                          <tr>
                                            <td key={index} style={styles} className="colll1">{b.name}</td>
                                            <td style={{borderBottom:"1px solid #D1D1D1"}} className={`colll2 demand${index+1}`}>{b.count}</td>
                                            <td style={{borderBottom:"1px solid #D1D1D1"}} className={`colll3 demand${index+1}`}>{b.value.toFixed()} %</td>
                                          </tr>
                                      );
                                  }) : <></>
                                }
                                <tr>
                                    <td className="collll1">Total</td>
                                    <td className="collll2">{state.currentMarketTest.totalLeads}</td>
                                    <td className="collll3">100 %</td>
                                </tr>
                            </table>
                            </div>
                            : 
                            <div>
                            <Chart
                                    options={apexGraphParkDmnd.options}
                                    series={apexGraphParkDmnd.series}
                                    type="bar"
                                    height="255"
                            />
                            <table>
                                <tr>
                                    <th className="coll1">Parking</th>
                                    <th className="coll2">Number of Leads</th>
                                    <th className="coll3">Percentage</th>
                                </tr>
                                {
                                  typeof state.currentMarketTest.parkingPercentages !== 'undefined' ? 
                                  state.currentMarketTest.parkingPercentages.map((b, index) => {
                                      return (
                                          index > 3 ? <></> :
                                          <tr>
                                            <td key={index} style={styles} className="colll1">{b.name}</td>
                                            <td style={{borderBottom:"1px solid #D1D1D1"}} className={`colll2 demand${index+1}`}>{b.count}</td>
                                            <td style={{borderBottom:"1px solid #D1D1D1"}} className={`colll3 demand${index+1}`}>{b.value.toFixed()} %</td>
                                          </tr>
                                      );
                                  }) : <></>
                                }
                                <tr>
                                    <td className="collll1">Total</td>
                                    <td className="collll2">{state.currentMarketTest.totalLeads}</td>
                                    <td className="collll3">100 %</td>
                                </tr>
                            </table>
                            </div>
                            }
                        </div>
                    </div> :
                    currentPage == "leads" ? 
                    <div className="mt-o__tab-pane-item mt-o__leads">
                      <b>Leads List</b>
                      <div className="parkingDmndTableWrapper">
                      <table className="parkingDmndTable">
                          <tr>
                              <th className="parkingDmndCol">Date</th>
                              <th className="parkingDmndCol">First Name</th>
                              <th className="parkingDmndCol">Last Name</th>
                              <th className="parkingDmndCol">Email Address</th>
                              <th className="parkingDmndCol">Phone Number</th>
                              <th className="parkingDmndCol">Bedroom</th>
                              <th className="parkingDmndCol">Budget</th>
                              <th className="parkingDmndCol">Parking</th>
                          </tr>
                          {
                              typeof state.currentMarketTest.results !== 'undefined' ? 
                              state.currentMarketTest.results.map((b, index) => {
                                  return (
                                       <tr key={index} onMouseEnter={() => actions.currentMarketTest.startAnimation(index)} onMouseLeave={() => actions.currentMarketTest.endAnimation(index)}>
                                          <td>{b.created_at.slice(0,-14)}</td>
                                          <td>{b.lead.first_name}</td>
                                          <td>{b.lead.last_name}</td>
                                          <td>{b.lead_email_address}</td>
                                          <td>{b.lead.phone_number}</td>
                                          { b.content.bedrooms == "bedroom1" ? 
                                            <td>1</td> :
                                            b.content.bedrooms == "bedroom2" ? 
                                            <td>2</td> :
                                            b.content.bedrooms == "bedroom3" ? 
                                            <td>3</td> :
                                            <td>Studio</td>
                                          }
                                          <td>{b.content.budget[0]} - {b.content.budget[0]}$</td>
                                          <td>{b.content.parkingSpaces}</td>
                                      </tr>
                                  );
                              }) : <></>
                          }
                      </table>
                      </div>
                      <div className="mt-map-container">
                        {loadError ? <>{JSON.stringify(loadError)}</> : isLoaded ? 
                        <>
                        <GoogleMap 
                            mapContainerStyle={{width: '100%', height: '600px', border: '1px solid #dddddd', borderTop: 'none', margin: '0 auto'}} 
                            options={mapOptions}
                            onLoad={onMapLoad}
                        >
                            { typeof state.currentMarketTest.results !== "undefined" ? 
                                state.currentMarketTest.results.map((b, index) => {
                                    return (
                                        <>
                                            <Marker position={new window.google.maps.LatLng(b.content.location.latitude, b.content.location.longitude)} icon={b.animation == 1 ? orangeMarker : blueMarker} animation={b.animation} />
                                        </>
                                    )
                                }) : <></> }
                        </GoogleMap> 
                        </>
                        : <></>}
                    </div>
                    </div> :
                    <div className="mt-o__tab-pane-item mt-o__lifestyle"></div> }
                </div>
            </div>
        </>
    );
}

export default MarketTest;