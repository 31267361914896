export const checkFileSize = (file, sizeLimit) => {
    if(file.size >= sizeLimit) {
        return true;
    } else {
        return false;
    }
}

export const checkFileValid = (arr, setModal, isImage) => {
    if(isImage) {
        for (const element of arr) {
            let lastIndex = element.name.split(".")[element.name.split(".").length - 1];
            if(lastIndex !== "jpeg" && lastIndex !== "jpg" && lastIndex !== "png"){
                setModal(true);
                return false
            }
        }
        setModal(false);
        return true;
    } else {
        for (const element of arr) {
            let lastIndex = element.name.split(".")[element.name.split(".").length - 1];
            if(lastIndex !== "xlsx" && lastIndex !== "xls" && 
               lastIndex !== "docx" && lastIndex !== "doc" &&
               lastIndex !== "pptx" && lastIndex !== "ppt" &&
               lastIndex !== "jpeg" && lastIndex !== "jpg" &&
               lastIndex !== "pdf" && lastIndex !== "png"){
                setModal(true);
                return false
            }
        }
        setModal(false);
        return true;
    }
}

export const findFileSize = (bytes, decimals = 2) => {
    if(bytes === 0) return '0 bytes'
    
    const stdSize = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB','MB','GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(stdSize));
    return parseFloat((bytes / Math.pow(stdSize, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const convertUrltoFile = async (url, fileName) => {
    let convertedFile = await fetch(url)
      .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], fileName, { contentType })
          return file
      })
      return convertedFile;
  }

export const createTinyName = (fileName) => {
    if(fileName.length > 17) {
        let extensionName = fileName.split(".")[fileName.split(".").length - 1];
        let finalFinalName = fileName.slice(0, 6) + "..." + fileName.slice(12, 17) + "." + extensionName
        return finalFinalName;
    }else{
        return fileName;
    }
}

export const fileExtensionName = (str) => {
    if(str){
        return str.split(".")[str.split(".").length - 1];
    }
}

export const checkPublicId = (files) => {
    for (const file of files) {
        if(file.publicId == '' || file.publicId == null) {
            return true;
        }
    }
    return false;
}