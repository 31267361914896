import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useOvermind } from 'src/overmind';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import './reservationForm.css';
import UnitSelection from './steps/unitSelection';
import IdentityForm from './steps/identityForm';
import Payment from './steps/payment';
import ReservationConfirmation from './steps/reservationConfirmation';
import SvgIcons from 'src/components/shared/svgIcons';


const ReservationForm = () => {
    const { state, actions } = useOvermind();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if(!state.currentVSO.enable_reservations) {
            let pathWithId = location.pathname.match(/(\/virtual-sales-office\/\d+)/)[0];        
            history.push(pathWithId);
        }

        actions.topbar.setCurrentPage('');

        if(location.pathname.includes('reservation-complete')) {
            actions.currentVSO.setReservationStep(3);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state.currentVSO.reservation.currentStep]);

    return (
        <>
        <div className="reservationForm__container">
            <div className="reservationForm__steps-container">
                <div className="reservationForm__steps-list">
                    <div className="reservationForm__steps-line"></div>
                    <div className="reservationForm__steps-line --active" style={{width: `${state.currentVSO.reservation.currentStep / 3 * 100}%`}}></div>
                    <div className={`reservationForm__steps-item${state.currentVSO.reservation.currentStep >= 0 ? ' --active' : ''}`} style={{left: `${0 / 3 * 100}%`}}>
                        <div className="reservationForm__step-circle">
                            {state.currentVSO.reservation.currentStep > 0 ? <SvgIcons type="round_checkmark" /> : '1'}
                        </div>
                        <div className="reserationForm__step-text">{t('salesOffice.reservationForm.unitSelection')}</div>
                    </div>
                    <div className={`reservationForm__steps-item${state.currentVSO.reservation.currentStep >= 1 ? ' --active' : ''}`} style={{left: `${1 / 3 * 100}%`}}>
                        <div className="reservationForm__step-circle">
                            {state.currentVSO.reservation.currentStep > 1 ? <SvgIcons type="round_checkmark" /> : '2'}
                        </div>
                        <div className="reserationForm__step-text">{t('salesOffice.reservationForm.personalDetails')}</div>
                    </div>
                    <div className={`reservationForm__steps-item${state.currentVSO.reservation.currentStep >= 2 ? ' --active' : ''}`} style={{left: `${2 / 3 * 100}%`}}>
                        <div className="reservationForm__step-circle">
                            {state.currentVSO.reservation.currentStep > 2 ? <SvgIcons type="round_checkmark" /> : '3'}
                        </div>
                        <div className="reserationForm__step-text">{t('salesOffice.reservationForm.payment')}</div>
                    </div>
                    <div className={`reservationForm__steps-item${state.currentVSO.reservation.currentStep >= 3 ? ' --active' : ''}`} style={{left: `${3 / 3 * 100}%`}}>
                        <div className="reservationForm__step-circle">
                            {state.currentVSO.reservation.currentStep > 3 ? <SvgIcons type="round_checkmark" /> : '4'}
                        </div>
                        <div className="reserationForm__step-text">{t('salesOffice.reservationForm.reservationConfirmation')}</div>
                    </div>
                </div>
            </div>
            <div className="reservationForm__step-container">
                {
                state.currentVSO.reservation.currentStep === 0 ? <UnitSelection /> :
                state.currentVSO.reservation.currentStep === 1 ? <IdentityForm /> :
                state.currentVSO.reservation.currentStep === 2 ? <Payment /> :
                state.currentVSO.reservation.currentStep === 3 ? <ReservationConfirmation /> :
                <></>
                }
            </div>
        </div>
        </>
    );
}

export default ReservationForm;