import React, {useState, useEffect, useCallback} from "react";
import './textInput.scss';
import AuditorComment from '../../auditorComment';
import debounce from 'lodash.debounce';
import InfoPopover from "../../infoPopover";

const TextInput = ({
    label, value, setValue, placeholder, info,
    dataObj, validateField, status = null, 
    auditorComment, error, theme, children,
    events={onFocus: null, onBlur: null},
    options={isEmail: false, isPhone: false, isPassword: false, isMandatory: false, maxWidth: ""}}) => {
        const [fieldId, setFieldId] = useState('');
        const [fieldStatus, setFieldStatus] = useState(status);
        const [fieldClasses, setFieldClasses] = useState('');

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(label + Math.floor(Math.random() * Date.now())));
    }, []);

    useEffect(() => {
        let classes = ['input__text'];
        if(theme) {
            classes.push(`--${theme}`);
        }
        if(fieldStatus) {
            classes.push(`--${fieldStatus}`);
        }
        if(typeof error === 'string') {
            if(classes.indexOf(`--denied`) === -1) {
                classes.push(`--denied`);
            }
        } else if(error === false) {
            if(classes.indexOf(`--valid`) === -1) {
                classes.push(`--valid`);
            }
        }
        setFieldClasses(classes.join(' '));
    }, [theme, fieldStatus, error]);

    const handleValueChange = (e) => {
        setValue(e, dataObj);
        if(validateField) {
            if(!validateField(dataObj.fieldId)){
                setFieldStatus('valid');
            } else {
                setFieldStatus('denied');
            };
        }
    }

    return(
    <div className="textInput__container" style={{maxWidth: options.maxWidth}}>
        <div className="textInput__label-info-container">
            <label htmlFor={`input__${fieldId}`}>{label} {options.isMandatory && <span>*</span>}</label>
            {info && <InfoPopover content={info} />}
        </div>
        {auditorComment && 
        <AuditorComment comment={auditorComment}/>
        }
        {options.isEmail ? 
        <input className={fieldClasses} style={{maxWidth: options.maxWidth}} type="email" value={value} onChange={handleValueChange} placeholder={placeholder} disabled={options.disabled} onFocus={events.onFocus} onBlur={events.onBlur}/>
        :
        options.isPhone ? 
        <input className={fieldClasses} style={{maxWidth: options.maxWidth}} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" value={value} onChange={handleValueChange} placeholder={placeholder} disabled={options.disabled} onFocus={events.onFocus} onBlur={events.onBlur}/> 
        :
        options.isPassword ?
        <input className={fieldClasses} style={{maxWidth: options.maxWidth}} type="password" value={value} onChange={handleValueChange} placeholder={placeholder} disabled={options.disabled} onFocus={events.onFocus} onBlur={events.onBlur}/>
        :
        <input className={fieldClasses} style={{maxWidth: options.maxWidth}} id={`input__${fieldId}`} type="text" value={value} onChange={handleValueChange} placeholder={placeholder} disabled={options.disabled} onFocus={events.onFocus} onBlur={events.onBlur}/>
        }
        {typeof error === 'string' && 
        <div className="input__text-error">{error}</div>
        }
        {children}
    </div>
    )
}

export default TextInput;