import { createOperator, createMutationOperator } from 'overmind';

export const mutateAndReturn = (operation) => createMutationOperator(
    'mutateAndReturn',
    operation.name,
    (err, context, value, next) => {
        if(err) next(err, value)
        else {
            const res = operation(context, value)
            next(null, res);
        }
    }
)

export const debounceUnitValuesChanges = (ms) => {
    let timeout
    let previousFinal
    let newValuesObj = {}

    return createOperator(
        'debounceWithId',
        String(ms),
        (err, context, value, next, final) => {
            if (err) next(err, value)
            else {
                if (timeout) {
                    clearTimeout(timeout)
                    previousFinal(null, value)
                }
                previousFinal = final
                newValuesObj[value.unitId] = {
                    unit_id: value.unitId,
                    values: {
                        ...newValuesObj[value.unitId]?.values,
                        [value.valueKey]: value.newValue
                    }
                }
                timeout = setTimeout(() => {
                    timeout = null
                    const newValues = Object.values(newValuesObj)
                    newValuesObj = {}
                    next(null, newValues)
                }, ms)
            }
        }
    )
}