import React, { useState, useEffect, useRef} from 'react';
import { Stage, Layer, Circle, Line } from 'react-konva';
import DrawingImage from './drawingImage';
import { flatten } from 'src/utils/canvas';
import './drawingCanvas.scss';
import FloorPoly from './floorPoly';
import UnitPoly from './unitPoly';

const DrawingCanvas = ({
    type,
    editMode, 
    points, 
    setPoints, 
    isClosed, 
    setIsClosed,
    flattenedPoints,
    setFlattenedPoints,
    imageUrl,
    containerWidth,
    containerHeight,
    data,
    setActiveFloor,
    activeFloor,
    units,
    handleUnitClick,
}) => {

    const [isMouseOverStartPos, setIsMouseOverStartPos] = useState(false);
    const [currentMousePos, setCurrentMousePos] = useState([0, 0]);
    const [windowMousePos, setWindowMousePos] = useState([0, 0]);
    const [showFloor, setShowFloor] = useState();
    const [showUnit, setShowUnit] = useState();
    const [imageSize, setImageSize] = useState({});
    const [scale, setScale] = useState(1);
    const stageRef = useRef(null);

    const handleDragStartPoint = () => {};

    const handleDragEndPoint = () => {};

    const getMousePosition = (stage) => {
        return [stage.getPointerPosition().x / scale, stage.getPointerPosition().y / scale];
    };

    const handlePointClick = (e) => {
        const stage = e.target.getStage();
        const mousePos = getMousePosition(stage);
        if (isClosed) {
            return;
        }
        if (isMouseOverStartPos && points.length >= 3) {
            setIsClosed(true);
        } else {
            setPoints([...points, mousePos]);
        }
    };

    const handleMouseMove = (e) => {
        const stage = e.target.getStage();
        const mousePosition = getMousePosition(stage);
        setCurrentMousePos(mousePosition);
    };

    useEffect(() => {
        if(editMode) {
            const flattenedPoints = points
                .concat(isClosed ? [] : currentMousePos)
                .reduce((a, b) => a.concat(b), []);
            setFlattenedPoints(flattenedPoints);
        }
    }, [points, currentMousePos]);

    useEffect(() => {
        let horizontalRatio = containerWidth / imageSize?.width;
        let verticalRatio = containerHeight / imageSize?.height;
        if( horizontalRatio < verticalRatio ) {
            setScale(horizontalRatio);
        } else {
            setScale(verticalRatio);
        }
    }, [containerWidth, containerHeight, imageSize]);

    useEffect(() => {
        const mouseMove = (e) => { 
            setWindowMousePos([e.clientX, e.clientY]);
        }

        document.addEventListener('mousemove', mouseMove);

        return () => document.removeEventListener('mousemove', mouseMove);
    }, []);


    const handleDragMovePoint = (e) => {
        const arrayPoints = points;
        if (e.target.name() === 'Circle') {
            const index = e.currentTarget.index;
            const position = [e.target.attrs.x, e.target.attrs.y];
            setPoints([
                ...arrayPoints.slice(0, index),
                position,
                ...arrayPoints.slice(index + 1)
            ]);
            setFlattenedPoints(flatten(arrayPoints));
        }
      };

    const handleMouseOverStartPoint = (e) => {
        if (isClosed || points.length < 3) return;
        e.target.scale({ x: 2, y: 2 });
        setIsMouseOverStartPos(true);
    };
    
    const handleMouseOutStartPoint = (e) => {
        e.target.scale({ x: 1, y: 1 });
        setIsMouseOverStartPos(false);
    };

    const getNumberOfAvailableUnits = (index) => {
        let floor = index + 1;
        if(!Array.isArray(units)) return [];
        let availableUnits = units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == floor : unit.unit_number.toString().slice(0, 2) == floor)
        .sort((unitA, unitB) => {
            if(unitA.unit_number > unitB.unit_number) {
                return 1;
            } else if(unitA.unit_number < unitB.unit_number) {
                return -1;
            }
            return 0;
        }).filter(unit => unit.status == 'available').length;
        return availableUnits;
    }


    const onMouseBuildingEnter = (index) => {
        setShowFloor(index);
        stageRef.current.content.style.cursor = 'pointer';
    }

    const onMouseBuildingLeave = () => {
        setShowFloor(-1);
        stageRef.current.content.style.cursor = 'default';
    }

    const onMouseUnitEnter = (e, unit, index) => {
        setShowUnit(index)
        if(unit.status == 'sold' || unit.status == 'rented' || unit.status == 'reserved') {
            stageRef.current.content.style.cursor = 'not-allowed';
        } else {
            stageRef.current.content.style.cursor = 'pointer';
        }
    }

    const onMouseUnitLeave = () => {
        setShowUnit(-1)
        stageRef.current.content.style.cursor = 'default';
    }

    
    return(
        <Stage
            ref={stageRef}
            width={imageSize?.width * scale}
            height={imageSize?.height * scale}
            onClick={handlePointClick}
            onMouseMove={handleMouseMove}
            scaleX={scale}
            scaleY={scale}
            style={{overflow: 'hidden'}}
        >
            <Layer>
                <DrawingImage 
                    url={imageUrl} 
                    setImageSize={setImageSize}
                />
            </Layer>
            { 
                type === 'floor' ? 
                data?.map((el, index) => {
                return (
                    el.outline_content.isFinished && el.status != 'notAvailable' &&(
                    <UnitPoly 
                        unitData={el} 
                        index={index} 
                        editMode={editMode} 
                        scale={scale}   
                        isHovered={showUnit == index}
                        isActive={showUnit != index}
                        activeFloor={activeFloor}
                        windowMousePos={windowMousePos}  
                        onMouseUnitEnter={onMouseUnitEnter} 
                        onMouseUnitLeave={onMouseUnitLeave}
                        handleUnitClick={handleUnitClick}
                    />
                    )
                );
                })
                :
                data?.map((el, index) => {
                    return (
                        el.isFinished && (
                        <FloorPoly 
                            floorData={el}
                            index={index}
                            editMode={editMode}
                            scale={scale}
                            isActive={activeFloor == (index + 1)} 
                            setActiveFloor={setActiveFloor} 
                            activeFloor={activeFloor}
                            isHovered={showFloor == index} 
                            units={units} 
                            onMouseBuildingEnter={onMouseBuildingEnter} 
                            onMouseBuildingLeave={onMouseBuildingLeave}
                            windowMousePos={windowMousePos}
                        />
                        )
                    );
                })
            }
                <Layer>
                    <Line
                        stroke="magenta"
                        strokeWidth={2 / scale}
                        closed={isClosed}
                        name="Poly"
                        points={flattenedPoints}
                        fill="#3D53F599"
                    />
                </Layer>
            {editMode && 
                <Layer>
                    {points?.map((el, index) => {
                        const fillPoly =
                            index === 0
                            ? {
                                onMouseOver: handleMouseOverStartPoint,
                                onMouseOut: handleMouseOutStartPoint
                                }
                            : null;
                        return (
                            <Circle
                                key={index}
                                name="Circle"
                                radius={5 / scale}
                                x={el[0]}
                                y={el[1]}
                                fill="white"
                                stroke="black"
                                strokeWidth={1}
                                onDragStart={handleDragStartPoint}
                                onDragMove={handleDragMovePoint}
                                onDragEnd={handleDragEndPoint}
                                draggable
                                {...fillPoly}
                            /> 
                        );
                    })}
                </Layer>
            }
            <Layer name="top-layer" />
        </Stage>
    );
}

export default DrawingCanvas;