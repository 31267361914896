import { useState, useEffect } from 'react';
import { useOvermind } from 'src/overmind';

export const useWindowDimensions = () => {
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {width, height};
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const useScrollPosition = () => {
    let throttle = require('lodash.throttle');
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", throttle(handleScroll, 100));

        return () => {
            window.removeEventListener("scroll", throttle(handleScroll, 100));
        };
    }, []);

    return scrollPosition;
}

export const useInitializeFields = (fields) => {
    const {state, actions} = useOvermind();

    useEffect(() => {
        for (const f of fields) {
            if(state.productForm.newProduct.fields[f.contentId] == undefined || state.productForm.newProduct.fields[f.contentId]?.value == null) {
                actions.productForm.setProductField(f);
            }
        }
    }, []);
}

export const useResizeContainer = (ref) => {
    const getContainerSize = () => {
        if(ref) {
            return {containerWidth: ref?.current?.clientWidth, containerHeight: ref?.current?.clientHeight};
        }
    }

    const [containerSize, setContainerSize] = useState(getContainerSize());

    useEffect(() => {
        setContainerSize(getContainerSize());
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setContainerSize(getContainerSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return containerSize;
}