require('dotenv').config();

export const fetchMarketTestFieldsAndResults = async (marketTestId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'marketTests/' + marketTestId + '/fields/results',
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}