import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useOvermind } from 'src/overmind';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { roundTo } from 'round-to';

import '../reservationForm.css';
import Button from 'src/components/shared/button';
import UnitCard from '../unitCard';
import CheckoutForm from '../checkoutForm';
require('dotenv').config();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Payment = () => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation();

    const [clientSecret, setClientSecret] = useState('');

    const [expirationDate, setExpirationDate] = useState(null);

    useEffect(() => {
        let tempDate = new Date();
        tempDate.setDate(tempDate.getDate() + state.currentVSO.reservation_time);

        setExpirationDate(tempDate.toLocaleDateString('en-CA', {year: 'numeric', month: 'long', day: '2-digit'}));


        // Create PaymentIntent as soon as the page loads
        fetch(
            process.env.REACT_APP_SERVER_URL + 'reservations/create-payment-intent/', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                vsoId: state.currentVSO.vsoId,
                unitNumber: state.currentVSO.reservation.currentUnit.unit_number,
                unitId: state.currentVSO.reservation.unitId,
                fullName: `${state.currentVSO.currentUser.firstName} ${state.currentVSO.currentUser.lastName}`,
                email: state.currentVSO.currentUser.email,
                phone: state.currentVSO.currentUser.phone
            }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };
    
    const getPrice = () => {
        let depositPrice = state.currentVSO.deposit_amount;
        if(depositPrice === '1stMonth') {
            depositPrice = state.currentVSO.reservation.currentUnit?.price;
        }
        depositPrice = roundTo(depositPrice * 1.15, 2);

        return depositPrice;
    }

    const handleBackClick = () => {
        actions.currentVSO.decrementReservationStep();
    }

    return (
        <div className="reservationForm__dual-container">
            <div className="identityForm__column">
                <Button options={{type: "link"}} text={`< ${t('salesOffice.reservationForm.back')}`} onClick={handleBackClick}/>
                <div className="identityForm__container">
                    <div className="identityForm__title">{t('salesOffice.reservationForm.payment')}</div>
                        {clientSecret &&
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm vsoId={state.currentVSO.vsoId}/>
                        </Elements>
                        }
                </div>
                <div className="identityForm__container">
                    <div className="identityForm__title">{t('salesOffice.reservationForm.importantInformation')}</div>
                    <div className="identityForm__subtitle">{t('salesOffice.reservationForm.freeCancellation')} {expirationDate}</div>
                    <div className="identityForm__paragraph">Morbi a sem non ex auctor faucibus sed nec lectus. Mauris eget risus aliquam dui rutrum elementum. Suspendisse quis massa pretium, feugiat est eget, volutpat leo. Nam consectetur ligula eu nunc tincidunt, a convallis augue bibendum. </div>
                    <div className="identityForm__subtitle">{t('salesOffice.reservationForm.reservationDetails')}</div>
                    <div className="identityForm__paragraph">Vestibulum rhoncus ac purus a consequat. Morbi ut tortor justo. Phasellus in sem lacus. Sed non justo non nibh aliquam eleifend non eu ex. Maecenas iaculis, eros eu facilisis luctus, tortor sem pellentesque ante, id molestie nisl est eget enim.</div>
                    <div className="identityForm__small-text">{t('salesOffice.reservationForm.byClicking')}</div>
                </div>
            </div>
            <UnitCard plan={state.currentVSO.reservation.currentUnit?.plan}
                unitNumber={state.currentVSO.reservation.currentUnit?.unit_number}
                area={state.currentVSO.reservation.currentUnit?.sqft}
                bedroom={state.currentVSO.reservation.currentUnit?.bedroom}
                bathroom={state.currentVSO.reservation.currentUnit?.bathroom}
                projectType={state.currentVSO.fields.project_type}
                projectName={state.currentVSO.fields.project_name}
                projectAddress={state.currentVSO.fields.project_address}
                price={getPrice()}
                reservationTime={state.currentVSO.reservation_time}
            />
        </div>
    );
}

export default Payment;