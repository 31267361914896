export const batchUploadFloorPlanText = [
    {
        title: "1. Separate each floor plan",
        text: "Make sure to separate each unit plan file into individual image files. Supported format: JPG, PNG."
    },
    {
        title: "2. Name each file after the floor",
        text: "Name every image files after the floor number. Example: The PNG file for the first floor must be named floor1"
    },
    {
        title: "3. Upload all the floor plans",
        text: "Select all the floor plans then drag them into the drop area on the right."
    },
    {
        title: "4. Press save when complete",
        text: "Press the Save button to upload your plans. You will be able to make correction as needed by removing or reuploading a unit's plan through its plan column."
    }
]