import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ScrollScene } from 'scrollscene';
import { useOvermind } from '../../overmind';
import { useParams, useLocation, useHistory } from "react-router-dom";
import './listing.css';

import logoInfinite from '../../assets/images/logo_infinite.svg';
import bedIcon from '../../assets/images/icons/bed.png';
import smoothLogo from '../../assets/images/listing/smooth_logo.svg';

import parkingIcon from '../../assets/images/listing/amenities/parking.png';
import elevatorIcon from '../../assets/images/listing/amenities/elevator.png';
import storageIcon from '../../assets/images/listing/amenities/storage.png';
import airconditionIcon from '../../assets/images/listing/amenities/aircondition.svg';
import electricstationIcon from '../../assets/images/listing/amenities/electricstation.svg';
import gymIcon from '../../assets/images/listing/amenities/gym.svg';
import poolIcon from '../../assets/images/listing/amenities/pool.svg';
import rooftopIcon from '../../assets/images/listing/amenities/rooftopterrace.svg';
import walkinclosetIcon from '../../assets/images/listing/amenities/walkincloset.svg';
import kitchenislandIcon from '../../assets/images/listing/amenities/kitchenisland.svg';
import balconyIcon from '../../assets/images/listing/amenities/balcony.svg';
import wifiIcon from '../../assets/images/listing/amenities/wifi.svg';


import BudgetRange from './budgetRange';
import AmenitiesList from './amenitiesList';

import { 
    GoogleMap,
    useLoadScript,
    Marker,
    OverlayView
} from '@react-google-maps/api';
require('dotenv').config();

const Listing = ({mtid}) => {
    const { state, actions, effects } = useOvermind();
    const params = useParams();
    const ipstackApiKey = process.env.REACT_APP_IPSTACK_API_KEY;
    const location = useLocation();
    const history = useHistory();
    const libraries = ['places']
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
        libraries,
    });

    const mapRef = useRef(null);
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const imageRef = useRef(null);
    const imageContainerRef = useRef(null);
    const heroRef = useRef(null);
    const infoSectionRef = useRef(null);
    const formPanelRef = useRef(null);

    const getUnitType = () => {
        if(state.listing.marketTest.fields.num_studios > 0){
            return "studio";
        }else if(state.listing.marketTest.fields.num_1_bdr > 0){
            return 1;
        }else if(state.listing.marketTest.fields.num_2_bdr > 0){
            return 2;
        }else if(state.listing.marketTest.fields.num_3_bdr > 0){
            return 3;
        }else if(state.listing.marketTest.fields.num_4_bdr > 0){
            return 4;
        }else if(state.listing.marketTest.fields.num_loft > 0){
            return "loft";
        }else if(state.listing.marketTest.fields.num_penthouse > 0){
            return "penthouse";
        }else if(state.listing.marketTest.fields.num_townhouse > 0){
            return "townhouse";
        }
    }

    const getBudget = () => {
        if(state.listing.marketTest.fields.num_studios > 0){
            return [Number(state.listing.marketTest.fields.min_price_studios), Number(state.listing.marketTest.fields.max_price_studios)];
        } else if(state.listing.marketTest.fields.num_1_bdr > 0){
            return [Number(state.listing.marketTest.fields.min_price_1_bdr), Number(state.listing.marketTest.fields.max_price_1_bdr)];
        } else if(state.listing.marketTest.fields.num_2_bdr > 0){
            return [Number(state.listing.marketTest.fields.min_price_2_bdr), Number(state.listing.marketTest.fields.max_price_2_bdr)];
        } else if(state.listing.marketTest.fields.num_3_bdr > 0){
            return [Number(state.listing.marketTest.fields.min_price_3_bdr), Number(state.listing.marketTest.fields.max_price_3_bdr)];
        } else if(state.listing.marketTest.fields.num_4_bdr > 0){
            return [Number(state.listing.marketTest.fields.min_price_4_bdr), Number(state.listing.marketTest.fields.max_price_4_bdr)];
        } else if(state.listing.marketTest.fields.num_loft > 0){
            return [Number(state.listing.marketTest.fields.min_price_loft), Number(state.listing.marketTest.fields.max_price_loft)];
        } else if(state.listing.marketTest.fields.num_penthouse > 0){
            return [Number(state.listing.marketTest.fields.min_price_penthouse), Number(state.listing.marketTest.fields.max_price_penthouse)];
        } else if(state.listing.marketTest.fields.num_townhouse > 0){
            return [Number(state.listing.marketTest.fields.min_price_townhouse), Number(state.listing.marketTest.fields.max_price_townhouse)];
        } else {
            return [100, 1000];
        }
    }

    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        result: {
            bedrooms: 1,
            parkingSpaces: '0',
            occupation: '0to6',
            budget: [400, 600],
            location: {
                latitude: 45.5438845,
                longitude: -73.6883796
            }
        },
    });
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        result: {
            bedrooms: '',
            parkingSpaces: '',
            occupation: '',
            budget: '',
        }
    });
    const [showThankYou, setShowThankYou] = useState(false);
    const [budgetLimit, setBudgetLimit] = useState({min: 0, max: 10000000});


    const setError = (field, error) => {
        setErrors({...errors, [field] : error});
    }

    const clearErrors = () => {
        setErrors({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            result: {
                bedrooms: '',
                parkingSpaces: '',
                occupation: '',
                budget: '',
            }
        });
    };

    useEffect(() => {
        actions.currentUser.loadCurrentUser().then(() => {
            let mtId;
            if(mtid) {
                mtId = mtid;
            } else {
                if(params.id) {
                    mtId = params.id;
                } else {
                    history.push("/");
                    return;
                }
            }
            actions.listing.loadMarketTestFields(mtId).then(() => {
                if(state.listing.marketTest.status !== "active" && state.listing.marketTest.status !== "completed"){
                    if(state.currentUser.type !== "auditor" && state.currentUser.type !== "admin"){
                        if(state.listing.marketTest.userId != state.currentUser.userId){
                            history.push("/");
                            return;
                        }
                    }
                } 
                const unitTypes = getUnitType();
                const budgetPrice = getBudget();
                setInputs({...inputs, result: {...inputs.result, bedrooms: unitTypes, budget: budgetPrice}});
                setBudgetLimit({min: budgetPrice[0], max: budgetPrice[1]});
            });
        });

        return () => {
            actions.listing.clearListing();
        }
    }, []);

    useEffect(() => {
        if(state.listing.isLoaded && heroRef.current !== null) {
            const { current: heroElem } = heroRef; 
            const { current: imageContainerElem } = imageContainerRef; 
            const { current: imageElem } = imageRef; 
            const { current: formPanelElem } = formPanelRef;
            const triggerElem = imageContainerElem;

            const scrollSceneForm = new ScrollScene({
                triggerElement: triggerElem,
                toggle: {
                    element: formPanelElem,
                    className: 'listing__form-container--closed',
                    reverse: true
                },
                triggerHook: 0.7,
                duration: '100%'
            });

            const scrollSceneImage = new ScrollScene({
                triggerElement: heroElem,
                triggerHook: 0,
                duration: '300%'
            });

            scrollSceneImage.Scene.on('progress', () => {
                imageElem.style.transform = `scale(${Math.max(1.3 - (scrollSceneImage.Scene.progress() * 2/3), 1)}) translateY(${(scrollSceneImage.Scene.progress() - 0.3) * 400}px)`;
            });

            // destroy on unmount
            return () => {
                scrollSceneForm.destroy()
                scrollSceneImage.destroy()
            }
        }
    })

    useEffect(() => {

        switch (inputs.result.bedrooms) {
            case "studio":
                setInputs({
                    ...inputs,
                    result: {...inputs.result, budget: [Number(state.listing.marketTest.fields.min_price_studios), Number(state.listing.marketTest.fields.max_price_studios)]}
                })
                setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_studios), max: Number(state.listing.marketTest.fields.max_price_studios)});
                break;
            case "1":
                setInputs({
                    ...inputs,
                    result: {...inputs.result, budget: [Number(state.listing.marketTest.fields.min_price_1_bdr), Number(state.listing.marketTest.fields.max_price_1_bdr)]}
                })
                setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_1_bdr), max: Number(state.listing.marketTest.fields.max_price_1_bdr)});
                break;
            case "2":
                setInputs({
                    ...inputs,
                    result: {...inputs.result, budget: [Number(state.listing.marketTest.fields.min_price_2_bdr), Number(state.listing.marketTest.fields.max_price_2_bdr)]}
                });
                setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_2_bdr), max: Number(state.listing.marketTest.fields.max_price_2_bdr)});
                break;
            case "3":
                setInputs({
                    ...inputs,
                    result: {...inputs.result, budget: [Number(state.listing.marketTest.fields.min_price_3_bdr), Number(state.listing.marketTest.fields.max_price_3_bdr)]}
                })
                setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_3_bdr), max: Number(state.listing.marketTest.fields.max_price_3_bdr)});
                break;
            case "4":
                setInputs({
                    ...inputs,
                    result: {...inputs.result, budget: [Number(state.listing.marketTest.fields.min_price_4_bdr), Number(state.listing.marketTest.fields.max_price_4_bdr)]}
                })
                setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_4_bdr), max: Number(state.listing.marketTest.fields.max_price_4_bdr)});
                break;
            case "loft":
                setInputs({
                    ...inputs,
                    result: {...inputs.result, budget: [Number(state.listing.marketTest.fields.min_price_loft), Number(state.listing.marketTest.fields.max_price_loft)]}
                })
                setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_loft), max: Number(state.listing.marketTest.fields.max_price_loft)});
                break;  
            case "penthouse":
                setInputs({
                    ...inputs,
                    result: {...inputs.result, budget: [Number(state.listing.marketTest.fields.min_price_penthouse), Number(state.listing.marketTest.fields.max_price_penthouse)]}
                })
                setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_penthouse), max: Number(state.listing.marketTest.fields.max_price_penthouse)});
                break;
            case "townhouse":
                setInputs({
                    ...inputs,
                    result: {...inputs.result, budget: [Number(state.listing.marketTest.fields.min_price_townhouse), Number(state.listing.marketTest.fields.max_price_townhouse)]}
                })
                setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_townhouse), max: Number(state.listing.marketTest.fields.max_price_townhouse)});
                break;            
            default:
                break;
        }
    }, [inputs.result.bedrooms])


        /*useEffect(() => {

            switch (inputs.result.bedrooms) {
                case "1":
                    setInputs({
                        ...inputs,
                        result: {...inputs.result, budget: [Math.max(Number(state.listing.marketTest.fields.min_price_1_bdr), Math.min(inputs.result.budget[0], inputs.result.budget[1])), 
                                                            Math.min(Number(state.listing.marketTest.fields.max_price_1_bdr), Math.max(inputs.result.budget[1], inputs.result.budget[0]))]}
                    })
                    setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_1_bdr), max: Number(state.listing.marketTest.fields.max_price_1_bdr)});
                    break;
                case "2":
                    setInputs({
                        ...inputs,
                        result: {...inputs.result, budget: [Math.max(Number(state.listing.marketTest.fields.min_price_2_bdr), Math.min(inputs.result.budget[0], inputs.result.budget[1])), 
                                                            Math.min(Number(state.listing.marketTest.fields.max_price_2_bdr), Math.max(inputs.result.budget[1], inputs.result.budget[0]))]}
                    });
                    setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_2_bdr), max: Number(state.listing.marketTest.fields.max_price_2_bdr)});
                    break;
                case "3":
                    setInputs({
                        ...inputs,
                        result: {...inputs.result, budget: [Math.max(Number(state.listing.marketTest.fields.min_price_3_bdr), Math.min(inputs.result.budget[0], inputs.result.budget[1])), 
                                                            Math.min(Number(state.listing.marketTest.fields.max_price_3_bdr), Math.max(inputs.result.budget[1], inputs.result.budget[0]))]}
                    })
                    setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_3_bdr), max: Number(state.listing.marketTest.fields.max_price_3_bdr)});
                    break;
                case "4":
                    setInputs({
                        ...inputs,
                        result: {...inputs.result, budget: [Math.max(Number(state.listing.marketTest.fields.min_price_4_bdr), Math.min(inputs.result.budget[0], inputs.result.budget[1])), 
                                                            Math.min(Number(state.listing.marketTest.fields.max_price_4_bdr), Math.max(inputs.result.budget[1], inputs.result.budget[0]))]}
                    })
                    setBudgetLimit({min: Number(state.listing.marketTest.fields.min_price_4_bdr), max: Number(state.listing.marketTest.fields.max_price_4_bdr)});
                    break;
                default:
                    break;
                }
            }, [inputs.result.bedrooms])
            */


    const onChange = (e) => {
        setInputs({...inputs, [e.target.name]
        : e.target.value})
    }

    const onResultInputChange = (e) => {
        setInputs({
            ...inputs, 
            result : {...inputs.result, [e.target.name] : e.target.value }
        });
    }
    
    const setBudget = (budgetArray) => {
        setInputs({
            ...inputs, 
            result : {...inputs.result, budget : budgetArray }
        });
    }

    // Handle normal signup
    const onSubmitForm = async(e) => {
        e.preventDefault();

        try {
            clearErrors();
            const body = {
                firstName: inputs.firstName,
                lastName: inputs.lastName,
                email: inputs.email,
                phoneNumber: inputs.phoneNumber,
                result: inputs.result,
                marketTestId: params.id
            };

            // Get IP 
            const ipFetch = await fetch(`https://api.ipstack.com/check?access_key=${ipstackApiKey}&fields=latitude,longitude`)
                .then(response => response.json())
                .then((data) => {
                    body.result.location.latitude = data.latitude;
                    body.result.location.longitude = data.longitude;
                });
    
            // API CALL
            const response = await actions.listing.sendResult(body);

            // Check errors and display them
            if(response.hasOwnProperty('errors')){
                setShowThankYou(false);
                for( let i = 0; i < response.errors.length; i++){
                    if( response.errors[i].param === 'email') {
                        setError('email', response.errors[i].msg);
                    } else if ( response.errors[i].param === 'firstName') {
                        setError('firstName', response.errors[i].msg);
                    } else if ( response.errors[i].param === 'lastName') {
                        setError('lastName', response.errors[i].msg);
                    } else {
                        console.log("Server Error");
                    }
                }
            } else {
                setShowThankYou(true);
            }   
        } catch (err) {
            console.error(err.message);
        }
    };

    return (
        <>
        {state.listing.isLoaded ?
        <div className="listing-container">
            <>
            <div className="listing__page-container">
                <div ref={heroRef} className="listing__hero-section">
                    {/* {((state.listing.marketTest.fields.project_logo !== "") && (state.listing.marketTest.fields.has_logo_in_listing)) ?  */}
                    {state.listing.marketTest.fields.project_logo !== "" ? 
                    <img className="listing__hero-project-title listing__jumbotron-logo" src={state.listing.marketTest.fields.project_logo} alt="Developer logo"/> 
                    :
                    <div className="listing__hero-project-title listing__jumbotron-name">{state.listing.marketTest.fields.project_name}</div> 
                    }
                    <div className="listing__jumbotron-container listing__left-col">
                        <div className="listing__jumbotron">
                            <div className="listing__jumbotron-title">
                                {state.listing.marketTest.fields.project_desc_en?.vp && 
                                <>{state.listing.marketTest.fields.project_desc_en.vp}</>
                                }
                            </div>
                            <div className="listing__jumbotron-date">New {state.listing.marketTest.fields.unit_type} for {state.listing.marketTest.fields.project_type == 'sell' ? 'sale' : 'rent'} in Montreal</div>
                            <div className="listing__jumbotron-status">
                                {state.listing.marketTest.fields.project_status}
                                <div></div>
                                Coming {state.listing.marketTest.fields.est_delivery_date}</div>
                            <div className="listing__jumbotron-units">
                                <div className="listing__image-container">
                                    <img className="listing__icon-bed" src={bedIcon}/>
                                </div>
                                <div className="listing__unit-types">
                                    {state.listing.marketTest.fields.num_studios > 0 ? <div>{'Studio'}</div> : ''}
                                    {state.listing.marketTest.fields.num_1_bdr > 0 ? <div>{'1 Bedroom'}</div> : ''}
                                    {state.listing.marketTest.fields.num_2_bdr > 0 ? <div>{'2 Bedroom'}</div> : ''}
                                    {state.listing.marketTest.fields.num_3_bdr > 0 ? <div>{'3 Bedroom'}</div> : ''}
                                    {state.listing.marketTest.fields.num_4_bdr > 0 ? <div>{'4 Bedroom'}</div> : ''}
                                    {state.listing.marketTest.fields.num_loft > 0 ? <div>{'Loft'}</div> : ''}
                                    {state.listing.marketTest.fields.num_penthouse > 0 ? <div>{'Penthouse'}</div> : ''}
                                    {state.listing.marketTest.fields.num_townhouse > 0 ? <div>{'Townhouse'}</div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={imageContainerRef} className="listing__big-image-container">
                    <div ref={imageRef} className="listing__big-image" style={{backgroundImage: `url(${state.listing.marketTest.fields.images}`}}></div>
                </div>
                <div ref={infoSectionRef} className="listing__info-section">
                    <div className="listing__left-col">
                        {state.listing.marketTest.fields.project_desc_en?.general && state.listing.marketTest.fields.project_desc_en?.general !== '' &&                       
                        <div className="listing__info-title-box listing__info-title-box--top">
                            <div className="listing__info-title">
                               {state.listing.marketTest.fields.project_desc_en?.general}
                            </div>
                        </div> 
                        }
                        <div className="listing__lines-container">
                            <div className="listing__lines-item">
                                <div className="listing__lines-item-name">Number of units available:</div>
                                <div className="listing__lines-item-value">{state.listing.marketTest.fields.num_units}</div>
                            </div>
                            <div className="listing__lines-item">
                                <div className="listing__lines-item-name">Number of floors:</div>
                                <div className="listing__lines-item-value">{state.listing.marketTest.fields.num_floors}</div>
                            </div>
                            <div className="listing__lines-item">
                                <div className="listing__lines-item-name">Type of units:</div>
                                <div className="listing__lines-item-value listing__lines-item-value--units">
                                {state.listing.marketTest.fields.num_studios > 0 ? <div>{'Studio'}</div> : ''}
                                    {state.listing.marketTest.fields.num_1_bdr > 0 ? <div>{'1 Bedroom'}</div> : ''}
                                    {state.listing.marketTest.fields.num_2_bdr > 0 ? <div>{'2 Bedroom'}</div> : ''}
                                    {state.listing.marketTest.fields.num_3_bdr > 0 ? <div>{'3 Bedroom'}</div> : ''}
                                    {state.listing.marketTest.fields.num_4_bdr > 0 ? <div>{'4 Bedroom'}</div> : ''}
                                    {state.listing.marketTest.fields.num_loft > 0 ? <div>{'Loft'}</div> : ''}
                                    {state.listing.marketTest.fields.num_penthouse > 0 ? <div>{'Penthouse'}</div> : ''}
                                    {state.listing.marketTest.fields.num_townhouse > 0 ? <div>{'Townhouse'}</div> : ''}
                                </div>
                            </div>
                            {state.listing.marketTest.fields.minArea != 0 && state.listing.marketTest.fields.maxArea != 0 &&
                            <div className="listing__lines-item">
                                <div className="listing__lines-item-name">Minimum - Maximum area:</div>
                                <div className="listing__lines-item-value">{state.listing.marketTest.fields.minArea} sqft - {state.listing.marketTest.fields.maxArea} sqft</div>
                            </div>
                            }
                        </div>
                        {state.listing.marketTest.fields.project_desc_en?.product !== '' && state.listing.marketTest.fields.project_desc_en?.product !== null ?
                        <div className="listing__description-title">
                            {state.listing.marketTest.fields.project_desc_en?.product}
                        </div>
                        : 
                        <div className="listing__info-title-box listing__info-title-box--inclusions">
                            <div className="listing__info-title">
                                Inclusions
                            </div>
                        </div>
                        }
                        {state.listing.marketTest.fields.primaryAmenities?.length > 0 &&
                        <div className="listing__amenities-icons-container">
                            {state.listing.marketTest.fields.primaryAmenities?.map((a, index) => {
                                a = JSON.parse(a);
                                return(
                                    <div key={`primaryamenities-${index}`} className="listing__primary-amenity">
                                        <img src={a.en === 'Parking' ? parkingIcon : 
                                                  a.en === 'Storage' ? storageIcon : 
                                                  a.en === 'Elevator' ? elevatorIcon : 
                                                  a.en === 'Air Conditioning' ? airconditionIcon : 
                                                  a.en === 'Kitchen Island' ? kitchenislandIcon :
                                                  a.en === 'Walk-in Closet' ? walkinclosetIcon : 
                                                  a.en === 'Gym' ? gymIcon : 
                                                  a.en === 'Roof Terrace' ? rooftopIcon : 
                                                  a.en === 'Electric Car Charging Stations' ? electricstationIcon : 
                                                  a.en === 'Swimming Pool' ? poolIcon : 
                                                  (a.en === 'Indoor Parking' || a.en === 'Outdoor Parking') ? parkingIcon : 
                                                  a.en === 'Internet/WIFI' ? wifiIcon :
                                                  (a.en === 'Indoor Pool' || a.en === 'Outdoor Pool') ? poolIcon :
                                                  a.en === 'Balcony' ? balconyIcon : ''} alt="amenities icons"/>
                                        <div>{a.en}</div>
                                    </div>
                                ) 
                            })}   
                        </div>
                        }
                        <AmenitiesList label={'Project Amenities'} array={
                            state.listing.marketTest.fields.project_amenities?.array
                            .concat(state.listing.marketTest.fields.common_spaces?.array)} 
                        />
                        {/* <AmenitiesList label={'Exterior Features'} array={state.listing.marketTest.fields.exterior_features?.array} options={{displayLabel: false}}/> */}
                        {/* <AmenitiesList label={'Roof'} array={state.listing.marketTest.fields.roof?.array}options={{displayLabel: false}} options={{displayLabel: false}}/> */}
                        {/* <AmenitiesList label={'Energy'} array={state.listing.marketTest.fields.energy?.array} options={{displayLabel: false}}/> */}
                        <AmenitiesList label={'Units Amenities'} array={
                            state.listing.marketTest.fields.units_amenities?.array
                            .concat(state.listing.marketTest.fields.units_general?.array)
                            .concat(state.listing.marketTest.fields.units_utilities?.array)
                        }
                        />
                        {/* <AmenitiesList label={'Flooring'} array={state.listing.marketTest.fields.units_flooring?.array} /> */}
                        <AmenitiesList label={'Kitchen'} array={state.listing.marketTest.fields.units_kitchen?.array} />
                        <AmenitiesList label={'Bathroom'} array={state.listing.marketTest.fields.units_bathroom?.array} />
 
                        <div className="listing__info-title-box listing__info-title-box--location">
                            <div className="listing__info-title">
                                {state.listing.marketTest.fields.project_desc_en != undefined ? state.listing.marketTest.fields.project_desc_en.location : ''}
                            </div>
                        </div>
                        <div className="listing__map-container" id="map">
                        {loadError ? <>{JSON.stringify(loadError)}</> : isLoaded ? 
                        <>
                        <GoogleMap 
                            mapContainerStyle={{width: '100%', height: '600px', border: '1px solid #dddddd', margin: '32px auto'}} 
                            options={{
                                center: {
                                    lat: state.listing.marketTest.fields.project_address?.coords?.lat, lng: state.listing.marketTest.fields.project_address?.coords?.lng
                                },
                                zoom: 12,
                            }}
                            onLoad={onMapLoad}
                        >
                            <OverlayView
                            position={{lat: state.listing.marketTest.fields.project_address?.coords?.lat, lng: state.listing.marketTest.fields.project_address?.coords?.lng}}
                            mapPaneName={OverlayView.OVERLAY_LAYER}
                            >
                                <div className="listing__map-address-container">
                                    {state.listing.marketTest.fields.project_address.address_components[0].long_name} {state.listing.marketTest.fields.project_address.address_components[1].long_name},
                                    <br/>
                                    {state.listing.marketTest.fields.project_address.address_components[3].long_name}, {state.listing.marketTest.fields.project_address.address_components[5].short_name} {state.listing.marketTest.fields.project_address.address_components[7].long_name}
                                </div>
                            </OverlayView>
                            <Marker position={{lat: state.listing.marketTest.fields.project_address.coords.lat, lng: state.listing.marketTest.fields.project_address.coords.lng}} />
                        </GoogleMap>
                        </> : <></>}
                        </div>
                        <div className="listing__description-container">
                            <div className="listing__description-title">Project description</div>
                            <p className="listing__description">{state.listing.marketTest.fields.project_desc_en != undefined ? state.listing.marketTest.fields.project_desc_en.p : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="listing__partners-container listing__left-col">
                    {/* <div className="listing__partners-title">An exclusive project by:</div>
                    <div className="listing__partners-grid">

                    </div> */}
                    <div className="listing__smalltext-container">
                        Tous les prix, les plans et autres contenus présentés sur ce site web sont sujets à changement sans préavis.
                        Les images sont des représentations d'artiste et sont à titre indicatif seulement.
                    </div>
                </div>
            </div>
            <div ref={formPanelRef} className="listing__form-container" style={{display: location.pathname.includes('start-market-test') ? 'none' : 'flex'}}>
                { showThankYou ? 
                <div className="listing__thank-you-container">
                    Thank you {inputs.firstName} {inputs.lastName} for your submission.
                </div>
                : 
                <>
                <div className="listing__form-header">
                    <img className="listing__form-smooth-logo" src={logoInfinite}/>
                    
                    <h2 className="listing__form-title">PRIORITY REGISTRATION</h2>
                    <div className="listing__form-tagline">Register to receive exclusive information about the project<br/> and be the first to know.
                        {/* <div>{state.listing.marketTest.fields.project_address != undefined ? state.listing.marketTest.fields.project_address : ''}</div> */}
                    </div>
                </div>
                <form className="listing__form" onSubmit={onSubmitForm}>
                    <div className="form__input-row">
                        <div className="form__input-group">
                            <label htmlFor="input__firstName">First name</label>
                            <input id="input__firstName" type="text" name="firstName" value={inputs.firstName} onChange={e => onChange(e)} placeholder="John" maxLength="64" className={ errors.firstName ? "error" : "" }/>
                            <p className="errorMsg">{errors.firstName}</p>
                        </div>
                        <div className="form__input-group">
                            <label htmlFor="input__lastName">Last name</label>
                            <input id="input__lastName" type="text" name="lastName" value={inputs.lastName} onChange={e => onChange(e)} placeholder="Smith" maxLength="64" className={ errors.lastName ? "error" : "" }/>
                            <p className="errorMsg">{errors.lastName}</p>
                        </div>
                    </div>
                    <div className="form__input-row">
                        <div className="form__input-group">
                            <label htmlFor="input__email">Email</label>
                            <input id="input__email" type="email" name="email" value={inputs.email} onChange={e => onChange(e)} placeholder="name@example.com" className={ errors.email ? "error" : "" }/>
                            <p className="errorMsg">{errors.email}</p>
                        </div>
                        <div className="form__input-group">
                            <label htmlFor="input__phone">Phone number</label>
                            <input id="input__phone" type="text" name="phoneNumber" value={inputs.phoneNumber} onChange={e => onChange(e)} placeholder="1231231234" className={ errors.phoneNumber ? "error" : "" }/>
                            <p className="errorMsg">{errors.phoneNumber}</p>
                        </div>
                    </div>
                    <div className="form__input-row">
                        <div className="form__input-group">
                            <label htmlFor="input__result--bedrooms">Number of bedrooms</label>
                            <select id="input__result--bedrooms" value={inputs.result.bedrooms} name="bedrooms" onChange={e => onResultInputChange(e)} className={ errors.email ? "error" : "" }>
                                {state.listing.marketTest.fields.num_studios > 0 ? <option value="studio">Studio</option> : ""}
                                {state.listing.marketTest.fields.num_1_bdr > 0 ? <option value="1">1 Bedroom</option> : ""}
                                {state.listing.marketTest.fields.num_2_bdr > 0 ? <option value="2">2 Bedroom</option> : ""}
                                {state.listing.marketTest.fields.num_3_bdr > 0 ? <option value="3">3 Bedroom</option> : ""}
                                {state.listing.marketTest.fields.num_4_bdr > 0 ? <option value="4">4 Bedroom</option> : ""}
                                {state.listing.marketTest.fields.num_loft > 0 ? <option value="loft">Loft</option> : ""}
                                {state.listing.marketTest.fields.num_penthouse > 0 ? <option value="penthouse">Penthouse</option> : ""}
                                {state.listing.marketTest.fields.num_townhouse > 0 ? <option value="townhouse">Townhouse</option> : ""}
                            </select>
                            <p className="errorMsg">{errors.result.bedrooms}</p>
                        </div>
                        <div className="form__input-group">
                            <label htmlFor="input__result--parking">Parking spaces</label>
                            <select id="input__result--parking" value={inputs.result.parkingSpaces} name="parkingSpaces" onChange={e => onResultInputChange(e)} className={ errors.email ? "error" : "" }>
                                <option value="0">None</option>
                                <option value="1">1 space</option>
                                <option value="2">2 spaces</option>
                                <option value="3">3 spaces</option>
                                <option value="4">4 spaces</option>
                            </select>
                            <p className="errorMsg">{errors.result.parkingSpaces}</p>
                        </div>
                    </div>
                    <div className="form__input-group">
                        <label htmlFor="input__result--occupation">Occupation</label>
                        <select id="input__result--occupation" value={inputs.result.occupation} name="occupation" onChange={e => onResultInputChange(e)} className={ errors.email ? "error" : "" }>
                            <option value="0to6">0-6 months</option>
                            <option value="6to12">6-12 months</option>
                            <option value="12to24">12-24 months</option>
                            <option value="24+">More than 24 months</option>
                        </select>
                        <p className="errorMsg">{errors.result.occupation}</p>
                    </div>
                    <div className="form__input-group">
                        <label htmlFor="input__result--budget">Your budget</label>
                          <BudgetRange id="input__result--budget" name="budget" budget={inputs.result.budget} setBudget={setBudget} limits={budgetLimit}/>
                        <p className="errorMsg">{errors.result.budget}</p>
                    </div>
                    <button className="btn-primary">Register</button>
                </form>
                </>
                }
            </div>
            </>
            <div className="listing__footer">
                <div className="listing__left-col">
                    <img className="listing__footer-logo" src={smoothLogo}/>
                    <p>Smooth, the Smooth logo, and other various trademarks, logos, designs, and slogans are the registered and unregistered trademarks of Go Smooth, Inc. dba Smooth in Canada. and/or other countries.</p>
                    <p>Corporate Responsibility, Privacy & Legal Notices: Smooth is a licensed real estate broker, licensed to do business as Smooth RE in Delaware, New Jersey, Pennsylvania and Tennessee, and Compass Real Estate in Washington, DC. California License # 01991628, 1527235, 1527365, 1356742, 1443761, 1997075, 1935359, 1961027, 1842987, 1869607, 1866771, 1527205, 1079009, 1272467. No guarantee, warranty or representation of any kind is made regarding the completeness or accuracy of descriptions or measurements (including square footage measurements and property condition), such should be independently verified, and Compass expressly disclaims any liability in connection therewith. No financial or legal advice provided. Equal Housing Opportunity. © Smooth 2021. 212-913-9058.</p>
                    <a className="listing__footer-smooth-link" href="gosmooth.ca">gosmooth.ca</a>
                </div>
            </div>
        </div>
        : <></>}
        </>
    );
}

export default Listing;