import React, { useEffect, useState, useRef, useCallback} from 'react';
import { 
    GoogleMap,
    useLoadScript,
    Marker,
    OverlayView,
    InfoWindow,
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import "./areaMap.scss";
import { useOvermind } from 'src/overmind';
import SvgIcons from 'src/components/shared/svgIcons';
import Button from 'src/components/shared/button';
import bluePin from 'src/assets/images/bluePin.png';
import redPin from 'src/assets/images/redPin.png';

const AreaMap = () => {
    const { state, actions } = useOvermind();
    const { t } = useTranslation();

    const [currentFilter, setCurrentFilter] = useState('bar');
    const [currentFilterPlaces, setCurrentFilterPlaces] = useState([]);
    const [markerId, setMarkerId] = useState(0);
    const [prevMarkerId, setPrevMarkerId] = useState(0);
    const [markerSelect, setMarkerSelect] = useState({});
    const libraries = ['places'];
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
        libraries,
    });
    
    const mapRef = useRef(null);
    const placesListRef = useRef(null);

    const onMapLoad = useCallback((map) => {
            mapRef.current = map
        
    }, []);
    
    useEffect(() => {
        actions.topbar.setCurrentPage('area');
        staggerPlacesMarkers(0, currentFilter);
        
        return () => {
            actions.topbar.setCurrentPage('');
        }
    }, [])

    useEffect(() => {
        if(mapRef.current?.panTo && markerSelect){
            mapRef.current?.panTo(markerSelect);
        }
    }, [markerSelect, markerId]);

    const onExitMarker = () => {
         setMarkerId(0);
    }

    const handleSelectMarker = (content, id) => {
        setPrevMarkerId(markerId);
        setMarkerSelect(content);
        setMarkerId(id);
    }

    const goBackToProject = () => {
        if(mapRef.current?.getZoom() !== 15){
            mapRef.current?.setZoom(15);
        }
        mapRef.current?.setCenter(state.currentVSO.fields.project_address?.coords);
        setMarkerSelect(state.currentVSO.fields.project_address?.coords);
        setMarkerId(0);
    }

    const staggerPlacesMarkers = (index, type) => {
        if(index < state.currentVSO.fields.nearby_places[type].length) {
            setTimeout(() => {
                setCurrentFilterPlaces((prev) => [...prev, state.currentVSO.fields.nearby_places[type][index]]);
                staggerPlacesMarkers(index + 1, type);
            }, 1);
        } else {
            return;
        }
    }

    const handleChangeFilter = (type) => {
        setCurrentFilterPlaces([]);
        setCurrentFilter(type);
        setMarkerSelect(state.currentVSO?.fields.project_address?.coords);
        setMarkerId(0);
        placesListRef.current.scrollTo(0,0);
        staggerPlacesMarkers(0, type);
    }

    const mapOptions = {
        center: {
            lat: state.currentVSO.fields.project_address?.coords?.lat,
            lng: state.currentVSO.fields.project_address?.coords?.lng
        },
        zoom: 15,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
    }
   
    return(
        <>
            <div className="areaMap__container">
                {state.currentVSO.fields.nearby_places &&
                <div className="areaMap__pane">
                    <div className="areaMap__title-header">
                        <h4>{t('salesOffice.areaPage.title')}</h4>
                        <p>{t('salesOffice.areaPage.caption')}</p>
                    </div>
                    <div ref={placesListRef} className="areaMap__browsing-container">
                       <div className="areaMap__filters-list">
                            <div className={`areaMap__filter-item ${currentFilter === 'bar' ? '--active' : ''}`} onClick={() => handleChangeFilter('bar')}><SvgIcons type={'bars'} />{t('salesOffice.areaPage.bar')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'cafe' ? '--active' : ''}`} onClick={() => handleChangeFilter('cafe')}><SvgIcons type={'coffee'} />{t('salesOffice.areaPage.cafe')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'drugstore' ? '--active' : ''}`} onClick={() => handleChangeFilter('drugstore')}><SvgIcons type={'drugstore'}/>{t('salesOffice.areaPage.drugstore')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'entertainment' ? '--active' : ''}`} onClick={() => handleChangeFilter('entertainment')}><SvgIcons type={'theater'} />{t('salesOffice.areaPage.entertainment')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'grocery' ? '--active' : ''}`} onClick={() => handleChangeFilter('grocery')}><SvgIcons type={'grocery'} />{t('salesOffice.areaPage.grocery')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'hospital' ? '--active' : ''}`} onClick={() => handleChangeFilter('hospital')}><SvgIcons type={'hospital'} />{t('salesOffice.areaPage.hospital')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'park' ? '--active' : ''}`} onClick={() => handleChangeFilter('park')}><SvgIcons type={'park'} />{t('salesOffice.areaPage.park')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'restaurant' ? '--active' : ''}`} onClick={() => handleChangeFilter('restaurant')}><SvgIcons type={'resturant'} />{t('salesOffice.areaPage.restaurant')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'school' ? '--active' : ''}`}onClick={() => handleChangeFilter('school')}><SvgIcons type={'school'} />{t('salesOffice.areaPage.school')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'service' ? '--active' : ''}`} onClick={() => handleChangeFilter('service')}><SvgIcons type={'service'} />{t('salesOffice.areaPage.service')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'shopping' ? '--active' : ''}`} onClick={() => handleChangeFilter('shopping')}><SvgIcons type={'shopping'} />{t('salesOffice.areaPage.shopping')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'transport' ? '--active' : ''}`} onClick={() => handleChangeFilter('transport')}><SvgIcons type={'transport'} />{t('salesOffice.areaPage.transport')}</div>
                            <div className={`areaMap__filter-item ${currentFilter === 'wellbeing' ? '--active' : ''}`} onClick={() => handleChangeFilter('wellbeing')}><SvgIcons type={'well_being'} />{t('salesOffice.areaPage.wellbeing')}</div>
                       </div>
                       <div className="areaMap__places-list-container">
                            <h5>{t(`salesOffice.areaPage.${currentFilter}`)} {t('salesOffice.areaPage.locations')}</h5>
                            <div className="areaMap__places-list">
                                {currentFilter && state.currentVSO.fields.nearby_places[currentFilter]?.map((el) => {
                                    return(
                                        <div key={el.id} className={`areaMap__place-item ${markerId === el.id ? '--active' : ''}`} onClick={() => handleSelectMarker(el.coords, el.id)}>
                                            <div className="areaMap__place-item-content">
                                                <div className="areaMap__place-name">{el.name}</div>
                                                <div className="areaMap__place-address">{el.vicinity}</div>
                                            </div>
                                            <div className="areaMap__place-distance">{el.distance >= 1000 ? (Math.round((el.distance / 1000) * 10) / 10) + ' km' : el.distance + ' m'}</div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div> 
                </div>
                }
                <div className="areaMap__area-google-map">
                     {loadError ? <>{JSON.stringify(loadError)}</> : isLoaded ? 
                     <GoogleMap
                        options={mapOptions}
                        mapContainerStyle={{width: '100%', height: '100%'}}
                        onLoad={onMapLoad}
                    >
                        <div className="areaMap__center-button-container">
                            <Button type="secondary" prefix={<SvgIcons type={'centerMap'} />} text={t('salesOffice.areaPage.center')} onClick={goBackToProject} />
                        </div>
                        <OverlayView
                            position={{lat: state.currentVSO.fields.project_address?.coords?.lat, lng: state.currentVSO.fields.project_address?.coords?.lng}}
                            mapPaneName={OverlayView.OVERLAY_LAYER}
                        >
                            <div className="areaMap__project-details">
                                <SvgIcons type="cabana_star" />
                                <div>
                                    <div className="areaMap__project-details-name">
                                        {state.currentVSO.fields.project_name}
                                    </div>
                                    <div  className="areaMap__project-details-address">
                                        {state.currentVSO.fields.project_address?.address_components?.[0]?.long_name} {state.currentVSO.fields.project_address?.address_components?.[1]?.long_name}
                                    </div>
                                </div>
                            </div>
                        </OverlayView>
                        <Marker 
                            position={new window.google.maps.LatLng(state.currentVSO.fields.project_address?.coords?.lat, state.currentVSO.fields.project_address?.coords?.lng)} 
                            icon={bluePin}
                        />
                        {currentFilter && currentFilterPlaces.length > 0 && currentFilterPlaces.map((el) => {
                            if(el) {
                                return(
                                    <Marker 
                                        key={el.id} position={new window.google.maps.LatLng(el.coords?.lat, el.coords?.lng)} 
                                        onClick={() => handleSelectMarker(el.coords, el.id)} 
                                        animation={window.google.maps.Animation.DROP}
                                        icon={redPin}
                                    >
                                        {
                                            markerId === el.id &&
                                            <InfoWindow onCloseClick={onExitMarker}>
                                                <div className="areaMap__place-details">
                                                    <div>{el.name}</div>
                                                    <div>{el.vicinity}&nbsp;&nbsp;•&nbsp;&nbsp;{el.distance >= 1000 ? (Math.round((el.distance / 1000) * 10) / 10) + ' km' : el.distance + ' m'}</div>
                                                </div>
                                            </InfoWindow>
                                        }
                                    </Marker>
                                )
                            }
                        })}
                    </GoogleMap> : <></> }
                </div>
            </div>
        </>
    )
}

export default AreaMap;