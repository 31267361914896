import React, {forwardRef} from 'react';
import SvgIcons from 'src/components/shared/svgIcons';
import './image.scss';

const Image = forwardRef(({style, firstIndex, element, onRemove, ...props}, ref) => {
    return (
            <div className="image__drag-images-item" ref={ref} style={style} {...props}>
                {firstIndex === 0 &&
                    <div className="image__cover-image-icon">
                        <SvgIcons type="cover_image"/>
                    </div> 
                }
                    <div className="image__delete-image" onClick={() => onRemove(element)}>
                        <SvgIcons type="trash_can" />
                    </div>
            </div>
        );
})

export default Image;