import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { roundTo } from 'round-to';

import SvgIcons from "src/components/shared/svgIcons";
import Modal from "src/components/shared/modal";
import './unitCard.css';

const UnitCard = ({
    plan,
    unitNumber,
    area,
    bedroom,
    bathroom,
    projectType,
    projectName,
    projectAddress,
    price,
    reservationTime
}) => {
    const { t } = useTranslation();

    const [isPlanOpen, setIsPlanOpen] = useState(false);
    const [checkoutItems, setCheckoutItems] = useState([]);
    const [expirationDate, setExpirationDate] = useState(null);

    useEffect(() => {
        let tempItems = [roundTo(Number(price), 2)];
        if(projectType === 'sell') {
            tempItems.push(roundTo(Number(price * 0.14975), 2));
        }
        setCheckoutItems(tempItems);

        let tempDate = new Date();
        tempDate.setDate(tempDate.getDate() + reservationTime);

        setExpirationDate(tempDate.toLocaleDateString('en-CA', {year: 'numeric', month: 'long', day: '2-digit'}));
    }, []);
    
    return (
        <div className="identityForm__column">
            <div className="unitCard__container">
                <div className="unitCard__title-container">
                    <div className="unitCard__title-row">
                        <div className="unitCard__unit-number"><span>{t('salesOffice.reservationForm.unit')} {unitNumber}</span> — {projectName}</div>
                        <div className="unitCard__unit-price">CA$ {price}{projectType === 'rent' ? <>/<span>mo</span><div>{t('salesOffice.reservationForm.monthlyRent')}</div></> : ''}</div>
                    </div>
                    <div className="unitCard__project-address">
                        <SvgIcons type="location_pin" />
                        <div>
                            {projectAddress.address_components[0].short_name} {projectAddress.address_components[1].short_name},
                            <br />{projectAddress.address_components[3].short_name}, {projectAddress.address_components[5].short_name}
                        </div>
                    </div>
                </div>
                <div className="unitCard__plan" style={{backgroundImage: `url(${plan})`}}>
                    <div className="unitCard__expand-button" onClick={() => setIsPlanOpen(true)}>
                        <div>Expand</div>
                        <SvgIcons type="expand" />    
                    </div>
                </div> 
                <div className="unitCard__info-container">
                    <div className="unitCard__unit-info-item">
                        <div className="unitCard__unit-info-value">{bedroom || '-'}</div>
                        <div className="unitCard__unit-info-title">{t('salesOffice.reservationForm.bedroom')}</div>
                    </div>
                    <div className="unitCard__unit-info-item">
                        <div className="unitCard__unit-info-value">{bathroom || '-'}</div>
                        <div className="unitCard__unit-info-title">{t('salesOffice.reservationForm.bathroom')}</div>
                    </div>
                    <div className="unitCard__unit-info-item">
                        <div className="unitCard__unit-info-value">{area || '-'}</div>
                        <div className="unitCard__unit-info-title">{t('salesOffice.reservationForm.sqft')}</div>
                    </div>
                </div>
                <div className="unitCard__checkout-container">
                    <div className="unitCard__checkout-title">{t('salesOffice.reservationForm.priceDetails')}</div>
                    <div className="unitCard__checkout-list">
                        <div className="unitCard__checkout-item">
                            <div className="unitCard__checkout-item-name">{t('salesOffice.reservationForm.reservationDeposit')}</div>
                            <div className="unitCard__checkout-item-price">CA$ {price}</div>
                        </div>
                        {/* {projectType === 'sell' && */}
                        <div className="unitCard__checkout-item">
                            <div className="unitCard__checkout-item-name">{t('salesOffice.reservationForm.taxAndFee')}</div>
                            <div className="unitCard__checkout-item-price">CA$ {roundTo(0.14975 * price, 2)}</div>
                        </div>
                        {/* } */}
                        <div className="unitCard__checkout-item">
                            <div className="unitCard__checkout-item-name --total">{t('salesOffice.reservationForm.total')}</div>
                            <div className="unitCard__checkout-item-price --total">CA$ {checkoutItems.reduce((sum, item) => sum + item, 0)}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="unitCard__cancellation-panel">
                {t('salesOffice.reservationForm.freeCancellation')} {expirationDate}
            </div>
            <div className="unitCard__questions-panel">
                <SvgIcons type="question_bubble" />
                <div>
                    <div>{t('salesOffice.reservationForm.haveQuestions')}</div>
                    <div>{t('salesOffice.reservationForm.speakWith')}</div>
                </div>
            </div>
            <Modal isOpen={isPlanOpen} setIsOpen={setIsPlanOpen} options={{contentCenter: true}}>
                <img src={plan} className="unitRow__view-plan" alt="unit plan"/>
            </Modal>
        </div>
    );
}

export default UnitCard;