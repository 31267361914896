export const getCurrentMarketTest = async ({state, effects}, marketTestId) => {
    try {
        const marketTest = await effects.currentMarketTest.fetchMarketTestFieldsAndResults(marketTestId);
        state.currentMarketTest = marketTest;
        state.currentMarketTest.fields = marketTest.fields;
        for(let i=0; i<marketTest.results.length; i++){
            marketTest.results[i].animation = 0;
        }
        state.currentMarketTest.results = marketTest.results;
    } catch (err) {
        console.error(err.message);
        return err;
    }
} 

export const startAnimation = ({state}, index) => {
    state.currentMarketTest.results[index].animation = 1;
}
export const endAnimation = ({state}, index) => {
    state.currentMarketTest.results[index].animation = 0;
}

export const sortBedroomPercentages = ({state}) => {
    state.currentMarketTest.bedroomPercentages.sort( (objA, objB) => {
        return objB.value - objA.value
    });
    state.currentMarketTest.parkingPercentages.sort( (objA, objB) => {
        return objB.value - objA.value
    });
}
export const calcBedroomPercentages = ({state}) => {

    let resultLen = state.currentMarketTest.results.length;
    let studioCount = 0;
    let bdr1Count = 0;
    let bdr2Count = 0;
    let bdr3Count = 0;
    let bdr4Count = 0;
    for(let i=0; i<resultLen; i++) {
        if(state.currentMarketTest.results[i].content.bedrooms == "studio"){
            studioCount++;
        };
        if(state.currentMarketTest.results[i].content.bedrooms == "bedroom1"){
            bdr1Count++;
        };
        if(state.currentMarketTest.results[i].content.bedrooms == "bedroom2"){
            bdr2Count++;
        };
        if(state.currentMarketTest.results[i].content.bedrooms == "bedroom3"){
            bdr3Count++;
        };
        if(state.currentMarketTest.results[i].content.bedrooms == "bedroom4"){
            bdr4Count++;
        };
    ;}
    if( resultLen !== 0 ) {
        let studioPercent = (studioCount/resultLen)*100;
        let bdr1Percent = (bdr1Count/resultLen)*100;
        let bdr2Percent = (bdr2Count/resultLen)*100;
        let bdr3Percent = (bdr3Count/resultLen)*100;
        let bdr4Percent = (bdr4Count/resultLen)*100;

        let bdrmArray = [];
        bdrmArray = [
            {
                name: "studio",
                value: studioPercent,
                count: studioCount
            },
            {
                name: "1 bedroom",
                value: bdr1Percent,
                count: bdr1Count
            },
            {
                name: "2 bedroom",
                value: bdr2Percent,
                count: bdr2Count
            },
            {
                name: "3 bedroom",
                value: bdr3Percent,
                count: bdr3Count
            },
            {
                name: "4 bedroom",
                value: bdr4Percent,
                count: bdr4Count
            }
        ];

        state.currentMarketTest.bedroomPercentages = bdrmArray;
    }
}

export const calcParkingPercentages = ({state}) => {

    let resultLen = state.currentMarketTest.results.length;
    let noParkCount = 0;
    let park1Count = 0;
    let park2Count = 0;
    let park3Count = 0;
    let park4Count = 0;
    let elecCount = 0;
    for(let i=0; i<resultLen; i++) {
        if(state.currentMarketTest.results[i].content.parkingSpaces == "0"){
            noParkCount++;
        };
        if(state.currentMarketTest.results[i].content.parkingSpaces == "1"){
            park1Count++;
        };
        if(state.currentMarketTest.results[i].content.parkingSpaces == "2"){
            park2Count++;
        };
        if(state.currentMarketTest.results[i].content.parkingSpaces == "3"){
            park3Count++;
        };
        if(state.currentMarketTest.results[i].content.parkingSpaces == "4"){
            park4Count++;
        };
        if(state.currentMarketTest.results[i].content.parkingSpaces == "electric"){
            elecCount++;
        };
    ;}
    let noParkPercent = (noParkCount/resultLen)*100;
    let park1Percent = (park1Count/resultLen)*100;
    let park2Percent = (park2Count/resultLen)*100;
    let park3Percent = (park3Count/resultLen)*100;
    let park4Percent = (park4Count/resultLen)*100;
    let elecPercent = (elecCount/resultLen)*100;

    let parkArray = [];
    parkArray = [
        {
            name: "no parking",
            value: noParkPercent,
            count: noParkCount
        },
        {
            name: "1 parking",
            value: park1Percent,
            count: park1Count
        },
        {
            name: "2 parking",
            value: park2Percent,
            count: park2Count
        },
        {
            name: "3 parking",
            value: park3Percent,
            count: park3Count
        },
        {
            name: "4 parking",
            value: park4Percent,
            count: park4Count
        },
        {
            name: "electric",
            value: elecPercent,
            count: elecCount
        }
    ];

    state.currentMarketTest.parkingPercentages = parkArray;

}

export const updateGraphData = ({state}) => {
    state.currentMarketTest.lastUpdated = Date.now();
}

export const parkingDemand = ({state}) => {
    console.log(state.currentMarketTest.results);
}

export const getAvgRoom = async ({state}) => {
    // Set Total Leads
    state.currentMarketTest.totalLeads = state.currentMarketTest.results.length;

    let hotLeads = 0;
    let studioMin = [];
    let studioMax = [];
    let studioAvg = [];
    let bdr1Min = [];
    let bdr1Max = [];
    let bdr1Avg = [];
    let bdr2Min = [];
    let bdr2Max = [];
    let bdr2Avg = [];
    let bdr3Min = [];
    let bdr3Max = [];
    let bdr3Avg = [];
    let bdr4Min = [];
    let bdr4Max = [];
    let bdr4Avg = [];
    // Loop through all results
    for(let i = 0; i < state.currentMarketTest.totalLeads; i++) {

        // get hot leads
        if( state.currentMarketTest.results[i].has_downloaded_package == true && state.currentMarketTest.results[i].has_opened_confirmation_email == true ) {
            hotLeads++;
        };
        
        // Sort to get Min, Max, and Avg for each type of room
        if( state.currentMarketTest.results[i].content.bedrooms == "studio" ) {
            studioMin.push(state.currentMarketTest.results[i].content.budget[0]);
            studioMax.push(state.currentMarketTest.results[i].content.budget[1]);
            studioAvg.push((state.currentMarketTest.results[i].content.budget[0]+state.currentMarketTest.results[i].content.budget[1])/2);
        };
        if( state.currentMarketTest.results[i].content.bedrooms == "bedroom1" ) {
            bdr1Min.push(state.currentMarketTest.results[i].content.budget[0]);
            bdr1Max.push(state.currentMarketTest.results[i].content.budget[1]);
            bdr1Avg.push((state.currentMarketTest.results[i].content.budget[0]+state.currentMarketTest.results[i].content.budget[1])/2);
        };
        if( state.currentMarketTest.results[i].content.bedrooms == "bedroom2" ) {
            bdr2Min.push(state.currentMarketTest.results[i].content.budget[0]);
            bdr2Max.push(state.currentMarketTest.results[i].content.budget[1]);
            bdr2Avg.push((state.currentMarketTest.results[i].content.budget[0]+state.currentMarketTest.results[i].content.budget[1])/2);
        };
        if( state.currentMarketTest.results[i].content.bedrooms == "bedroom3" ) {
            bdr3Min.push(state.currentMarketTest.results[i].content.budget[0]);
            bdr3Max.push(state.currentMarketTest.results[i].content.budget[1]);
            bdr3Avg.push((state.currentMarketTest.results[i].content.budget[0]+state.currentMarketTest.results[i].content.budget[1])/2);
        };
        if( state.currentMarketTest.results[i].content.bedrooms == "bedroom4" ) {
            bdr4Min.push(state.currentMarketTest.results[i].content.budget[0]);
            bdr4Max.push(state.currentMarketTest.results[i].content.budget[1]);
            bdr4Avg.push((state.currentMarketTest.results[i].content.budget[0]+state.currentMarketTest.results[i].content.budget[1])/2);
        };

    }

    let graphMinMax = {
        global: {
            min: 0,
            max: 0,
            avg: 0,
        },
        studio: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr1: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr2: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr3: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
        bdr4: {
            min: 0,
            max: 0,
            avg: 0,
            bar1: 0,
            bar2: 0,
            bar3: 0,
            bar4: 0,
            bar5: 0,
            bar1Rng: 0,
            bar2Rng: 0,
            bar3Rng: 0,
            bar4Rng: 0,
            bar5Rng: 0,
            barMax: 0,
        },
    }
    
    // Check if room exists and insert info
    if( studioMax.length != 0 ) {
        graphMinMax.studio.min = Math.min(...studioMin);
        graphMinMax.studio.max = Math.max(...studioMax);

        let barSize = (graphMinMax.studio.max - graphMinMax.studio.min)/5;
        let studiobar1 = 0;
        let studiobar2 = 0;
        let studiobar3 = 0;
        let studiobar4 = 0;
        let studiobar5 = 0;
        let studioSum = 0;
        let studiobar1Rng = graphMinMax.studio.min;
        let studiobar2Rng = graphMinMax.studio.min+barSize;
        let studiobar3Rng = graphMinMax.studio.min+barSize+barSize;
        let studiobar4Rng = graphMinMax.studio.min+barSize+barSize+barSize;
        let studiobar5Rng = graphMinMax.studio.min+barSize+barSize+barSize+barSize;
        for(let i = 0; i < studioAvg.length; i++){
            studioSum += parseInt(studioAvg[i], 10);
            if( studiobar1Rng<=studioAvg[i] && studioAvg[i]<=studiobar2Rng-1) {
                studiobar1++;
            }
            else if( studiobar2Rng<=studioAvg[i] && studioAvg[i]<=studiobar3Rng-1) {
                studiobar2++;
            }
            else if( studiobar3Rng<=studioAvg[i] && studioAvg[i]<=studiobar4Rng-1) {
                studiobar3++;
            }
            else if( studiobar4Rng<=studioAvg[i] && studioAvg[i]<=studiobar5Rng-1) {
                studiobar4++;
            }
            else if( studiobar5Rng<=studioAvg[i] && studioAvg[i]<=graphMinMax.studio.max) {
                studiobar5++;
            };
        }
        graphMinMax.studio.avg = Math.round(studioSum/studioAvg.length);
        graphMinMax.studio.bar1 = studiobar1;
        graphMinMax.studio.bar2 = studiobar2;
        graphMinMax.studio.bar3 = studiobar3;
        graphMinMax.studio.bar4 = studiobar4;
        graphMinMax.studio.bar5 = studiobar5;
        graphMinMax.studio.bar1Rng = studiobar1Rng;
        graphMinMax.studio.bar2Rng = studiobar2Rng;
        graphMinMax.studio.bar3Rng = studiobar3Rng;
        graphMinMax.studio.bar4Rng = studiobar4Rng;
        graphMinMax.studio.bar5Rng = studiobar5Rng;

        // Get Y axis max
        let studioValues = [studiobar1, studiobar2, studiobar3, studiobar4, studiobar5];
        graphMinMax.studio.barMax = Math.max(...studioValues);

    } 
    if( bdr1Max.length != 0 ) {
        graphMinMax.bdr1.min = Math.min(...bdr1Min);
        graphMinMax.bdr1.max = Math.max(...bdr1Max);

        let barSize = (graphMinMax.bdr1.max - graphMinMax.bdr1.min)/5;
        let bdr1bar1 = 0;
        let bdr1bar2 = 0;
        let bdr1bar3 = 0;
        let bdr1bar4 = 0;
        let bdr1bar5 = 0;
        let bdr1Sum = 0;
        let bdr1bar1Rng = graphMinMax.bdr1.min;
        let bdr1bar2Rng = graphMinMax.bdr1.min+barSize;
        let bdr1bar3Rng = graphMinMax.bdr1.min+barSize+barSize;
        let bdr1bar4Rng = graphMinMax.bdr1.min+barSize+barSize+barSize;
        let bdr1bar5Rng = graphMinMax.bdr1.min+barSize+barSize+barSize+barSize;
        for(let i = 0; i < bdr1Avg.length; i++){
            bdr1Sum += parseInt(bdr1Avg[i], 10);
            if( bdr1bar1Rng<=bdr1Avg[i] && bdr1Avg[i]<=bdr1bar2Rng-1) {
                bdr1bar1++;
            }
            else if( bdr1bar2Rng<=bdr1Avg[i] && bdr1Avg[i]<=bdr1bar3Rng-1) {
                bdr1bar2++;
            }
            else if( bdr1bar3Rng<=bdr1Avg[i] && bdr1Avg[i]<=bdr1bar4Rng-1) {
                bdr1bar3++;
            }
            else if( bdr1bar4Rng<=bdr1Avg[i] && bdr1Avg[i]<=bdr1bar5Rng-1) {
                bdr1bar4++;
            }
            else if( bdr1bar5Rng<=bdr1Avg[i] && bdr1Avg[i]<=graphMinMax.bdr1.max) {
                bdr1bar5++;
            };
        }
        graphMinMax.bdr1.avg = Math.round(bdr1Sum/bdr1Avg.length);
        graphMinMax.bdr1.bar1 = bdr1bar1;
        graphMinMax.bdr1.bar2 = bdr1bar2;
        graphMinMax.bdr1.bar3 = bdr1bar3;
        graphMinMax.bdr1.bar4 = bdr1bar4;
        graphMinMax.bdr1.bar5 = bdr1bar5;
        graphMinMax.bdr1.bar1Rng = bdr1bar1Rng;
        graphMinMax.bdr1.bar2Rng = bdr1bar2Rng;
        graphMinMax.bdr1.bar3Rng = bdr1bar3Rng;
        graphMinMax.bdr1.bar4Rng = bdr1bar4Rng;
        graphMinMax.bdr1.bar5Rng = bdr1bar5Rng;

        // Get Y axis max
        let bdr1Values = [bdr1bar1, bdr1bar2, bdr1bar3, bdr1bar4, bdr1bar5];
        graphMinMax.bdr1.barMax = Math.max(...bdr1Values);

    } 
    if( bdr2Max.length != 0 ) {
        graphMinMax.bdr2.min = Math.min(...bdr2Min);
        graphMinMax.bdr2.max = Math.max(...bdr2Max);

        let barSize = (graphMinMax.bdr2.max - graphMinMax.bdr2.min)/5;
        let bdr2bar1 = 0;
        let bdr2bar2 = 0;
        let bdr2bar3 = 0;
        let bdr2bar4 = 0;
        let bdr2bar5 = 0;
        let bdr2Sum = 0;
        let bdr2bar1Rng = graphMinMax.bdr2.min;
        let bdr2bar2Rng = graphMinMax.bdr2.min+barSize;
        let bdr2bar3Rng = graphMinMax.bdr2.min+barSize+barSize;
        let bdr2bar4Rng = graphMinMax.bdr2.min+barSize+barSize+barSize;
        let bdr2bar5Rng = graphMinMax.bdr2.min+barSize+barSize+barSize+barSize;
        for(let i = 0; i < bdr2Avg.length; i++){
            bdr2Sum += parseInt(bdr2Avg[i], 10);
            if( bdr2bar1Rng<=bdr2Avg[i] && bdr2Avg[i]<=bdr2bar2Rng-1) {
                bdr2bar1++;
            }
            else if( bdr2bar2Rng<=bdr2Avg[i] && bdr2Avg[i]<=bdr2bar3Rng-1) {
                bdr2bar2++;
            }
            else if( bdr2bar3Rng<=bdr2Avg[i] && bdr2Avg[i]<=bdr2bar4Rng-1) {
                bdr2bar3++;
            }
            else if( bdr2bar4Rng<=bdr2Avg[i] && bdr2Avg[i]<=bdr2bar5Rng-1) {
                bdr2bar4++;
            }
            else if( bdr2bar5Rng<=bdr2Avg[i] && bdr2Avg[i]<=graphMinMax.bdr2.max) {
                bdr2bar5++;
            };
        }
        graphMinMax.bdr2.avg = Math.round(bdr2Sum/bdr2Avg.length);
        graphMinMax.bdr2.bar1 = bdr2bar1;
        graphMinMax.bdr2.bar2 = bdr2bar2;
        graphMinMax.bdr2.bar3 = bdr2bar3;
        graphMinMax.bdr2.bar4 = bdr2bar4;
        graphMinMax.bdr2.bar5 = bdr2bar5;
        graphMinMax.bdr2.bar1Rng = bdr2bar1Rng;
        graphMinMax.bdr2.bar2Rng = bdr2bar2Rng;
        graphMinMax.bdr2.bar3Rng = bdr2bar3Rng;
        graphMinMax.bdr2.bar4Rng = bdr2bar4Rng;
        graphMinMax.bdr2.bar5Rng = bdr2bar5Rng;

        // Get Y axis max
        let bdr2Values = [bdr2bar1, bdr2bar2, bdr2bar3, bdr2bar4, bdr2bar5];
        graphMinMax.bdr2.barMax = Math.max(...bdr2Values);

    } 
    if( bdr3Max.length != 0 ) {
        graphMinMax.bdr3.min = Math.min(...bdr3Min);
        graphMinMax.bdr3.max = Math.max(...bdr3Max);

        let barSize = (graphMinMax.bdr3.max - graphMinMax.bdr3.min)/5;
        let bdr3bar1 = 0;
        let bdr3bar2 = 0;
        let bdr3bar3 = 0;
        let bdr3bar4 = 0;
        let bdr3bar5 = 0;
        let bdr3Sum = 0;
        let bdr3bar1Rng = graphMinMax.bdr3.min;
        let bdr3bar2Rng = graphMinMax.bdr3.min+barSize;
        let bdr3bar3Rng = graphMinMax.bdr3.min+barSize+barSize;
        let bdr3bar4Rng = graphMinMax.bdr3.min+barSize+barSize+barSize;
        let bdr3bar5Rng = graphMinMax.bdr3.min+barSize+barSize+barSize+barSize;
        for(let i = 0; i < bdr3Avg.length; i++){
            bdr3Sum += parseInt(bdr3Avg[i], 10);
            if( bdr3bar1Rng<=bdr3Avg[i] && bdr3Avg[i]<=bdr3bar2Rng-1) {
                bdr3bar1++;
            }
            else if( bdr3bar2Rng<=bdr3Avg[i] && bdr3Avg[i]<=bdr3bar3Rng-1) {
                bdr3bar2++;
            }
            else if( bdr3bar3Rng<=bdr3Avg[i] && bdr3Avg[i]<=bdr3bar4Rng-1) {
                bdr3bar3++;
            }
            else if( bdr3bar4Rng<=bdr3Avg[i] && bdr3Avg[i]<=bdr3bar5Rng-1) {
                bdr3bar4++;
            }
            else if( bdr3bar5Rng<=bdr3Avg[i] && bdr3Avg[i]<=graphMinMax.bdr3.max) {
                bdr3bar5++;
            };
        }
        graphMinMax.bdr3.avg = Math.round(bdr3Sum/bdr3Avg.length);
        graphMinMax.bdr3.bar1 = bdr3bar1;
        graphMinMax.bdr3.bar2 = bdr3bar2;
        graphMinMax.bdr3.bar3 = bdr3bar3;
        graphMinMax.bdr3.bar4 = bdr3bar4;
        graphMinMax.bdr3.bar5 = bdr3bar5;
        graphMinMax.bdr3.bar1Rng = bdr3bar1Rng;
        graphMinMax.bdr3.bar2Rng = bdr3bar2Rng;
        graphMinMax.bdr3.bar3Rng = bdr3bar3Rng;
        graphMinMax.bdr3.bar4Rng = bdr3bar4Rng;
        graphMinMax.bdr3.bar5Rng = bdr3bar5Rng;

        // Get Y axis max
        let bdr3Values = [bdr3bar1, bdr3bar2, bdr3bar3, bdr3bar4, bdr3bar5];
        graphMinMax.bdr3.barMax = Math.max(...bdr3Values);

    } 
    if( bdr4Max.length != 0 ) {
        graphMinMax.bdr4.min = Math.min(...bdr4Min);
        graphMinMax.bdr4.max = Math.max(...bdr4Max);

        let barSize = (graphMinMax.bdr4.max - graphMinMax.bdr4.min)/5;
        let bdr4bar1 = 0;
        let bdr4bar2 = 0;
        let bdr4bar3 = 0;
        let bdr4bar4 = 0;
        let bdr4bar5 = 0;
        let bdr4Sum = 0;
        let bdr4bar1Rng = graphMinMax.bdr4.min;
        let bdr4bar2Rng = graphMinMax.bdr4.min+barSize;
        let bdr4bar3Rng = graphMinMax.bdr4.min+barSize+barSize;
        let bdr4bar4Rng = graphMinMax.bdr4.min+barSize+barSize+barSize;
        let bdr4bar5Rng = graphMinMax.bdr4.min+barSize+barSize+barSize+barSize;
        for(let i = 0; i < bdr4Avg.length; i++){
            bdr4Sum += parseInt(bdr4Avg[i], 10);
            if( bdr4bar1Rng<=bdr4Avg[i] && bdr4Avg[i]<=bdr4bar2Rng-1) {
                bdr4bar1++;
            }
            else if( bdr4bar2Rng<=bdr4Avg[i] && bdr4Avg[i]<=bdr4bar3Rng-1) {
                bdr4bar2++;
            }
            else if( bdr4bar3Rng<=bdr4Avg[i] && bdr4Avg[i]<=bdr4bar4Rng-1) {
                bdr4bar3++;
            }
            else if( bdr4bar4Rng<=bdr4Avg[i] && bdr4Avg[i]<=bdr4bar5Rng-1) {
                bdr4bar4++;
            }
            else if( bdr4bar5Rng<=bdr4Avg[i] && bdr4Avg[i]<=graphMinMax.bdr4.max) {
                bdr4bar5++;
            };
        }
        graphMinMax.bdr4.avg = Math.round(bdr4Sum/bdr4Avg.length);
        graphMinMax.bdr4.bar1 = bdr4bar1;
        graphMinMax.bdr4.bar2 = bdr4bar2;
        graphMinMax.bdr4.bar3 = bdr4bar3;
        graphMinMax.bdr4.bar4 = bdr4bar4;
        graphMinMax.bdr4.bar5 = bdr4bar5;
        graphMinMax.bdr4.bar1Rng = bdr4bar1Rng;
        graphMinMax.bdr4.bar2Rng = bdr4bar2Rng;
        graphMinMax.bdr4.bar3Rng = bdr4bar3Rng;
        graphMinMax.bdr4.bar4Rng = bdr4bar4Rng;
        graphMinMax.bdr4.bar5Rng = bdr4bar5Rng;

        // Get Y axis max
        let bdr4Values = [bdr4bar1, bdr4bar2, bdr4bar3, bdr4bar4, bdr4bar5];
        graphMinMax.bdr4.barMax = Math.max(...bdr4Values);

    };

    // Grab the min and max for global chart
    if(graphMinMax.studio.min != 0) {
        graphMinMax.global.min = graphMinMax.studio.min;
        if(graphMinMax.bdr1.min != 0 && graphMinMax.bdr1.min < graphMinMax.global.min) {
            graphMinMax.global.min = graphMinMax.bdr1.min;
        }
        if(graphMinMax.bdr2.min != 0 && graphMinMax.bdr2.min < graphMinMax.global.min) {
            graphMinMax.global.min = graphMinMax.bdr2.min;
        }
        if(graphMinMax.bdr3.min != 0 && graphMinMax.bdr3.min < graphMinMax.global.min) {
            graphMinMax.global.min = graphMinMax.bdr3.min;
        }
        if(graphMinMax.bdr4.min != 0 && graphMinMax.bdr4.min < graphMinMax.global.min) {
            graphMinMax.global.min = graphMinMax.bdr4.min;
        }
    }
    if(graphMinMax.studio.max != 0) {
        graphMinMax.global.max = graphMinMax.studio.max;
        if(graphMinMax.bdr1.max != 0 && graphMinMax.bdr1.max > graphMinMax.global.max) {
            graphMinMax.global.max = graphMinMax.bdr1.max;
        }
        if(graphMinMax.bdr2.max != 0 && graphMinMax.bdr2.max > graphMinMax.global.max) {
            graphMinMax.global.max = graphMinMax.bdr2.max;
        }
        if(graphMinMax.bdr3.max != 0 && graphMinMax.bdr3.max > graphMinMax.global.max) {
            graphMinMax.global.max = graphMinMax.bdr3.max;
        }
        if(graphMinMax.bdr4.max != 0 && graphMinMax.bdr4.max > graphMinMax.global.max) {
            graphMinMax.global.max = graphMinMax.bdr4.max;
        }
    }

    // Set avg budget
    let sumAvg = 0;
    let lengthOfAvgs = 0;
    if(studioAvg.length != 0 ){
        for(let i=0; i<studioAvg.length; i++){
            sumAvg += studioAvg[i];
            lengthOfAvgs++;
        }
    };
    if(bdr1Avg.length != 0 ){
        for(let i=0; i<bdr1Avg.length; i++){
            sumAvg += bdr1Avg[i];
            lengthOfAvgs++;
        }
    };
    if(bdr2Avg.length != 0 ){
        for(let i=0; i<bdr2Avg.length; i++){
            sumAvg += bdr2Avg[i];
            lengthOfAvgs++;
        }
    };
    if(bdr3Avg.length != 0 ){
        for(let i=0; i<bdr3Avg.length; i++){
            sumAvg += bdr3Avg[i];
            lengthOfAvgs++;
        }
    };
    if(bdr4Avg.length != 0 ){
        for(let i=0; i<bdr4Avg.length; i++){
            sumAvg += bdr4Avg[i];
            lengthOfAvgs++;
        }
    };
    if( lengthOfAvgs === 0 ) {
        state.currentMarketTest.avgBudget = 0;
    } else {
        state.currentMarketTest.avgBudget = Math.round(sumAvg/lengthOfAvgs);
    }
    // Set hot leads
    state.currentMarketTest.hotLeads = hotLeads;
    // Set graph data
    state.currentMarketTest.graphMinMax = graphMinMax;
}