export const getCurrentMarketTestFieldsUser = async ({state, effects}, marketTestId) => {
    try {
        const marketTest = await effects.auditor.fetchMarketTestFields(marketTestId);
        
        // Fill in the state
        state.auditor.currentMarketTest = marketTest;      

        // Return an object usable by inputs state (auditorMarketTest page)
        let returnObj = {};
        for (const f of marketTest.fields) {
            returnObj[f.field_content_id] = {
                value: f.field_value,
                auditorComment: f.auditor_comment,
                status: f.status,
                edit: true
            }
        }

        state.auditor.currentMarketTest.inputs = returnObj;

    } catch (err) {
        console.error(err.message);
        return err;
    }
} 
export const loadAllMarketTests = async ({state, effects}) => {
    try {
        const marketTests = await effects.auditor.fetchAllMarketTests();
        state.auditor.marketTestsList = marketTests;
    } catch (err) {
        console.error(err.message);
        return err;
    }
}
export const changeMarketTestFieldInput = async ({state}, {contentId, input, value}) => {
    if(input == 'edit') {
        state.auditor.currentMarketTest.inputs[contentId][input] = !state.auditor.currentMarketTest.inputs[contentId][input];
    } else {
        state.auditor.currentMarketTest.inputs[contentId][input] = value;
    }
}