import React, { useState, useEffect } from 'react';
import Button from 'src/components/shared/button';
import TextInput from 'src/components/shared/inputs/textInput';
import SvgIcons from 'src/components/shared/svgIcons';
import SelectColumnType from './selectColumnType';
import SelectOptionPanel from './selectOptionPanel';
import ErrorMessage from 'src/components/shared/errorMessage';
import Modal from 'src/components/shared/modal';
import SelectInput from 'src/components/shared/inputs/selectInput';
import './customColumnForm.scss';

const CustomColumnForm = ({setCustomColumnModal, addColumnsData, optionalColumns, editMode, setEditMode, editColumnData, sendEditColumnData, removeCustomColumn}) => {
    const camelCase = require('lodash.camelcase');

    const [columnTitle, setColumnTitle] = useState('');
    const [selectType, setSelectType] = useState('');
    const [customOptions, setCustomOptions] = useState([]);
    const [columnTitleError, setColumnTitleError] = useState(false);
    const [selectTypeError, setSelectTypeError] = useState(false);
    const [customOptionError, setCustomOptionError] = useState(false);
    const [duplicateColumnError, setDuplicateColumnError] = useState(false);
    const [dimensionType, setDimensionType] = useState('Feet');
    const [dimenstionTypeError, setDimensionTypeError] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    
    useEffect(() => {
        if(editMode) {
            setColumnTitle(editColumnData.name.en);
            setSelectType(editColumnData.type);
            if (editColumnData.type === "single_select") { 
                let tempArray = [];
                for (const element of editColumnData.selectOptions[0].en) {
                    tempArray.push(element[0]);
                }
                setCustomOptions([...tempArray]);
            } else if (editColumnData.type === "dimension") {
                setDimensionType(editColumnData.dimensionType);
            }
        }
    }, []);

    const checkDuplicateColumns = (columnTitle) => {
        for (const column of optionalColumns) {
            if(column.isCustom){
                if(column.name.en.toLowerCase() === columnTitle.toLowerCase()){
                    return true;
                }
            } else {
                if(column.name.toLowerCase() === columnTitle.toLowerCase()){
                    return true;
                }
            }
        }
    }

    const checkSelectOptionsEqual = () => {
        let tempArray = [];
        for (const element of editColumnData.selectOptions[0].en) {
            tempArray.push(element[0]);
        }
        tempArray.sort();
        let tempCustomArray = [...customOptions].sort();

        if(editColumnData.selectOptions[0].en.length != customOptions.length) {
            return false;
        }
        
        for(let i = 0; i < tempArray.length; i++) {
            if(tempArray[i] !== tempCustomArray[i]){
                return false;
            }
        }

        if(tempArray === tempCustomArray) {
            return true;
        }
        return true;
    }

    const checkCustomOptionsOrderChange = () => {
        let tempArray = [];
        for (const element of editColumnData.selectOptions[0].en) {
            tempArray.push(element[0]);
        }
        
        for(let i = 0; i < tempArray.length; i++) {
            if(tempArray[i] !== customOptions[i]){
                return true;
            }
        }
        return false;
    }

    const OptionSelect = ({iconType, text, desc, isWithDesc}) => {
        return(
            <div className={`${isWithDesc ? "customColumnForm__type-item-with-desc" : "customColumnForm__type-item"}`}>
                <SvgIcons type={iconType}/>
                { isWithDesc ? 
                <div className="customColumnForm__item-text-container">
                    <div className="customColumnForm__item-text">{text}</div>
                    <div className="customColumnForm__item-desc">{desc}</div>
                </div> 
                :
                <div className="customColumnForm__item-text">{text}</div>
                }
            </div>
        )
    }

    useEffect(() => {
        if(!editMode && selectType !== "single_select") { 
            setCustomOptions([]);
        }
        if(!editMode && selectType !== "dimension") { 
            setDimensionType("Feet");
        }
    }, [selectType]);

    const handleColumnSave = () => {
        
        if(!editMode && checkDuplicateColumns(columnTitle)) {
            setDuplicateColumnError(true);
            return;
        }

        if(columnTitle === "" || columnTitle.trim() === "") {
            setColumnTitleError(true);
            return;
        }
        
        if(selectType === ""){
            setSelectTypeError(true);
            return;
        }

        if(selectType === "single_select" && customOptions.length === 0) {
            setCustomOptionError(true);
            return;
        }

        if(selectType === "dimension" && dimensionType === "") { 
            setDimensionTypeError(true);
            return;
        }

        let columnObj = {};
        let extraContentObj = {};

        //for adding extra column depending on type
        if(selectType === "text" || selectType === "date" || selectType === "number" || selectType === "price") {
            columnObj = {
                key: camelCase(columnTitle),
                name: {
                    en: columnTitle,
                    fr: ""
                },
                type: selectType,
                isCustom: true,
                isShown: true
            };
        } else if (selectType === "toggle") { 
            columnObj = {
                key: camelCase(columnTitle),
                name: {
                    en: columnTitle,
                    fr: ""
                },
                selectOptions: ['yes', 'no'],
                type: selectType,
                isCustom: true,
                isShown: true
            };
        } else if (selectType === "dimension") {
            columnObj = {
                key: camelCase(columnTitle),
                name: {
                    en: columnTitle,
                    fr: ""
                },
                type: selectType,
                dimensionType: dimensionType,
                isCustom: true,
                isShown: true
            };

        } else if (selectType === "single_select") {
            columnObj = {
                key: camelCase(columnTitle),
                name: {
                    en: columnTitle,
                    fr: ""
                },
                selectOptions: [...customOptions],
                type: selectType,
                isCustom: true,
                isShown: true
            };
        } 

        //for intializing extra content depending on type.
        if(selectType === "text" || selectType === "date") {
            extraContentObj = {
                [`${columnObj.key}`]: ""
            };
        } else if (selectType === "number" || selectType === "price" || selectType === "dimension") {
            extraContentObj = {
                [`${columnObj.key}`]: 0
            };
        } else if (selectType === "toggle") {
            extraContentObj = {
                [`${columnObj.key}`]: 'No'
            };
        } else if ((selectType === "single_select")) {
            extraContentObj = {
                [`${columnObj.key}`]: columnObj.selectOptions[0]
            };
        }

        if(editMode) {
            if(editColumnData.name.en === columnTitle && selectType === editColumnData.type) {
                if(editColumnData.type === "single_select" && checkSelectOptionsEqual() && !checkCustomOptionsOrderChange()) {
                    setCustomColumnModal(false);
                    setEditMode(false);
                    return;
                } else {
                    sendEditColumnData({extraColumnObj: columnObj, extraContentObj: extraContentObj, initialColumnData: editColumnData})
                    setCustomColumnModal(false);
                    setEditMode(false);
                }
                setCustomColumnModal(false);
                setEditMode(false);
                return;
            } else {
                if(editColumnData.name.en !== columnTitle && checkDuplicateColumns(columnTitle)){
                    setDuplicateColumnError(true);
                    setEditMode(false);
                    return;
                }
                sendEditColumnData({extraColumnObj: columnObj, extraContentObj: extraContentObj, initialColumnData: editColumnData})
                setCustomColumnModal(false);
                setEditMode(false);
            }
        } else {
            addColumnsData({extraColumnObj: columnObj, extraContentObj: extraContentObj});
            setCustomColumnModal(false);
        }
    }

    const handleDeleteColumn = () => {
        removeCustomColumn(editColumnData);
        setCustomColumnModal(false);
    }
    
    return(
        <div className="customColumnForm__container">
            <div className="customColumnForm__header">
                {editMode ? "Edit Column" : "Add a custom column"}
            </div>
            <div className="customColumnForm__body">
                <div className="customColumnForm__body-split-container">
                    <div className="customColumnForm__choose-type-container">
                        <TextInput label="Column name (required)" placeholder={"Column title"}
                            value={columnTitle} setValue={(e) => setColumnTitle(e.currentTarget.value)}/>
                        <SelectColumnType label="Field type (required)" placeholder="Select Type" value={selectType}
                            setValue={setSelectType} 
                            items={[
                                {columnValue: 'text', 
                                content_desc: <OptionSelect iconType={"text_column"} text="Text" desc="Up to 150 characters" isWithDesc={true} />,
                                content: <OptionSelect iconType={"text_column"} text="Text" isWithDesc={false} />
                                },
                                {columnValue: 'date', 
                                content_desc: <OptionSelect iconType={"date_column"} text="Date" desc="For selected dates" isWithDesc={true} />,
                                content: <OptionSelect iconType={"date_column"} text="Date" isWithDesc={false} />,
                                },
                                {columnValue: 'number', 
                                content_desc: <OptionSelect iconType={"numeric_column"} text="Numeric" desc="For numerical information" isWithDesc={true} />,
                                content: <OptionSelect iconType={"numeric_column"} text="Numeric" isWithDesc={false} />,
                                },
                                {columnValue: 'dimension',
                                content_desc: <OptionSelect iconType={"dimension_icon"} text="Unit of measure" desc="For measurement data" isWithDesc={true} />,
                                content: <OptionSelect iconType={"dimension_icon"} text="Unit of measure" isWithDesc={false} />,
                                },
                                {columnValue: 'price', 
                                content_desc: <OptionSelect iconType={"monetary_column"} text="Monetary" desc="For monetary data" isWithDesc={true} />,
                                content: <OptionSelect iconType={"monetary_column"} text="Monetary" isWithDesc={false} />,
                                },
                                {columnValue: 'toggle', 
                                content_desc: <OptionSelect iconType={"toggle_column"} text="Yes or no" desc="Defined yes or no option" isWithDesc={true} />,
                                content: <OptionSelect iconType={"toggle_column"} text="Yes or no" isWithDesc={false} />,
                                },
                                {columnValue: 'single_select', 
                                content_desc: <OptionSelect iconType={"single_select_column"} text="Single select" desc="Predefined list of options with a single choice" isWithDesc={true} />,
                                content: <OptionSelect iconType={"single_select_column"} text="Single select" isWithDesc={false} />,
                                },
                            ]}
                        />
                        {selectType === "dimension" && 
                        <SelectInput label="Measurement type" value={dimensionType} setValue={(e) => setDimensionType(e.currentTarget.value)} 
                            selectOptions={[
                                ["Feet", "Feet"],
                                ["Inch", "Inch"],
                                ["SQFT²", "FT²"],
                                ["Meter", "Meter"]
                            ]}
                        />}
                        {editMode &&
                        <Button text="Delete column" type="danger" size="small" onClick={() => setShowDeleteConfirmation(true)} />
                        }
                        {showDeleteConfirmation &&
                        <div className="customColumnForm__delete-confirmation">
                            <div>All units data related to this column will be lost, are you sure you want to delete this column ?</div>
                            <div className="customColumnForm__delete-buttons-container">
                                <Button type="secondary" text="Cancel " onClick={() => setShowDeleteConfirmation(false)} />
                                <Button type="danger" text="Yes, I want to delete this column" onClick={handleDeleteColumn} />
                            </div>
                        </div>
                        }
                    </div>
                    {
                        selectType === "single_select" && <SelectOptionPanel customOptions={customOptions} setCustomOptions={setCustomOptions}/>
                    }
                </div>
                <div className="customColumnForm__buttons-container">
                    {/* <Button type="ternary" text="CANCEL" onClick={handleColumnSave} /> */}
                    <Button type="primary" text="SAVE" onClick={handleColumnSave} suffix={<SvgIcons type="cabana_right_arrow" />} />
                </div>
            </div>
            <Modal isOpen={columnTitleError} 
            setIsOpen={setColumnTitleError} 
            options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={() => setColumnTitleError(false)} 
                    items={[
                        {content: <div>Column name cannot be empty</div>},
                        ]}
                    errorCode={'1311'}
                    />   
            </Modal>
            <Modal isOpen={selectTypeError} 
            setIsOpen={setSelectTypeError} 
            options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={() => setSelectTypeError(false)} 
                    items={[
                        {content: <div>Field type cannot be empty please chose a type</div>},
                        ]}
                    errorCode={'1312'}
                    />   
            </Modal>
            <Modal isOpen={customOptionError} 
            setIsOpen={setCustomOptionError} 
            options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={() => setCustomOptionError(false)} 
                    items={[
                        {content: <div>Options cannot be empty</div>},
                        ]}
                    errorCode={'1310'}
                    />   
            </Modal>
            <Modal isOpen={duplicateColumnError} 
            setIsOpen={setDuplicateColumnError} 
            options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={() => setDuplicateColumnError(false)} 
                    items={[
                        {content: <div>Cannot have custom column with the same name.</div>},
                        ]}
                    errorCode={'1313'}
                    />   
            </Modal>
            <Modal isOpen={dimenstionTypeError} 
            setIsOpen={setDimensionTypeError} 
            options={{disableBackdropClick: true, contentCenter: true}}>
                <ErrorMessage onClick={() => setDimensionTypeError(false)} 
                    items={[
                        {content: <div>Measurement type cannot be empty</div>},
                        ]}
                    errorCode={'1321'}
                    />   
            </Modal>

        </div>
    )
}

export default CustomColumnForm;