import React, { useState, useEffect, useRef } from 'react';
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';
import { useOvermind } from 'src/overmind';
import { useResizeContainer } from 'src/utils/hooks';
import './buildingOutline.scss';
import { floorNaming } from 'src/utils/units';
import { flatten } from 'src/utils/canvas';
import { useHistory } from 'react-router-dom';
import DrawingCanvas from 'src/components/shared/drawingCanvas';

const BuildingOutline = ({changeStep, steps, validateForm, validateField, formErrors}) => {
    
    const {state, actions} = useOvermind();
    const [points, setPoints] = useState([]);
    const [flattenedPoints, setFlattenedPoints] = useState([]);
    const [isClosed, setIsClosed] = useState(false);
    const [currentFloor, setCurrentFloor] = useState(0);
    const [saveButton, setSaveButton] = useState(false);
    const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(true);
    const buildingRef = useRef(null);
    const history = useHistory();
    
    const {containerWidth, containerHeight} = useResizeContainer(buildingRef ? buildingRef : '');

    useEffect(() => {
        if(state.productForm.newProduct.fields.buildingView_floors.value.array.length === 0) {
            let tempArray = [];
            if(state.productForm.newProduct.fields.num_floors?.value != null) { 
                for (let i = 1; i <= state.productForm.newProduct.fields.num_floors.value; i++) {
                  let tempObj = {
                    id: i - 1,
                    name: floorNaming(i) + " floor",
                    points: [],
                    isFinished: false
                  };
                  tempArray.push(tempObj);
                }
            }
            actions.productForm.initializeBuildingFloors(tempArray);
        }

        if(state.productForm.newProduct.fields.buildingView_floors.value?.array?.length > 0) {
            checkWhichFloorsFinished();
            checkIfFloorNotFinished();   
        }

        setTimeout(() => {
            setIsInfoBoxOpen(false);
        }, [5000]);

    }, []);

    useEffect(() => {
        if(state.productForm.newProduct.fields.buildingView_floors?.value.array) {
            for (const item of state.productForm.newProduct.fields.buildingView_floors?.value?.array) {
                if (!item.isFinished) {
                    setCurrentFloor(item.id);
                    return;
                }
            }
            setIsClosed(true);
        }
    }, [saveButton]);

    const handleSaveClick = (index) => {
      let floorObj = {
          id: index,
          points: points,
          isFinished: true
      }
      actions.productForm.setBuildingViewFloor(floorObj);
      actions.productForm.updateProductFields(['buildingView_floors']).then(() => {
          setPoints([]);
          setFlattenedPoints([]);
          setIsClosed(false);
          setSaveButton(!saveButton);
      });
    };

    const handleEditClick = (index) => {
        if(index !== currentFloor && points.length != 0) {
            let floorObj = {
                ...state.productForm.newProduct.fields.buildingView_floors.value.array[currentFloor],
                isFinished: true
            }
            actions.productForm.setBuildingViewFloor(floorObj);
        }
        let floor = index;
        setCurrentFloor(floor);
        let floorObj = {
            ...state.productForm.newProduct.fields.buildingView_floors.value.array[index],
            isFinished: false
        }
        actions.productForm.setBuildingViewFloor(floorObj);
        let indexPoints = state.productForm.newProduct.fields.buildingView_floors.value.array[index];
        setPoints(indexPoints.points);
        setFlattenedPoints(flatten(indexPoints.points));
        setIsClosed(true);
    };

      const handleDeleteClick = (index) => {
        let floorObj = {
            id: index,
            points: [],
            isFinished: false
        }
        actions.productForm.setBuildingViewFloor(floorObj);
        setPoints([]);
        setFlattenedPoints([]);
        setIsClosed(false);
        setSaveButton(!saveButton);
    };

    const handleBackClick = () => {
        actions.productForm.updateProductFields(['buildingView_floors']);
        changeStep(steps - 1);
    }

    const handleNextClick = () => {
        actions.productForm.updateProductFields(['buildingView_floors']);
        validateField('buildingView_floors');
        changeStep(steps + 1);   
        
    }

    const checkWhichFloorsFinished = () => {
        let counter = 0;
        for(const floor of state.productForm.newProduct.fields.buildingView_floors?.value?.array) {
            if(floor.isFinished) {
                counter++;
            }
        }
        if(counter == state.productForm.newProduct.fields.num_floors?.value) {
            setCurrentFloor(counter - 1);
            setIsClosed(true);
        }
    }


    const checkIfFloorNotFinished = () => {
        for(const floor of state.productForm.newProduct.fields.buildingView_floors?.value?.array) {
            if(!floor.isFinished) {
                setCurrentFloor(floor.id);
                return;
            }
        }
    }

    const handleSaveFloorsAndExit = () => {
        actions.productForm.updateProductFields(['buildingView_floors']).then(() => {
            history.push('/virtual-office-list');
        })
    }

    const handleImageChange = () => {
        actions.productForm.updateProductFields(['buildingView_floors']).then(() => {
            changeStep(steps - 1);
        }); 
    }

    return(
        <div className="buildingOutline__container">
            <div className="buildingOutline__split-container">
                <div className="buildingOutline__building-container">
                    <div className="buildingOutline__outline-change-image">
                        <div className="buildingOutline__outline-text">{currentFloor + 1 <= state.productForm.newProduct.fields.num_floors.value && `Outline ${floorNaming(currentFloor + 1)} floor`}</div>
                        <div className="buildingOutline__change-image" onClick={handleImageChange}>Change building image</div>
                    </div>
                    <div ref={buildingRef} className="buildingOutline__building-outline">
                        <DrawingCanvas 
                            editMode={true}
                            points={points}
                            setPoints={setPoints}
                            isClosed={isClosed}
                            setIsClosed={setIsClosed}
                            flattenedPoints={flattenedPoints}
                            setFlattenedPoints={setFlattenedPoints}
                            containerWidth={containerWidth}
                            containerHeight={containerHeight}
                            imageUrl={state.productForm.newProduct.fields.buildingView_image?.value}
                            data={state.productForm.newProduct.fields.buildingView_floors?.value?.array}
                        />
                    </div>
                    <div className={`buildingOutline__building-infobox ${isInfoBoxOpen ? '--active' : '--closed'}`}>
                        <div className="buildingOutline__infobox-close" onClick={() => setIsInfoBoxOpen(false)}><SvgIcons type="cabana_circle_close_icon" /></div>
                        <div className="buildingOutline__infobox">
                            <SvgIcons type="cabana_pen"/>
                            <div className="buildingOutline__infobox-text">Click on the image to start <br /> outlining the floor</div>
                        </div>
                    </div>
                </div>
                <div className={`buildingOutline__floors-container ${typeof formErrors.buildingView_floors === 'string' ? '--error' : ''}`}>
                    <div className='buildingOutline__floors-button-error-container'>
                        <Button type="primary" onClick={handleSaveFloorsAndExit}>Save floors and exit</Button>
                        {typeof formErrors.buildingView_floors === 'string' && <div className="buildingOutline__floors-error">{formErrors.buildingView_floors}</div>}
                    </div>
                    <div className="buildingOutline__floors-guide-container">
                        <div className="buildingOutline__floors-guide-title">Outline building floors</div>
                        <div className="buildingOutline__floors-guide-info">Draw every building floors of the project</div>
                    </div>
                    <div className="buildingOutline__floors-items-container">
                    {state.productForm.newProduct.fields.buildingView_floors?.value?.array?.map((el, index) => {
                    return (
                        <div className="buildingOutline__floors-items">
                            <div className="buildingOutline__floor-item-text-icon">
                                <div className="floor-item-text">{el.name}</div>
                                    {el.isFinished ? 
                                    <SvgIcons type="cabana_green_circle_checkmark" />
                                    : 
                                    index === currentFloor ? 
                                    <SvgIcons type="cabana_blue_right_arrow_circle" />
                                    :
                                    <div className="buildingOutline__checkmark-placeholder" />     
                                    }   
                            </div>
                            {el.isFinished ? (
                                <div className="buildingOutline__edit-delete-buttons">
                                    <Button type="secondary" style={{padding: '6px 16px'}} onClick={() => handleEditClick(index)}><SvgIcons type="cabana_edit"/>Edit</Button>
                                    <Button type="secondary" style={{padding: '6px 16px'}} onClick={() => handleDeleteClick(index)}><SvgIcons type="cabana_trashcan"/>Delete</Button>
                                </div>
                            ) : index === currentFloor ? (
                                <div className="buildingOutline__edit-delete-buttons">
                                    <Button type="primary" style={{padding: '6px 16px'}} onClick={() => handleSaveClick(index)}><SvgIcons type="cabana_save"/> Save</Button>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                    })}
                    </div>
                    <div className="buildingOutline__options-container">
                        <div className="buildingOutline__divider" />
                        <div className="buildingOutline__button-options">
                            <Button type="ternary" onClick={handleBackClick}>Back</Button>
                            <Button type="primary" onClick={handleNextClick}>Next</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuildingOutline;