import { derived } from 'overmind';

export const state = {
    vsoInfo: {
        vsoId: '',
        projectId: ''
    },
    isLoading: false,
    list: [],
    columns: [],
    plans: [],
    selectedFloor: '',
    getUnitsByFloor: derived(state => floor => {
        if(!Array.isArray(state.list)) return [];
        if(state.list.length === 0) return [];
        if(floor === null) {
            let unitsArr = [...state.list];
            return unitsArr.sort((unitA, unitB) => {
                if(unitA.floor >= unitB.floor && unitA.order_in_floor > unitB.order_in_floor) {
                    return 1;
                } else if(unitA.floor <= unitB.floor && unitA.unit_number < unitB.unit_number) {
                    return -1;
                }
                return 0;
            });
        } else {
            return state.list.filter(unit => unit.floor == floor).sort((unitA, unitB) => {
                if(unitA.order_in_floor > unitB.order_in_floor) {
                    return 1;
                } else if(unitA.order_in_floor < unitB.order_in_floor) {
                    return -1;
                }
                return 0;
            });  
        }
    }),
    sortedFloorUnits: derived((state) => {
        if(!Array.isArray(state.list)) {
            return [];
        }
        if(state.list.length === 0) {
            return [];
        }
        if(state.selectedFloor !== '') {
            return state.list.filter(unit => unit.floor == state.selectedFloor).sort((unitA, unitB) => {
                if(unitA.order_in_floor > unitB.order_in_floor) {
                    return 1;
                } else if(unitA.order_in_floor < unitB.order_in_floor) {
                    return -1;
                }
                return 0;
            });
        } else {
            let unitsArr = [...state.list];
            return unitsArr.sort((unitA, unitB) => {
                if(unitA.floor >= unitB.floor && unitA.order_in_floor > unitB.order_in_floor) {
                    return 1;
                } else if(unitA.floor <= unitB.floor && unitA.unit_number < unitB.unit_number) {
                    return -1;
                }
                return 0;
            });
        }
    })
};