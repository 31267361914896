export const setUserId = ({ state }, id) => {
    state.currentUser.userId = id;
};

export const setUserType = ({ state }, type) => {
    state.currentUser.type = type;
};

export const setUserFirstName = ({ state }, firstName) => {
    state.currentUser.firstName = firstName;
};

export const setUserLastName = ({ state }, lastName) => {
    state.currentUser.lastName = lastName;
};

export const setUserLastLogin = ({ state }, lastLogin) => {
    state.currentUser.lastLogin = lastLogin;
};

export const clearCurrentUser = ({ state }) => {
    state.currentUser.userId = '';
    state.currentUser.type = '';
    state.currentUser.firstName = '';
    state.currentUser.lastName = '';
    state.currentUser.lastLogin = '';
};

export const loadCurrentUser = async ({state, effects}) => {
    try {
        const currentUserData = await effects.currentUser.fetchCurrentUser();
        state.currentUser.userId = localStorage.userId; 
        state.currentUser.type = currentUserData.type; 
        state.currentUser.firstName = currentUserData.first_name; 
        state.currentUser.lastName = currentUserData.last_name; 
        state.currentUser.lastLogin = currentUserData.last_login;
    } catch (err) {
        console.error(err.message);
        return err;
    }

};

export const getUserType = ({state}) => {
    try {
        return state.currentUser.type;
    } catch (err) {
        console.error(err.message);
    }
}
