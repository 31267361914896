import React from 'react';
import { floorNaming } from 'src/utils/units';
import './floorItems.scss';
import Button from 'src/components/shared/button';
import SvgIcons from 'src/components/shared/svgIcons';

const FloorItems = ({
    floorData,
    index,
    floorPlanRef,
    handleChange,
    isActive,
    handleEditClick,
    handleClearClick,
    handleUploadClick
}) => {
    return(
        <div className={`floorItems__floors-items ${isActive ? '--active': ''}`}>
            <div className="floors-items-text-icon">
                <div className="floors-items-text">{floorNaming(floorData?.name?.split(' ')[1])} floor</div>
                {
                    floorData.isUploaded ? 
                    <SvgIcons type="cabana_green_circle_checkmark" />
                    : 
                    isActive ? 
                    <SvgIcons type="cabana_blue_right_arrow_circle" />
                    :
                    <div className="floorItems__checkmark-placeholder" />     
                } 
            </div>
            {floorData.isUploaded ? (
                <div className="floorItems__edit-delete-buttons">
                    <Button type="secondary" style={{padding: '6px 16px'}} onClick={() => handleEditClick(index)}><SvgIcons type="cabana_edit"/>Edit</Button>
                    <Button type="secondary" style={{padding: '6px 16px'}} onClick={() => handleClearClick(index)}><SvgIcons type="cabana_trashcan"/>Clear</Button>
                    <input
                        type="file"
                        ref={floorPlanRef}
                        onChange={handleChange}
                        style={{display: 'none'}} 
                    />
                </div>
            ) : (
                <>
                    <Button type="secondary" style={{padding: '6px 16px'}} onClick={() => handleUploadClick(index)}><SvgIcons type="cabana_floor_plan_upload"/>Upload</Button>
                    <input
                        type="file"
                        ref={floorPlanRef}
                        onChange={handleChange}
                        style={{display: 'none'}} 
                    />
                </>
            )}
        </div>
    )
}

export default FloorItems;