import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
.use(LanguageDetector)
.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'fr',
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            common: require('./locales/en/common.json'),
            translations: require('./locales/en/translations.json'),
            unitTable: require('./locales/en/unitTable.json'),
            filterTable: require('./locales/en/filterTable.json'),
            planView: require('./locales/en/planView.json'),
            buildingView: require('./locales/en/buildingView.json'),
            overview: require('./locales/en/overview.json'),
        },
        fr: {
            common: require('./locales/fr/common.json'),
            translations: require('./locales/fr/translations.json'),
            unitTable: require('./locales/fr/unitTable.json'),
            filterTable: require('./locales/fr/filterTable.json'),
            planView: require('./locales/fr/planView.json'),
            buildingView: require('./locales/fr/buildingView.json'),
            overview: require('./locales/fr/overview.json'),
        }
    },
    ns: ['translations', 'common', 'unitTable', 'filterTable', 'planView', 'buildingView', 'overview'],
    defaultNS: 'translations',
    fallbackNS: 'common'
});

i18n.languages = ['en', 'fr'];

export default i18n;