export const loadMarketTestFields = async ({state, actions, effects}, id) => {
    try {
        const marketTest = await effects.listing.fetchListing(id);
        state.listing.marketTest.id = id;
        state.listing.marketTest.status = marketTest.status;
        state.listing.marketTest.userId = marketTest.userId;
        for(const f of marketTest.fields) {
            state.listing.marketTest.fields[f.field_content_id] = 
                f.field_value === null ? null
                : f.field_value.includes('{"') ? JSON.parse(f.field_value) 
                : f.field_value === "true" ? true : f.field_value === "false" ? false 
                : f.field_value;
        }

        const allAmenitiesArray = state.listing.marketTest.fields.project_amenities.array
        .concat(state.listing.marketTest.fields.common_spaces.array)
        .concat(state.listing.marketTest.fields.exterior_features.array)
        .concat(state.listing.marketTest.fields.roof.array)
        .concat(state.listing.marketTest.fields.energy.array)
        .concat(state.listing.marketTest.fields.units_amenities.array)
        .concat(state.listing.marketTest.fields.units_general.array)
        .concat(state.listing.marketTest.fields.units_utilities.array)
        .concat(state.listing.marketTest.fields.units_flooring.array)
        .concat(state.listing.marketTest.fields.units_kitchen.array)
        .concat(state.listing.marketTest.fields.units_bathroom.array);

        // ASSIGN AMENITIES
        state.listing.marketTest.fields.primaryAmenities = allAmenitiesArray.filter((elem) => {
            elem = JSON.parse(elem);
            console.log(elem.en);
            if(state.listing.marketTest.fields.project_type == 'sell'){
                return (elem.en == 'Elevator' || 
                        elem.en == 'Storage' || 
                        elem.en == 'Air Conditioning' || 
                        elem.en == 'Kitchen Island' || 
                        elem.en == 'Walk-in Closet' ||
                        elem.en == 'Gym' ||
                        elem.en == 'Roof Terrace' ||
                        elem.en == 'Electric Car Charging Stations' ||
                        elem.en == 'Swimming Pool'
                        );  
            }else{
                return (elem.en == 'Elevator' || 
                        elem.en == 'Storage' || 
                        elem.en == 'Indoor Parking' || 
                        elem.en == 'Outdoor Parking' ||
                        elem.en == 'Gym' ||
                        elem.en == 'Internet/WIFI' ||
                        elem.en == 'Indoor Pool' ||
                        elem.en == 'Outdoor Pool' ||
                        elem.en == 'Air Conditioning' ||
                        elem.en == 'Balcony'
                        )
            }
        });

        // CALCULATE MIN MAX AREAS
        state.listing.marketTest.fields.minArea = Math.min(
            state.listing.marketTest.fields.min_area_studios == 0 ? Infinity : state.listing.marketTest.fields.min_area_studios,
            state.listing.marketTest.fields.min_area_1_bdr == 0 ? Infinity : state.listing.marketTest.fields.min_area_1_bdr,
            state.listing.marketTest.fields.min_area_2_bdr == 0 ? Infinity : state.listing.marketTest.fields.min_area_2_bdr,
            state.listing.marketTest.fields.min_area_3_bdr == 0 ? Infinity : state.listing.marketTest.fields.min_area_3_bdr,
            state.listing.marketTest.fields.min_area_4_bdr == 0 ? Infinity : state.listing.marketTest.fields.min_area_4_bdr,
            state.listing.marketTest.fields.min_area_loft == 0 ? Infinity : state.listing.marketTest.fields.min_area_loft,
            state.listing.marketTest.fields.min_area_penthouse == 0 ? Infinity : state.listing.marketTest.fields.min_area_penthouse,
            state.listing.marketTest.fields.min_area_townhouse == 0 ? Infinity : state.listing.marketTest.fields.min_area_townhouse,
        );
        if(state.listing.marketTest.fields.minArea == Infinity) {
            state.listing.marketTest.fields.minArea = 0;
        }

        state.listing.marketTest.fields.maxArea = Math.max(
            state.listing.marketTest.fields.max_area_studios,
            state.listing.marketTest.fields.max_area_1_bdr,
            state.listing.marketTest.fields.max_area_2_bdr,
            state.listing.marketTest.fields.max_area_3_bdr,
            state.listing.marketTest.fields.max_area_4_bdr,
            state.listing.marketTest.fields.max_area_loft,
            state.listing.marketTest.fields.max_area_penthouse,
            state.listing.marketTest.fields.max_area_townhouse,
        );

        // ASSIGN RIGHT STRINGS
        if(state.listing.marketTest.fields.unit_type == 'app_condo') state.listing.marketTest.fields.unit_type = 'apartments';
        if(state.listing.marketTest.fields.unit_type == 'row_house') state.listing.marketTest.fields.unit_type = 'row house';
        if(state.listing.marketTest.fields.unit_type == 'semi_detached_house') state.listing.marketTest.fields.unit_type = 'semi detached house';
        if(state.listing.marketTest.fields.unit_type == 'single_detached_house') state.listing.marketTest.fields.unit_type = 'single detached house';

        // REPLACE WITH DEFAULT BUDGET IF MINMAX PRICES = 0
        actions.listing.setDefaultBudget();

        // DISPLAY LISTING ONCE LOADED
        state.listing.isLoaded = true;

    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const setDefaultBudget = ({state}) => {
    if(state.listing.marketTest.fields.min_price_studios == 0 || state.listing.marketTest.fields.min_price_studios === null) {
        state.listing.marketTest.fields.min_price_studios = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 600 : 150000;
    }
    if(state.listing.marketTest.fields.max_price_studios == 0 || state.listing.marketTest.fields.max_price_studios === null) {
        state.listing.marketTest.fields.max_price_studios = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 1200 : 400000;
    }
    if(state.listing.marketTest.fields.min_price_1_bdr == 0 || state.listing.marketTest.fields.min_price_1_bdr === null) {
        state.listing.marketTest.fields.min_price_1_bdr = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 700 : 180000;
    }
    if(state.listing.marketTest.fields.max_price_1_bdr == 0 || state.listing.marketTest.fields.max_price_1_bdr === null) {
        state.listing.marketTest.fields.max_price_1_bdr = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 1500 : 430000;
    }
    if(state.listing.marketTest.fields.min_price_2_bdr == 0 || state.listing.marketTest.fields.min_price_2_bdr === null) {
        state.listing.marketTest.fields.min_price_2_bdr = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 900 : 200000;
    }
    if(state.listing.marketTest.fields.max_price_2_bdr == 0 || state.listing.marketTest.fields.max_price_2_bdr === null) {
        state.listing.marketTest.fields.max_price_2_bdr = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 2500 : 550000;
    }
    if(state.listing.marketTest.fields.min_price_3_bdr == 0 || state.listing.marketTest.fields.min_price_3_bdr === null) {
        state.listing.marketTest.fields.min_price_3_bdr = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 1000 : 250000;
    }
    if(state.listing.marketTest.fields.max_price_3_bdr == 0 || state.listing.marketTest.fields.max_price_3_bdr === null) {
        state.listing.marketTest.fields.max_price_3_bdr = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 3000 : 650000;
    }
    if(state.listing.marketTest.fields.min_price_4_bdr == 0 || state.listing.marketTest.fields.min_price_4_bdr === null) {
        state.listing.marketTest.fields.min_price_4_bdr = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 1200 : 300000;
    }
    if(state.listing.marketTest.fields.max_price_4_bdr == 0 || state.listing.marketTest.fields.max_price_4_bdr === null) {
        state.listing.marketTest.fields.max_price_4_bdr = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 4000 : 750000;
    }
    if(state.listing.marketTest.fields.min_price_loft == 0 || state.listing.marketTest.fields.min_price_loft === null) {
        state.listing.marketTest.fields.min_price_loft = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 1000 : 250000;
    }
    if(state.listing.marketTest.fields.max_price_loft == 0 || state.listing.marketTest.fields.max_price_loft === null) {
        state.listing.marketTest.fields.max_price_loft = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 4000 : 1000000;
    }
    if(state.listing.marketTest.fields.min_price_penthouse == 0 || state.listing.marketTest.fields.min_price_penthouse === null) {
        state.listing.marketTest.fields.min_price_penthouse = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 1000 : 300000;
    }
    if(state.listing.marketTest.fields.max_price_penthouse == 0 || state.listing.marketTest.fields.max_price_penthouse === null) {
        state.listing.marketTest.fields.max_price_penthouse = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 5000 : 1000000;
    }
    if(state.listing.marketTest.fields.min_price_townhouse == 0 || state.listing.marketTest.fields.min_price_townhouse === null) {
        state.listing.marketTest.fields.min_price_townhouse = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 1000 : 350000;
    }
    if(state.listing.marketTest.fields.max_price_townhouse == 0 || state.listing.marketTest.fields.max_price_townhouse === null) {
        state.listing.marketTest.fields.max_price_townhouse = 
        (state.listing.marketTest.fields.project_type == 'rent') ? 5000 : 1000000;
    }
}

export const setMarketTestId = ({state}, id) => {
    state.listing.marketTest.id = id;
}

export const sendResult = async ({effects}, body) => {
    return await effects.listing.sendResult(body);
}

export const clearListing = ({state}) => {
    state.listing.marketTest.id = '';
    state.listing.marketTest.fields = [];
    state.listing.isLoaded = false;
}