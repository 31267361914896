import React, { useState } from "react";
import ToggleButton from "src/components/shared/toggleButton";
import SvgIcons from "src/components/shared/svgIcons";
import CustomColumnForm from "./customColumnForm";
import Modal from "src/components/shared/modal";
import Button from "src/components/shared/button";
import './editColumnsMenu.scss';

const EditColumnsMenu = ({optionalColumns, setOptionalColumns, setCustomColumn, removeCustomColumn, addColumnsData, sendEditColumnData}) => {
    const [customColumnModal, setCustomColumnModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editColumnData, setEditColumnModeData] = useState({});

    const iconType = (type) => {
        switch (type) {
            case "text":
                return "text_column";
            case "date":
                return "date_column";
            case "number":
                return "numeric_column";
            case "price":
                return "monetary_column";
            case "dimension":
                return "dimension_icon";
            case "toggle":
                return "toggle_column";
            case "single_select":
                return "single_select_column";
            case "multi_select":
                return "multi_select_column";
            default:
                break;
        }
    }

    const handleDeleteCustomColumn = (e, columnData) => {
        e.stopPropagation();
        removeCustomColumn(columnData);
    }

    const handleClickDefaultCol = (columnData) => {
        setOptionalColumns({name: columnData.name, isShown: !optionalColumns[columnData.index]?.isShown});
    }

    const handleClickCustomCol = (columnData) => {
        setIsEditMode(true);
        setEditColumnModeData(columnData);
        setCustomColumnModal(!customColumnModal);
    }

    const handleToggleDefaultCol = (columnData) => {
        setOptionalColumns({name: columnData.name, isShown: !optionalColumns[columnData.index]?.isShown});
    }

    const handleToggleCustomCol = (columnData) => {
        setOptionalColumns({name: columnData.name.en, isShown: !optionalColumns[columnData.index]?.isShown, isCustom: true});
    }

    return(
        <div className="editColumns__container">
            <div className="editColumns__header">
                <div className="editColumns__title">Edit columns</div>
                <div className="editColumns__subtitle">Add, hide and customize columns</div>
            </div>
            <div className="editColumns__divider" />
            <div className="editColumns__list">
                {optionalColumns.map((col, index) =>
                    <div key={index} className="editColumns__item" onClick={() => !col.isCustom ? handleClickDefaultCol({...col, index: index}) : handleClickCustomCol(col)}>
                        <div className="editColumns__item-name-container">
                            <SvgIcons type="cabana_grip" />
                            <SvgIcons type={iconType(col.type)} />
                            <div>{col.isCustom ? col.name.en : col.name} {col.isCustom ? <span className="editColumns__item-custom-tag" >Custom</span> : ''}</div>
                        </div>
                        <ToggleButton value={col.isShown} 
                            setValue={() => !col.isCustom ? handleToggleDefaultCol({...col, index: index}) : handleToggleCustomCol({...col, index: index})}
                        />
                    </div>
                )}
            </div>
            <div className="editColumns__divider" />
            <Button type="secondary" text="Add column" size="small" onClick={() => {setIsEditMode(false); setCustomColumnModal(true)}}>
                <SvgIcons type="cabana_plus_circle_full" />
            </Button>
            <Modal isOpen={customColumnModal} setIsOpen={setCustomColumnModal} options={{contentCenter: true, disableBackdropClick: false}}>
                <CustomColumnForm setCustomColumn={setCustomColumn} 
                    setCustomColumnModal={setCustomColumnModal} 
                    addColumnsData={addColumnsData}
                    optionalColumns={optionalColumns}
                    editMode={isEditMode}
                    editColumnData={editColumnData}
                    setEditMode={setIsEditMode}
                    sendEditColumnData={sendEditColumnData}
                    removeCustomColumn={removeCustomColumn}/>
            </Modal>
        </div>
    )
}

export default EditColumnsMenu;