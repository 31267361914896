import React from 'react';
import StepProgressBar from 'src/components/productForm/stepsBanner/stepProgressBar';
import './inCompleteForm.scss';

const InCompleteForm = ({completedFields, totalFields}) => {
    return (
        <div className="inCompleteForm__container">
            <div className="inCompleteForm__progress-bar">
                <StepProgressBar 
                    completedFields={completedFields} 
                    totalFields={totalFields}
                    options={{circleWidth: '160px'}}
                />
                <div className="inCompleteForm__progress-text">Almost finished!</div>
            </div>
            <div className="inCompleteForm__fields-text-container">You still have a few fields to complete before submitting your VSO.</div>
        </div>
    )
}

export default InCompleteForm;