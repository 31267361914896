require('dotenv').config();

export const fetchVSOs = async () => {
    try{
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/user/' + localStorage.userId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            });
        return response.json();
    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const fetchVso = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/' + vsoId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const fetchVsoFields = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/' + vsoId + '/fields',
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const fetchVsoReservations = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'reservations/vso/' + vsoId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
            
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const fetchVsoLeads = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'visitors/' + vsoId,
            {
                method: 'GET'
            });
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const changeStatus = async (vsoId, status) => {
    try {
        let body = {
            vsoId: vsoId,
            status: status
        }
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/status/',
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        )
        return response.json();
    } catch (err) {
        console.error(err.message)
        return err
    }
}

export const deleteVso = async (vsoId) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'virtualSalesOffices/' + vsoId,
            {
                method: 'DELETE',
                headers: {'token' : localStorage.token}
            });
        
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}

export const fetchUnits = async (projectId) => {
    try {
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/project/' + projectId,
            {
                method: 'GET',
                headers: {'token': localStorage.token}
            });
        return response.json();
    } catch (err) {
        console.log(err.message);
        return err;
    }
}

export const putUnitValue = async (_, {value: {unitId, valueKey, newValue}}) => {
    try{
        const body = {
            [valueKey]: newValue
        };

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/' + unitId,
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const deleteUnit = async (unitId) => {
    try{
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/' + unitId,
            {
                method: 'DELETE',
                headers: {
                    'token' : localStorage.token
                },
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const putUnitsArray = async (_, {value: units}) => {
    try{
        const body = {units: units};

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/batch/' + 1,
            {
                method: 'PUT',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const createUnit = async (projectId, unitNumber) => {
    try{
        const body = {
            project_id: projectId,
            unit_number: unitNumber
        };

        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/',
            {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
                body: JSON.stringify(body)
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}

export const createMultipleUnits = async (projectId, numOfUnits, selectedFloorList, selectedFloor) => {
    try {
        const body = {
            projectId: projectId,
            numOfUnits: numOfUnits,
            selectedFloorList: selectedFloorList,
            selectedFloor: selectedFloor
        }
        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'units/multiple/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.token
                },
                body: JSON.stringify(body)
            }
        )
        response.json()
    } catch (err) {
        console.error(err.message);
        return err
    }
}

export const putUnitPlan = async (unitId, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}units/plan/${unitId}`,
            {
                method: 'PUT',
                headers: {
                    'token': localStorage.token
                },
                body: formData
            }
        );
        return response.json();
    } catch(err) {
        console.error(err.message);
        return err   
    }
}

export const deleteUnitPlan = async (unitId) => {
    try{
        const response = await fetch (
            process.env.REACT_APP_SERVER_URL + 'units/plan/' + unitId,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type' : 'application/json',
                    'token' : localStorage.token
                },
            });
        return response.json();

    } catch (err){
        console.log(err.message);
        return err;
    }
}