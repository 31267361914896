import { derived } from 'overmind';

export const state = {
    isLoading: true,
    list: {},
    selectedVSOid: null,
    selectedFloor: 1,
    selectedVSO: derived((state) => {
        if(state.selectedVSOid === null) return null;
        return {
            ...state.list[state.selectedVSOid],
            get availableUnits() {
                if(Array.isArray(state.selectedVSO.units)) {
                    return state.selectedVSO.units.filter(u => u.status === 'available').length.toString();
                } else {
                    return 0;
                }
            },
        }
    }),
    selectedVSOsortedFloorList: derived((state) => {
        if(!Array.isArray(state.list[state.selectedVSOid]?.units)) return [];
        return state.list[state.selectedVSOid].units.filter(unit => unit.unit_number.toString().length < 4 ? unit.unit_number.toString().slice(0, 1) == state.selectedFloor : unit.unit_number.toString().slice(0, 2) == state.selectedFloor)
        .sort((unitA, unitB) => {
            if(unitA.unit_number > unitB.unit_number) {
                return 1;
            } else if(unitA.unit_number < unitB.unit_number) {
                return -1;
            }
            return 0;
        })
    }),
    selectedVSOreservations: derived((state) => {
        if(!Array.isArray(state.list[state.selectedVSOid]?.reservations)) return [];
        return state.list[state.selectedVSOid]?.reservations;
    }),
    selectedVSOsortedUnits: derived((state) => {
        if(!Array.isArray(state.list[state.selectedVSOid]?.units)) return [];
        let sortedUnits = [...state.list[state.selectedVSOid]?.units]
        return sortedUnits.sort((unitA, unitB) => {
            if(unitA.unit_number > unitB.unit_number) {
                return 1;
            } else if(unitA.unit_number < unitB.unit_number) {
                return -1;
            }
            return 0;
        })
    }),
    leads: []
};