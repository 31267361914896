import React, {useState, useEffect} from "react";
import "./radioInput.scss";
import { useTranslation } from "react-i18next";

const RadioInput = ({value, setValue, dataObj, options, radioOptions, translationsNamespace}) => {
    const [fieldId, setFieldId] = useState('');
    const { t } = useTranslation(translationsNamespace);

    useEffect(() => {
        let kebabCase = require('lodash.kebabcase');
        setFieldId(kebabCase(`rti-${Math.floor(Math.random() * Date.now())}`));
    }, []);

    const handleValueChange = (e) => {
        dataObj.newValue = e.currentTarget.getAttribute('data-value');
        setValue(dataObj);
    }

    return(
        <div id={`input__${fieldId}`} className="tableInput__radio">
            {radioOptions.map((o, index) => {
                return(
                <div key={`option__${fieldId}-${index}`} data-value={o} onClick={handleValueChange} className={`${value === o ? '--selected' : ''}`}>{dataObj.isCustom ? o : t(`selectOptions.${o}`)}</div>
                )
            })}
        </div>
    )
}

export default RadioInput;