import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import UnitsTable from 'src/components/shared/unitsTable';
import FloorsPagination from 'src/components/shared/floorsPagination';
import { useOvermind } from 'src/overmind';
import { useLocation, useHistory } from 'react-router-dom';
import Tooltip from 'src/components/shared/tooltip'; 

import './unitsPlans.scss';
import infiniteLogo from 'src/assets/images/infiniteLogo.svg';
import FilterTable from 'src/components/shared/filterTable';
import SvgIcons from 'src/components/shared/svgIcons';
import PlanView from './planView';
import Button from 'src/components/shared/button';
import Modal from 'src/components/shared/modal';
import PlanDetailViewer from './planView/planDetailViewer';
import HeaderBanner from 'src/components/shared/headerBanner';
import BuildingView from './buildingView';

const UnitsPlans = () => {
    const { state, actions } = useOvermind();
    const { t, i18n } = useTranslation();

    let location = useLocation();
    const history = useHistory();
    const [isBottomLogoHovered, setIsBottomLogoHovered] = useState(false);
    const [expandPlan, setExpandPlan] = useState(false);
    const [unitInfo, setUnitInfo] = useState();
    const [filteredUnits, setFilteredUnits] = useState([]);
    const [viewMode, setViewMode] = useState("table_view");
    const [detailViewerHeight, setDetailViewerHeight] = useState('');
    const headerBannerRef = useRef(null);
    
    const columns = useMemo(() => [
        {accessor: 'unit_number', Header: t('unitTable.unit'), minWidth: 80},
        {accessor: 'bedroom', Header: t('unitTable.bedroom'), minWidth: 96},
        {accessor: 'bathroom', Header: t('unitTable.bathroom')},
        {accessor: 'sqft', Header: <div className="columnHeader__sqft">{t('unitTable.sqft')} <strong>(net)</strong></div>, minWidth: 96},
        ... state.currentVSO?.optionalColumns[state.currentVSO?.optionalColumns?.findIndex((el) => el.name === "orientation")]?.isShown ? [{accessor: 'orientation', Header: t('unitTable.orientation'), minWidth: 104}] : [],
        ... state.currentVSO?.optionalColumns[state.currentVSO?.optionalColumns?.findIndex((el) => el.name === "position")]?.isShown ? [{accessor: 'position', Header: t('unitTable.position'), minWidth: 104}] : [],
        ... state.currentVSO?.optionalColumns[state.currentVSO?.optionalColumns?.findIndex((el) => el.name === "view")]?.isShown ? [{accessor: 'view', Header: t('unitTable.view'), minWidth: 104}] : [],
        ... state.currentVSO?.optionalColumns[state.currentVSO?.optionalColumns?.findIndex((el) => el.name === "parking")]?.isShown ? [{accessor: 'parking', Header: t('unitTable.parking'), minWidth: 104}] : [],
        ... state.currentVSO?.optionalColumns[state.currentVSO?.optionalColumns?.findIndex((el) => el.name === "locker")]?.isShown ? [{accessor: 'locker', Header: t('unitTable.locker'), minWidth: 104}] : [],
        {accessor: 'price', Header: <div className="columnHeader__price">{t('unitTable.price')} <strong>(tx incl.)</strong></div>, minWidth: 104},
        {accessor: 'plan', Header: t('unitTable.plan'), minWidth: 104},
        ... state.currentVSO?.optionalColumns.filter((element) => element.isCustom === true).length > 0 ?
            state.currentVSO?.optionalColumns.filter((element) => element.isCustom === true && element.isShown)
                                                         .map((element) => (
                                                             element.type === "text" || element.type === "date" || element.type ==="number" || element.type === "price" || element.type === "dimension" ?
                                                             {accessor: `extraContent_${element.key}`, Header: element.name.en, editData: {isCustom: element.isCustom}, minWidth: element.type === "date" ? 160 : 132, dimensionType: element.type === "dimension" ? element.dimensionType : ""}
                                                             :
                                                             {accessor: `extraContent_${element.key}`, Header: element.name.en, minWidth: 132, editData: {isCustom: element.isCustom}}
                                                                )) : [],
        {accessor: 'status', Header: t('unitTable.status'), minWidth: 120},
    ], [i18n.language]);

    useEffect(() => {
        actions.topbar.setCurrentPage('plans');

        return () => {
            actions.topbar.setCurrentPage('');
        }
    }, [])

    useEffect(() => {
        if(headerBannerRef.current) {
            setDetailViewerHeight(`calc(100vh - 64px - ${headerBannerRef.current.offsetHeight}px)`);
        }
    });

    const handleUnitClick = (unitId) => {
        if(state.currentVSO.units[state.currentVSO.units.findIndex(u => u.unit_id == unitId)].status == 'available') {
            let unitIndex = state.currentVSO.units.findIndex(u => u.unit_id == unitId);
            setExpandPlan(true);
            setUnitInfo(state.currentVSO.units[unitIndex]);
        }
        // if(state.currentVSO.enable_reservations) {
        //     if(state.currentVSO.units[state.currentVSO.units.findIndex(u => u.unit_id == unitId)].status == 'available') {            
        //         let pathWithId = location.pathname.match(/(\/virtual-sales-office\/\d+)/)[0];        
        //         history.push(pathWithId + '/reservation');
        //         actions.currentVSO.setReservationUnit(unitId);
        //         actions.currentVSO.setReservationStep(1);
        //     }
        // } else {
        //     // Open message box ?
        // }
    }

    const handleHoverInBottomLogo = () => {
        setIsBottomLogoHovered(true);
    }

    const handleHoverOutBottomLogo = () => {
        setIsBottomLogoHovered(false);
    }

    return (
        <>
        <div className="unitsPlans__container">
            <div className="unitsPlans__header-container">
                <div className="unitsPlans__availability-and-buttons">
                    <div className="unitsPlans__units-available">
                        {t('unitTable.unit') + " & " + t('unitTable.plan')} — <strong>{filteredUnits.length > 0 ? filteredUnits.filter(u => u.status === 'available').length.toString() : state.currentVSO.availableUnits} {t('salesOffice.unitsPage.available')}</strong>
                    </div>
                    <div className="unitsPlans__view-buttons">
                        <Button type="pill" 
                            text={t('salesOffice.unitsPage.tableView')}
                            onClick={() => { actions.currentVSO.setCurrentFloor(''); setViewMode('table_view')}} 
                            options={{activeState: viewMode === "table_view"}}><SvgIcons type="cabana_table_view_icon"/></Button>
                        <Button type="pill" 
                            text={t('salesOffice.unitsPage.planView')}
                            onClick={() => { actions.currentVSO.setCurrentFloor(''); setViewMode('plan_view')}} 
                            options={{activeState: viewMode === "plan_view"}}><SvgIcons type="cabana_plan_view_icon"/></Button>
                        <Button type="pill" 
                            text={t('salesOffice.unitsPage.buildingView')}
                            onClick={() => { actions.currentVSO.setCurrentFloor(1); setViewMode('building_view')}} 
                            options={{activeState: viewMode === "building_view"}}><SvgIcons type="cabana_building_view_icon"/></Button>
                    </div>
                </div>
                <div className="unitsPlans__info-text">{t('salesOffice.unitsPage.exploreUnit')}</div>
            </div>
            {viewMode !== 'building_view' &&
                <div className="unitsPlans__filterTable-wrapper">
                    <FilterTable 
                        sortedActiveFloorList={state.currentVSO.currentFloor === "" ? state.currentVSO.allSortedUnits : state.currentVSO.sortedFloorUnitsList}
                        filteredUnits={filteredUnits}
                        setFilteredUnits={setFilteredUnits}
                        numberOfFloors={state.currentVSO.fields.num_floors}
                        setCurrentFloor={actions.currentVSO.setCurrentFloor}
                        currentFloor={state.currentVSO.currentFloor}
                        optionalColumn={state.currentVSO.optionalColumns}
                        units={state.currentVSO.units}
                    />
                </div> 
            }
            { viewMode === "table_view" && 
                <UnitsTable 
                    headings={columns} 
                    units={filteredUnits}
                    floorHasUnits={state.currentVSO.sortedFloorUnitsList.length > 0 ? true : false} 
                    currentFloor={state.currentVSO.currentFloor} 
                    projectType={state.currentVSO.fields.project_type} 
                    isEditMode={false} 
                    options={{maxHeight: '400px'}}
                    handleRowClick={handleUnitClick}
                    showPrices={state.currentVSO.show_prices}
                />
            }
            {
                viewMode === "plan_view" && 
                <PlanView 
                  units={filteredUnits.filter(u => u.status === 'available')}
                  projectType={state.currentVSO.fields?.project_type}
                  reservationClick={handleUnitClick}
                  moveDate={state.currentVSO.fields?.est_delivery_date}
                />
            }
            {
                viewMode === "building_view" &&
                <BuildingView />
            }
            {/* <FloorsPagination currentFloor={state.currentVSO.currentFloor} setCurrentFloor={actions.currentVSO.setCurrentFloor} numberOfFloors={state.currentVSO.fields.num_floors}/>         */}
            {/* <div className="sales-office__bottom-logo-container">
                <div className="sales-office__bottom-logo-wrapper" onMouseEnter={handleHoverInBottomLogo} onMouseLeave={handleHoverOutBottomLogo}>
                    <Tooltip text={`This Virtual Sales Office is powered by Smooth ✨`} isOpen={isBottomLogoHovered} options={{maxWidth: 'initial'}}/> 
                    <img className="sales-office__bottom-logo" src={infiniteLogo} />
                </div>
            </div> */}
        </div>
        <Modal setIsOpen={setExpandPlan} isOpen={expandPlan}>
            <div className="planView__modal-view">
                <HeaderBanner options={{ref: headerBannerRef}} headerTitle={`${t('planViewCard.unit', {ns: 'planView'})} ${unitInfo?.unit_number}`} setIsOpen={() => setExpandPlan(false)}/>
                <PlanDetailViewer detailViewerHeight={detailViewerHeight} item={unitInfo} type={state.currentVSO.fields.project_type} moveDate={state.currentVSO.fields?.est_delivery_date} reservationClick={() => null}/>
            </div>
        </Modal>
        </>
    );
}

export default UnitsPlans;