require('dotenv').config();

export const verifyToken = async () => {
    const response = await fetch(
        process.env.REACT_APP_SERVER_URL + 'auth/verify',
        {
          method: 'GET',
          headers: {'token' : localStorage.token},
      });

      const parseRes = await response.json();

      if(parseRes.isVerified === true) {
        localStorage.setItem('userId', parseRes.userId);
        localStorage.setItem('isAuth', true);
        return true;
      }
      else {
        localStorage.setItem('isAuth', false);
        return false;
      }
}

export const fetchCurrentUser = async () => {
    try {

        const response = await fetch(
            process.env.REACT_APP_SERVER_URL + 'users/' + localStorage.userId,
            {
                method: 'GET',
                headers: {'token' : localStorage.token}
            });
    
        return response.json();
    } catch (err) {
        console.error(err.message);
        return err;
    }
}